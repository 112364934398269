/// <reference path="../../../_all.ts"/>
/// <reference path="../../../shared/models/open-bankiing-widget/WidgetAccount.ts" />

module Shared {

    interface IBrooksonWidgetsOpenBankingBindings {
        onComplete: Array<any>;
    }

    export class BrooksonWidgetsOpenBanking implements ng.IComponentOptions {
        public controller: Function = BrooksonWidgetsOpenBankingController;
        public templateUrl: string = "src/app/shared/views/widgets/brookson.widgets.open-banking.html";
        public bindings: { [binding: string]: string } = {
            onComplete: '&'
        };
    }

    class BrooksonWidgetsOpenBankingController implements IBrooksonWidgetsOpenBankingBindings {

        public error: boolean;
        public onComplete;
        public widgetName: string = "brookson-widgets-open-banking";
        public showSpinner: boolean;
        public hasCsvUpload: boolean;
        public bankAccountList: Array<Shared.openbankingWidget.WidgetAccount>;
        static $inject = ['$q', '$timeout', 'openbankingWidgetService', '$window', '$scope', 'brookson.file.download', 'memberSrv', 'growl', 'dashboardService', 'localStorageService', '$state'];
        public disableButtons: boolean = false;

        constructor(
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private openbankingWidgetService: Shared.OpenbankingWidgetService,
            public $window: ng.IWindowService,
            public $scope: ng.IScope,
            private fileDownloadSrv: any,
            private memberSrv: Shared.IMemberService,
            private growl: ng.growl.IGrowlService,
            private dashboardService: Shared.IDashboardService,
            public localStorageService: ng.local.storage.ILocalStorageService,
            private $state: ng.ui.IStateService
        ) { }

        $onInit = (): void => {
            this.showSpinner = true;
            this.$q.all([
                this.openbankingWidgetService.getOpenBankingWidgetData(true)
            ]).then((data) => {
                this.bankAccountList = data[0];
                this.setupAccounts(this.bankAccountList);
                this.showSpinner = false;
            }, () => {
                    this.error = true;
            }).then(() => {
                this.showSpinner = false;
                this.$timeout(() => { this.onComplete(); });
                this.$timeout(() => { this.onComplete(); }, 5000);
            });

            this.$scope.$on("OPENBANKING_WIDGET_POPUP_WINDOW_EVENT_CHANGE", this.trueLayerPopupWindowEventChange);
            this.$scope.$on("OPENBANKING_WIDGET_TOKEN_EXCHANGE_RESPONSE", this.WidgetTokenExchangeResponse);
        };

        private setupAccounts = (bankAccounts : Shared.openbankingWidget.WidgetAccount[]): void => {
            _.each(bankAccounts, (bankAccount): void => {
                bankAccount.type = Shared.openbankingWidget.bankType[bankAccount.type].replace('_', ' ');
                bankAccount.accountType = Shared.openbankingWidget.accountType[bankAccount.accountType];
                if (bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.AccessTokenReceived && !bankAccount.consentExpired) {
                    bankAccount.success = true;
                }

                if (bankAccount.viewAccess === false) {
                    bankAccount.className = 'redBox';
                } else {
                    if (bankAccount.viewAccessDescription.toLowerCase().indexOf('view access') > -1) {
                        bankAccount.className = 'orangeBox';
                    }
                    if (bankAccount.viewAccessDescription.toLowerCase().indexOf('open banking') > -1) {
                        bankAccount.className = 'greenBox';
                    }
                }

            });
            this.hasCsvUpload = bankAccounts.some(x => x.csvUploadEnabled);
        }

        public openTrueLayerBankPopup = (event, authLink: string) : void => {

            if (this.disableButtons) {
                event.preventDefault();
                return;
            }
            this.openbankingWidgetService.disableWidgetButtons(true);
            var title = "Open Banking";
            var w = 814;
            var h = 778;

            var dualScreenLeft = this.$window.screenLeft;
            var dualScreenTop = this.$window.screenTop;

            var width = this.$window.innerWidth ? this.$window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : this.$window.screen.width;
            var height = this.$window.innerHeight ? this.$window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : this.$window.screen.height;

            var left = dualScreenLeft < 0 ? ((width / 2) - (w / 2)) - dualScreenLeft : ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = dualScreenTop < 0 ? ((height / 2) - (h / 2)) - dualScreenTop : ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = this.$window.open(authLink, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            var timer = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(timer);
                    this.openbankingWidgetService.disableWidgetButtons(false);
                }
            }, 1000);

            if (this.$window.focus) {
                newWindow.focus();
            }
        }

        public openExendingConnection = (event, bankaccount: Shared.openbankingWidget.WidgetAccount): void => {
            if (this.disableButtons) {
                event.preventDefault();
                return;
            }
            this.localStorageService.set("OpenBankingReconsent", bankaccount.key);
            this.openbankingWidgetService.disableWidgetButtons(true);
            var title = "Open Banking - Reconfirm Consent";
            var w = 814;
            var h = 778;
            var dualScreenLeft = this.$window.screenLeft;
            var dualScreenTop = this.$window.screenTop;

            var width = this.$window.innerWidth ? this.$window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : this.$window.screen.width;
            var height = this.$window.innerHeight ? this.$window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : this.$window.screen.height;

            var left = dualScreenLeft < 0 ? ((width / 2) - (w / 2)) - dualScreenLeft : ((width / 2) - (w / 2)) + dualScreenLeft;
            var top = dualScreenTop < 0 ? ((height / 2) - (h / 2)) - dualScreenTop : ((height / 2) - (h / 2)) + dualScreenTop;
            var newWindow = this.$window.open(bankaccount.authLink, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

            var timer = setInterval(() => {
                if (newWindow.closed) {
                    clearInterval(timer);
                    this.openbankingWidgetService.disableWidgetButtons(false);
                }
            }, 1000);

            if (this.$window.focus) {
                newWindow.focus();
            }
        }

        public trueLayerPopupWindowEventChange = (event, notification): void => {
            this.disableButtons = Boolean(notification.DisableButtons);
        }



        viewBankFileUpload = (expense: Shared.ExpenseHeader): void => {
            this.$state.go("brookson.bank-file-upload");
        }

        public WidgetTokenExchangeResponse = (event, notification): void => {
            let bankAccount = _.find(this.bankAccountList, (bankAccount: Shared.openbankingWidget.WidgetAccount) => {
                return bankAccount.key === notification.State;
            });
            if (bankAccount !== undefined) {
                if (Number(notification.OpenBankingStatus) === Shared.openbankingWidget.ConsentStatus.AccessTokenReceived &&
                    bankAccount.consentStatus !== Shared.openbankingWidget.ConsentStatus.AccessTokenReceived
                ) {
                    // The following timeout functions are to render a smooth transition when the widget messages are changed.
                    this.$timeout(() => {
                        bankAccount.pendingStatus = false;
                        bankAccount.consentStatus = Number(notification.OpenBankingStatus);
                        bankAccount.consentDateDue = notification.ReConsentDate;
                        bankAccount.consentExpired = false;
                        bankAccount.consentReminder = false;
                        bankAccount.hasAccessToken = true;
                        if (!bankAccount.success) {
                            this.$timeout(() => {
                                if (bankAccount.viewAccessDescription !== 'Open Banking Access' || bankAccount.className !== 'greenBox') {
                                    bankAccount.viewAccessDescription = 'Open Banking Access';
                                    bankAccount.className = 'greenBox';
                                }
                                bankAccount.success = true;
                                this.$timeout(() => { this.onComplete(); }, 500);
                            }, 1000);
                        }
                    }, 4000);
                }

                if (Number(notification.OpenBankingStatus) === Shared.openbankingWidget.ConsentStatus.AccessTokenError) {
                    this.$timeout(() => {
                        bankAccount.pendingStatus = false;
                        bankAccount.hasAccessToken = false;
                        bankAccount.consentExpired = false;
                        if (!bankAccount.success) {
                            this.$timeout(() => {
                                bankAccount.consentStatus = Number(notification.OpenBankingStatus);
                                this.$timeout(() => { this.onComplete(); }, 500);
                            }, 1000);
                        }
                    }, 4000);
                 
                }

                if (Number(notification.OpenBankingStatus) === Shared.openbankingWidget.ConsentStatus.AccessTokenPending) {
                    this.$timeout(() => {
                        bankAccount.consentStatus = Number(notification.OpenBankingStatus);
                        if (!bankAccount.success) {
                            this.$timeout(() => {
                                bankAccount.pendingStatus = true;
                                this.$timeout(() => { this.onComplete(); }, 1000);
                            }, 1000);
                        }
                    }, 1000);
                }

                if (Number(notification.OpenBankingStatus) === Shared.openbankingWidget.ConsentStatus.NotConsented) {
                    this.$timeout(() => {
                        bankAccount.consentStatus = Number(notification.OpenBankingStatus);
                        if (!bankAccount.success) {
                            this.$timeout(() => {
                                bankAccount.consentExpired = false;
                                bankAccount.pendingStatus = false;
                                bankAccount.hasAccessToken = false;
                                bankAccount.consentStatus = Shared.openbankingWidget.ConsentStatus.NotConsented;
                                bankAccount.viewAccessDescription = 'View Access';
                                bankAccount.className = 'orangeBox';
                                this.$timeout(() => { this.onComplete(); }, 1000);
                            }, 1000);
                        }
                    }, 1000);
                }
            }
        }

        public switchToOpenBanking = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            if (!bankAccount.authorisedProvider) return false;
            if (bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.NotConsented ||
                bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.AccessTokenError ||
                bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.ConsentError && !bankAccount.hasAccessToken) {
                return true;
            }
            return false;
        }

        public reconsentOpenBanking = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            if (!bankAccount.authorisedProvider) return false;
            if (bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.ConsentError && bankAccount.hasAccessToken ||
                (bankAccount.consentExpired && bankAccount.consentStatus !== Shared.openbankingWidget.ConsentStatus.AccessTokenPending)
            ) {
                return true;
            }
            return false;
        }

        public maskedBankAccountNumber = (bankAccount: Shared.openbankingWidget.WidgetAccount): string => {
            return '****' + bankAccount.accountNumber.substr(bankAccount.accountNumber.length - 4);
        }

        public showReconsentMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.ConsentError && bankAccount.hasAccessToken ||
                (bankAccount.consentExpired && bankAccount.consentStatus !== Shared.openbankingWidget.ConsentStatus.AccessTokenPending);
        }

        public showTechIssueMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.authorisedProvider && bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.ConsentError
                && !bankAccount.hasAccessToken;
        }

        public showAccessTokenErrorMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.authorisedProvider && (!bankAccount.success && bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.AccessTokenError);
        }

        public showNotConsentMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.authorisedProvider && bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.NotConsented;
        }

        public showAccessTokenPendingMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.authorisedProvider && bankAccount.pendingStatus && bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.AccessTokenPending;
        }

        public showReconsentDueMessage = (bankAccount: Shared.openbankingWidget.WidgetAccount): boolean => {
            return bankAccount.authorisedProvider && bankAccount.hasAccessToken
                && bankAccount.consentStatus !== Shared.openbankingWidget.ConsentStatus.AccessTokenPending
                && bankAccount.success && moment(bankAccount.consentDateDue).isAfter(moment());
        }

        public downloadMandateForm = (bankName: string) => {
            if (bankName.toLowerCase() === 'metro') { bankName = 'Metro Bank'; }
            if (bankName.toLowerCase() === 'cashplus' || bankName.toLowerCase() ===  'Cashplus') { bankName = 'Zempler'; }
            
            var url = "api/BankLink/DownloadMandateForm?id=" + bankName;
            var expectedMediaType = "application/octet-stream";
            var memberId = this.memberSrv.getMember().memberId;

            this.fileDownloadSrv.downloadAndSave(url, null, 'Mandate - ' + bankName + ' - ' + memberId + '.pdf', expectedMediaType);
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetsOpenBanking", new Shared.BrooksonWidgetsOpenBanking());