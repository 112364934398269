/// <reference path="./InvoicingAssignmentRate.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class EmployeeRate  {
        
        // CONTRACTRATEID
        public contractRateID: number = 0;
        // EMPLOYEEID
        public employeeID: number = 0;
        // RATEDESCRIPTION
        public rateDescription: string = null;
        // RATE
        public rate: number = 0;
        // STAFFID
        public staffID: string = null;
        // STAFFIDM
        public staffIDM: string = null;
        // CREATEDDATETIME
        public createdDateTime: Date = null;
        // YEARKEY
        public yearKey: number = 0;
        // ISDAYRATE
        public isDayRate: boolean = false;
        // PAYMENTCODE
        public paymentCode: string = null;
        // RATEPERIOD
        public ratePeriod: RatePeriods = null;
        // STARTDATE
        public startDate: Date = null;
        // ENDDATE
        public endDate: Date = null;
        // CURRENCY
        public currency: string = null;
    }
}
