module Shared {
    export class SdcQuestionnaireInputForm {
        sdcWillIncurExpenses: boolean;
        sdcIntendMultipleAssignments: boolean;
        sdcDoesClientSupervise: boolean;
        sdcDoesClientInstruct: boolean;
        sdcCannotOverruleImplementation: boolean;
        sdcCannotOverrulePlan: boolean;
        sdcInPermanentTeam: boolean;
        sdcStatus: SdcOutcomeType;
    }
}