/// <reference path="../../../_all.ts"/>

module Shared {
    'use strict';

    export class AllocationConfirmationController {
        static $inject = ['$uibModalInstance', 'bankLinkFactory', 'bankLink.enums'];

        constructor(
            private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            public data: Shared.IBankLinkFactory,
            public bankLinkEnums: Shared.IBankLinkEnums
        ) {
            // Loop through all the transactions, split out this.data.modifiedTransactions to 'confirmed' and 'warning', add totals up for confirmed
            this.splitTransactionsAndTotalUp();
        }

        private splitTransactionsAndTotalUp = () => {

            _.each(this.data.modifiedTransactions, (o) => {
                if (!this.data.selectedAssignment || !this.data.selectedAssignment.dateStarted) {
                    o.passes24MonthRule = true;
                } else {
                    o.passes24MonthRule = !this.data.check24MonthRuleApplicable(o);
                }
            });

            this.data.confirmedTransactions = _.filter(this.data.modifiedTransactions, (o) => o.passes24MonthRule);
            this.data.warningTransactions = _.filter(this.data.modifiedTransactions, (o) => !o.passes24MonthRule);

            let runningTotal = 0;

            _.each(this.data.confirmedTransactions, (o) => {
                runningTotal += o.debitAmount;
            });

            this.data.confirmedTransactionsTotal = runningTotal;
        }

        public cancel = () => {
            this.$uibModalInstance.close();
        }
    }
}

angular
    .module('app.shared')
    .controller('bankAllocationConfirmationCtrl', Shared.AllocationConfirmationController);