/// <reference path="../../../_all.ts"/>
/// <reference path="../../models/auto-allocation/AutoAllocationItem.ts"/>
/// <reference path="../../services/brookson.services.auto-allocation.ts"/>

module Shared {

    export class BrooksonBankLinkAutoAllocationSettings implements ng.IComponentOptions {
        controller: Function = BrooksonBankLinkAutoAllocationSettingsController;
        templateUrl = "src/app/shared/views/bank/brookson.banklink.auto.allocation.settings.html";
    }

    class BrooksonBankLinkAutoAllocationSettingsController {
        static $inject = ["$window", "autoAllocationService", "bankLinkSrv", "serviceLineSrv", "brookson.utilities.date", "moneyManagerSrv", "$sanitize"];

        loading: boolean;
        companyConfigList: Array<AutoAllocation.IAutoAllocationItem>;
        showDisabled: boolean;
        allowAddNewSetting: boolean;
        autoAllocationFrm;
        isVatReclaimable: boolean;

        constructor(
            private window,
            private autoAllocationService: Shared.IAutoAllocationService,
            private bankLinkService: Shared.IBankLinkSrv,
            private serviceLineSrv: Shared.ServiceLineService,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            private moneyManagerSrv: Shared.IMoneyManagerService,
            private $sanitize: any
        ) {
            this.showDisabled = false;
            this.loading = true;
        }

        $onInit = (): void => {
            this.bankLinkService.getCategories().then((categories) => {
                this.autoAllocationService.categoryList = this.getAllowedCategories(categories);
                this.serviceLineSrv.getBusinessDetailSummary().then((businessSummary: BusinessDetailSummary) => {
                    this.isVatReclaimable = businessSummary.getEffectiveVatSchemeOn(this.dateUtils.convertToJsDate(moment())) === VatScheme.nonFlatRate;
                    this.loadAutoAllocationSettings()
                        .then(() => {
                            this.moneyManagerSrv.getTransactions().then((data: any) => {
                                this.autoAllocationService.transactions = data.transactions;
                                this.loading = false;
                            });
                        });
                });
            });
        }       

        private getAllowedCategories = (categories: Array<any>): Array<any> => {
            _.remove(categories, (category: any) => {
                if (category.code === "DLA" || category.code === "SALARY") {
                    return true;
                }
            });

            _.forEach(categories, category => {
                category.transactionTypeLabel = category.transactionType === 0 ? "Personal" : "Business";
                if (category.code === "EXP0185") { category.description = "Subsistence"; } // Hack: don't want to change it properly in NAV so SW said to do it in the view
            });

            let nonSoleTraderCategories = _.filter(categories, (category: any) => {
                return !_.startsWith(category.code, "ST");
            });
            var categoriesWithNoCcDiv = _.filter(nonSoleTraderCategories, (category: any )=> { return category.code !== "CC DIV"; });
            var creditOrDebitCategories = _.filter(categoriesWithNoCcDiv, (category: any) => { return category.type === "Debit"; });
            return creditOrDebitCategories;
        }

        private loadAutoAllocationSettings = (): ng.IPromise<Array<AutoAllocation.IAutoAllocationItem>> => {
            return this.autoAllocationService.getAutoAllocationSettings().then((autoAllocationSettings: Array<AutoAllocation.IAutoAllocationItem>) => {
                this.autoAllocationService.companyConfig = autoAllocationSettings;
                this.showDisabledMatches();
                this.allowAddNewSetting = true;
                return autoAllocationSettings;
            });
        }

        showDisabledMatches = (): void => {
            this.companyConfigList = this.autoAllocationService.getSettingsList(this.showDisabled);
            this.autoAllocationFrm.$setPristine();
        }

        addNew = () => {
            this.companyConfigList.push(this.autoAllocationService.createNewAllocationSetting());
            this.allowAddNewSetting = false;
        }

        saveNewAutoSetting = (newSetting: AutoAllocation.IAutoAllocationItem) => {

            let uniqueKeywordCheck = _.find(this.autoAllocationService.companyConfig, (config) => {
                return config.keyword === newSetting.keyword;
            });

            if (!_.isUndefined(uniqueKeywordCheck)) {
                return;
            }

            if (!_.isUndefined(newSetting)) {
                newSetting.categoryCode = newSetting.navCategory.code;
                if (_.isUndefined(newSetting.reclaimVat)) newSetting.reclaimVat = false;
                this.autoAllocationService
                    .saveNewAutoAllocationSetting(newSetting as Shared.AutoAllocation.IAutoAllocationItem)
                    .then((status: boolean) => {
                        if (status) {
                            this.loadAutoAllocationSettings();
                        }
                    });
            }

            this.allowAddNewSetting = true;
        }

        cancelNewAutoSetting = () => {
            this.companyConfigList.pop();
            this.allowAddNewSetting = true;
        }

        disableSaveButton = (newSetting: AutoAllocation.IAutoAllocationItem): boolean => {
            if (_.isUndefined(newSetting) || _.isUndefined(newSetting.keyword)) return true;
            if (this.autoAllocationService.duplicateKeyword(newSetting)) {
                return true;
            }
            if (_.isUndefined(newSetting.navCategory)) return true;
            return false;
        }      

        keywordChanged = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {
            if (_.isUndefined(autoSetting)) return;
            if (_.isUndefined(autoSetting.keyword)) {
                this.autoAllocationService.resetTransactionMatches(autoSetting);
            }
            if (autoSetting.id === -1) {
                if (!this.autoAllocationService.duplicateKeyword(autoSetting)) {
                    this.autoAllocationService.searchTransactions(autoSetting);
                }
                return;
            }

            let originalValue = _.find(this.autoAllocationService.companyConfig, (setting: AutoAllocation.IAutoAllocationItem) => {
                return setting.id === autoSetting.id;
            });
            if (_.isUndefined(originalValue)) return;

            autoSetting.hasChanged = false;
            if (_.isUndefined(autoSetting.keyword)) {
                autoSetting.hasChanged = true;
                return;
            }

            if (autoSetting.keyword.toLowerCase() !== originalValue.keyword.toLowerCase()) {
                autoSetting.hasChanged = true;
                if (!this.autoAllocationService.duplicateKeyword(autoSetting)) {
                    this.autoAllocationService.searchTransactions(autoSetting);
                }
            }
        };

        finishedKeyword = (autoSetting: AutoAllocation.IAutoAllocationItem) => {
            if (_.isUndefined(autoSetting)) return;
            this.autoAllocationService.resetTransactionMatches(autoSetting);
        }

        settingChanged = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {
            if (_.isUndefined(autoSetting)) return;
            if (_.isUndefined(autoSetting.keyword)) return;
            let originalValue = _.find(this.autoAllocationService.companyConfig, (setting: AutoAllocation.IAutoAllocationItem) => {
                return setting.id === autoSetting.id;
            });

            if (_.isUndefined(originalValue)) { 
                if (!_.isUndefined(autoSetting.navCategory) && !autoSetting.navCategory.isVatable) autoSetting.reclaimVat = false;
                return;
            }
            
            autoSetting.hasChanged = false;
            if (autoSetting.keyword.toLowerCase() !== originalValue.keyword.toLowerCase() ||
                !_.isUndefined(autoSetting.navCategory) && autoSetting.navCategory.code !== originalValue.categoryCode ||
                autoSetting.reclaimVat !== originalValue.reclaimVat ||
                autoSetting.enabled !== originalValue.enabled) {
                autoSetting.hasChanged = true;
                if (!_.isUndefined(autoSetting.navCategory) && autoSetting.keyword.toLowerCase() !== originalValue.keyword.toLowerCase()) {
                    this.autoAllocationService.duplicateKeyword(autoSetting);
                }
                if (!autoSetting.navCategory.isVatable) {
                    autoSetting.reclaimVat = false;
                }
            }       
        };

        cancelUpdate = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {

            let originalValue = _.find(this.autoAllocationService.companyConfig, (setting: AutoAllocation.IAutoAllocationItem) => {
                return setting.id === autoSetting.id;
            });

            if (!_.isUndefined(originalValue)) {
                autoSetting.keyword = originalValue.keyword;
                autoSetting.navCategory = _.find(this.autoAllocationService.categoryList, (category) => {
                    if (!_.isUndefined(originalValue))
                    return category.code === originalValue.categoryCode;
                });
                autoSetting.reclaimVat = originalValue.reclaimVat;
                autoSetting.enabled = originalValue.enabled;
            }
            autoSetting.validationMessage = "";
            autoSetting.hasChanged = false;
        }

        updateAutoSetting = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {

            let createdBy = "Customer";
            if(!_.isNull(this.autoAllocationService.memberModel.isAdmin))
            {
                createdBy = "Superuser";
            } else if (!_.isNull(this.autoAllocationService.memberModel.navReferral)) 
            {
                createdBy = this.autoAllocationService.memberModel.navReferral;
            }

            autoSetting.updatedBy = createdBy;
            autoSetting.categoryCode = autoSetting.navCategory.code;
            this.autoAllocationService.updateAutoAllocationSetting(autoSetting).then(() => {
                let config = _.find(this.autoAllocationService.companyConfig, (config: AutoAllocation.IAutoAllocationItem) => {
                    return config.id === autoSetting.id;
                });

                config.keyword = autoSetting.keyword;
                config.categoryCode = autoSetting.categoryCode;
                config.reclaimVat = autoSetting.reclaimVat;
                config.enabled = autoSetting.enabled;
                config.hasChanged = false;

                if (!this.showDisabled && !config.enabled) {
                    _.remove(this.companyConfigList, c => c.id === config.id);
                }

                this.settingChanged(autoSetting);
            });
        };

        invokeBackButton = (): void => {
            this.window.history.back();
        }

    }

}

angular.module("app.shared")
    .component("brooksonAutoAllocationSettings", new Shared.BrooksonBankLinkAutoAllocationSettings());