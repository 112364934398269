module Shared {
    export function FilterBkShortDate(brooksonUtilitiesDate: Shared.IBrooksonUtilitiesDate) {
        return (input, format, defaultValue) => {
            if (!input) {
                return '';
            }

            var m = brooksonUtilitiesDate.convertToMoment(input);

            if (m.year() === 1 || isNaN(m.year()) || m.year() === 1753) {
                if (defaultValue) {
                    let convertToDate = brooksonUtilitiesDate.convertToMoment(defaultValue).format(format);

                    return convertToDate === 'Invalid date' ? defaultValue : convertToDate;
                } else {
                    return '';
                }
            }

            return m.format(format);
        };
    }
    FilterBkShortDate.$inject = ['brookson.utilities.date'];

    export function FilterBkRelativeDate(brooksonUtilitiesDate: Shared.IBrooksonUtilitiesDate) {
        return (input) => {
            if (!input) {
                return '';
            }

            var m = brooksonUtilitiesDate.convertToMoment(input);

            if (m.year() === 1 || isNaN(m.year()) || m.year() === 1753) {
                return '';
            }

            return m.from(moment());
        };
    }
    FilterBkRelativeDate.$inject = ['brookson.utilities.date'];
}

angular.module("app.shared")
    .filter("bkShortDate", Shared.FilterBkShortDate)
    .filter("bkRelativeDate", Shared.FilterBkRelativeDate);