/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .service('dialogueSrv', dialogueSrv);

    dialogueSrv.$inject = ['$http', '$q', 'shared.config', 'dialogue.enums', 'localStorageService', 'memberSrv'];

    function dialogueSrv($http, $q, sharedConfig, enums, localStorageService, memberSrv) {
        var member = memberSrv.getMember();
        var dialogueSrvFactory: any = {};
        var serviceBase = sharedConfig.baseApiUrl;
        var localStorageData = null;
        var localStorageDashboardData = null;


        // Get
        function hasExpensesNoticeBeenDisplayed() {
            if (getData().expensesNotice !== member.memberId) {
                return $http.get(serviceBase + 'api/Dialogue/HasExpensesNoticeBeenDisplayed', {
                    silentMode: true
                }).then((response) => {
                    if (response.data === true) {
                        localStorageData.expensesNotice = member.memberId;
                        setData();
                    }

                    return response.data;
                });
            } else {
                var deferred = $q.defer();
                deferred.resolve(true);
                return deferred.promise;
            }
        }

        // Set
        function setExpensesNoticeDisplayed() {
            return $http.get(serviceBase + 'api/Dialogue/SetExpensesNoticeDisplayed', {
                silentMode: true
            }).then((response) => {
                if (response.data === true) {
                    localStorageData.expensesNotice = member.memberId;
                    setData();
                }

                return response.data;
            });
        }


        // Get
        function hasFinancialReviewBannerHidden() {
            if (getDashboardData().hidemortgagereviewbanner !== member.memberId) {
                return $http.get(serviceBase + 'api/Dialogue/HasFinancialReviewBannerHidden', {
                    silentMode: true
                }).then((response) => {
                    if (response.data === true) {
                        localStorageDashboardData.hidemortgagereviewbanner = member.memberId;
                        setData();
                    }

                    return response.data;
                });
            } else {
                var deferred = $q.defer();
                deferred.resolve(true);
                return deferred.promise;
            }
        }

        // Set
        function setFinancialReviewBannerHidden() {
            return $http.get(serviceBase + 'api/Dialogue/SetFinancialReviewBannerHidden', {
                silentMode: true
            }).then((response) => {
                if (response.data === true) {
                    localStorageDashboardData.hidemortgagereviewbanner = member.memberId;
                    setData();
                }

                return response.data;
            });
        }
        
        // Set
        function setFinancialReviewBannerClosed() {
            localStorageDashboardData.hidemortgagereviewbanner = member.memberId;
                    setDashboardData();
        }
                
        // Set
        function setRecommendAFriendTileClosed() {
            localStorageDashboardData.recommendAFriendTile = member.memberId;
            setDashboardData();
}


        // Get
        function hasRecommendAFriendTileHidden() {
            if (getDashboardData().recommendAFriendTile !== member.memberId) {
                return $http.get(serviceBase + 'api/Dialogue/HasRecommendAFriendTileHidden', {
                    silentMode: true
                }).then((response) => {
                    if (response.data === true) {
                        localStorageDashboardData.recommendAFriendTile = member.memberId;
                        setDashboardData();
                    }

                    return response.data;
                });
            } else {
                var deferred = $q.defer();
                deferred.resolve(true);
                return deferred.promise;
            }
        }


        // Set
        function setRecommendAFriendTileHidden() {
            return $http.get(serviceBase + 'api/Dialogue/SetRecommendAFriendTileHidden', {
                silentMode: true
            }).then((response) => {
                if (response.data === true) {
                    localStorageDashboardData.recommendAFriendTile = member.memberId;
                    setDashboardData();
                }

                return response.data;
            });
        }


        // Local storage
        function setData() {
            localStorageService.set('brookson.dialogues', localStorageData);
        }

        function getData() {
            var data = localStorageService.get('brookson.dialogues');

            return data === null ? {} : data;
        }
        
        function setDashboardData() {
            localStorageService.set('brookson.dashboard', localStorageDashboardData);
        }

        function getDashboardData() {
            var data = localStorageService.get('brookson.dashboard');

            return data === null ? {} : data;
        }

        localStorageData = getData();
        localStorageDashboardData = getDashboardData();

        dialogueSrvFactory.hasExpensesNoticeBeenDisplayed = hasExpensesNoticeBeenDisplayed;
        dialogueSrvFactory.setExpensesNoticeDisplayed = setExpensesNoticeDisplayed;
        
        dialogueSrvFactory.hasFinancialReviewBannerHidden = hasFinancialReviewBannerHidden;
        dialogueSrvFactory.setFinancialReviewBannerHidden = setFinancialReviewBannerHidden;
        dialogueSrvFactory.setFinancialReviewBannerClosed = setFinancialReviewBannerClosed;
        dialogueSrvFactory.setRecommendAFriendTileClosed = setRecommendAFriendTileClosed;
        
        dialogueSrvFactory.hasRecommendAFriendTileHidden = hasRecommendAFriendTileHidden;
        dialogueSrvFactory.setRecommendAFriendTileHidden = setRecommendAFriendTileHidden;
        return dialogueSrvFactory;
    }
})();
