/// <reference path="./CoreExpenseItem.ts" />
/// <reference path="./AppliedRate.ts" />
/// <reference path="./ExpenseMileageVehicle.ts" />

module Shared {
    export class MileageExpense extends CoreExpenseItem {
        miles: number;
        appliedRates: Array<Shared.AppliedRate>;
        workSite: string;
    }
}