module Shared {
    export enum VatProdPostingGroup
    {
        // ""
        Blank = 0,
        // "EXEMPT"
        EXEMPT = 1,
        // "VAT10"
        VAT10 = 2,
        // "VAT17"
        VAT17 = 3,
        // "VAT5"
        VAT5 = 4,
        // "ZERO"
        ZERO = 5
    }
}