/// <reference path="../../services/brookson.services.task-planner.ts" />

module Shared {
    'use strict';

    export interface IBrooksonUtrInputBindings {
  }
    const BrooksonUtrInput: ng.IComponentOptions = {
        bindings: {
        },
        controller: 'BrooksonUtrInputController',
        templateUrl: 'src/app/shared/views/ui/brookson.ui.utr.input.html',
        transclude: true
    };

    angular
        .module('app.shared')
        .component('brooksonUtrInput', BrooksonUtrInput);
}

module Shared {
    'use strict';

    export interface IBrooksonUtrInputComponentController extends IBrooksonUtrInputBindings {
         utrNumber:string;
         branchId:string;
         branchPattern:any;
         utrPattern:any;
    }

    export class BrooksonUtrInputController implements IBrooksonUtrInputComponentController {
        public utrNumber:string;
        public branchId:string;
        public utrPattern:any= /^\d{10}$/;
        public branchPattern:any= /^\d{3}$/;

        static $inject: Array<string> = ['taskPlannerSrv'];
        constructor(private taskPlannerSrv: Shared.ITaskPlannerSrv) {}

        public updateUTR =():void=>{
            this.taskPlannerSrv.updateUTR(this.utrNumber,this.branchId);
        }
    }

    angular
        .module('app.shared')
        .controller('BrooksonUtrInputController', BrooksonUtrInputController);
}
