/// <reference path="../_all.ts" />

(() => {
    "use strict";

    var app = angular.module("app.shared");

    // Collect the routes
    app.config([
        "$stateProvider", "$urlRouterProvider", ($stateProvider, $urlRouterProvider) => {
            $stateProvider
                .state("brookson",
                {
                    views: {
                        'navigation': {
                            template: "<brookson-shared-navigation></brookson-shared-navigation>"
                        },
                        'header': {
                            template: "<brookson-shared-header></brookson-shared-header>"
                        },
                        'footer': {
                            templateUrl: "src/app/shared/views/footer.html"
                        },
                        'main': {
                            template: "<div data-ui-view></div>"
                        }
                    }
                });
            $stateProvider.state("brookson.banklink-auto-allocation-settings",
                {
                    url: "/banklink/auto-allocation/settings",
                    views: {
                        '': {
                            template: "<brookson-auto-allocation-settings></brookson-auto-allocation-settings>"
                        }
                    },
                    sticky: false
                } as ng.ui.IState);
            $stateProvider.state("brookson.bank-file-upload",
                {
                    url: "/banklink/bank-file/upload",
                    views: {
                        '': {
                            template: "<brookson-bank-file-import></brookson-bank-file-import>"
                        }
                    },
                    sticky: false
                } as ng.ui.IState);
        }
    ]);
})();