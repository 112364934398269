/// <reference path="../models/Member.ts"/>
/// <reference path="../models/StatePensionAge.ts" />
/// <reference path="../models/member/ServiceLine.ts" />
/// <reference path="../models/IR35AssessmentAnswers.ts" />
/// <reference path="../models/IR35AssessmentAnswers.ts" />

/// <reference path="./../interfaces/brookson.interfaces.http.ts" />

module Shared {
    export class MemberModel {
        public userName: string = "";
        public token: string = "";
        public refreshToken: string = "";
        public isAdmin: boolean = false;
        public isAuthenticated: boolean = false;
        public memberId: string = "";
        public serviceLines: Array<Shared.ServiceLine>;
        public useRefreshToken: boolean = false;
        public hasUmbBusinessType: boolean = false;
        public hasLtdBusinessType: boolean = false;
        public hasMulipleLtdCompanies: boolean = false;
        public umbCustomerReference: string = "";
        public ltdCustomerReference: string = "";
        public companyRef: string = "";
        public businessType: string = "";
        public role: string = "";
        public expires: string = "";
        public refreshTokenExpires: string = "";
        public navReferral: string = "";
        public companyName: string = "";
        public apiKeySource: string = "";
    }

    export interface IMemberService {
        member: MemberModel;

        setMember(data: MemberModel): MemberModel;
        getMember(): MemberModel;
        getMemberBusinessType(): string;
        updateMember(data: MemberModel): MemberModel;
        clearMember(): void;
        getMemberDetails(silentMode?: boolean, cached?: boolean): ng.IPromise<Member>;
        getPensionAge(silentMode?: boolean, cached?: boolean): ng.IPromise<StatePensionAge>;
        showMemberMTD(): ng.IPromise<boolean>;
        insertAuthorsationCode(authorisationCode: number): ng.IPromise<boolean>;        
        getHasAdvisoryKeyInformation(): ng.IPromise<boolean>;
        getHasAdvisoryKeyInformationUmbrella(): ng.IPromise<boolean>;
    }

    export class MemberService implements IMemberService {

        static $inject: Array<string> = ['$injector', "localStorageService", 'Analytics'];

        public member: MemberModel;
        public $http: Shared.IBrooksonHttp;

        constructor(
            public $injector: ng.auto.IInjectorService,
            public localStorageService: ng.local.storage.ILocalStorageService,
            public analytics: any
        ) {
            this.member = new MemberModel();
        }

        public isMiniConnectUser = (): boolean => {
            if (this.member.serviceLines) {
                let line: ServiceLine[] = _.filter(this.member.serviceLines, (o: ServiceLine): boolean => o.customerReference === this.member.companyRef);

                if (line.length > 0) {
                    if ((line[0].isSecondShareholder === true) || ((line[0].isEligible === true) && (line[0].isActive === false))) {
                        return true;
                    }
                }
            }

            return false;
        }

        public companyName = (): string => {
            if (this.member.serviceLines) {
                let line: ServiceLine[] = _.filter(this.member.serviceLines, (o: ServiceLine): boolean => o.customerReference === this.member.companyRef);
                
                if(line[0])
                {
                    return line[0].companyName;
                }
                if(this.member.companyName)
                {
                    return this.member.companyName;
                }
            }
        }


        setMember = (data: MemberModel): MemberModel => {
            //set in memory values
            this.member = <MemberModel>_.assign(this.member, data);
            this.member.businessType = this.getMemberBusinessType();

            //persist changes
            this.localStorageService.set("brookson.member", this.member);

            this.analytics.set("dimension1", this.member.memberId);
            this.analytics.set("dimension2", this.member.businessType);
            this.analytics.set("dimension3", this.member.companyRef);
            this.analytics.set("dimension4", this.member.isAdmin ? "Y" : "N");
            return this.member;
        }

        public getMember = (): MemberModel => {
            if (!this.member || !this.member.userName) {
                let store: MemberModel = <MemberModel>this.localStorageService.get("brookson.member");
                if (store) {
                    this.member = store;
                }
            }

            return this.member;
        }

        getMemberBusinessType = (): string => {
            let member = this.member;

            // If there's service lines, return the one matching business type from here
            if (member.serviceLines) {
                let line: ServiceLine[] = _.filter(member.serviceLines, (o: ServiceLine): boolean => o.customerReference === member.companyRef);
                if(line[0])
                {
                    return line[0].businessType;
                } else 
                {
                    return member.businessType;
                }
                
            } else {
                return member.businessType;
            }
        }

        updateMember = (data: MemberModel): MemberModel => {
            return this.setMember(data);
        }

        clearMember = (): void => {
            this.member = null;
            this.localStorageService.set("brookson.member", {});
            this.localStorageService.set("brookson.dashboard", {});
        }

        getMemberDetails = (silentMode?: boolean, cached?: boolean): ng.IPromise<Member> => {
            this.$http = this.$http || <any>this.$injector.get('$http');
            return this.$http.get('api/Member/GetMemberDetails', {
                silentMode: silentMode ? silentMode : silentMode,
                cache: cached ? cached : false
            }).then((response: ng.IHttpPromiseCallbackArg<Member>): Member => {
                return response.data;
            });
        }

        getPensionAge = (silentMode?: boolean, cached?: boolean): ng.IPromise<StatePensionAge> => {
            const config = {
                silentMode: silentMode ? silentMode : false,
                cache: cached ? cached : false
            };

            return this.$http.get('api/Member/PensionAge', config).then((response: ng.IHttpPromiseCallbackArg<StatePensionAge>): StatePensionAge => {
                return response.data;
            });
        }

        getHasAdvisoryKeyInformation(silentMode?: boolean, cached?: boolean): ng.IPromise<boolean> {
            const config = {
                silentMode: silentMode ? silentMode : false,
                cache: cached ? cached : false
            };

            var hasAdvisor = this.$http.get('api/Advisory/HasAdvisoryKeyInformation', config).then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                return response.data;
            });

            return hasAdvisor;
        }

        getHasAdvisoryKeyInformationUmbrella(silentMode?: boolean, cached?: boolean): ng.IPromise<boolean> {
            const config = {
                silentMode: silentMode ? silentMode : false,
                cache: cached ? cached : false
            };

            var hasAdvisor = this.$http.get('api/Advisory/HasAdvisoryKeyInformationUmbrella', config).then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                return response.data;
            });

            return hasAdvisor;
        }

        showMemberMTD(): ng.IPromise<boolean> {
            return this.$http.get('api/Member/ShowMemberMTD').then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                return response.data;
            });
        }
        
        insertAuthorsationCode(authorisationCode: number): ng.IPromise<boolean> {
            const requestData = {
                authorisationCode: authorisationCode
            };
            return this.$http.post('api/Member/InsertMTDAuthorisationCode', requestData)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }
    }
}

angular.module('app.shared').service('memberSrv', Shared.MemberService);