module Shared {
    'use strict';

    export interface IBrooksonModalSelect {
        showSelect(items: Array<ModalSelectItem>, title: string): ng.IPromise<any>;
    }

    export class BrooksonModalSelect implements IBrooksonModalSelect {
        static $inject: Array<string> = ['$uibModal'];
        constructor(private $uibModal: ng.ui.bootstrap.IModalService) { }

        public showSelect = (items: Array<ModalSelectItem>, title: string) => {
            var options: ng.ui.bootstrap.IModalSettings = {
                animation: true,
                size: "md",
                templateUrl: "src/app/shared/views/modals/brookson.modals.select.html",
                controller: Shared.BrooksonModalSelectController,
                resolve: {
                    selectItems: () => { return items; },
                    title: () => { return title; }
                }
            };

            return this.$uibModal.open(options).result;
        }
    }

    angular
        .module('app.shared')
        .service('brookson.modal.select.service', BrooksonModalSelect);
}

module Shared {
    'use strict';

    export class BrooksonModalSelectController {

        static $inject: Array<string> = ['$scope', '$uibModalInstance', 'selectItems', 'title'];

        constructor(private $scope: any, private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance, public selectItems: Array<Shared.ModalSelectItem>, public title: string) {
            $scope.cancel = this.cancel;
            $scope.ok = this.ok;
            $scope.selectItems = this.selectItems;
            $scope.title = this.title;
        }

        public cancel = () => {
            this.$uibModalInstance.dismiss();
        }

        public ok = () => {

            this.$uibModalInstance.close(_.filter(this.selectItems, (item) => { return item.selected; }));
        }

    }

    angular
        .module('app.shared')
        .controller('brooksonModalSelectController', BrooksonModalSelectController);
}


module Shared {
    export class ModalSelectItem {
        constructor(description: string, id: number, selected: boolean) {
            this.description = description;
            this.id = id;
            this.selected = selected;
        }

        public description: string = null;

        public id: number = null;

        public selected: boolean = false;
    }
}