/// <reference path="./../../_all.ts"/>
/// <reference path="./../models/Task.ts"/>
/// <reference path="./../interfaces/brookson.interfaces.http.ts"/>
/// <reference path="../models/MemberCurrentStage.ts" />
/// <reference path="./../modules/brookson.enums.ts" />
/// <reference path="../models/UtrNumber.ts" />



module Shared {

    export interface ITaskPlannerSrv {
        taskTitle: string;
        sectionTitle: any;
        sectionTitles: any;
        selectedTask: any;
        selectedAction: Object;
        viewDate: any;
        annualMonths: any;
        flatActions: Array<any>;
        tasks: Array<Shared.Task>;
        taskActions: Array<Shared.TaskAction>;
        enums: ITaskPlannerEnums;
        screenSize: any;
        overrideCacheMode: boolean;


        updateUTR(utrNumber: string, branchId: string): ng.IPromise<any>;
        updateAuthCode(authCode: string): ng.IPromise<any>;
        buildMonths(silentMode?: boolean): ng.IPromise<Array<any>>;
        getAndParseTasks(silentMode?: boolean): ng.IPromise<Array<any>>;
        parseTasks(data);
        getTasksComplete(data);
        getTasks(silentMode?: boolean, cacheMode?: boolean): ng.IPromise<any>;
        getActionsByDay(day): any;
        addMonth(): any;
        subtractMonth(): any;
        getTaskActions(silentMode?: boolean): ng.IPromise<Array<TaskAction>>;
        getTasksToBeDisplayed(silentMode: boolean): ng.IPromise<Array<any>>;
        getFolderByID(folderID, silentMode): any;
        get3MonthsRelatedDocs(folderID): any;
        getYearEndStage(silentMode?: boolean): any;
        getYearEndMemberConfirmationStatus(silentMode: boolean): any;
        exportCalendar(silentMode?: boolean): any;
        generateCalendarKey(silentMode?: boolean): any;
        getTaskCalendarSync(silentMode?: boolean): any;
        showTaskCompletionInput(webTaskNo: string);
        confirmAccountsStep(step: number);
        getYearEndStatus(): ng.IPromise<number>;
        previewDocument(document: any): any;
        previewCallback(id: any, path: any): any;
        isMobile(): boolean;
        closeTask(taskAction: Shared.ITaskAction, silentMode?: boolean): ng.IPromise<boolean>;
        // Tax Pack Confirm and Approval
        getIsPayBeforeSubmit(silentMode?: boolean): ng.IPromise<boolean>;
        getUtrNumber(): ng.IPromise<UtrNumber>;
    }
}

module Shared {

    export class TaskPlannerSrv implements ITaskPlannerSrv {

        public taskTitle: string;
        public flatActions: Array<any>;
        public sectionTitle: string = '';
        public sectionTitles: any;
        public selectedTask: any = {};
        public selectedAction: Shared.TaskAction = new Shared.TaskAction();
        public viewDate: any;
        public annualMonths: any;
        public tasks: Array<Shared.Task>;
        public taskActions: Array<Shared.TaskAction>;
        public overrideCacheMode: boolean;
        static $inject: Array<string> = ['$http', '$q', 'brookson.logger', 'growl', '$stateParams', 'documentStorageSrv', 'brookson.pdf.modal', 'taskPlanner.enums', 'screenSize', '$cacheFactory', '$rootScope'];

        constructor(
            private $http: IBrooksonHttp,
            private $q: ng.IQService,
            private logger: any,
            private growl: angular.growl.IGrowlService,
            public $stateParams: ng.ui.IStateParamsService,
            public documentStorageSrv: any,
            public brooksonPdfModal: any,
            public enums: ITaskPlannerEnums,
            public screenSize: any,
            private $cacheFactory: angular.ICacheFactoryService,
            private $rootScope: angular.IRootScopeService
        ) {
            this.sectionTitles = {
                business: {
                    name: 'Business Tasks',
                    overdue: 0
                },
                personal: {
                    name: 'Personal',
                    overdue: 0
                },
                personalTax: {
                    name: 'Personal Tax'
                },
                saPersonalTax: {
                    name: 'SA Personal Tax'
                },
                paye: {
                    name: 'PAYE'
                },
                payroll: {
                    name: 'Payroll'
                },
                utr: {
                    name: 'UTR'
                },
                vat: {
                    name: 'VAT'
                },
                yearEnd: {
                    name: 'Year End'
                }
            };
        }
        //private methods
        private buildMonthsArray = () => {
            var months = [];
            var i;

            for (i = -2; i < 10; i++) {
                var selectedMonth = moment(this.viewDate).add(i, 'month').startOf('month');
                var currentMonth = moment().startOf('month');
                var month = {
                    timestamp: selectedMonth,
                    events: [],
                    highlightMonth: (moment(selectedMonth).isSame(currentMonth) ? true : false)
                };
                months.push(month);
            }
            return months;
        }


        private flattenActions = (data) => {
            var result = [];

            result = _.chain(data).map((task) => {

                return _.map(task.taskActions, (taskAction: any) => {
                    taskAction.taskGroup = task.taskTitle;
                    return taskAction;
                });
            }).flatten().value();

            return result;

        }

        //public methods
        public updateUTR = (utrNumber: string, branchId: string): ng.IPromise<any> => {
            var url = 'api/Planner/UpdateUTRNumbers';

            return this.$http.post(url, {
                UtrNumber: utrNumber, BranchId: branchId
            }
            ).then((response) => {

                this.buildMonths(false, false).then((data) => {
                    let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                    this.growl.success("UTR has been updated.", config);
                    this.closeTask(this.selectedAction).then(() => {
                        let cache = this.$cacheFactory.get('$http');
                        cache.remove('api/Planner/GetTasks');
                        this.getAndParseTasks().then(() => {
                            this.updateCurrentTaskAndAction();
                        });
                    });
                });

                return response.data;
            }).catch(() => {
                let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                this.growl.error("UTR failed to update.", config);

            });

        }

        public updateAuthCode = (authCode: string): ng.IPromise<any> => {
            var url = 'api/Planner/UpdateAuthCode';

            return this.$http.post(url, {
                AuthCode: authCode
            }
            ).then((response) => {

                this.buildMonths(false, false).then((data) => {
                    let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                    this.growl.success("Auth Code has been updated.", config);
                    this.closeTask(this.selectedAction).then(() => {
                        let cache = this.$cacheFactory.get('$http');
                        cache.remove('api/Planner/GetTasks');
                        this.getAndParseTasks().then(() => {
                            this.updateCurrentTaskAndAction();
                        });
                    });
                });

                return response.data;
            }).catch(() => {
                let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                this.growl.error("Auth Code failed to update.", config);

            });

        }

        private updateCurrentTaskAndAction = () => {
            if (this.tasks.length) {
                let task = _.find<any>(this.tasks, n => (n.id.toLowerCase() === this.$stateParams['taskID'].toLowerCase()));

                if (!task || task.taskActions.length === 0) {
                    this.taskActions = [];
                } else {
                    this.taskActions = task.taskActions;
                    this.selectedTask = task;
                    this.taskTitle = task.taskTitle;

                    if (this.selectedAction.action_Web_Task_no) {
                        let action = _.find<any>(this.taskActions,
                            m => (m.action_Web_Task_no === this.selectedAction.action_Web_Task_no));
                        if (action) {
                            this.selectedAction.status = action.status;
                            this.selectedAction = action;
                        }
                    }
                }
            }
        }

        public buildMonths = (silentMode: boolean = true, cacheMode: boolean = true) => {

            // Get the tasks, once complete, filter them into the monthly buckets
            return this.getTasks(silentMode, cacheMode).then(
                (data) => {
                    this.flatActions = this.flattenActions(data);
                    return this.getTasksComplete(data);
                }
            );
        }

        public getAndParseTasks = (silentMode?: boolean) => {
            return this.getTasks(silentMode).then((data) => {
                return this.parseTasks(data);
            });
        }

        public parseTasks = (data) => {
            // Do any special parsing of the tasks here

            let deferred = this.$q.defer();

            this.sectionTitles.business.overdue = 0;
            this.sectionTitles.personal.overdue = 0;

            // Add on an ID for menus and an overdue counter
            for (let i = 0; i < data.length; i++) {
                data[i].id = _.camelCase(data[i].taskTitle);
                data[i].overdue = 0;

                data[i].taskActions = _.orderBy(data[i].taskActions, ['actionOrder'], ['asc']);

                let taskActions = data[i].taskActions;
                for (let j = 0; j < taskActions.length; j++) {
                    // Status: 0 = Completed, 1 = Current, 2 = Future, 3 = Overdue

                    // Overdue calcs
                    if (taskActions[j].status === 3) {
                        // BUSINESS
                        if (data[i].business) {
                            this.sectionTitles.business.overdue++;
                        } else {
                            // PERSONAL
                            this.sectionTitles.personal.overdue++;
                        }
                        data[i].overdue++;
                    }
                    // /Overdue calcs
                }
            }

            this.tasks = data;

            deferred.resolve(data);

            return deferred.promise;
        }

        public getTasksComplete = (data) => {
            var months = [];

            var deferred = this.$q.defer();

            months = this.buildMonthsArray();

            // Loop over each month and find any data within data
            for (var month = 0; month < months.length; month++) {

                var currentMonth = moment(months[month].timestamp);
                var i;

                for (i = 0; i < data.length; i++) {
                    var j;
                    for (j = 0; j < data[i].taskActions.length; j++) {

                        var taskDate = data[i].taskActions[j].dueDate;

                        if (moment(taskDate).startOf('month').isSame(currentMonth)) {

                            var date = moment(taskDate);
                            var task = {
                                title: data[i].taskTitle,
                                type: 'info',
                                startsAt: date,
                                endsAt: date,
                                action_Web_Task_no: data[i].taskActions[j].action_Web_Task_no,
                                // Status: 0 = Completed, 1 = Current, 2 = Future, 3 = Overdue
                                status: data[i].taskActions[j].status,
                                actionId: data[i].taskActions[j].actionId,
                                actionTitle: data[i].taskActions[j].actionTitle
                            };

                            months[month].events.push(task);
                        }
                    }
                }
            }

            this.annualMonths = months;
            deferred.resolve(this.annualMonths);

            return deferred.promise;
        }

        public getTasks = (silentMode: boolean, cacheMode: boolean = true) => {
            var url = 'api/Planner/GetTasks';

            if (this.overrideCacheMode)
                cacheMode = false;

            return this.$http.get(url, {
                silentMode: silentMode ? silentMode : false,
                cache: cacheMode
            }).then((response) => {
                if (response.data === "nill") {
                    response.data = [];
                }

                return response.data;
            });
        }


        public getActionsByDay = (day) => {
            var result = [];

            result = _.filter(this.flatActions, (n) => {
                return moment(n.dueDate).isSame(day, 'day');
            });

            return result;
        }

        public getActionById = (id) => {
            var result = [];

            result = _.filter(this.flatActions, (n) => {
                return n.actionId === id;
            });

            return result;
        }

        public addMonth = () => {
            this.viewDate = moment(this.viewDate).add(1, 'months');
            this.buildMonths(false);
        }

        public subtractMonth = () => {
            this.viewDate = moment(this.viewDate).subtract(1, 'months');
            this.buildMonths(false);
        }

        public getTaskActions = (silentMode: boolean = false) => {
            return this.getTasks(silentMode).then((data) => {
                let result = [];
                _.forEach(data, (task) => {
                    result = _.concat(result, task.taskActions);
                });

                return result;
            });
        }

        public getTasksToBeDisplayed = (silentMode: boolean = false, cacheMode: boolean = true) => {
            return this.$http.get('api/Planner/GetTasksToBeDisplayed', {
                silentMode: silentMode,
                cache: cacheMode
            }).then((response) => {
                return response.data;
            });
        }

        public getFolderByID = (folderID, silentMode = false) => {
            var url = 'api/DocumentStorage/GetFolderById/' + folderID;

            return this.$http.get(url, {
                silentMode: silentMode,
                cache: true
            }).then((data: any) => {
                return data.data.documents;
            });
        }

        public get3MonthsRelatedDocs = (folderID) => {
            return this.getFolderByID(folderID)
                .then(this.getFolderByIDComplete)
                .catch((error) => {
                    // logger, error
                });
        }

        private getFolderByIDComplete = (data) => {
            var docData = data;
            var docs = [];

            // #5102 FUTURE VAT2 was bringing back previous VAT return doc if created too early.
            if (!(this.selectedAction.status === TaskStatus.future && this.selectedAction.action_Web_Task_no === 'VAT2')) {
                var threeMonthsAgo = moment().subtract(3, 'months');
                docs = _.filter(docData, (o: any) => {
                    return moment(o.date).isAfter(threeMonthsAgo);
                });
            }

            this.selectedAction.documents = docs;
            return this.selectedAction.documents;
        }

        public getYearEndStage = (silentMode?: boolean): ng.IPromise<MemberCurrentStage> => {
            var url = 'api/Planner/GetYearEndStage';

            return this.$http.get(url, {
                cache: 'true',
                params: {
                    silentMode: silentMode ? silentMode : false
                }
            })
                .then((response) => {
                    return response.data;
                });
        }

        public getYearEndMemberConfirmationStatus = (silentMode: boolean = false) => {
            var url = 'api/Planner/GetYearEndMemberConfirmationStatus';

            return this.$http.get(url, {
                cache: 'true',
                silentMode: silentMode
            }).then((response) => {
                return response.data;
            });
        }

        public exportCalendar = (silentMode?: boolean) => {
            var url = 'api/Planner/ExportTasks';

            return this.$http.get(url, {
                cache: true,
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        public generateCalendarKey = (silentMode?: boolean) => {
            var url = 'api/Planner/GenerateCalendarKey';

            return this.$http.get(url, {
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }
        public getTaskCalendarSync = (silentMode?: boolean) => {
            var url = 'api/Planner/GetTaskCalendarSync';

            return this.$http.get(url, {
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        public showTaskCompletionInput = (webTaskNo: string, actionDetails?) => {
            if (actionDetails) {
                this.selectedAction = actionDetails;
            }
            if (this.selectedAction.action_Web_Task_no === webTaskNo && (this.selectedAction.status > 0 && this.selectedAction.status < 4)) {
                return true;
            }
            return false;
        }

        public confirmAccountsStep = (step: number) => {
            var url = 'api/Planner/UpdateConfirmAccountsStep';

            return this.$http.post(url, {
                step: step
            }
            ).then((response) => {

                return this.buildMonths(false, false).then((data) => {
                    let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                    this.growl.success(`Step ${step} has been updated`, config);
                    return response.data;
                });

            }).catch(() => {
                let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                this.growl.error("Account confirmation failed to update.", config);

            });
        }

        public getYearEndStatus = (): ng.IPromise<number> => {
            var url = 'api/Planner/GetYearEndStatus';

            return this.$http.get(url, {
                cache: true
            }).then((response) => {
                return response.data;
            });
        }

        public updatePackStatus = (): ng.IPromise<boolean> => {
            var url = 'api/SelfAssessment/UpdatePackStatus';

            return this.$http.post(url, {
                status: 2
            }).then((response) => {
                    this.closeTask(this.selectedAction).then(() => {
                    var cache = this.$cacheFactory.get('$http');
                    cache.remove('api/Planner/GetTasks');
                    this.getAndParseTasks().then(() => {
                        this.updateCurrentTaskAndAction();
                    });
                });
                return response.data;
            });
        }

        public getTaxPackStatus = (): ng.IPromise<number> => {
            var url = 'api/SelfAssessment/GetTaxPackStatus';

            return this.$http.get(url, {
                cache: false
            }).then((response) => {
                return response.data;
            });
        }

        public getUtrNumber = (): ng.IPromise<UtrNumber> => {
            var url = 'api/SelfAssessment/GetUtrNumber';

            return this.$http.get(url, {
                cache: false
            }).then((response) => {
                return response.data;
            });
        }

        public previewDocument = (document: any) => {
            this.brooksonPdfModal.view(document.documentId, document.path, this.previewCallback);
        }

        public previewCallback = (id, path) => {
            return this.documentStorageSrv.getSelectedPDF(id, path).then(data => data);
        }

        /**
         * Returns whether the user is viewing on a small device
         */
        public isMobile = (): boolean => this.screenSize.is('xs', 'sm');

        /**
         * Closes a task by adding an entry into the database based on the task action number.
         * 
         * 
         * @memberOf TaskPlannerSrv
         */
        public closeTask = (taskAction: Shared.TaskAction, silentMode?: boolean): ng.IPromise<boolean> => {
            let actionWebTaskNo = taskAction.action_Web_Task_no;
            let actionNumber = taskAction.actionId;

            var actionNumbers = actionWebTaskNo + '|' + actionNumber;

            return this.$http.put('api/Planner/CloseTask/' + actionNumbers, {}, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        public getIsPayBeforeSubmit = (silentMode?: boolean): ng.IPromise<boolean> => {
            var url = 'api/SelfAssessment/GetIsPayBeforeSubmit';
            return this.$http.get(url, { silentMode: silentMode ? silentMode : false
                        }).then((response) => {
                            return response.data;
                        });
        }
    }


}
angular
    .module('app.shared')
    .service('taskPlannerSrv', Shared.TaskPlannerSrv);
