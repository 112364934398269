/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var appShared = angular.module("app.shared");

    appShared.directive('brooksonUiSelectRequired', [() => {
        return {
            restrict: 'A',
            require: '^ngModel',
            link: (scope, el, attrs, ngModel) => {
                var validate = (value) => {
                    ngModel.$setValidity('brooksonUiSelectRequired', value !== null && value !== undefined && value !== "" && value.titleText !== "");
                };

                if (scope.$eval(attrs.brooksonUiSelectRequired)) {
                    ngModel.$parsers.unshift((value) => {
                        validate(value);
                        return value;
                    });

                    ngModel.$formatters.unshift((value) => {
                        validate(value);
                        return value;
                    });
                }
            }
        };
    }]);
})();