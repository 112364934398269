  (() => {
    'use strict';

    var appShared = angular.module("app.shared");

   appShared.directive('brooksonInputDatepicker', ['$detection', ($detection) => {
        return {
            restrict: 'AE',
            require: ['^form','^ngModel'],
            templateUrl: 'src/app/shared/views/inputs/brookson.input.datepicker.html',
            scope: {
                ngModel: '=',
                ngReadonly: '=',
                label: '@',
                field: '@',
                isRequired: '=',
                placeholder: '@',
                ngDisabled: '=',
                minDate: '=',
                maxDate: '=',
                viewOnly: '=',
                ngChange: '=',
                maxCustomMessage: '@?',
                minCustomMessage: '@?',
                max: '=',
                showDateHint: '='
            },
            link: (scope, el, attrs, requires) => {

                scope.form = requires[0];
                scope.format = "dd/MM/yyyy";
                scope.useMobileInput = ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone());

                scope.datepickerStatus = {
                    isOpen: false
                };

                scope.openDatepicker = ($event) => {
                    scope.datepickerStatus.isOpen = !scope.datepickerStatus.isOpen;
                    $event.preventDefault();
                };
            }
        };
    }]);

    appShared.directive('brooksonDatepickerAttr', ['brookson.utilities.date', '$detection', (dateUtils, $detection) => {
        return {
            require: '^ngModel',
            link: (scope, element, attrs, ctrl)  => {
                scope.format = "dd/MM/yyyy";
                scope.useMobileInput = ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone());

                attrs.$observe('brooksonDatepickerAttr', (newValue) => {
                    if (scope.format === newValue || !ctrl.$modelValue) {
                        return;
                    }
                    scope.format = "dd/MM/yyyy";
                    ctrl.$modelValue = new Date(ctrl.$setViewValue);
                });

                if (scope.max) {
                    ctrl.$validators.max = (viewValue) => {
                        if (viewValue > scope.max) {
                            scope.form[scope.field].$error.max = scope.maxCustomMessage;
                            return false;
                        }
                        return dateUtils.convertToJsDate(viewValue);
                    };
                }

                if (scope.min) {
                    ctrl.$validators.min = (viewValue) => {
                        if (viewValue > scope.min) {
                            scope.form[scope.field].$error.min = scope.minCustomMessage;
                            return false;
                        }
                        return dateUtils.convertToJsDate(viewValue);
                    };
                }

                ctrl.$formatters.unshift((modelValue) => {
                    if (!modelValue || modelValue === '' || !dateUtils.isValid(modelValue)) {
                        return '';
                    }

                    return scope.useMobileInput ? dateUtils.convertToJsDate(modelValue) : dateUtils.convertToViewString(modelValue);
                });

                ctrl.$parsers.unshift((viewValue) => {
                    if (!viewValue || viewValue === '' || !dateUtils.isValid(viewValue)) {
                        return '';
                    }

                    return dateUtils.convertToJsDate(viewValue);
                });
            }
        };
    }]);
  })();
