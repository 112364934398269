/// <reference path="./AssignmentHeader.ts"/>
/// <reference path="./Review.ts"/>
/// <reference path="./Location.ts"/>
/// <reference path="./BrooksonDate.ts"/>
/// <reference path="./publicSectorAssignmentCheck/assignmentSelection.ts" />
/// <reference path="./sdc/JobTitle.ts" />
/// <reference path="./EnumObj.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class Assignment extends AssignmentHeader {

        // RATETYPES
        public rateTypes: string[] = [];
        // MEMBERID
        public memberId: number = 0;
        // HASIR35QUESTIONNAIRE
        public hasIR35Questionnaire: boolean = false;
        // IR35QUESTIONNAIRECOMPLETE
        public iR35QuestionnaireComplete: boolean = false;
        // COMPANY
        public company: string = null;
        // MEMBERADVISEDSTATUSOLD
        public memberAdvisedStatusOld: string = null;
        // REVIEWS
        public reviews: Review[] = [];
        // PREFERREDEMAILADDRESSES
        public preferredEmailAddresses: string[] = [];
        // LOCATIONS
        public locations: Location[] = [];

        public isAssignment24MonthRuleReclaimable: boolean = false;

        public sdcStatus: number = 0;
        public publicSector: Sectors = null;

        public publicSectorFromDate: Shared.BrooksonDate;

        public ir35Capture: boolean;

        public jobTitleOption: JobTitle = null;

        public jobTitle: string = null;

        public industry: string = null;

        public iR35CapturedFromDate: string = null;

        public iR35CapturedEffectiveDate: string = null;

        public showExpensesPopup: boolean = false;
        public timesheetFrequencySelector: EnumObj;
        public timesheetFrequencyStatus: any;
        public timesheetFrequency: any;
        public timesheetFrequencyOptionsList: Array<EnumObj>;
    }

    export enum Sectors {
        public,
        private,
        nolongerinuse,
    }
}
