/// <reference path="./../../_all.ts"/>
/// <reference path="./../factories/brookson.factories.feedback.ts"/>

module Shared {
    export class BrooksonModalFeedbackController {

        constructor(private factory: IBrooksonUiFeedbackFactory, private $detection : any) {
            this.factory.getUserFeedbackCategories();
            this.factory.resetScreenshot();
            this.factory.userFeedback.userFeedbackCategoryId = null;
            this.factory.userFeedback = new UserFeedback();
            this.factory.userFeedback.userFeedbackCategoryId = null;
        }

        // Public Methods //
        closeModal() {
            this.factory.closeModal();
        }

        submitButtonPressed(form: ng.IFormController) {
            if (form.$invalid) {
                return;
            }

            this.factory.getUserAgentInfo();
            this.factory.getState();
            this.factory.getUrl();
            this.factory.postUserFeedback();
        }

        takeScreenshotButtonPressed() {
            // If mobile, take screenshot only
            if (this.$detection.isAndroid() || this.$detection.isiOS() || this.$detection.isWindowsPhone() || this.$detection.isBB10()) {
                this.factory.takeScreenshot();
            } else {
                this.factory.hideModal();
                this.factory.isScreenshotMode = true;
                this.factory.createCanvas();
            }
        }
    }
}
