/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetUmbrellaFaq', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.umbrella.faq.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetUmbrellaFaq
    });

    brooksonWidgetUmbrellaFaq.$inject = ['$timeout', 'invoicingSrv', 'brookson.navigation','appSettings','memberSrv'];

    function brooksonWidgetUmbrellaFaq($timeout, invoicingSrv, brooksonNavigation, dashboardService, appSettings, memberSrv) {
        var vm = this;

        vm.latestInvoices = {};
        vm.latestPayments = {};
        vm.showSpinner = false;
        vm.brooksonNavigation = brooksonNavigation;
        vm.memberSrv = memberSrv;
        vm.showNewSetupText = false;
        vm.dashboardService = dashboardService;
        vm.widgetName = "brookson-widget-umbrella-faq";
        vm.appSettings = appSettings;
        vm.memberBusinessType = '';
    }
})();
