
module EmploymentStatus {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class AssignmentHeader  {

        public diff: number = null;
        // AGENCY
        public agency: string = null;
        // DATESTARTED
        public dateStarted: Date = null;
        // DATEENDED
        public dateEnded: Date = null;
        // ASSIGNMENTID
        public assignmentId: string = null;
        // CLIENT
        public client: string = null;
        // ASSIGNMENTNAME
        public assignmentName: string = null;
        // INSTRUCTIONTEXT
        public instructionText: string = null;
        // OVERALLSTATUSTEXT
        public overallStatusText: string = null;
        // ASSIGNMENTADDRESS
        public assignmentAddress: string = null;
        // ASSIGNMENTNOTES
        public assignmentNotes: string = null;
        // CUSTOMER
        public customer: string = null;
        // AGENCYREFERENCE
        public agencyReference: string = null;
        // STATUSSTYLE
        public ir35Status: IR35Status = null;
    }

    export class IR35Status {
      text:string;
      style: string;
    }
}
