module Shared {
    export class AssignmentSdcOutcome {
        agencyName: string;
        assignmentId: string;
        canSubmitExpenses: boolean;
        canSubmitTimesheets: boolean;
        clientName: string;
        dateStarted: Date;
        outcome: number;
        outcomeText: string;
    }
}