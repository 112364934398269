/// <reference path="./NavPersonalInformation.ts" />
/// <reference path="./sdc/JobTitle.ts" />

module Shared {
    export class PscInformation {
        navPersonalInformation: NavPersonalInformation = null;
        jobTitle: string = null;
        jobTitleOption: Shared.JobTitle = null;
        industry: string = null;
        assignmentId: string = null;
        doSubmitPSC: boolean = null;
    }
}