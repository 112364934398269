module Shared {
    export class AdviceTable {
    section: string;
    advice: string;
    subSection: string;
    subSectionOrder: string;
    businessID: string;
    personID: string;
    connectUser: string;
    createdDate: string;
    }   
}