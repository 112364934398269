module Shared {
    export class AssignmentInfo {
        jobTitle: string;
        jobTitleOption: Shared.JobTitle;
        assignmentIndustry: string; 
        clientName: string; 
        clientAddress: Shared.Address; 
        dateStartedAtWorkSite: Shared.BrooksonDate;
        expectedLengthOfAssignmentMonths: number; 
        siteAddress: Shared.Address; 
    }
}