module Shared {
    export class PscPayeTable {
        monthlyDirectorsFee: string;
        pscPayeIncomeYTD: string;
        totalPscPayeincome: string;
        businessID: string;
        personID: string;
        connectUser: string;
        createdDate: string;
        section: string;
        subSection: string;
        order: string;
    }
}