/// <reference path="./FixedTaxData.ts"/>
/// <reference path="./ForecastCalculations.ts"/>
/// <reference path="./UserInputData.ts"/>
/// <reference path="./UmbrellaTaxData.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class PersonalTaxTrackerData  {
        
        // FIXEDTAXDATA
        public fixedTaxData: FixedTaxData = null;
        // FORECASTCALCULATIONS
        public forecastCalculations: ForecastCalculations = null;
        // USERINPUTDATA
        public userInputData: UserInputData = null;
        // COMPANYNAME
        public companyName: string = null;

        public umbrellaTaxTracker: UmbrellaTaxTracker = null;

        public hasUmbrella: boolean = null;
    }
}
