/// <reference path="./../services/brookson.services.calendly.ts" />

module Shared {
    /**
     * Interface for the Calendly Factory
     * 
     * @export
     * @interface IBrooksonFactoriesCalendly
     */
    export interface IBrooksonFactoriesCalendly {
        getCalendlyIntroductionBooking(): ng.IPromise<MicroCalendlyBooking>;

        getCalendlyAdvisoryBooking(): ng.IPromise<MicroCalendlyBooking>;

        getCalendlyFinancialHealthCheckBooking(): ng.IPromise<MicroCalendlyBooking>;
        /**
         * 
         * Gets the latest advisory doc date for customer
         * @returns {ng.IPromise<boolean>}
         */
        //getAdvisoryDocDate(): ng.IPromise<Date>;

    }

    /**
     * 
     * Factory to handle all the intro walkthroughs for each section
     * @export
     * @class BrooksonFactoriesCalendly
     * @implements {IBrooksonFactoriesCalendly}
     */
    export class BrooksonFactoriesCalendly implements IBrooksonFactoriesCalendly {

        /**
        * 
        * DI
        * @static
        */
        static $inject = ['$q', 'calendlySrv'];

        constructor(
            private $q: ng.IQService,
            private service: Shared.ICalendlyService
        ) {
        }

        getCalendlyIntroductionBooking = (): ng.IPromise<MicroCalendlyBooking> => {
            return this.service.getCalendlyIntroductionBooking(true).then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                return response;
            });
        }

        
        getCalendlyAdvisoryBooking = (): ng.IPromise<MicroCalendlyBooking> => {
            return this.service.getCalendlyAdvisoryBooking(true).then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                return response;
            });
        }

        getCalendlyFinancialHealthCheckBooking = (): ng.IPromise<MicroCalendlyBooking> => {
            return this.service.getCalendlyFinancialHealthCheckBooking(true).then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                return response;
            });
        }
    }
}

angular.module('app.shared')
    .service('brooksonFactoriesCalendly', Shared.BrooksonFactoriesCalendly);