/// <reference path="../models/auto-allocation/AutoAllocationItem.ts"/>

module Shared {

    export interface IAutoAllocationService {
        memberModel: MemberModel;
        categoryList: Array<any>;
        companyConfig: Array<Shared.AutoAllocation.IAutoAllocationItem>;
        transactions: Array<any>;

        getAutoAllocationSettings(): ng.IPromise<Array<any>>;
        saveNewAutoAllocationSetting(model: Shared.AutoAllocation.IAutoAllocationItem): ng.IPromise<boolean>;
        updateAutoAllocationSetting(model: Shared.AutoAllocation.IAutoAllocationItem): ng.IPromise<boolean>;

        internalNavUser(): boolean;
        RequestedFromAllowedApiSource(): boolean;
        getSettingsList(includeDisabled: boolean): Array<Shared.AutoAllocation.IAutoAllocationItem>;
        createNewAllocationSetting(): Shared.AutoAllocation.IAutoAllocationItem;
        duplicateKeyword(newSetting: Shared.AutoAllocation.IAutoAllocationItem): boolean;
        resetTransactionMatches(autoSetting: Shared.AutoAllocation.IAutoAllocationItem): void;
        searchTransactions(autoSetting: Shared.AutoAllocation.IAutoAllocationItem): void;
    }

    export class AutoAllocationService implements IAutoAllocationService {
        static $inject = ["$http", "$q", "appSettings", "localStorageService"];
        public member: Shared.MemberModel = <Shared.MemberModel>{};
        memberModel: MemberModel;
        categoryList: Array<any>;
        companyConfig: Array<AutoAllocation.IAutoAllocationItem>;
        transactions: Array<any>;

        constructor(
            private $http: IBrooksonHttp,
            private $q: ng.IQService,
            private appSettings,
            private localStorageService: ng.local.storage.ILocalStorageService
        ) {
            this.memberModel = this.localStorageService.get("brookson.member") as MemberModel;
            this.transactions = [];
        }

        getAutoAllocationSettings(silentMode?: boolean): ng.IPromise<any> {
            const deferred = this.$q.defer();
            const url = "api/autoallocation/getconfig";

            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        saveNewAutoAllocationSetting(model: Shared.AutoAllocation.IAutoAllocationItem): angular.IPromise<boolean> {
            const deferred = this.$q.defer();
            const url = "api/autoallocation/addnew";

            this.$http.post(url, model)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;        
        }

        updateAutoAllocationSetting(model: Shared.AutoAllocation.IAutoAllocationItem): angular.IPromise<boolean> {
            const deferred = this.$q.defer();
            const url = "api/autoallocation/update";

            this.$http.post(url, model)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;                    
        }

        internalNavUser(): boolean {
            if (!this.appSettings.isInternal) return false;
        }

        RequestedFromAllowedApiSource(): boolean {
            if (!this.appSettings.validApiSource) return false;
            if (_.isNull(this.memberModel) || _.isNull(this.memberModel.navReferral)) {
                return false;
            }
            return true;
        }

        getSettingsList(includeDisabled: boolean): Array<AutoAllocation.IAutoAllocationItem> {
            let list = this.companyConfig;

            if (!includeDisabled) {
                list = _.filter(list, (config: AutoAllocation.IAutoAllocationItem) => {
                    return config.enabled === true;
                });
            }

            list = _.orderBy(angular.copy(list), (data: AutoAllocation.IAutoAllocationItem) => data.id);
            this.populateNavCategories(list);
            return list;
        }

        private populateNavCategories(companyConfig: Array<AutoAllocation.IAutoAllocationItem>): void {
            _.forEach(companyConfig, (autoAllocationSettings: AutoAllocation.IAutoAllocationItem) => {
                autoAllocationSettings.navCategory = _.find(this.categoryList, (category) => {
                    return category.code === autoAllocationSettings.categoryCode;
                });
            });
        }

        createNewAllocationSetting(): AutoAllocation.IAutoAllocationItem {
            const newAutoSetting: AutoAllocation.AutoAllocationItem = new AutoAllocation.AutoAllocationItem();
            let createdBy = "Customer";
            if(!_.isNull(this.memberModel.isAdmin))
            {
                createdBy = "Superuser";
            } else if (!_.isNull(this.memberModel.navReferral)) 
            {
                createdBy = this.memberModel.navReferral;
            }

            newAutoSetting.id = -1;
            newAutoSetting.enabled = true;
            newAutoSetting.createdBy = createdBy;
            newAutoSetting.memberId = parseInt(this.memberModel.memberId);
            newAutoSetting.company = this.memberModel.companyRef;
            return newAutoSetting;
        }

        duplicateKeyword = (newSetting: AutoAllocation.IAutoAllocationItem): boolean => {
            newSetting.validationMessage = "";
            if (_.isUndefined(newSetting.keyword)) return false;

            let uniqueKeywordCheck = _.find(this.companyConfig, (config) => {
                return config.keyword.toLowerCase() === newSetting.keyword.toLowerCase();
            });

            if (!_.isUndefined(uniqueKeywordCheck)) {
                if (!uniqueKeywordCheck.enabled) {
                    newSetting.validationMessage = "A disabled rule already exists with this keyword.";
                } else {
                    newSetting.validationMessage = "A rule already exists with this keyword.";
                }
                this.resetTransactionMatches(newSetting);
                return true;
            }

            return false;

        }

        resetTransactionMatches = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {
            if (_.isUndefined(autoSetting.transactionMatches)) {
                autoSetting.transactionMatches = new AutoAllocation.MatchingTransactions();
            }
            autoSetting.transactionMatches.count = 0;
            autoSetting.transactionMatches.items = [];
        }

        searchTransactions = (autoSetting: AutoAllocation.IAutoAllocationItem): void => {
            if (!_.isUndefined(autoSetting.keyword) && autoSetting.keyword.length >= 1) {
                let transactionMatches = _.filter(this.transactions,
                    (transaction) => {
                        return _.unescape(transaction.description).toLowerCase().indexOf(_.unescape(autoSetting.keyword).toLowerCase()) > -1
                            && transaction.debitAmount < 0 && (transaction.transactionType === 0 || transaction.transactionType === 2);
                    });
                transactionMatches = _.orderBy(angular.copy(transactionMatches), (matchedTransactions: any) => matchedTransactions.transactionDate, "desc");
                if (transactionMatches.length === 0) {
                    let noRecordsFound = { description: "No business paid transactions found", transactionDate: null, debitAmount: null };
                    transactionMatches.push(noRecordsFound);
                }
                autoSetting.transactionMatches = new AutoAllocation.MatchingTransactions();
                autoSetting.transactionMatches.count = transactionMatches.length;
                autoSetting.transactionMatches.items = transactionMatches.slice(0, 10);
                this.decorateKeyword(autoSetting);
            } else {
                this.resetTransactionMatches(autoSetting);
            }
        }

        private decorateKeyword(autoSetting: AutoAllocation.IAutoAllocationItem): void {
            if (_.isUndefined(autoSetting)) return;
            if (_.isUndefined(autoSetting.transactionMatches) || autoSetting.transactionMatches.count === 0) return;
            _.forEach(autoSetting.transactionMatches.items, (matchedTransaction: any) => {
                matchedTransaction.description = _.unescape(matchedTransaction.description.toUpperCase())
                    .replace(_.escape(autoSetting.keyword).toUpperCase(), `<b>${_.escape(autoSetting.keyword).toUpperCase()}</b>`);

            });
        }

    }
}

angular.module("app.shared").service("autoAllocationService", Shared.AutoAllocationService);