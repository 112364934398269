/// <reference path="../../_all.ts"/>

module Shared {
    export interface IBrooksonUiPopup {
        showConfirmationPopup(title: string, message: string, acceptText?: string, closeText?: string): ng.IPromise<any>;
        showTemplatedConfirmationPopup(title: string, templateUrl: string, model: any, acceptText?: string, closeText?: string, popupSize?: string): ng.IPromise<any>;
        showYesNoPopup(title: string, message: string): ng.IPromise<any>;
        showInfoPopup(title: string, message: string): ng.IPromise<any>;
        showTemplatedInfoPopup(title: string, templateUrl: string): ng.IPromise<any>;
        showTemplatedHelpPopup(title: string, templateUrl: string): ng.IPromise<any>;
        showErrorPopup(message: string, errorRef: string): ng.IPromise<any>;
        showAcceptPopup(title: string, message: string, acceptText: string, size?: string): ng.IPromise<any>;
        showSaveAs(title: string, message: string): ng.IPromise<string>;
    }
}

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.ui.popup", [
        "$q", "$uibModal", "$sce", "$sanitize", ($q, $uibModal, $sce, $sanitize) => {
            var options: any = {
                animation: true,
                size: "sm",
                templateUrl: "src/app/shared/views/ui/brookson.ui.popup.html"
            };
            var _showConfirmationPopup = (title, message, acceptText, closeText) => {
                var defer = $q.defer();
                var confirmationOptions = options;

                confirmationOptions.controller = ['$scope', '$uibModalInstance', '$rootScope', ($scope, $uibModalInstance, $rootScope) => {
                    $scope.title = title;
                    $scope.message = $sanitize($sce.trustAsHtml(message));

                    if (acceptText) {
                        $scope.acceptButtonText = acceptText;
                    } else {
                        $scope.acceptButtonText = "Ok";
                    }

                    if (closeText) {
                        $scope.closeButtonText = closeText;
                    } else {
                        $scope.closeButtonText = "Cancel";
                    }

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        $rootScope.$broadcast('UserCancelledConfirmationPopup', { userClickedCancelledButton: true });
                        defer.reject();
                    };
                }];
                $uibModal.open(confirmationOptions);

                return defer.promise;
            };

            var _showTemplatedConfirmationPopup = (title, messageTemplateUrl, model, acceptText, closeText, popupSize) => {
                var defer = $q.defer();
                popupSize = popupSize || "sm";

                var infoOptions: any = {
                    animation: true,
                    size: popupSize,
                    windowClass: "templated-confirmation-popup",
                    templateUrl: "src/app/shared/views/ui/brookson.ui.templated-confirmation-popup.html"
                };;

                infoOptions.controller = ['$scope', '$uibModalInstance', 'memberSrv', ($scope, $uibModalInstance, memberSrv) => {
                    $scope.title = title;
                    $scope.messageTemplateUrl = messageTemplateUrl;
                    $scope.model = model;
                    $scope.businessType = memberSrv.member.businessType;
                    
                    if (acceptText) {
                        $scope.acceptButtonText = acceptText;
                    } else {
                        $scope.acceptButtonText = "Ok";
                    }

                    if (closeText) {
                        $scope.closeButtonText = closeText;
                    } else {
                        $scope.closeButtonText = "Cancel";
                    }

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];
                $uibModal.open(infoOptions);

                return defer.promise;
            };

            var _showSaveAs = (title, message, acceptText, closeText) => {
                var defer = $q.defer();

                options.templateUrl = "src/app/shared/views/ui/brookson.ui.popup.input.html";
                options.size = "md";
                var confirmationOptions = options;

                confirmationOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.message = $sanitize($sce.trustAsHtml(message));
                    $scope.result = "";

                    if (acceptText) {
                        $scope.acceptButtonText = acceptText;
                    } else {
                        $scope.acceptButtonText = "Ok";
                    }

                    if (closeText) {
                        $scope.closeButtonText = closeText;
                    } else {
                        $scope.closeButtonText = "Cancel";
                    }

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve($scope.result);
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];
                $uibModal.open(confirmationOptions);

                return defer.promise;
            };


            var _showYesNoPopup = (title, message) => {
                var defer = $q.defer();
                var yesNoOptions = options;

                yesNoOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.message = $sanitize($sce.trustAsHtml(message));
                    $scope.acceptButtonText = "Yes";
                    $scope.closeButtonText = "No";

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];

                $uibModal.open(yesNoOptions);

                return defer.promise;
            };
            var _showInfoPopup = (title, message) => {
                var defer = $q.defer();
                var infoOptions = options;

                infoOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.message = $sanitize($sce.trustAsHtml(message));
                    $scope.acceptButtonText = "Ok";

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];
                $uibModal.open(infoOptions);

                return defer.promise;
            };
            var _showTemplatedInfoPopup = (title, messageTemplateUrl) => {
                var defer = $q.defer();
                var infoOptions: any = {
                    animation: true,
                    size: "sm",
                    windowClass: "templated-info-popup",
                    templateUrl: "src/app/shared/views/ui/brookson.ui.templated-info-popup.html"
                };;

                infoOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.messageTemplateUrl = messageTemplateUrl;
                    $scope.acceptButtonText = "Ok";

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];
                $uibModal.open(infoOptions);

                return defer.promise;
            };
            var _showTemplatedHelpPopup = (title, messageTemplateUrl) => {
                var defer = $q.defer();
                var infoOptions: any = {
                    animation: true,
                    size: "sm",
                    windowClass: "templated-info-popup",
                    templateUrl: "src/app/shared/views/ui/brookson.ui.templated-help-popup.html"
                };;

                infoOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.messageTemplateUrl = messageTemplateUrl;
                    $scope.acceptButtonText = "Ok";

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];
                $uibModal.open(infoOptions);

                return defer.promise;
            };

            var _showErrorPopup = (message, errorRef) => {
                var defer = $q.defer();
                var errorOptions = options;

                errorOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = "Sorry";
                    $scope.message = $sanitize($sce.trustAsHtml(message));
                    $scope.acceptButtonText = "Ok";

                    if (errorRef) {
                        var errorMsg = "<strong>(Ref: " + errorRef + ")</strong>";
                        $scope.errorRef = $sce.trustAsHtml(errorMsg);
                    }

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };

                    $scope.cancel = () => {
                        $uibModalInstance.dismiss();
                        defer.reject();
                    };
                }];

                $uibModal.open(errorOptions);

                return defer.promise;
            };
            var _showAcceptPopup = (title, message, acceptText, size) => {
                var defer = $q.defer();

                options.size = size ? size : 'sm';
                var acceptOptions = options;

                acceptOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.title = title;
                    $scope.message = $sanitize($sce.trustAsHtml(message));

                    if (acceptText) {
                        $scope.acceptButtonText = acceptText;
                    } else {
                        $scope.acceptButtonText = "Ok";
                    }

                    $scope.ok = () => {
                        $uibModalInstance.close();
                        defer.resolve();
                    };
                }];
                $uibModal.open(acceptOptions);

                return defer.promise;
            };
            return {
                showConfirmationPopup: _showConfirmationPopup,
                showTemplatedConfirmationPopup: _showTemplatedConfirmationPopup,
                showYesNoPopup: _showYesNoPopup,
                showInfoPopup: _showInfoPopup,
                showTemplatedInfoPopup: _showTemplatedInfoPopup,
                showTemplatedHelpPopup: _showTemplatedHelpPopup,
                showErrorPopup: _showErrorPopup,
                showAcceptPopup: _showAcceptPopup,
                showSaveAs: _showSaveAs
            };
        }
    ]);
})();
