/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.http.gritter", ["growl", "$q", brooksonHttpGritter])
        .config(['$httpProvider', httpInterceptorGritterConfig]);

    function brooksonHttpGritter(growl, $q) {
        var extractMessages = (response) => {
            if (response.headers) {
                var messages = response.headers("gritter-messages");

                if (messages) {
                    messages = JSON.parse(messages);

                    var config: any = {
                         ttl: 5000,
                        disableCountDown: true
                    };

                    _.forEach(messages, (message) => {
                        switch (message.Severity) {
                            case "success":
                                config.ttl = 15000;
                                config.title = message.Title;
                                growl.success(message.Text, config);
                               
                                break;
                            case "info":
                                config.title = message.Title;
                                growl.info(message.Text, config);
                                break;
                            case "warning":
                                config.ttl = 15000;
                                config.title = message.Title;
                                growl.warning(message.Text, config);
                                 
                                break;
                            case "error":
                                config.ttl = 15000;
                                config.title = message.Title;
                                growl.error(message.Text, config);
                                break;
                            default:
                                config.ttl = -1;
                                config.title = message.Title;
                                growl.error(message.Text, config);
                        }
                    });
                }
            }

            return response;
        };

        return {
            'response': (response) => {
                return extractMessages(response);
            },

            'responseError': (response) => {
                return $q.reject(extractMessages(response));
            }
        };
    }

    function httpInterceptorGritterConfig($httpProvider) {
        $httpProvider.interceptors.push("brookson.http.gritter");
    }
})();
