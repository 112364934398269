/// <reference path="../../../_all.ts"/>

module Shared {
    'use strict';

    interface IBrooksonWidgetsPersonalTaxTrackerBindings {
        onComplete: Array<any>;
    }

    export class BrooksonWidgetsPersonalTaxTracker implements ng.IComponentOptions {
        public controller: Function = BrooksonWidgetsPersonalTaxTrackerController;
        public templateUrl: string = "src/app/shared/views/widgets/brookson.widgets.personal-tax.tracker.html";
        public bindings: { [binding: string]: string } = {
            onComplete: "&"
        };
    }

    class BrooksonWidgetsPersonalTaxTrackerController implements IBrooksonWidgetsPersonalTaxTrackerBindings {
        static $inject = ["$timeout", "personalTaxTrackerSrv", "brookson.navigation", "dashboardService"];

        public showSpinner: boolean = true;
        public showNewSetupText: boolean = false;
        public error: boolean = false;
        public widgetName: string = "brookson-widget-personal-tax-tracker";

        public onComplete;

        constructor(
            private $timeout: ng.ITimeoutService,
            private personalTaxSrv: Shared.IPersonalTaxTrackerService,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private dashboardService: Shared.IDashboardService
        ) { }

        $onInit = (): void => {
            this.personalTaxSrv.selectedTaxYearChanged("Previous Tax Year")
                .then((data) => {
                        this.showNewSetupText = _.sum([
                            data.forecastCalculations.confirmedAsDividends,
                            data.forecastCalculations.pscEmployment,
                            data.forecastCalculations.taxDeductedFromPSCIncome,
                            data.forecastCalculations.transactionsBeingProcessed,
                            data.forecastCalculations.unconfirmedTransactions
                        ]) === 0 && false;
                    }, error => {
                        this.error = true;
                    })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); });
                });
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetPersonalTaxTracker", new Shared.BrooksonWidgetsPersonalTaxTracker());