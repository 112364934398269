/// <reference path="./../services/brookson.services.ir35DetailCapture.ts" />

module Shared {
    /**
     * Interface for the Brookson IR35 Detail Capture Modal Service
     */
    export interface IBrooksonIR35DetailCaptureModalService {
        /**
         * Displays the IR35 Detail Capture modal
         */
        show(): ng.ui.bootstrap.IModalServiceInstance;
    }

    interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }

    /**
     * Brookson IR35 Detail Capture Modal Service
     */
    export class BrooksonIR35DetailCaptureModalService implements IBrooksonIR35DetailCaptureModalService {
        /**
         * The options for the bootstrap modal 
         */
        options: ng.ui.bootstrap.IModalSettings = {
            animation: true,
            size: "md",
            templateUrl: "src/app/shared/views/modals/brookson.modals.ir35DetailCapture.html",
            keyboard: false,
            controller: BrooksonIR35DetailCaptureModalController,
            controllerAs: "$ctrl",
            backdrop: 'static',
            windowClass: "ir35-detail-capture"
        };

        static $inject = ['$uibModal'];

        constructor(
            private $uibModal: ng.ui.bootstrap.IModalService
        ) { }

        show = (): ng.ui.bootstrap.IModalServiceInstance => {
            return this.$uibModal.open(this.options);
        }
    }

    /**
     * The controller for the Brookson IR35 Detail Capture Modal
     */
    export class BrooksonIR35DetailCaptureModalController {

        // From the backend: whether or not the popup modal can be cancelled or not
        public canBeCancelled: boolean = false;

        // Whether the user is on InternalConnect or not
        private isInternal: boolean;
        private validApiSource: boolean;

        static $inject = ['$uibModalInstance', 'ir35DetailCaptureService', '$window', 'memberSrv'];

        constructor(
            private $uibModalInstance,
            private ir35DetailCaptureService: Shared.IIR35DetailCaptureService,
            private $window: AppSettings,
            private memberService: Shared.IMemberService
        ) {
            this.validApiSource = this.$window.appSettings.validApiSource;
            this.isInternal = this.$window.appSettings.isInternal;
            this.canBeCancelled = (this.memberService.member.isAdmin || this.isInternal || this.validApiSource);
        }

        // One of the three buttons has been pressed...
        public buttonPressed = (sector: IR35DetailCaptureButtonPressed): void => {
            console.log('buttonPressed', sector);

            this.ir35DetailCaptureService.postIR35DetailCapture(sector).then((data: Shared.IR35DetailCaptureButtonPressed) => {
                this.close();
            });
        }

        // Close the modal
        public close = (): void => {
            this.$uibModalInstance.dismiss('cancel');
        }
    }
}

angular.module("app.shared").service("brookson.modal.ir35DetailCapture", Shared.BrooksonIR35DetailCaptureModalService);