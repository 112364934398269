/// <reference path="./../models/ConnectIntro.ts" />
/// <reference path="./../services/brookson.services.intro.ts" />
/// <reference path="./../services/brookson.services.member.ts" />
/// <reference path="./../services/brookson.services.ir35modal.ts" />
/// <reference path="./../services/brookson.services.assignment.ts" />
/// <reference path="./../models/MicroCalendlyBooking.ts" />
/// <reference path="./../models/InvoicingAssignment.ts" />


module Shared {

    export interface IBrooksonFactoriesIntro {

        getConnectIntros(): ng.IPromise<ConnectIntro>;
        setFirstTimeLoginSeen(): ng.IPromise<boolean>;
        startIntro: any;
        insertAuthorsationCode(authorisationCode: number): ng.IPromise<boolean>;
        showIR35AssessmentModal(): ng.IPromise<boolean>;
        insertIR35Assessment(answers: IR35AssessmentAnswers): ng.IPromise<boolean>;
        getAllAssignments(): ng.IPromise<Array<Shared.InvoicingAssignment>>;
        getListOfJobTitles(): ng.IPromise<string[]>;
        getListOfSectors(): ng.IPromise<string[]>;

    }

    export class BrooksonFactoriesIntro implements IBrooksonFactoriesIntro {

        connectIntros: ConnectIntro;
        member: MemberModel;

        static INTRO_LOCAL_STORAGE_KEY: string = "brookson.intro";


        static $inject = ['$q', 'brooksonServicesIntro', 'localStorageService', 'memberSrv', 'AssignmentSrv','ir35ModalService'];



        constructor(
            private $q: ng.IQService,
            private service: Shared.IBrooksonServicesIntro,
            private localStorageService: ng.local.storage.ILocalStorageService,
            private memberSrv: Shared.IMemberService,
            private assignmentSrv: Shared.IAssignmentService,
            private ir35ModalService: Shared.IIR35ModalService
        ) {
            this.member = this.memberSrv.getMember();
        }

        insertAuthorsationCode = (authorisationCode: number): ng.IPromise<boolean> => {
            return this.memberSrv.insertAuthorsationCode(authorisationCode)
                .then((data) => {
                    return data;
            });
        }

        getAllAssignments = (): ng.IPromise<Array<Shared.InvoicingAssignment>> => {
            return this.assignmentSrv.getAssignments().then((data) => {
                return data;
            });
        }

        showIR35AssessmentModal = (): ng.IPromise<boolean> => {
            return this.ir35ModalService.showIR35AssessmentModal().then((data) => {
                return data;
            });
        }

        insertIR35Assessment = (answers: IR35AssessmentAnswers): ng.IPromise<boolean> => {
            return this.ir35ModalService.insertIR35Assessment(answers).then((data) => {
                return data;
            });
        }

        getListOfJobTitles = (): ng.IPromise<string[]> => {
            return this.ir35ModalService.getListOfJobTitles().then((data) => {
                return data;
            });
        }

        getListOfSectors = (): ng.IPromise<string[]> => {
            return this.ir35ModalService.getListOfSectors().then((data) => {
                return data;
            });
        }

        /**
         * Returns an object containing each section on whether a user has seen the walkthrough
         * or not. It first checks local storage, if it exists, it will not make another request
         */
        getConnectIntros = (): ng.IPromise<ConnectIntro> => {
            let data = this.getIntrosFromLocalStorage();

            if (data) {
                var defer = this.$q.defer();
                defer.resolve(data);
                return defer.promise;
            }

            return this.service.getConnectIntros(true).then((data) => {
                this.connectIntros = data;
                this.setIntrosIntroLocalStorage();

                return data;
            });
        }


        setFirstTimeLoginSeen = (): ng.IPromise<boolean> => {
            this.connectIntros.firstTimeLogin = true;
            this.setIntrosIntroLocalStorage();

            return this.service.setFirstTimeLoginSeen(true).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        startIntro = () => { }

        private getIntrosFromLocalStorage = (): ConnectIntro => {
            var data = <ConnectIntro>this.localStorageService.get(this.getLocalStorageKey());

            this.connectIntros = data;

            return data;
        }


        private setIntrosIntroLocalStorage = (): void => {
            this.localStorageService.set(this.getLocalStorageKey(), this.connectIntros);
        }


        private getLocalStorageKey = (): string => `${BrooksonFactoriesIntro.INTRO_LOCAL_STORAGE_KEY}-${this.member.memberId}`;
    }
}

angular.module('app.shared')
    .service('brooksonFactoriesIntro', Shared.BrooksonFactoriesIntro);