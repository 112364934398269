/// <reference path="../../../_all.ts"/>

module Shared {
    'use strict';

    export interface IWidgetTaskPlannerBindings {
    }

    export class BrooksonWidgetTaskPlanner implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: '&'
            };

            this.controller = WidgetTaskPlannerCtrl;
            this.templateUrl = 'src/app/shared/views/widgets/brookson.widgets.task-planner.html';
        }
    };

    export interface IWidgetTaskPlannerCtrl extends IWidgetTaskPlannerBindings {
        tasks: Array<Object>;
    }

    export class WidgetTaskPlannerCtrl implements IWidgetTaskPlannerCtrl {
        public tasks: Array<Object> = [];
        public showSpinner: boolean = true;
        public onComplete: Function;
        public widgetName = "brookson-widget-task-planner";

        public dashboardService: Shared.IDashboardService = this._dashboardService;

        static $inject: Array<string> = ['$timeout', '$scope', 'taskPlannerSrv', 'brookson.navigation', 'taskPlanner.enums', 'dashboardService'];

        constructor(private $timeout: ng.ITimeoutService,
            private $scope: ng.IScope,
            private taskPlannerSrv: Shared.TaskPlannerSrv,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private enums: Shared.ITaskPlannerEnums,
            private _dashboardService: Shared.IDashboardService) {
            taskPlannerSrv.buildMonths()
                .then(() => {
                        this.tasks = _.chain(taskPlannerSrv.flatActions)
                            .filter((action) => {
                                return (action.status === 1 || action.status === 3) &&
                                    action.responsibility === this.enums.TaskResponsibility.ME;
                            })
                            .orderBy('dueDate', 'asc')
                            .take(5)
                            .map((n) => {
                                n.camelCase = _.camelCase(n.taskGroup);
                                return n;
                            })
                            .value();
                        this.showSpinner = false;
                        this.$timeout(() => { this.onComplete(); });
                    },
                    ex => {
                        this.showSpinner = false;
                        this.$timeout(() => { this.onComplete(); });
                    });
        }

        public gotoTask = (task) => {
            this.brooksonNavigation.taskPlanner.selectedActionTask(task.camelCase, task.actionId);
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonWidgetTaskPlanner', new Shared.BrooksonWidgetTaskPlanner());
