module Shared {
    export function FilterCurrency($filter, currencyEnums) {
        return (input, code, useValue, fractionSize) => {
            code = code ? code : currencyEnums.CurrencyCodes.GBP;
            input = input ? input : 0;

            return useValue ? $filter('currency')(input, currencyEnums.CurrencyCodes.properties[code].value, fractionSize)
                : $filter('currency')(input, currencyEnums.CurrencyCodes.properties[code].code, fractionSize);
        };
    }
    FilterCurrency.$inject = ['$filter', 'currency.enums'];
}

angular.module("app.shared").filter("brooksonCurrency", Shared.FilterCurrency);