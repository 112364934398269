module Shared {
    export enum SdcOutcomeType {
        NoChange = -1,
        NotAssessed = 0,
        Fail = 1,
        AwaitingEndClient = 2,
        FailedEndClient = 3,
        Pass = 4
    }

    export class SdcOutcomeTypeParser {
        public static getDescription = (value: Shared.SdcOutcomeType): string => {
            switch (value) {
                case Shared.SdcOutcomeType.NoChange:
                    return 'No Changes';
                case Shared.SdcOutcomeType.NotAssessed:
                    return 'Not Assessed';
                case Shared.SdcOutcomeType.Fail:
                    return 'Failed';
                case Shared.SdcOutcomeType.AwaitingEndClient:
                    return 'Awaiting End Client';
                case Shared.SdcOutcomeType.FailedEndClient:
                    return 'Failed End Client';
                case Shared.SdcOutcomeType.Pass:
                    return 'Passed';
                default:
                    return '';
            }
        }
    }
}