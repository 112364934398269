module Shared {

    export interface IBrooksonConfirmFinancialHealthCheckController extends IConfirmFinancialHealthCheck {
        
    }

    export class BrooksonConfirmFinancialHealthCheckController implements IBrooksonConfirmFinancialHealthCheckController {
        public action: Shared.TaskAction;
        public welcomeScreenData: Shared.WelcomeScreen;
        public financialHealthCheckUrl: string;
        //default to false otherwise will show on load.
        public showCalendarScreen: boolean = false;

        static $inject = ['$sce', 'calendlySrv', 'welcomeSrv', 'brookson.utilities.date'];
        constructor(
            public $sce: ng.ISCEService,
            public calendlySrv: Shared.CalendlyService,
            public welcomeSrv: Shared.WelcomeScreenSrv,
            public dateUtils: Shared.IBrooksonUtilitiesDate
        ) { }

        $onInit = (): void => {
            var financialCalendarUrl = this.welcomeScreenData.calendars.some(f => f.eventType === "Financial Health Check") ? this.welcomeScreenData.calendars.filter(f => f.eventType === "Financial Health Check")[0].calendarUrl : null;
            var financialHealthCheckUrl = !financialCalendarUrl ? null : this.$sce.trustAsResourceUrl(financialCalendarUrl);
            if (financialHealthCheckUrl) {
                this.calendlySrv.getCalendlyFinancialHealthCheckBooking().then((data: MicroCalendlyBooking): void => {
                    if (data) {
                        var financialHealthCheckCalendlyBooking = data;
                        var endDate = this.dateUtils.convertToJsDate(data.endDate);
                        var todaysDate = new Date();
                        var minimumPreviousDate = new Date();
                        minimumPreviousDate.setDate(minimumPreviousDate.getDate() - 30);

                        if (financialHealthCheckCalendlyBooking.uuid && endDate >= todaysDate) {
                            this.welcomeSrv.financialHealthCheckCalendlyBooking = financialHealthCheckCalendlyBooking;
                            this.financialHealthCheckUrl = this.$sce.trustAsResourceUrl('https://calendly.com/reschedulings/' + financialHealthCheckCalendlyBooking.uuid);
                        }
                        if (endDate <= minimumPreviousDate) {
                            this.welcomeSrv.financialHealthCheckCalendlyBooking = financialHealthCheckCalendlyBooking;
                            this.financialHealthCheckUrl = financialHealthCheckUrl;
                            this.welcomeSrv.financialHealthCheckCalendlyBooking.uuid = null;
                        }
                    }
                });
            }
        }

        openBookingScreen(): void {
            this.showCalendarScreen = true;
        }
    }
    export const BrooksonConfirmFinancialHealthCheck: ng.IComponentOptions = {
        bindings: {
            action: '=',
            welcomeScreenData: '='
        },
        controller: BrooksonConfirmFinancialHealthCheckController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.confirm.financial-health-check.html',
        transclude: true
    };

    export interface IConfirmFinancialHealthCheck {
        action: any;
        welcomeScreenData: Shared.WelcomeScreen;
    }
}

angular.module('app.shared')
    .component('brooksonConfirmFinancialHealthCheck', Shared.BrooksonConfirmFinancialHealthCheck);