/// <reference path="../../../_all.ts"/>
/// <reference path="../../models/auto-allocation/AutoAllocationItem.ts"/>
/// <reference path="../../services/brookson.services.auto-allocation.ts"/>
/// <reference path="../../../shared/models/open-bankiing-widget/WidgetAccount.ts" />

module Shared {
    
    interface Transaction {
        date: string;
        amount: number;
        balance: number;
        type: string;
        payee: string;
        description: string;
        reference: string;
        selected: boolean;
    }

    interface Step {
        title: string;
        isOpen: boolean;
    }

    export class BrooksonBankFileImport implements ng.IComponentOptions {
        controller: Function = BrooksonBankFileImportController;
        templateUrl = "src/app/shared/views/bank/brookson.banklink.bankfilupload.html";
        onComplete: Function;
    }

    class BrooksonBankFileImportController {
        static $inject = ["$window", "autoAllocationService", "bankLinkSrv", "serviceLineSrv", "brookson.utilities.date", "moneyManagerSrv", "$sanitize", "$scope", "$timeout", "flowFactory", "bankLinkFactory", 'openbankingWidgetService','$q', 'authenticationSrv', '$window','$sce', '$log', "growl"];
        public dropboxUploadOptions: flowjs.IFlow;
        loading: boolean;
        companyConfigList: Array<AutoAllocation.IAutoAllocationItem>;
        showDisabled: boolean;
        allowAddNewSetting: boolean;
        autoAllocationFrm;
        showTransactionDateFrm: boolean;
        todaysDate: Date;
                
        public steps: Step[];
        public selectedBankAccount: any;
        public updatedTransactionDate: Date;
        public bankAccounts: any[];
        public fileContent: string;
        public transactions: Transaction[];
        public progress: number;
        public uploadStatus: string;
        public uploadMessage: string;
        public selectAll: boolean;
        public submissionSuccess: boolean;
        public dateTransactionSuccess: boolean;
        public submittedRowCount: number;
        public submittedDateRange: string;
        public submittedTotalAmount: number;

        public showSpinner: boolean;
        public bankAccountList: Array<Shared.openbankingWidget.WidgetAccount>;
        public error: boolean;
        public isSubmitError: boolean;
        public submitMessage: string;
        public onComplete;
        currentFileId: string;
        public options: any;
        public summary: any;
        public selectedPendingStatement: boolean;
        public navBankTransactions: any = {};        

        constructor(
            private window,
 
            private autoAllocationService: Shared.IAutoAllocationService,
            private bankLinkService: Shared.IBankLinkSrv,
            private serviceLineSrv: Shared.ServiceLineService,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            private moneyManagerSrv: Shared.IMoneyManagerService,
            private $sanitize: any,
            private $scope: ng.IScope,
            private $timeout: ng.ITimeoutService,
            private flowFactory: ng.flow.IFlowFactory,
            private data: Shared.IBankLinkFactory,
            private openbankingWidgetService: Shared.OpenbankingWidgetService,
            private $q: ng.IQService,

            public authenticationSrv: Shared.IAuthenticationService,
            public $window: ng.IWindowService,
            public $sce: ng.ISCEService,
            private $log: ng.ILogService,
            private growl: ng.growl.IGrowlService,
        ) {
            this.showDisabled = false;
            this.loading = true;
            $scope['uploadOptions'] = this.flowFactory.create({
                permanentErrors: ['404', '500', '501'],
                target: '/api/BankLink/UploadStatementFile',
                headers: {
                    '__RequestVerificationToken': $window.brookson.antiForgeryToken,
                    "Accept": "application/json"
                },
                testChunks: false,
                chunkSize: 9007199254740991,
                singleFile: true
            });

            $scope['cancel'] = this.cancel;
            $scope['flowFilesSubmitted'] = this.flowFilesSubmitted;
            $scope['flowFileError'] = this.flowFileError;
            $scope['flowFileSuccess'] = this.flowFileSuccess;
            $scope['fileErrors'] = [];
            $scope['sentSuccessfully'] = false;
            $scope['title'] = "Upload Bank Statement";
            $scope['successfullMessage'] = "Uploaded";
            $scope['uploadMessage'] = "";
            $scope['additionalHtml'] = this.$sce.trustAsHtml("");
            $scope['authenticationSrv'] = authenticationSrv;

        }

        $onInit = (): void => {
            this.todaysDate = new Date();
            this.showSpinner = true;
            this.$q.all([
                this.openbankingWidgetService.getGetBankStatementData(true)
            ]).then((data) => {
                this.bankAccountList = data[0];
                this.setupAccounts(this.bankAccountList);
                this.showSpinner = false;
            }, () => {
                    this.error = true;
            }).then(() => {
                this.showSpinner = false;
            });

            this.data.getBankAccounts();
            
            this.currentFileId = this.uuid();
              
            this.steps = [
                { title: 'Select Bank Account', isOpen: true },
                { title: 'Upload Bank Statement', isOpen: false },
                // Add Step 3 for column mapping
                { title: 'Review Transactions', isOpen: false },
                { title: 'Submission Summary', isOpen: false },
            ];

            this.transactions = [];
            this.progress = 0;
            this.uploadStatus = '';
            this.uploadMessage = '';
            this.selectAll = false;
            this.submissionSuccess = false;
        }       

        public cancel = (): void => {
             
        }

        fileDeletetedClicked(flow) {
            flow.cancel();
        }

        public flowFilesSubmitted = (files, event, flow, path) => {
            this.$log.debug("Submitted");
            // Clear any previous upload errors
            this.$scope['fileErrors'] = [];
            this.$scope['sentSuccessfully'] = false;
            this.$scope['authenticationSrv'].refreshToken().then(data => {
                flow.opts.query.dropboxUploadPath = path;
                flow.opts.headers.Authorization = "Bearer " + data.access_token;
                flow.opts.headers.BankAccountId = this.selectedBankAccount.key;
                flow.opts.headers.BankAccountKey = this.selectedBankAccount.bankAccountKey;
                flow.upload();
            });
        }

        public flowFileError($file, $message, $flow) {
            this.growl.error("There was an error uploading this bank file " + $file.name);
            this.$log.debug("Error");
            this.uploadStatus = 'error';
            this.$scope['fileErrors'].push($file);
            this.error = true;

        }

        public flowFileSuccess = ($file, $message, $flow) => {
            if($message!=="")
            {
                this.$scope['sentSuccessfully'] = true; 
                this.summary = JSON.parse($message);
                this.uploadStatus = 'uploaded';
                this.openNextStep(1);
            } else 
            {
                this.$scope['sentSuccessfully'] = true; 
                this.uploadStatus = 'progress';     
            }
        }

        showTransactionDate = (): void =>
        {
            this.showTransactionDateFrm = true;
        }

        hideTransactionDate = (): void =>
        {
            this.showTransactionDateFrm = false;
        }

        /**
         * Flowjs event when the the upload has been completed
         * 
         * @param {flowjs.IFlow} $flow
         * 
         * @memberOf BrooksonExpensesAddReceiptController
         */
        flowComplete = ($flow: flowjs.IFlow): void => {
            this.$log.debug("Complete");
            
            this.$timeout((flow) => {
                flow.files = [];
                // this.fileTypeValidationErrors = [];
                // this.fileSizeValidationErrors = [];

            }, 4000, true, $flow);
        }

        uuid = () => {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
              var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
              return v.toString(16);
            });
        }       

        private setupAccounts = (bankAccounts : Shared.openbankingWidget.WidgetAccount[]): void => {
            _.each(bankAccounts, (bankAccount): void => {
                bankAccount.type = Shared.openbankingWidget.bankType[bankAccount.type].replace('_', ' ');
                bankAccount.accountType = Shared.openbankingWidget.accountType[bankAccount.accountType];
                if (bankAccount.consentStatus === Shared.openbankingWidget.ConsentStatus.AccessTokenReceived && !bankAccount.consentExpired) {
                    bankAccount.success = true;
                }

                if (bankAccount.viewAccess === false) {
                    bankAccount.className = 'redBox';
                } else {
                    if (bankAccount.viewAccessDescription.toLowerCase().indexOf('view access') > -1) {
                        bankAccount.className = 'orangeBox';
                    }
                    if (bankAccount.viewAccessDescription.toLowerCase().indexOf('open banking') > -1) {
                        bankAccount.className = 'greenBox';
                    }
                }

            });
        }
                
        getLastFiveBankTransaction = (): void => {
            this.bankLinkService.getLastFiveBankTransaction(this.selectedBankAccount.bankAccountKey).then((navBankTransactions) => {
                navBankTransactions = _.sortBy(navBankTransactions, 'entryNo');
                this.navBankTransactions = navBankTransactions.reverse();
            });
        }

        selectBankAccount(account: any, $flow: flowjs.IFlow): void {
            if($flow){
                $flow.cancel();
            }
            if($flow && $flow.files)
            {
                $flow.removeFile($flow.files[0]);
                $flow.files = [];
            }
            this.$scope['sentSuccessfully'] = false; 
            this.selectedPendingStatement = false;

            if(account.hasPendingBankStatement)
            {
                this.selectedPendingStatement = true;

            } else {
                this.selectedPendingStatement = false;
            }

            this.selectedBankAccount = account;
            this.getLastFiveBankTransaction();

            this.openNextStep(0);
        }

        openNextStep(stepIndex: number): void {
            this.steps[stepIndex].isOpen = false;
            this.steps[stepIndex+1].isOpen = true;
        }

        openBackStep(stepIndex: number): void {
            this.steps[stepIndex].isOpen = false;
            this.steps[stepIndex-1].isOpen = true;
        }

        fileUploaded(flow: flowjs.IFlow, file: any, message: string): void {
            this.uploadStatus = '';
            this.uploadMessage = 'Uploading file...';
            this.progress = 0;

            flow.upload();

            
            flow.on('progress', (flowProgress: number) => {
                this.$timeout(() => {
                    this.progress = Math.floor(flowProgress * 100);
                });
            });

            flow.on('fileSuccess', (file: any, message: string) => {
                
                this.$timeout(() => {
                    this.fileContent = message;
                    this.uploadStatus = 'Success';
                    this.uploadMessage = 'File uploaded successfully!';
                });
            });

            flow.on('fileError', (file: any, message: string) => {
                this.growl.error("There was an error uploading this bank file " + file.name);
                this.$timeout(() => {
                    this.uploadStatus = 'Error';
                    this.uploadMessage = 'File upload error: ' + message;
                    this.error = true;
                });
            });
        }
        
        toggleAll(): void {
            this.summary.summary.bankStatementRows.forEach((transaction: Transaction) => {
                transaction.selected = this.selectAll;
            });
        }

        toggleRow(transaction: Transaction): void {
            transaction.selected = !transaction.selected;
            this.selectAll = this.summary.summary.bankStatementRows.every((t) => t.selected);
        }

        numberOfSelectedRows(): number {
            return this.summary.summary.bankStatementRows.filter((t) => t.selected).length;
        }

        totalAmountOfSelectedRows(): number {
            return this.summary.summary.bankStatementRows.reduce((sum: number, t) => {
                return t.selected ? sum + t.amount : sum;
            }, 0);
        }
     
        newBalanceAmount(): number {
            this.updatedTransactionDate = this.summary != undefined && this.summary.summary.postingDate;
            return this.summary != undefined  && this.summary.summary.totalAmount + this.selectedBankAccount.availableBalance;
        }

        hasSelectedTransactions(): boolean {
            return this.summary != undefined  && this.summary.summary.totalCount > 0;
        }   

        updateTransactions(): void {
            this.submittedRowCount = this.numberOfSelectedRows();
            this.submittedTotalAmount = this.totalAmountOfSelectedRows();

            const selectedTransactions = this.summary.summary.bankStatementRows.filter((t) => t.selected);
            const minDate = Math.min(...selectedTransactions.map(t => new Date(t.date).getTime()));
            const maxDate = Math.max(...selectedTransactions.map(t => new Date(t.date).getTime()));
            this.submittedDateRange = new Date(minDate).toLocaleDateString() + ' - ' + new Date(maxDate).toLocaleDateString();
            //API Submit
        }

        submitTransactions = () => {
            this.updateTransactions();
            this.bankLinkService.submitBankStatementBatch(this.summary.summary.batchId, this.selectedBankAccount.key, new Date(this.updatedTransactionDate)).then(
                data => {
                    this.submissionSuccess = data.success;
                    this.isSubmitError = !data.success;
                    this.submitMessage = data.message;
                }
            );
        }

        submitTransactionDate = () => {
            this.bankLinkService.updateBankStatementDate(this.selectedBankAccount.key, new Date(this.updatedTransactionDate)).then(
                data => {
                    this.dateTransactionSuccess = data;
                        }
            );
        }

        cancelBatch(): void {
            this.bankLinkService.cancelBankStatementBatch(this.selectedBankAccount.batchId);
        }

        clearBatch(batchId: number): void {
            this.showSpinner = true;

            this.$q.all([this.bankLinkService.clearBankStatementBatch(batchId)   
            ]).then((data) => {
                this.$window.location.href = `${this.$window.location.origin}/banklink/bank-file`;
            }, () => {
                    this.error = true;
            }).then(() => {
                this.showSpinner = false;
            });

            this.data.getBankAccounts();           
        }

        addNew = () => {
            this.companyConfigList.push(this.autoAllocationService.createNewAllocationSetting());
            this.allowAddNewSetting = false;
        }

        invokeBackButton = (): void => {
            this.window.history.back();
        }
    }
}

angular.module("app.shared")
    .component("brooksonBankFileImport", new Shared.BrooksonBankFileImport());