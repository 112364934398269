/// <reference path="./AccountType.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class Member {

        // MEMBERID
        public memberID: number = 0;
        // ALTMEMBERID
        public altMemberID: number = 0;
        // FIRSTNAME
        public firstname: string = null;
        // LASTNAME
        public lastname: string = null;
        // EMAIL
        public email: string = null;
        // GROUPS
        public groups: string = null;
        // USERNAME
        public username: string = null;
        // PERSONALTAXACTIVE
        public personalTaxActive: boolean = false;
        // COMPANYREF
        public companyRef: string = null;
        // USERTYPE
        public userType: number = 0;
        // ACCOUNTTYPE
        public accountType: AccountType = null;
        // USESTSTYLEINVOICING
        public useSTStyleInvoicing: boolean = false;
        // ISACTIVE
        public isActive: boolean = false;
        // DATEOFBIRTH
        public dateOfBirth: Date = null;
        // CREATEDDATE
        public createdDate: Date = null;

        public businessType: string = null;

        public role: string = null;

        public connect2Enabled: boolean = false;
        public companyName: string = null;
    }
}
