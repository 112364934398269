
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class MemberCurrentStage  {
        
        // MEMBERID
        public memberId: number = 0;
        // STAGE
        public stage: number = 0;
        // STEP3
        public step3: boolean = false;
        // STEP4
        public step4: boolean = false;
        // COMPLETIONDATE
        public completionDate: Date = null;
        // COUNTOFASSIGNMENTREQUIRINGACTIONS
        public countOfAssignmentRequiringActions: number = 0;
        // ISIR35CONFIRMED
        public isIr35Confirmed: boolean = false;
        // ISDRAFTSCONFIRMED
        public isDraftsConfirmed: boolean = false;
        // SHOWCONFIRMATIONMESSAGE
        public showConfirmationMessage: boolean = false;
    }
}
