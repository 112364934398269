/// <reference path="./ESAQuestionaireAnswer.ts"/>
/// <reference path="./ESAContractFile.ts"/>
/// <reference path="./IndustrySectors.ts"/>

module EmploymentStatus {

    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


     export class EsaQuestionaire {

            // ESAQUESTIONAIREID
            public esaQuestionaireId: number = 0;
            // REVIEWID
            public reviewId: number = 0;
            // DATECREATED
            public dateCreated: Date = null;
            // VERSION
            public version: number = 0;
            // EMPSTATUSHELPCONFIRM
            public empStatusHelpConfirm: boolean = false;
            // ANSWERSTRUECONFIRM
            public answersTrueConfirm: boolean = false;
            // CONTACTTIME
            public contactTime: string = null;
            // ASSIGNMENTID
            public assignmentId: string = null;
            // SAVED
            public saved: boolean = false;
            // SITELOCATION
            public siteLocation: string = null;
            // JOBTITLE
            public jobTitle: string = null;
            // INDUSTRYSECTOR
            public industrySector: string = null;
            // DATESUBMITTED
            public dateSubmitted: Date = null;
            // ESAQUESTIONAIREANSWERS
            public esaQuestionaireAnswers: Array<EsaQuestionaireAnswer> = [];
            //ESACONTRACTFILE
            public contractFileExists: boolean = false;
            //FLOW
            public flow: any = null;
            //FLOWOPTIONS
            public flowOptions: any = null;
            //ESACONTRACTFILE
            public esaContractFile: ESAContractFile = null;
            //DELETE CONTRACT
            public deleteContract: boolean = false;

            /**
             *
             */
            constructor(assignmentID:string) {
               this.assignmentId = assignmentID;
               this.dateCreated = new Date();
               this.version = 3;
               this.dateSubmitted = new Date();
            }
    }
}
