/// <reference path="../../services/brookson.services.task-planner.ts" />

module Shared {
    'use strict';

    export interface IBrooksonAuthCodeInputBindings {
    }

    const BrooksonAuthCodeInput: ng.IComponentOptions = {
        bindings: {
        },
        controller: 'BrooksonAuthCodeInputController',
        templateUrl: 'src/app/shared/views/ui/brookson.ui.auth-code.input.html',
        transclude: true
    };

    angular
        .module('app.shared')
        .component('brooksonAuthCodeInput', BrooksonAuthCodeInput);
}

module Shared {
    'use strict';

    export interface IBrooksonAuthCodeInputComponentController extends IBrooksonAuthCodeInputBindings {
        authCode: string;
        authCodePattern: any;
       
    }

    export class BrooksonAuthCodeInputController implements IBrooksonAuthCodeInputComponentController {

        authCode: string;
        authCodePattern: any = /^SA\d{8}$/i;

        static $inject: Array<string> = ['taskPlannerSrv', 'growl'];

        constructor(
            private taskPlannerSrv: Shared.ITaskPlannerSrv,
            private growl: angular.growl.IGrowlService)
        { }

        public updateAuthCode = (): void => {

            if (!this.authCodePattern.test(this.authCode))
            {
                let config: angular.growl.IGrowlMessageConfig = { title: "Task Planner" };
                this.growl.error("Authorisation code must be 10 digits", config);
                return;
            }
            this.taskPlannerSrv.updateAuthCode(this.authCode);
        }
    }

    angular
        .module('app.shared')
        .controller('BrooksonAuthCodeInputController', BrooksonAuthCodeInputController);
}
