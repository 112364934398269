/// <reference path="../../_all.ts"/>

(() => {
    'use strict';
    var appShared = angular.module('app.shared');

    appShared.directive('focusOn', () => {
        return (scope, elem, attr) => {
            return scope.$on('focusOn', (e, name) => {
                if (name === attr.focusOn) {
                    return elem[0].focus();
                }
            });
        };
    });

    appShared.factory('focus', [
      '$rootScope', '$timeout', (($rootScope, $timeout) => {
          return (name) => {
              return $timeout(() => {
                  return $rootScope.$broadcast('focusOn', name);
              });
          };
      })
    ]);
})();
