/// <reference path="./Address.ts"/>
/// <reference path="./AssignmentCompact.ts"/>
/// <reference path="./ClientRate.ts"/>


module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class CustomerCompact  {
        
        // CUSTOMERNUMBER
        public customerNumber: string = null;
        // CUSTOMERNAME
        public customerName: string = null;
        // CUSTOMERADDRESS
        public customerAddress: string = null;
        // CUSTOMERADDRESSOBJ
        public customerAddressObj: Address = null;
        // CUSTOMEREMAIL
        public customerEmail: string = null;
        // CONTACTNAME
        public contactName: string = null;
        // CONTACTNUMBER
        public contactNumber: string = null;
        // CONTACTEMAIL
        public contactEmail: string = null;
        // BILLINGADDRESS
        public billingAddress: string = null;
        // BILLINGADDRESSOBJ
        public billingAddressObj: Address = null;
        // ASSIGNMENTS
        public assignments: AssignmentCompact[] = [];
        // STATUS
        public status: boolean = false;
        // ISSELFBILLING
        public isSelfBilling: boolean = false;
        // SELFBILLINGMETHOD
        public selfBillingMethod: number = 0;
        // RATES
        public rates: ClientRate[] = [];
        // CUSTOMERADDRESSADDRESSLINE1
        public customerAddressAddressLine1: string = null;
        // CUSTOMERADDRESSADDRESSLINE2
        public customerAddressAddressLine2: string = null;
        // CUSTOMERADDRESSADDRESSLINE3
        public customerAddressAddressLine3: string = null;
        // CUSTOMERADDRESSTOWN
        public customerAddressTown: string = null;
        // CUSTOMERADDRESSCOUNTY
        public customerAddressCounty: string = null;
        // CUSTOMERADDRESSPOSTCODE
        public customerAddressPostcode: string = null;
        // CUSTOMERADDRESSCOUNTRY
        public customerAddressCountry: string = null;
        // BILLINGADDRESSADDRESSLINE1
        public billingAddressAddressLine1: string = null;
        // BILLINGADDRESSADDRESSLINE2
        public billingAddressAddressLine2: string = null;
        // BILLINGADDRESSADDRESSLINE3
        public billingAddressAddressLine3: string = null;
        // BILLINGADDRESSTOWN
        public billingAddressTown: string = null;
        // BILLINGADDRESSCOUNTY
        public billingAddressCounty: string = null;
        // BILLINGADDRESSPOSTCODE
        public billingAddressPostcode: string = null;
        // BILLINGADDRESSCOUNTRY
        public billingAddressCountry: string = null;
        // AGENCYREFERENCE
        public agencyReference: string = null;
        // CLIENTID
        public clientId: number = 0;
        // CLIENTADDRESSID
        public clientAddressId: number = 0;
        // BILLINGADDRESSID
        public billingAddressId: number = 0;
        // NARRATIVE
        public narrative: string = null;
        // PONUMBER
        public poNumber: string = null;
        public timesheetFrequencySelector: EnumObj;
        public timesheetFrequencyStatus: any;
        public timesheetFrequency: Shared.ITimesheetFrequency;
        public timesheetFrequencyOptionsList: Array<EnumObj>;
        public isDirectEngagement: boolean = false;
    }
}
