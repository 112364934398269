/// <reference path="../../../../app/_all.ts"/>

module Shared {
    export class BrooksonExpensesInputValidVatReceipt implements ng.IComponentOptions {
        public bindings: any;
        public templateUrl: any;
        public controller: any;

        constructor() {
            this.bindings = {
                ngModel: '=',
                inputName: '=',
                ngChange: '&',
                ngDisabled: '=',
                showLabel: '='
            };
            this.templateUrl = 'src/app/shared/views/inputs/expenses.input.valid-vat-receipt.html';
            this.controller = BrooksonExpensesInputValidVatReceiptController;
        }
    }

    export class BrooksonExpensesInputValidVatReceiptController {
        ngChange: any;

        static $inject = ['$timeout'];

        constructor(
            private $timeout: ng.ITimeoutService
        ) {
        }

        changeInternal = () => {
            if (this.ngChange) {
                this.$timeout(() => {
                    this.ngChange();
                });
            }
        };
    }
}

angular.module('app.shared')
    .component("brooksonExpensesInputValidVatReceipt", new Shared.BrooksonExpensesInputValidVatReceipt());