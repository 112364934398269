/// <reference path="./../models/company-year-end/CompanyYearEndTile.ts" />

module Shared {
   
    export interface IBrooksonServicesCompanyYearEnd {
        
        getCompanyYearEndTileDetails(silentMode?: boolean) : ng.IPromise<CompanyYearEndTile>;
       
        setDraftAccountsConfirmed(taxYear: number, reason: string): ng.IPromise<boolean>;
    }

   
    export class BrooksonServicesCompanyYearEnd implements IBrooksonServicesCompanyYearEnd {
       
        static $inject = ['$http'];
       
        constructor(private $http: Shared.IBrooksonHttp) {}

       
        getCompanyYearEndTileDetails = (silentMode?: boolean) : ng.IPromise<CompanyYearEndTile> => {
            return this.$http.get(`api/CompanyYearEnd/GetCompanyYearEndTile`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<CompanyYearEndTile>) => {
                return response.data;
            });
        }

        setDraftAccountsConfirmed = (taxYear: number, reason: string): ng.IPromise<boolean> => {
            let url = 'api/CompanyYearEnd/SetDraftAccountsConfirmed';

            var requestData = {
                taxYear: taxYear,
                reason: reason
            };

            const config = {
                silentMode: false
            };

            return this.$http.post(url, requestData, config).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared')
    .service('brooksonServicesCompanyYearEnd', Shared.BrooksonServicesCompanyYearEnd);