/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetUmbrellaFinancialAdverts', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.umbrella-financial-adverts.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetUmbrellaFinancialAdverts
    });

    brooksonWidgetUmbrellaFinancialAdverts.$inject = ['$timeout', 'brookson.navigation', 'dashboardService'];

    function brooksonWidgetUmbrellaFinancialAdverts($timeout, brooksonNavigation, _dashboardService) {

        var vm = this;

        vm.brooksonNavigation = brooksonNavigation;
        vm.widgetName = "brookson-widget-umbrella-financial-adverts";
        
    }
})();
