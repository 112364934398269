module Shared {
    export class AppSettings {
        environment: string;
        isDevelopment: boolean;
        isStaging: boolean;
        isProduction: boolean;
        isInternal: boolean;
        dashboardEnabled: boolean;
        personalTaxPlannerEnabled: boolean;
        personalTaxEnabled: boolean;
        personalTaxTrackerEnabled: boolean;
        personalTaxHMRCPersonalAccountBalanceEnabled: boolean;
        personalTaxSelfAssessment: boolean;
        personalFinanceEnabled: boolean;
        pscAssignmentsEnabled: boolean;
        personalFinancePortfolioTrackerEnabled: boolean;
        personalTaxEnabledPersonalFinanceRiskProfileEnabled: boolean;
        personalFinanceRetirementPlannerEnabled: boolean;
 
        financialServicesEnabled: boolean;
        pscEnabled: boolean;
        pscMoneyManagerEnabled: boolean;
        pscExpensesEnabled: boolean;
        pscExpensesEnabledGlobally: boolean;
        pscInvoicingEnabled: boolean;
        pscBankEnabled: boolean;
        pscTaskPlannerEnabled: boolean;
        pscDocumentStorageEnabled: boolean;
        pscEmploymentStatusEnabled: boolean;
        pscCalendarEnabled: boolean;
        knowledgeBaseEnabled: boolean;
        advisorySectionEnabled: boolean;
        allowedToFileSelfAssessments: boolean;
        googleAnalyticsTrackingCode: string;
        smeEnabled: boolean;
        smeHolidaysEnabled: boolean;
        smePayslipsEnabled: boolean;
        smeExpensesEnabled: boolean;
        smeStaffEnabled: boolean;
        smeOvertimeEnabled: boolean;
        smeManagement: boolean;
        employeeSmeEnabled: boolean;
        smeHolidayRequestsEnabled: boolean;
        smeOvertimeRequestsEnabled: boolean;
        smeDocumentStorageEnabled: boolean;
        smeEmployeeProfile: boolean;
        smeEmployeeOvertime: boolean;
        smeEmployeeHolidays: boolean;
        smeEmployeeExpenses: boolean;
        smeEmployeePayslips: boolean;
        smeEmployeeDocumentStorageEnabled: boolean;
        smePersonalTaxTrackerEnabled: boolean;
        connect2Url: string;
        invoiceTitle: string;
        paymentTitle: string;
        invoiceDescription: string;
        hasBeenSdcAssessedForAllAssignments: boolean;
        displaySdcQuestionnaireCompletionPopup: boolean;
        displayIR35DetailCapturePopup: boolean;
        disableExpenseSubmission: boolean;
        disableExpenseSubmissionDateTime: boolean;
        displayPublicSectorAssignmentCheckPopup: boolean;
        displayUmbrellaTrustModal: boolean;
        umbEnabled: boolean;
        showMoneyMangerTransactions: boolean;
        disableSDCOnAssignment: boolean;
        enableExpenseEnhancementModal: boolean;
        ltdCustomerReference: string;
        hasLtdBusinessType: boolean;
        hasMulipleLtdCompanies: boolean;
        umbCustomerReference: string;
        hasUmbBusinessType: boolean;
        finplanUrl: string;
        validApiSource: boolean;
        isIntouchSite: boolean;
        newInvoiceEnabled: boolean;
        pdfModalEnabled: boolean;
        disableWorldPay: boolean;
        siteCompanyName: string;
    }
}