module Shared {
    /**
     * 
     * Interface for the Privacy Factory
     * @export
     * @interface IBrooksonFactoryPrivacy
     */
    export interface IBrooksonFactoryPrivacy {
        privacyModeEnabled: boolean;
        hotKeyToEnablePrivacyMode: string;

        togglePrivacy(): void;
        isPrivacyModeEnabled(silentMode?: boolean): ng.IPromise<boolean>;
        setPrivacyMode(enabled: boolean, silentMode?: boolean): ng.IPromise<boolean>;
    }

    /**
     * 
     * Factory to handle toggling the privary feature
     * @export
     * @class BrooksonFactoryPrivacy
     * @implements {IBrooksonFactoryPrivacy}
     */
    export class BrooksonFactoryPrivacy implements IBrooksonFactoryPrivacy {

        /**
         * 
         * Stores the value on whether privacy mode is enabled
         * @private
         * @type {boolean}
         */
        public privacyModeEnabled: boolean = false;

        /**
         * 
         * The hotkey to toggle privacy mode
         * WARNING: This is used by the hotkey library and also used to display the hotkey on screen.
         * @private
         * @type {string}
         */
        public hotKeyToEnablePrivacyMode: string = "Ctrl + Shift + H";

        /**
         * 
         * DI
         * @static
         */
        static $inject = ['$document', '$http', 'hotkeys','screenSize'];

        /**
         * Creates an instance of BrooksonFactoryPrivacy.
         * 
         * @param {ng.IDocumentService} $document
         */
        constructor(
            private $document: ng.IDocumentService,
            private $http: Shared.IBrooksonHttp,
            private hotkeys: angular.hotkeys.HotkeysProvider,
            private screenSize: any
        ) {
            this.setupHotkeys();
        }

        /**
         * Turn the privacy feature on and off
         */
        public togglePrivacy = () => {
            this.privacyModeEnabled = !this.privacyModeEnabled;
            this.toggleBlur();
            this.setPrivacyMode(this.privacyModeEnabled, true);
        };

        /**
         * Returns whether the last state of the dashboard was in privacy mode or not
         * 
         * @private
         * @param {boolean} [silentMode]
         * @returns {ng.IPromise<boolean>}
         */
        public isPrivacyModeEnabled(silentMode?: boolean): ng.IPromise<boolean> {
            return this.$http.get(`api/Dashboard/IsPrivacyModeEnabled`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                if (!this.screenSize.is("lg")) {
                    response.data = false; //this will be toggled to true for anything but lg
                }
                if (response.data) this.togglePrivacy();
                return response.data;
            });
        }

        /**
         * Sets the PrivacyModeEnabled flag in the database to either true or false
         * 
         * @param {boolean} enabled
         * @param {boolean} [silentMode]
         * @returns {ng.IPromise<boolean>}
         */
        public setPrivacyMode(enabled: boolean, silentMode?: boolean): ng.IPromise<boolean> {
            return this.$http.post(`api/Dashboard/SetPrivacyMode`, {
                enabled: enabled
            }, {
                    silentMode: silentMode ? silentMode : false
                }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                    return response.data;
                });
        }

        /**
         * 
         * Toggles the blur class on the blur-area element identifier
         * @private
         */
        private toggleBlur = () => {
            const blurArea = angular.element('.blur-area');

            if (this.privacyModeEnabled) blurArea.addClass('blur');
            else blurArea.removeClass('blur');
        };

        /**
         * Uses the hotkeys library to create a hotkey for enabling and disabling
         * 
         * @private
         */
        private setupHotkeys = () => {
            let formatHotKey = this.hotKeyToEnablePrivacyMode.replace(/ /g, '').toLowerCase();

            this.hotkeys.add({
                combo: formatHotKey,
                callback: () => {
                    this.togglePrivacy();
                }
            });
        };
    }
}

angular.module('app.shared')
    .service('brookson.factory.privacy', Shared.BrooksonFactoryPrivacy);