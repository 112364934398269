/// <reference path="../../../../app/_all.ts" />
module Shared {
    export class BrooksonWidgetsSmeProfitAndLoss implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$timeout", "$q", "financialCenterSrv", "brookson.navigation", "brookson.utilities.date", "dashboardService", BrooksonWidgetsSmeProfitAndLossController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.sme.profit-loss.html";
        }
    }

    export class BrooksonWidgetsSmeProfitAndLossController {
        error: boolean;
        showSpinner: boolean = true;
        onComplete: Function;
        previousMonth: ProfitAndLossSummary;
        currentMonth: ProfitAndLossSummary;
        startOfPreviousPeriods: string;
        endOfPreviousPeriods: string;
        startOfCurrentPeriod: string;
        endOfPeriod: string;

        public widgetName: string = "brookson-widget-sme-profit-loss";

        public dashboardService: Shared.IDashboardService = this._dashboardService;
        constructor(private $timeout, private $q, private financialCenterSrv: IFinancialCenterService, private brooksonNavigation: IBrooksonNavigation, private dateUtils: IBrooksonUtilitiesDate, private _dashboardService: Shared.IDashboardService) { }

        $onInit() {
            this.getSummary();
        }

        private getSummary() {
            var endOfCurrentPeriod = moment().add(1, 'months').set('date', 1).add(-1, 'days');
            var startOfCurrentPeriod = moment().set('date', 1).add(-3, 'months');
            var startOfPreviousPeriods = moment().set('date', 1).add(-12, 'months');
            var endOfPreviousPeriods = moment().add(1, 'months').set('date', 1).add(-1, 'days');
            

            this.startOfCurrentPeriod = this.dateUtils.convertToViewString(startOfCurrentPeriod);
            this.startOfPreviousPeriods = this.dateUtils.convertToViewString(startOfPreviousPeriods);
            this.endOfPreviousPeriods = this.dateUtils.convertToViewString(endOfPreviousPeriods);
            this.endOfPeriod = this.dateUtils.convertToViewString(endOfCurrentPeriod);

            var getPreviousMonthSummary = this.financialCenterSrv.getProfitAndLossSummary(startOfPreviousPeriods.toDate(), endOfPreviousPeriods.toDate());
            var getCurrentMonthSummary = this.financialCenterSrv.getProfitAndLossSummary(startOfCurrentPeriod.toDate(), endOfCurrentPeriod.toDate());

            this.$q.all([getPreviousMonthSummary, getCurrentMonthSummary])
                .then(data => {
                    this.previousMonth = data[0];
                    this.currentMonth = data[1];
                },
                ex => {
                    this.error = true;
                })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); }, 0);
                });
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetSmeProfitLoss", new Shared.BrooksonWidgetsSmeProfitAndLoss());
