/// <reference path="./AccountType.ts"/>
/// <reference path="./SubmissionType.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class ExpenseType  {
        
        // EXPENSETYPEID
        public expenseTypeId: string = null;
        // EXPENSETYPEDESCRIPTION
        public expenseTypeDescription: string = null;
        // EXPENSESUBMISSIONTYPE
        public expenseSubmissionType: number = 0;
        // SUBMISSIONTYPEENUM
        public submissionTypeEnum: SubmissionType = null;
        // EXPENSERECEIPTATTACHABLE
        public expenseReceiptAttachable: boolean = false;
        // PAYMENTCODE
        public paymentCode: string = null;
        // CATEGORY
        public category: string = null;
        // ACCOUNTTYPE
        public accountType: AccountType = null;
        // DEFAULTEXPENSERATE
        public defaultExpenseRate: number = 0;
        // RATES
        public rates: Array<Shared.MileageExpenseRateLookup> = null;

        public quantityLabel: string = null;
        public isWholeNumber: boolean = false;
        public offsite: boolean = false;
        public deemed: boolean = false;
        public isPublicSector: boolean = false;
        public expenseGroup: string = null;
        public sdcCheckApplicable: boolean = false;
        showWarning: boolean = false;
        warningHtml: string = null;
    }
}
