/// <reference path="../../../../app/_all.ts" />
module Shared {
    export class BrooksonWidgetSmeNextHoliday implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$timeout", "$q", "$window", "holidaysSrv", "brookson.navigation", "brookson.utilities.date", "dashboardService", "memberSrv", BrooksonWidgetsSmeNextHolidayController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.sme.next-holiday.html";
        }
    }

    export class BrooksonWidgetsSmeNextHolidayController {
        error: boolean = false;
        showSpinner: boolean = true;
        onComplete: Function;
        nextHoliday: HolidayRequest;
        remainingHolidayAllowance: string;

        public widgetName: string = "brookson-widget-sme-next-holiday";

        public dashboardService: Shared.IDashboardService = this._dashboardService;
        constructor(private $timeout, private $q, private $window, private holidaysSrv: IHolidaysSrv, private brooksonNavigation: IBrooksonNavigation, private dateUtils: IBrooksonUtilitiesDate, private _dashboardService: Shared.IDashboardService, private memberSrv: IMemberService) { }

        $onInit() {
            this.getNextHoliday();
            this.getRemainingHolidayAllowance();
        }

        public goToHolidays() {
            var member = this.getMember();
            if (member.businessType === "SME" && member.role === "EMPLOYEE") {
                this.$window.location.href = "/EmployeeZone/EmployeeHolidays";
            }
            else if (member.businessType === "SME" && member.role === "DIRECTOR") {
                this.$window.location.href = "/Sme/Holidays";
            }
        }

        private getNextHoliday() {
            this.holidaysSrv.getNextHoliday()
                .then(data => {
                    this.nextHoliday = data;
                },
                ex => {
                    this.error = true;
                })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); }, 0);
                });
        }

        private getRemainingHolidayAllowance() {
            this.holidaysSrv.getRemainingHolidayAllowance()
                .then(data => {
                    var stringData = data.toString();
                    stringData = stringData.split(":")[0];
                    var days = stringData.split(".")[0];
                    var hours = stringData.split(".")[1];
                    this.remainingHolidayAllowance = days + " days" + (hours !== '00' ? ", " + hours + " hours" : "");
                });
        }

        private getMember() {
            return this.memberSrv.getMember();
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetSmeNextHoliday", new Shared.BrooksonWidgetSmeNextHoliday());
