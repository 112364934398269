module Shared {

    export class ExpenseDetails {
        expenseHeader: ExpenseHeader;
        mileageExpenses: MileageExpense[];
        regularExpenses: RegularExpense[];
        allowanceExpenses: RegularExpense[];
    }
}

