/// <reference path="./AssignmentInfo.ts" />
/// <reference path="./SdcQuestionnaireOutcome.ts" />
/// <reference path="./SdcQuestionnaireAnswer.ts" />

module Shared {
    export class SdcQuestionnaire {
        memberId: number;
        companyRef: string;
        agencyNumber: string;
        assignmentId: string;
        crmAssignmentId: string;
        extraInfo: Shared.AssignmentInfo;
        outcome: Shared.SdcQuestionnaireOutcome;
        answers: SdcQuestionnaireAnswer<any>[];
    }
}