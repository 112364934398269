module Shared {
    export interface IExpenseWarningsService {
        saveExpenseWarnings(assignmentId: string, memberId: string, expenseHeaderId: number, expenseWarnings: ExpenseWarning[]): ng.IPromise<boolean>;
        getConfirmedExpenseWarnings(memberid, assignmentId): angular.IPromise<ConfirmedExpenseWarning[]>;
        deleteExpenseWarningLogById(expenseWarningLogId: number): angular.IPromise<Boolean>;
    }

    export class ExpenseWarningsService implements IExpenseWarningsService {

        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        saveExpenseWarnings(assignmentId: string,
            memberId: string,
            expenseHeaderId: number,
            expenseWarnings: ExpenseWarning[]): ng.IPromise<boolean> {
            let expenseConfirmationModel = {
                memberId: memberId,
                assignmentId: assignmentId,
                expenseHeaderId: expenseHeaderId,
                confirmedWarnings: expenseWarnings
            };
            return this.$http.post("/api/expenses/saveExpenseWarnings", expenseConfirmationModel)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        getConfirmedExpenseWarnings(memberid, assignmentId): angular.IPromise<ConfirmedExpenseWarning[]> {
            const requestData = {
                memberId: memberid,
                assignmentId: assignmentId
            };
            return this.$http.get("/api/expenses/getExpenseWarningConfirmations", { params: requestData })
                .then((response: ng.IHttpPromiseCallbackArg<Array<ConfirmedExpenseWarning>>) => response.data);
        }

        deleteExpenseWarningLogById(expenseWarningLogId): angular.IPromise<Boolean> {
            return this.$http.delete(`api/Expenses/DeleteExpenseWarningLogById/${expenseWarningLogId}`)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

    }
}

angular
    .module("app.shared")
    .service("expensesWarningsService", Shared.ExpenseWarningsService);