module Shared {
    export enum PaymentFrequency {
        weekly = 0,
        fortnightly = 2,
        monthly = 3,
    }


    export class PaymentFrequencyParser {
        public static getDescription = (value: number): PaymentFrequency => {
            switch (value) {
                case 0:
                    return PaymentFrequency.weekly;
                case 2:
                    return PaymentFrequency.fortnightly;
                case 3:
                    return PaymentFrequency.monthly;
                default:
                    return PaymentFrequency.weekly;
            }
        }
        public static getEnum = (value: number): EnumObj => {
            switch (value) {
                case 0:
                    return new EnumObj('Weekly', 0);
                case 2:
                    return new EnumObj('Fortnightly', 2);
                case 3:
                    return new EnumObj('Monthly', 3);
                default:
                    return new EnumObj('Weekly', 0);
            }
        }
    }
}