/// <reference path="../../_all.ts"/>

module Shared {
    // contact.enums
    export interface IContactEnums {
        ContactInformation: IContactInformation;
    }
    export interface IContactInformation {
        CustomerServicesPhone: string;
        CustomerServicesEmail: string;
    }

    // invoicing.enums
    export interface IInvoicingEnums {
        RatePeriods: IRatePeriods;
        InvoiceCreationStatus: IInvoiceCreationStatus;
        InvoiceStatus: IInvoiceStatus;
        Sectors: ISectors;
        Ir35Captured: IIR35Captured;
        TimesheetFrequency: ITimesheetFrequency;
    }
    export interface IRatePeriods {
        HOUR: number;
        DAY: number;
        WEEK: number;
        properties: any;
    }
    export interface IInvoiceCreationStatus {
        OVERRIDE: number;
        SUCCESSFUL: number;
        EXCEPTION: number;
    }
    export interface IInvoiceStatus {
        NONE: number;
        NEW: number;
        MODIFIED: number;
        DELETED: number;
    }

    export interface ISectors {
        PUBLIC: number;
        PRIVATE: number;
        NOLONGERINUSE: number;
        NEITHER: number;
        properties: any;
    }
    //IIR35Enums  Status
    export interface IIR35Captured {
        INSIDEIR35: number;
        OUTSIDEIR35: number;
        NOTASSESSED: number;
        properties: any;
    }

    export interface ITimesheetFrequency {
        WEEKLY: number;
        FORTNIGHTLY: number;
        MONTHLY: number;
        properties: any;
    }

    // currency.enums
    export interface ICurrencyEnums {
        CurrencyCodes: ICurrencyCodes;
    }
    export interface ICurrencyCodes {
        GBP: number;
        EUR: number;
        USD: number;
        YEN: number;
        AUD: number;
        CAD: number;
        NOK: number;
        ZAR: number;
        SEK: number;
        CHF: number;
        AED: number;
        SGD: number;
        properties: any;
    }
    export interface IEngineSize {
        PETROL1_1400: number;
        PETROL1401_2000: number;
        PETROL2001_9999: number;
        LPG1_1400: number;
        LPG1401_2000: number;
        LPG2001_9999: number;
        DIESEL1_1600: number;
        DIESEL1601_2000: number;
        DIESEL2001_9999: number;
        properties: any;
    }
    export interface IFuelTypes {
        PETROL: number;
        DIESEL: number;
        LPG: number;
        properties: any;
    }

    // expenses.enums
    export interface IExpensesEnums {
        ExpenseTypeCategory: IExpenseTypeCategory;
        ExpenseSubmissionType: IExpenseSubmissionType;
        ReceiptStatus: IReceiptStatus;
        EngineSize: IEngineSize;
        FuelTypes: IFuelTypes;
        PersonalOrCompany: IPersonalOrCompany;
        ExpenseWarningCodeTypes: IExpenseWarningCodeTypes;
    }
    export interface IPersonalOrCompany {
        PERSONAL: number;
        COMPANY: number;
        properties: any;
    }
    export interface IExpenseTypeCategory {
        MILEAGE: string;
    }

    export interface IExpenseWarningCodeTypes {
        HighRisk: number;
        Amount: number;
        TwentyFourMonthRule: number;
    }
    export interface IExpenseSubmissionType {
        UNKNOWN: number;
        MILEAGE: number;
        RECEIPTATTACHED: number;
        RECEIPTPRINTED: number;
        MIRACLEMANUAL: number;
        properties: any;
    }
    export interface IReceiptStatus {
        UNKNOWN: number;
        UNATTACHED: number;
        UNSUBMITTED: number;
        SUBMITTED: number;
    }

    // image.enums
    export interface IImageEnums {
        ImageSize: IImageSize;
    }
    export interface IImageSize {
        ORIGINAL: number;
        CUSTOM: number;
        ASPECTRATIO: number;
    }

    // documentStorage.enums
    export interface IDocumentStorageEnums {
        FileView: IFileView;
        SharedDriveLinkStatus: ISharedDriveLinkStatus;
        DocumentCategory: IDocumentCategory;
        DocumentDataSource: IDocumentDataSource;
    }
    export interface IFileView {
        DETAILED: number;
        THUMBNAIL: number;
    }
    export interface ISharedDriveLinkStatus {
        NONE: number;
        SUCCESSFUL: number;
        ERROR: number;
    }
    export interface IDocumentCategory {
        FOLDER: string;
        RECENTLYADDED: string;
        FAVOURITES: string;
        SHAREDDRIVE: string;
    }
    export interface IDocumentDataSource {
        MEMBERWEBDOCUMENTS: number;
        QUEUESERVICE: number;
        MOLEDATA: number;
        DROPBOX: number;
    }

    // dialogue.enums
    export interface IDialogueEnums {
        Dialogues: IDialogues;
    }
    export interface IDialogues {
        MILEAGE24MONTHRULE: string;
        EXPENSESNOTICE: string;
    }

    // personalTax.enums
    export interface IPersonalTaxEnums {
        Timeframes: IPersonalTaxEnumsTimeframes;
    }
    export interface IPersonalTaxEnumsTimeframes {
        FORECAST: string;
        YEARTODATE: string;
        properties: any;
    }

    // taskPlanner.enums
    export interface ITaskPlannerEnums {
        TaskStatus: ITaskStatus;
        TaskResponsibility: ITaskResponsibility;
    }

    export interface ITaskStatus {
        COMPLETED: number;
        CURRENT: number;
        FUTURE: number;
        OVERDUE: number;
        properties: any;
    }

    export interface ITaskResponsibility {
        ME: string;
        BROOKSON: string;
    }

    // logging.enums
    export interface ILoggingEnums {
        LoggingLevel: ILoggingLevel;
    }

    export interface ILoggingLevel {
        DEBUG: number;
        INFO: number;
        WARN: number;
        ERROR: number;
        FATAL: number;
    }

    // bankLink.enums


    export interface IBankLinkEnums {
        BankAccountType: IBankAccountType;
        TransactionType: ITransactionType;
        TransactionStatuses: ITransactionStatuses;
        BankLogos: IBankLogos;
        BankIcons: IBankIcons;
    }

    export interface IBankAccountType {
        CURRENT: number;
        DEPOSIT: number;
        CREDIT_CARD: number;
        DEPOSIT_BOND: number;
    }

    export interface ITransactionType {
        BUSINESS: number;
        PERSONAL: number;
        UNALLOCATED: number;
        ALL: number;
        OUTGOINGS: number;
        properties: any;
    }

    export interface ITransactionStatuses {
        NONE: number;
        SAVED: number;
        SUBMITTED: number;
        PROCESSED: number;
    }
    export interface IBankLogos {
        BARCLAYS: string;
        CASHPLUS: string;
        HSBC: string;
        LLOYDS: string;
        RBS: string;
        SANTANDER: string;
        HALIFAX: string;
    }

    export interface IBankIcons {
        BARC: string;
        CASHPLUS: string;
        HSBC: string;
        LLOYDS: string;
        RBS: string;
        SANTANDER: string;
        HALIFAX: string;
    }

    // notifications.enums
    export interface INotificationEnums {
        NotificationSymbol: INotificationSymbol;
        NotificationColourLabel: INotificationColourLabel;
    }

    export interface INotificationSymbol {
        money: string;
        process: string;
        guide: string;
        video: string;
        document: string;
        book: string;
        information: string;
    }

    export interface INotificationColourLabel {
        red: string;
        blue: string;
        green: string;
        yellow: string;
        grey: string;
    }

    // holidays.enums
    export interface IHolidaysEnums {
        HolidayRequestStatus: IHolidayRequestStatus;
    }

    export interface IHolidayRequestStatus {
        ALL: number;
        PENDING: number;
        APPROVED: number;
        REJECTED: number;
        properties: any;
    }

    // member.enums
    export interface IMemberEnums {
        ServiceLineBusinessType: IServiceLineBusinessType;
        ServiceLineRole: IServiceLineRole;
    }

    export interface IServiceLineBusinessType {
        UNKNOWN: string;
        LTD: string;
        UMBRELLA: string;
        SME: string;
    }

    export interface IServiceLineRole {
        UNKNOWN: string;
        DIRECTOR: string;
        SHAREHOLDER: string;
        EMPLOYEE: string;
        NONPRINCIPALDIR: string;
    }

    //ir35.enums
    export interface IIR35Enums {
        IR35Status: IIR35Status;
        ESAQuestionGroups: IESAQuestionGroups;
    }

    export interface IIR35Status {
        IR35Status_TaxedAsPAYE: number;
        IR35Status_TaxedAsSelfEmployed: number;
        IR35Status_Caution: number;
        IR35Status_ActionRequired: number;
        IR35Status_WithdrawnReview: number;
        IR35Status_AssessmentFormSubmit: number;
        IR35Status_AssessmentFormStarted: number; 
    }

    export interface IESAQuestionGroups {
        Group1: string;
        Group1Dot1: string;
        Group1Dot2: string;
        Group1Dot3: string;
        Group2: string;
        Group2Dot1: string;
        Group3: string;
        Group3Dot1: string;
        Group3Dot2: string;
        Group4: string;
        Group5: string;
        Group6: string;
        Group7: string;
        Group8: string;
        Group9: string;
        Group10: string;
        Group11: string;
        Group12: string;
        Group13: string;
        Group14: string;
        Group15: string;
        Group16: string;
        Group17: string;
        Group18: string;
    }

    export class ContactEnums implements IContactEnums {
        public ContactInformation = {
            CustomerServicesPhone: '0345 058 1200 or 01925 232 526',
            CustomerServicesEmail: 'businessadvisors@brookson.co.uk'
        };
        static Default() { return new ContactEnums(); }
    }

    export class InvoicingEnums implements IInvoicingEnums {
        public RatePeriods = {
            HOUR: 0,
            DAY: 1,
            WEEK: 2,
            properties: {
                0: { value: "Hour", adjective: "Hourly" },
                1: { value: "Day", adjective: "Daily" },
                2: { value: "Week", adjective: "Weekly" }
            }
        };
        public InvoiceCreationStatus = {
            OVERRIDE: 0,
            SUCCESSFUL: 1,
            EXCEPTION: 2
        };
        public InvoiceStatus = {
            NONE: 0,
            NEW: 1,
            MODIFIED: 2,
            DELETED: 3
        };

        public Sectors = {
            PUBLIC: 0,
            PRIVATE: 1,
            NOLONGERINUSE: 2,
            NEITHER: 3,
            properties: {
                0: { description: 'Public', id: 0 },
                1: { description: 'Private', id: 1 },
                2: { description: 'NoLongerInUse', id: 2 },
                3: { description: 'Neither', id: 3 }
            }
        };

        public TimesheetFrequency = {
            WEEKLY: 0,
            FORTNIGHTLY: 2,
            MONTHLY: 3,
            properties: {
                0: { description: 'Weekly', id: 0 },
                1: { description: 'Fortnightly', id: 2 },
                2: { description: 'Monthly', id: 3 },
            }
        };

        
        public Ir35Captured = {
            NOTASSESSED: 0,
            OUTSIDEIR35: 1,
            INSIDEIR35: 2,
            
            properties: {
                0: { description: 'Status not assessed yet', id: 0 },
                1: { description: 'Outside IR35', id: 1 },
                2: { description: 'Inside IR35', id: 2 },
            }
        };
        static Default() { return new InvoicingEnums(); }

    }

    export class CurrencyEnums implements ICurrencyEnums {
        public CurrencyCodes = {
            GBP: 10,
            EUR: 20,
            USD: 30,
            YEN: 60,
            AUD: 70,
            CAD: 80,
            NOK: 90,
            ZAR: 100,
            SEK: 110,
            CHF: 120,
            AED: 130,
            QAR: 140,
            SGD: 150,
            properties: {
                10: { value: "GBP", code: "£" },
                20: { value: "EUR", code: "€" },
                30: { value: "USD", code: "$" },
                60: { value: "YEN", code: "¥" },
                70: { value: "AUD", code: "$" },
                80: { value: "CAD", code: "$" },
                90: { value: "NOK", code: "kr" },
                100: { value: "ZAR", code: "R" },
                110: { value: "SEK", code: "kr" },
                120: { value: "CHF", code: "chf" },
                130: { value: "AED", code: "aed" },
                140: { value: "QAR", code: "qar" },
                150: { value: "SGD", code: "S$"}
            }
        };
        static Default() { return new CurrencyEnums(); }
    }

    export class ExpensesEnums implements IExpensesEnums {
        public PersonalOrCompany = {
            PERSONAL: 0,
            COMPANY: 1,
            properties: {
                0: { description: 'Personal' },
                1: { description: 'Company' }
            }
        };
        public ExpenseTypeCategory = {
            MILEAGE: 'MILEAGE'
        };
        public ExpenseWarningCodeTypes = {
            HighRisk: 1,
            Amount: 2,
            TwentyFourMonthRule: 3,  
        };
        public ExpenseSubmissionType = {
            UNKNOWN: 0,
            MILEAGE: 1,
            RECEIPTATTACHED: 2,
            RECEIPTPRINTED: 3,
            MIRACLEMANUAL: 4,
            properties: {
                0: { description: 'Unknown' },
                1: { description: 'Mileage' },
                2: { description: 'Receipt Attached' },
                3: { description: 'Receipt Printed' },
                4: { description: 'Manual' }
            }
        };
        public ReceiptStatus = {
            UNKNOWN: 0,
            UNATTACHED: 1,
            UNSUBMITTED: 2,
            SUBMITTED: 3
        };
        public FuelTypes = {
            PETROL: 1,
            DIESEL: 2,
            LPG: 3,
            properties: {
                1: { description: 'Petrol' },
                2: { description: 'Diesel' },
                3: { description: 'LPG' }
            }
        };
        public EngineSize = {
            PETROL1_1400: 10,
            PETROL1401_2000: 20,
            PETROL2001_9999: 30,
            LPG1_1400: 40,
            LPG1401_2000: 50,
            LPG2001_9999: 60,
            DIESEL1_1600: 70,
            DIESEL1601_2000: 80,
            DIESEL2001_9999: 90,
            properties: {
                10: { description: "1-1400cc", code: "1400" },
                20: { description: "1401-2000cc", code: "2000" },
                30: { description: "2001-9999cc", code: "9999" },
                40: { description: "1-1400cc", code: "1400" },
                50: { description: "1401-2000cc", code: "2000" },
                60: { description: "2001-9999cc", code: "9999" },
                70: { description: "1-1600cc", code: "1600" },
                80: { description: "1601-2000cc", code: "2000" },
                90: { description: "2001-9999cc", code: "9999" },
            }
        };
        static Default() { return new ExpensesEnums(); }
    }

    export class ImageEnums implements IImageEnums {
        public ImageSize = {
            ORIGINAL: 0,
            CUSTOM: 1,
            ASPECTRATIO: 2
        };
        static Default() { return new ImageEnums(); }
    }

    export class DocumentStorageEnums implements IDocumentStorageEnums {
        public FileView = {
            DETAILED: 0,
            THUMBNAIL: 1
        };
        public SharedDriveLinkStatus = {
            NONE: 0,
            SUCCESSFUL: 1,
            ERROR: 2
        };
        public DocumentCategory = {
            FOLDER: "",
            RECENTLYADDED: "Recently Added",
            FAVOURITES: "Favourites",
            SHAREDDRIVE: "Brookson Shared Drive"
        };
        public DocumentDataSource = {
            MEMBERWEBDOCUMENTS: 0,
            QUEUESERVICE: 1,
            MOLEDATA: 2,
            DROPBOX: 3
        };
        static Default() { return new DocumentStorageEnums(); }
    }

    export class DialogueEnums implements IDialogueEnums {
        public Dialogues = {
            MILEAGE24MONTHRULE: "MILEAGE24MONTHRULE",
            EXPENSESNOTICE: "EXPENSESNOTICE"
        };
        static Default() { return new DialogueEnums(); }
    }

    export class PersonalTaxEnums implements IPersonalTaxEnums {
        public Timeframes = {
            FORECAST: "FORECAST",
            YEARTODATE: "YEARTODATE",
            properties: {
                "FORECAST": { value: "FORECAST", description: "Forecast" },
                "YEARTODATE": { value: "YEARTODATE", description: "Year To Date" }
            }
        };
        static Default() { return new PersonalTaxEnums(); }
    }

    export class TaskPlannerEnums implements ITaskPlannerEnums {
        public TaskStatus = {
            COMPLETED: 0,
            CURRENT: 1,
            FUTURE: 2,
            OVERDUE: 3,
            properties: {
                0: { values: 0, description: "Completed", css: "task-completed" },
                1: { values: 1, description: "Current", css: "task-current" },
                2: { values: 2, description: "Future", css: "task-future" },
                3: { values: 3, description: "Overdue", css: "task-overdue" },
            }
        };
        public TaskResponsibility = {
            ME: 'Me',
            BROOKSON: 'Brookson'
        };
        static Default() { return new TaskPlannerEnums(); }
    }

    export class LoggingEnums implements ILoggingEnums {
        public LoggingLevel = {
            DEBUG: 0,
            INFO: 1,
            WARN: 2,
            ERROR: 3,
            FATAL: 4
        };
        static Default() { return new LoggingEnums(); }
    }

    export class BankLinkEnums implements IBankLinkEnums {
        public BankAccountType = {
            CURRENT: 0,
            DEPOSIT: 1,
            CREDIT_CARD: 2,
            DEPOSIT_BOND: 3
        };
        public TransactionType = {
            BUSINESS: 0,
            PERSONAL: 1,
            UNALLOCATED: 2,
            ALL: 3,
            OUTGOINGS: 4,
            properties: {
                0: { description: 'Business' },
                1: { description: 'Personal' },
                2: { description: 'Unallocated' },
                3: { description: 'All Types' },
                4: { description: 'Outgoings & Balances' }
            }
        };
        public TransactionStatuses = {
            NONE: 0,
            SAVED: 1,
            SUBMITTED: 2,
            PROCESSED: 3,
            INTOUCHPROCESSED: 4,
        };
        public BankLogos = {
            BARCLAYS: '/Content/img/logos/banklogos/svg/logo_barclays.svg',
            CASHPLUS: '/Content/img/logos/banklogos/svg/logo_cashplus.svg',
            HSBC: '/Content/img/logos/banklogos/svg/logo_hsbc.svg',
            LLOYDS: '/Content/img/logos/banklogos/svg/logo_lloyds.svg',
            RBS: '/Content/img/logos/banklogos/svg/logo_rbs.svg',
            SANTANDER: '/Content/img/logos/banklogos/svg/logo_santander.png',
            HALIFAX: '/Content/img/logos/banklogos/svg/halifax.svg'
        };
        public BankIcons = {
            BARC: '/Content/img/logos/banklogos/svg/icon_barclays.svg',
            CASHPLUS: '/Content/img/logos/banklogos/svg/icon_cashplus.svg',
            HSBC: '/Content/img/logos/banklogos/svg/icon_hsbc.svg',
            LLOYDS: '/Content/img/logos/banklogos/svg/icon_lloydsbank.svg',
            RBS: '/Content/img/logos/banklogos/svg/icon_rbs.svg',
            SANTANDER: '/Content/img/logos/banklogos/svg/icon_santander.svg',
            HALIFAX: '/Content/img/logos/banklogos/svg/halifax.svg'
        };
        static Default() { return new BankLinkEnums(); }
    }

    export class NotificationEnums implements INotificationEnums {
        public NotificationSymbol = {
            "money": 'fa-gbp',
            "process": 'fa-cog',
            "guide": 'fa-question-circle',
            "video": 'fa-play',
            "document": 'fa-file',
            "book": 'fa-calendar',
            "information": 'fa-info-circle'
        };
        public NotificationColourLabel = {
            "red": 'label-danger',
            "blue": 'label-primary',
            "green": 'label-success',
            "yellow": 'label-warning',
            "grey": 'label-default'
        };
        static Default() { return new NotificationEnums(); }
    }

    export class HolidaysEnums implements IHolidaysEnums {
        public HolidayRequestStatus = {
            ALL: 0,
            PENDING: 1,
            APPROVED: 2,
            REJECTED: 3,
            properties: {
                0: { description: "All" },
                1: { description: "Pending" },
                2: { description: "Approved" },
                3: { description: "Rejected" }
            }
        };
        static Default() { return new HolidaysEnums(); }
    }

    export class MemberEnums implements IMemberEnums {
        public ServiceLineRole = {
            UNKNOWN: 'UNKNOWN',
            DIRECTOR: 'DIRECTOR',
            SHAREHOLDER: 'SHAREHOLDER',
            EMPLOYEE: 'EMPLOYEE',
            NONPRINCIPALDIR: 'NON PRINCIPAL DIR'
        };
        public ServiceLineBusinessType = {
            UNKNOWN: 'UNKNOWN',
            LTD: 'LTD',
            UMBRELLA: 'UMBRELLA',
            SME: 'SME'
        };
        static Default() { return new MemberEnums(); }
    }

    export class IR35Enums implements IIR35Enums {
        public IR35Status = {
            IR35Status_TaxedAsPAYE: 0,
            IR35Status_TaxedAsSelfEmployed: 1,
            IR35Status_Caution: 2,
            IR35Status_ActionRequired: 3,
            IR35Status_WithdrawnReview: 4,
            IR35Status_AssessmentFormSubmit: 5,
            IR35Status_AssessmentFormStarted: 6,
            properties: {
                0: { description: "Taxed as PAYE" },
                1: { description: "Taxed as Self Employed" },
                2: { description: "Caution" },
                3: { description: "Action Required" },
                4: { description: "Review Incomplete" },
                5: { description: "Assessment Form Submitted" },
                6: { description: "Assessment Form Incomplete" }
            }
        };

        public ESAQuestionGroups: {
            Group1: '1',
            Group1Dot1: '1.1',
            Group1Dot2: '1.2',
            Group1Dot3: '1.3',
            Group2: '2',
            Group2Dot1: '2.1',
            Group3: '3',
            Group3Dot1: '3.1',
            Group3Dot2: '3.2',
            Group4: '4',
            Group5: '5',
            Group6: '6',
            Group7: '7',
            Group8: '8',
            Group9: '9',
            Group10: '10',
            Group11: '11',
            Group12: '12',
            Group13: '13',
            Group14: '14',
            Group15: '15',
            Group16: '16',
            Group17: '17',
            Group18: '18'
        };
        static Default() { return new IR35Enums(); }
    }

    angular.module("app.shared")
        .constant("contact.enums", ContactEnums.Default())
        .constant("invoicing.enums", InvoicingEnums.Default())
        .constant("currency.enums", CurrencyEnums.Default())
        .constant("expenses.enums", ExpensesEnums.Default())
        .constant("image.enums", ImageEnums.Default())
        .constant("documentStorage.enums", DocumentStorageEnums.Default())
        .constant('dialogue.enums', DialogueEnums.Default())
        .constant('personalTax.enums', PersonalTaxEnums.Default())
        .constant('taskPlanner.enums', TaskPlannerEnums.Default())
        .constant('logging.enums', LoggingEnums.Default())
        .constant('bankLink.enums', BankLinkEnums.Default())
        .constant('notification.enums', NotificationEnums.Default())
        .constant('holidays.enums', HolidaysEnums.Default())
        .constant('member.enums', MemberEnums.Default())
        .constant('ir35.enums', IR35Enums.Default());
}