/// <reference path="./Assignment.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class Review  {
        
        // REVIEWID
        public reviewId: number = 0;
        // CONTRACTRECEIVED
        public contractReceived: Date = null;
        // RESARECEIVED
        public resaReceived: Date = null;
        // LEGALREVIEWED
        public legalReviewed: Date = null;
        // REVIEWCOMPLETED
        public reviewCompleted: Date = null;
        // RESASTATUS
        public resaStatus: string = null;
        // CONTRACTSTATUS
        public contractStatus: string = null;
        // FINALOUTCOME
        public finalOutcome: string = null;
        // RESADOCUMENT
        public resaDocument: string = null;
        // ATTENDANCENOTEDOCUMENT
        public attendanceNoteDocument: string = null;
        // CONTRACTDOCUMENT
        public contractDocument: string = null;
        // ASSIGNMENTID
        public assignmentID: string = null;
        // ASSIGNMENT
        public assignment: Assignment = null;
    }
}
