module Shared {
    export class ExpensesTable {
    businessID: string;
    personID: string;
    connectUser: string;
    accommodation: number;
    accommodationDisallowable: number;
    accountLegalProf: number;
    accountLegalProfFees: number;
    advertising: number;
    airRailTravelParking: number;
    businessEntertain: number;
    businessEntertainment: number;
    compensationPenalties: number;
    goodsForResale: number;
    hireOfEquipment: number;
    insurance: number;
    InsuranceDisallowable: number;
    itMaintSupport: number;
    lightHeat: number;
    mileageFuel: number;
    motorExpenses: number;
    motorExpensesDisallowable: number;
    officeExpenses: number;
    officeExpensesDisallowable: number;
    otherBusinessExp: number;
    otherBusinessExpenses: number;
    printStationeryPost: number;
    privateMedicalIns: number;
    ratesWater: number;
    relocationExpensesAllow: number;
    rent: number;
    rent__disallowable_: number;
    repairsPropertyEquip: number;
    staffEntertainment: number;
    subcontractors: number;
    subsistence: number;
    telephoneFax: number;
    training_: number;
    createdDate: string;
    } 
}