module Shared {
    'use strict';

    export interface IBrooksonUmbrellaFlexFormController {
        actionTile: any;
    }

    export class BrooksonUmbrellaFlexFormController implements IBrooksonUmbrellaFlexFormController{
        public actionTile: Notification;
    }

    export const BrooksonUmbrellaFlexForm: ng.IComponentOptions = {
        bindings: {
            actionTile: '='
        },
        controller: BrooksonUmbrellaFlexFormController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.flex.form.html',
        transclude: true
    };

}

angular
    .module('app.shared')
    .component('brooksonUmbrellaFlexForm', Shared.BrooksonUmbrellaFlexForm);