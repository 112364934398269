module Shared {
    export class ProfitAndLossSummary {
        grossMargin: number;

        grossMarginPercentage: number;

        incomeBeforeInterestAndVat: number;

        operatingExpenses: number;

        operatingExpensesMargin: number;

        operatingExpensesMarginPercentage: number;

        otherExpenses: number;

        totalCost: number;

        totalRevenue: number;
    }
}