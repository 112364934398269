/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/enums/SelfAssessmentSectionStatus.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .directive('brooksonUiAside', brooksonUiAside);

    brooksonUiAside.$inject = ['$q', '$templateRequest', '$compile', '$document', '$injector'];
    function brooksonUiAside($q, $templateRequest, $compile, $document, $injector) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            bindToController: true,
            controller: ['$scope', '$rootScope', '$document', '$injector', brooksonAsideController],
            controllerAs: 'vm',
            link: link,
            restrict: 'E',
            scope: {
                template: '=',
                show: '=',
                templateUrl:'=',
                asideClass:'=',
                appSettings: '=',
                summaryForms: '=',
                colorOverride: '=',
                isSelfAssessment: '='
            }
        };
        return directive;

        function link(scope, element, attrs) {

            if (scope.vm.isSelfAssessment) {
                var selfAssessmentFactory = $injector.get('selfAssessmentFactory');
                selfAssessmentFactory.getSummary();
            }

            var opts = {
                template: attrs.template,
                templateUrl: attrs.templateUrl,
            };


            getTemplatePromise(opts).then((template) => {
                var body = $document.find('body').eq(0);
                var menu = '<div class="aside-container"><div class="aside-backdrop" ng-click="vm.show=false"></div><div class="' + (attrs.asideClass ? attrs.asideClass : '') + ' aside">' + template + '</div></div>';
                if (scope.vm.colorOverride) { menu = '<div class="aside-container"><div class="aside-coloroverride"><div class="aside-backdrop" ng-click="vm.show=false"></div><div class="' + (attrs.asideClass ? attrs.asideClass : '') + ' aside">' + template + '</div></div></div>'; }
                var comp  = $compile(menu)(scope);
                body.append(comp);
            });

        }

        function getTemplatePromise(options) {
            return options.template ? $q.when(options.template) :
                $templateRequest(angular.isFunction(options.templateUrl) ? (options.templateUrl)() : options.templateUrl);
        }
    }
    /* @ngInject */
    function brooksonAsideController($scope, $rootScope, $document, $injector) {
        var vm = this;

        if (vm.isSelfAssessment) {
            var selfAssessmentFactory = $injector.get('selfAssessmentFactory');
            vm.selfAssessment = selfAssessmentFactory;
        }
        

        vm.sectionStatusEnum  = Shared.SelfAssessmentSectionStatus;

        $scope.$watch('vm.show', (newValue, oldValue) => {


            if (newValue === true) {
                vm.showAside();
            } else {
               vm.hideAside();
            }
        });
        // this will listen for route changes and call the callback
        $rootScope.$on('$stateChangeStart', () => {
           vm.show = false;
        });

        vm.showAside = () => {
             $document.find('.aside-container').addClass('in');
              $document.find('.aside-container').removeClass('out');
        };

        vm.hideAside = () => {
             $document.find('.aside-container').addClass('out');
            $document.find('.aside-container').removeClass('in');
        };
    }
})();
