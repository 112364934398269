module Shared {
    export class AccountsTable {
    companyNo: string;
    periodNo: string;
    companiesHouseDeadline: string;
    cTPaymentDate: string;
    hMRCFilingDate: string;
    draftsPosted: string;
    finalsProduced: string;
    draftsConfirmed: string;
    businessID: string;
    personID: string;
    connectUser: string;
    createdDate: string;
    section: string;
    subSetion: string;
    order: number;
    }
}