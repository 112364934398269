module Shared {
    export class ExpenseWarning {
        showWarningHighRisk: boolean = false;
        showWarningAmount: boolean = false;
        showWarningMonthRule: boolean = false;
        warning: ExpenseWarnings = null;

        warningMessage: string = null;
        confirmed: boolean = false;
        dateConfirmed: Shared.BrooksonDate = null;

        warningMessageTwentyFour: string = null; 
        confirmedTwentyFour: boolean = false;
        dateConfirmedTwentyFour: Shared.BrooksonDate = null;

        assignment: Assignment;
    }

    export class ConfirmedExpenseWarning {
        expenseWarningLogId: number;
        expenseCodeValue: string;
        dateConfirmed: Shared.BrooksonDate;
        twentyFourMonthRuleApplied: boolean;
        expenseId: string;
    }

    export class ExpenseTabWarning {
        name: string = null;
        presentedWarnings = 0;
        constructor(tabName: string) {
            this.name = tabName;
        }
    }

    export class ExpenseTabWarnings {
        warningCount: number = 0;
        tabWarnings: ExpenseTabWarning[];
        constructor() {
            this.tabWarnings = new Array<ExpenseTabWarning>();
        }
    }
}