module Shared {
    export class ExpensesOverview {
        chartData: any;
    }

    export class ExpensePlottingData {
        mileageExpensesSum: number;
        regularExpensesSum: number;
        allowancesExpensesSum: number;
    }

}