/// <reference path="../models/questionnaire/QuestionnaireFormitem.ts"/>

module Shared {

    export interface IQuestionnaireService {
        getQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]>;
        saveQuestionnaireForm(questionnaire: any): ng.IPromise<boolean>;
        saveQuestionnaireUpdateForm(questionnaire: any): ng.IPromise<boolean>;
        hasCustomerSubmittedForm(): ng.IPromise<boolean>;

        getDirectorFeesQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]>;
        getMortgageQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]>;
        getDirectorFeesMonthlySalaryPayments(): ng.IPromise<Shared.questionnaire.QuestionnaireMonthlySalaryPayments[]>;    
        hasCustomerSubmittedDirectorFeesForm(): ng.IPromise<boolean>;
        hasCustomerSubmittedMortgageForm(): ng.IPromise<boolean>;
        
        showDirectorFeeFormBanner(): ng.IPromise<boolean>;

        recordAuditTrail(auditTrail: Shared.questionnaire.AuditTrail): ng.IPromise<boolean>;
    }

    export class QuestionnaireService implements IQuestionnaireService {
        static $inject = ["$http", "$q"];

        constructor(private $http: IBrooksonHttp,
            private $q: ng.IQService) {

        }

        getQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]> {
            const deferred = this.$q.defer<Shared.questionnaire.QuestionaireFormItem[]>();
            const url = "api/Questionnaire/GetQuestionnaireForm";

            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        getDirectorFeesQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]> {
            const deferred = this.$q.defer<Shared.questionnaire.QuestionaireFormItem[]>();
            const url = "api/Questionnaire/GetDirectorFeesQuestionnaireForm";

            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        getMortgageQuestionnaireForm(): ng.IPromise<Shared.questionnaire.QuestionaireFormItem[]> {
            const deferred = this.$q.defer<Shared.questionnaire.QuestionaireFormItem[]>();
            const url = "api/Questionnaire/GetMortgageQuestionnaireForm";

            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        getDirectorFeesMonthlySalaryPayments(): ng.IPromise<Shared.questionnaire.QuestionnaireMonthlySalaryPayments[]> {
            const deferred = this.$q.defer<Shared.questionnaire.QuestionnaireMonthlySalaryPayments[]>();
            const url = "api/Questionnaire/GetDirectorFeesMonthlySalaryPayments";

            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        saveQuestionnaireForm(questionnaire: Shared.questionnaire.QuestionaireFormItem[]): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/SaveForm";

            this.$http.post(url, questionnaire)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });
            return deferred.promise;
        }

        saveQuestionnaireUpdateForm(questionnaire: Shared.questionnaire.QuestionaireFormItem[]): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/SaveUpdateForm";

            this.$http.post(url, questionnaire)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });
            return deferred.promise;
        }

        hasCustomerSubmittedForm(): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/HasCustomerSubmittedFlexFormForm";
            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }


        showDirectorFeeFormBanner(): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/ShowDirectorFeesFormBanner";
            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }


        hasCustomerSubmittedDirectorFeesForm(): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/HasCustomerSubmittedDirectorFeesForm";
            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        hasCustomerSubmittedMortgageForm(): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/HasCustomerSubmittedMortgageForm";
            this.$http.get(url)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }
        recordAuditTrail(auditTrail: Shared.questionnaire.AuditTrail): ng.IPromise<boolean> {
            const deferred = this.$q.defer<boolean>();
            const url = "api/Questionnaire/RecordAuditTrail";
            this.$http.post(url, auditTrail)
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });
            return deferred.promise;
        }

    }

}
angular.module("app.shared").service('questionnaireSrv', Shared.QuestionnaireService);