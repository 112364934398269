/// <reference path="../../services/brookson.services.task-planner.ts" />
/// <reference path="../../models/MemberCurrentStage.ts" />

module Shared {
    'use strict';

    export interface IBrooksonConfirmAccountsController extends IConfirmAccounts {
        yearEndStatus: number;
        yearEndStage: Shared.MemberCurrentStage;
    }

    export class BrooksonConfirmAcccountsController implements IBrooksonConfirmAccountsController {

        public action: Shared.TaskAction;
        public yearEndStatus: number;
        public yearEndStage: Shared.MemberCurrentStage = new Shared.MemberCurrentStage();

        static $inject: Array<string> = ['$location', 'taskPlannerSrv', 'brookson.navigation', 'brookson.file.download'];

        constructor(
            private $location: ng.ILocationService,
            private data: Shared.TaskPlannerSrv,
            private navigation: Shared.IBrooksonNavigation,
            private brooksonFileDownload: any
        ) {
            data.getYearEndStatus().then((status: number) => {
                this.yearEndStatus = status;
            });
            data.getYearEndStage().then((data: Shared.MemberCurrentStage) => {
                this.yearEndStage = data;
            });
        }

        public get step1Confirmed(): boolean {
            return this.yearEndStatus >= 2;
        }

        public get step2Confirmed(): boolean {
            return this.yearEndStatus >= 3;
        }

        public confirmStep = (step: number) => {
            this.data.confirmAccountsStep(step).then((result) => {
                if (result) {
                    this.data.getYearEndStatus().then((status: number) => {
                        this.yearEndStatus = status;
                    });
                }
            });
        }

        /**
         * Takes the user to the IR35 section
         * 
         * 
         * @memberOf BrooksonConfirmAcccountsController
         */
        public goToIR35() {
            this.navigation.employmentStatus.main(this.$location.url(), 'Back to Task Planner');
        }

        public downloadDocuments() { 
            var url = "api/DocumentStorage/GetZipDocuments";
            var expectedMediaType = "application/zip";
            var requestData = this.action.documents;
            this.brooksonFileDownload.downloadAndSave(url, requestData, "Brookson.Documents.zip", expectedMediaType);
        }
    }

    export interface IConfirmAccounts {
        action: any;
    }

    export const BrooksonConfirmAccounts: ng.IComponentOptions = {
        bindings: {
            action: '='
        },
        controller: BrooksonConfirmAcccountsController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.confirm.accounts.html',
        transclude: true
    };
}

angular
        .module('app.shared')
        .component('brooksonConfirmAccounts', Shared.BrooksonConfirmAccounts);