module Shared {
    export class AdvisoryAdvice {
        section: string;
        advice: string;
        colour: string;
        subSection: string;
        subSectionOrder: string;
        businessID: string;
        personID: string;
        connectUser: string;
        createdDate: string;
    }
}