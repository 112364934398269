/// <reference path="../../../../app/_all.ts"/>

module Shared {

    /**
     * 
     * The privacy button component for disabling privacy mode.
     * This button is used when privacy mode is enabled
     * @export
     * @class BrooksonUiPrivacyButton
     * @implements {ng.IComponentOptions}
     */
    export class BrooksonUiPrivacyButton implements ng.IComponentOptions {
        /**
         * 
         * Privacy button component
         * @type {*}
         */
        public controller: any;
        /**
         * 
         * Privacy button template url 
         * @type {*}
         */
        public templateUrl: any;

        /**
         * Creates an instance of BrooksonUiPrivacyButton.
         * 
         */
        constructor() {
            this.controller = BrooksonUiPrivacyButtonController;
            this.templateUrl = "src/app/shared/views/ui/brookson.ui.privacy.button.html";
        }
    }

    /**
     * 
     * The controller for the privacy button
     * @class BrooksonUiPrivacyButtonController
     */
    class BrooksonUiPrivacyButtonController {

        /**
         * 
         * 
         * @static
         */
        static $inject = ['brookson.factory.privacy'];

        /**
         * Creates an instance of BrooksonUiPrivacyButtonController.
         * 
         * @param {Shared.IBrooksonFactoryPrivacy} privacyFactory
         */
        constructor(
            private privacyFactory: Shared.IBrooksonFactoryPrivacy
        ) { }
    }
}
angular
    .module('app.shared')
    .component('brooksonUiPrivacyButton', new Shared.BrooksonUiPrivacyButton());