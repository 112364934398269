/// <reference path="../../../_all.ts"/>
/// <reference path="../../../shared/modules/brookson.ui.popup.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetUmbrellaBrooksonAdverts', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.umbrella-brookson-adverts.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetUmbrellaBrooksonAdverts
    });

    brooksonWidgetUmbrellaBrooksonAdverts.$inject = ['$timeout', 'brookson.navigation', 'dashboardService', 'brookson.ui.popup', 'remusRewardSrv'];

    function brooksonWidgetUmbrellaBrooksonAdverts($timeout, brooksonNavigation, _dashboardService, _brooksonUiPopup: Shared.IBrooksonUiPopup, remusRewardSrv) {

        var vm = this;

        vm.brooksonUiPopup = _brooksonUiPopup;
        vm.brooksonNavigation = brooksonNavigation;
        vm.widgetName = "brookson-widget-umbrella-brookson-adverts";
        vm.isUmbrellaPlus = false;
        vm.isUmbrellaPlusSelected = false;
        vm.agencyMargin = Shared.Models.AgencyMarginCompact;
     
        vm.selectUmbrellaProtect = () => { 
            // pop confirm modal
            vm.brooksonUiPopup.showConfirmationPopup(
                'CHOOSE YOUR PACKAGE',
                '<p>If you wish to downgrade your package in the future, please contact customer services.</p><p>Upgrade my service to Umbrella Protect?</p>',
                'Confirm',
                'Dismiss'
            ).then(
                () => {
                     remusRewardSrv.setPackageNavUmbrellaProtect();
                     vm.selectedPackage = Shared.SelectedPackageEnum.umbrellaProtect;
                     vm.isUmbrellaPlusSelected = true;
                },
                () => { }
            );
        };


              
        function isUserUmbrellaPlus() {
            console.log('isUserUmbrellaPlus');
            remusRewardSrv.isUserUmbrellaPlus().then((data) => {
                vm.isUmbrellaPlus = data;
            });
        }

        function getAgencyMargin() {
            remusRewardSrv.getAgencyMargin().then((data) => {
                vm.agencyMargin = data;
            });
        }

        function activate() {
            isUserUmbrellaPlus();
            getAgencyMargin();
        }

        activate();

    }
})();
