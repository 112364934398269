/// <reference path="./../../enums/IndustryList.ts" />

namespace Shared {
    'use strict';

    export class BrooksonInputJobIndustry implements ng.IComponentOptions {
        public bindings: { [binding: string]: string };
        public controller: Function;
        public templateUrl: string;
        public require: {[controller: string]: string};

        constructor() {
            this.bindings = {
                ngModel: '=',
                isRequired: '=',
                label: '@',
                field: '@',
                ngDisabled: '='
            };
            this.require = {
                form: '^form'
            };
            this.controller = BrooksonInputJobIndustryController;
            this.templateUrl = 'src/app/shared/views/inputs/brookson.input.job-industry.html';
        }
    }

    export class BrooksonInputJobIndustryController {
        public industryList: Array<string>;

        static $inject: Array<string> = ['sdcFactory'];

        constructor(
            private sdcFactory: Shared.ISdcFactory
        ) { }

        $onInit = () => {
            this.industryList = Shared.IndustryList.getArray();
        }
    }
}

angular.module('app.shared')
    .component('brooksonInputJobIndustry', new Shared.BrooksonInputJobIndustry());