module BankLink {
    export class Transaction {
        public balance : number = null;
        public bankId: string = null;
        public bankKey: string = null;
        public creditAmount: number = null;
        public customerLedgerCategory: number = null;
        public customerLedgerDescription: string = null;
        public customerLedgerInvoice: boolean = null;
        public debitAmount: number = null;
        public description: string = null;
        public isMobile: boolean = null;
        public personalDiv1Amount: number = null;
        public personalDiv2Amount: number = null;
        public personalExpensesAmount: number = null;
        public personalLoanAmount: number = null;
        public personalSalaryAmount: number = null;
        public receiptID: number = null;
        public receiptable: boolean = null;
        public serviceResponse: number = null;
        public transactionCategory: any = null;
        public transactionDate: any = null;
        public transactionId: string = null;
        public transactionReceiptStatus: number = null;
        public transactionStatus: number = null;
        public transactionType: number = null;
        public unallocatedTransactionSource: number = null;
        public flow: any = null;
        public flowOptions: any = null;
        public receiptThumbnail: any = null;
        public category: any = null;
        public relatedCategories: any[] = null;
        public isVatReclaimable: boolean = false;
        public hasValidVatReceipt: boolean = false;
        public gross: number = 0;
        public vat: number = 0;
        public net: number = 0;
        public passes24MonthRule: boolean = false;
        public accept24MonthRuleRisk: boolean = undefined;
    }
}
