/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .service('authInterceptorSrv', authInterceptorSrv);

    authInterceptorSrv.$inject = ['$q', '$window', '$injector', 'memberSrv', 'authenticationSrv'];

    function authInterceptorSrv($q, $window, $injector, memberSrv, authenticationSrv) {

        var MAX_RETRY_COUNT = 3;
        var authInterceptorServiceFactory: any = {};
        var $http;
        var refreshingRequest = false;
        var requestQueue = [];

        var _request = (config) => {
            config.headers = config.headers || {};

            var authData = memberSrv.getMember();

            if (authData) {
                if (authData.isAuthenticated) {
                    config.headers.Authorization = 'Bearer ' + authData.token;

                    if ($window.brookson && $window.brookson.antiForgeryToken) {
                        config.headers.__RequestVerificationToken = $window.brookson.antiForgeryToken || "no request verification token";
                    }
                }
            }

            return config;
        };

        var _responseError = (rejection) => {
            var deferred = $q.defer();

            if (rejection.status === 401) {

                requestQueue.push({ config: rejection.config, deferred: deferred });

                if (!refreshingRequest) {
                    refreshingRequest = true;
                    authenticationSrv.refreshToken().then((response) => {
                        //retry login with new refresh
                        _.each(requestQueue, (request) => {
                            if (!request.config.retryCount || request.config.retryCount < MAX_RETRY_COUNT) {
                                _retryHttpRequest(request.config, request.deferred);
                            }else{
                                request.deferred.reject("Too many of the same request has been made");
                            }
                        });

                        requestQueue = [];
                        refreshingRequest = false;
                    }, (err) => {
                        requestQueue = [];
                        authenticationSrv.logout();
                        deferred.reject(rejection);
                    });
                }
            }
            else {
                deferred.reject(rejection);
            }
            return deferred.promise;
        };

        var _retryHttpRequest = (config, deferred) => {
            $http = $http || $injector.get('$http');

            if (!config.retryCount) config.retryCount = 0;

            config.retryCount++;

            $http(config).then((response) => {
                deferred.resolve(response);
            }, (response) => {
                deferred.reject(response);
            });
        };

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.responseError = _responseError;

        return authInterceptorServiceFactory;
    }
})();
