module Shared {

    export class SaveExpenseRequest {
        expenseSubmissionId: number;
        description: string;
        assignmentId: string;
        assignmentName: string;
        mainSiteDateTime: Shared.BrooksonDate;
        endDateTime: string;
        mileageExpenseItems: MileageExpense[];
        expenseItems: RegularExpense[];
        allowanceItems: RegularExpense[];
        dataSource: number;
        isDeleted: boolean;
        hideGrowl: boolean;
    }

}

