/// <reference path="../../_all.ts"/>

var shared = angular.module('app.shared');

shared.directive('brooksonImageNotFound', () => {
	return {
		restrict: 'AE',
		templateUrl: 'src/app/shared/views/ui/brookson.ui.image-not-found.html'
	};
});
