/// <reference path="../../_all.ts" />
/// <reference path="./../models/HolidayRequest.ts" />
/// <reference path="./../models/BrooksonDate.ts" />
/// <reference path="./../modules/brookson.utilities.date.ts" />

module Shared {
    export interface IHolidaysSrv {
        submitHolidayRequest(startDate: BrooksonDate, endDate: BrooksonDate, employeeName: string): ng.IPromise<HolidayRequest>;
        approveHolidayRequest(requestId: number): ng.IPromise<boolean>;
        rejectHolidayRequest(requestId: number, reason: string): ng.IPromise<boolean>;
        getCompanyHolidays(startDate: Date, endDate: Date): ng.IPromise<Array<Date>>;
        getBankHolidays(country: string, endDate: Date, region: string, startDate: Date): ng.IPromise<Array<Date>>;
        getRemainingHolidayAllowance(): ng.IPromise<string>;
        getEmployeeHolidayAllowance(): ng.IPromise<number>;
        getStartOfHolidayYear(): ng.IPromise<Date>;
        deleteHolidayRequest(holidayRequestId: number, employeeId: number): ng.IPromise<boolean>;
        getUserHolidayRequests(startDate: BrooksonDate, endDate: BrooksonDate, status: number): angular.IPromise<Array<HolidayRequest>>;
        getCompanyHolidayRequests(startDate: Date, endDate: Date, status: number): angular.IPromise<Array<HolidayRequest>>;
        getCompanyActiveHolidayRequests(): angular.IPromise<Array<HolidayRequest>>;
        getNextHoliday(): ng.IPromise<HolidayRequest>;
    }

    export class HolidaysSrv implements IHolidaysSrv {

        static $inject = ["$http", "brookson.utilities.date"];

        constructor(
            private $http: ng.IHttpService,
            private dateUtils: Shared.IBrooksonUtilitiesDate) {
        }

        submitHolidayRequest(startDate: BrooksonDate, endDate: BrooksonDate, employeeName: string): angular.IPromise<HolidayRequest> {
            let url = "api/Holiday/SubmitHolidayRequest";

            var requestData = {
                startDate: this.dateUtils.convertToServerString(startDate),
                endDate: this.dateUtils.convertToServerString(endDate),
                employeeName: employeeName
            };

            return this.$http.get(url, { params: requestData }).then((response: ng.IHttpPromiseCallbackArg<HolidayRequest>) => response.data);
        }

        approveHolidayRequest(requestId: number): angular.IPromise<boolean> {
            let url = "api/Holiday/ApproveHolidayRequest?requestId=" + requestId;

            const config = {
                silentMode: false
            };

            return this.$http.get(url, config).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        rejectHolidayRequest(requestId: number, reason: string): angular.IPromise<boolean> {
            let url = "api/Holiday/RejectHolidayRequest";

            var requestData = {
                holidayRequestId: requestId,
                reason: reason
            };

            const config = {
                silentMode: false
            };

            return this.$http.post(url, requestData, config).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        getCompanyHolidays(startDate: Date, endDate: Date): angular.IPromise<Array<Date>> {
            let url = "api/Holiday/GetCompanyHolidays";

            var requestData = {
                startDate: this.dateUtils.convertToServerString(startDate),
                endDate: this.dateUtils.convertToServerString(endDate)
            };

            return this.$http.get(url, { params: requestData }).then((response: ng.IHttpPromiseCallbackArg<Array<Date>>) => response.data);
        }

        getCompanyHolidayRequests(startDate: Date, endDate: Date, status: number): angular.IPromise<Array<HolidayRequest>> {
            let url = "api/Holiday/GetCompanyHolidayRequests";

            var requestData = {
                startDate: startDate,
                endDate: endDate,
                status: status
            };

            const config = {
                silentMode: false
            };

            return this.$http.post(url, requestData, config).then((response: ng.IHttpPromiseCallbackArg<Array<HolidayRequest>>) => {
                return response.data;
            });
        }

        getUserHolidayRequests(startDate: BrooksonDate, endDate: BrooksonDate, status: number): angular.IPromise<Array<HolidayRequest>> {
            let url = "api/Holiday/GetUserHolidayRequests";

            var requestData = {
                startDate: startDate,
                endDate: endDate,
                status: status
            };

            const config = {
                silentMode: false
            };

            return this.$http.post(url, requestData, config).then((response: ng.IHttpPromiseCallbackArg<Array<HolidayRequest>>) => {
                return response.data;
            });
        }

        getBankHolidays(country: string, endDate: Date, region: string, startDate: Date): angular.IPromise<Date[]> {
            throw new Error("Not implemented");
        }

        getRemainingHolidayAllowance(): angular.IPromise<string> {
            let url = "api/Holiday/GetRemainingHolidayTime";

            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<string>) => {
                return response.data;
            });
        }

        getEmployeeHolidayAllowance(): angular.IPromise<number> {
            let url = "api/Holiday/GetEmployeeHolidayAllowance";

            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<number>) => {
                return response.data;
            });
        }

        deleteHolidayRequest(holidayRequestId: number, employeeId: number): angular.IPromise<boolean> {
            let url = `api/Holiday/DeleteHolidayRequest?holidayRequestId=${holidayRequestId}&employeeId=${employeeId}`;

            const config = {
                silentMode: false
            };

            return this.$http.delete(url, config).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            },
                error => {
                    return false;
                });
        }

        getStartOfHolidayYear(): angular.IPromise<Date> {
            let url = "api/Holiday/GetStartOfHolidayYear";

            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<Date>) => {
                return response.data;
            });
        }

        getNextHoliday(): angular.IPromise<HolidayRequest> {
            let url = "api/Holiday/GetNextHoliday";

            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<HolidayRequest>) => {
                return response.data;
            });
        }

        getCompanyActiveHolidayRequests(): angular.IPromise<HolidayRequest[]> {
            let url = "api/Holiday/GetCompanyActiveHolidayRequests";

            const config = {
                silentMode: false
            };

            return this.$http.get(url, config).then((response: ng.IHttpPromiseCallbackArg<Array<HolidayRequest>>) => {
                return response.data;
            });
        }
    }
}

angular.module("app.shared").service("holidaysSrv", Shared.HolidaysSrv);