/// <reference path="../../../_all.ts"/>

module Shared {
    'use strict';

    export interface IWidgetActionsBindings {
    }

    export class BrooksonWidgetActions implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: '&'
            };

            this.controller = WidgetActionsCtrl;
            this.templateUrl = 'src/app/shared/views/widgets/brookson.widgets.actions.html';
        }
    };

    export interface IWidgetActionsCtrl extends IWidgetActionsBindings {
        tasks: Array<Object>;
    }

    export class WidgetActionsCtrl implements IWidgetActionsCtrl {
        public tasks: Array<Object> = [];
        public showSpinner: boolean = true;
        public onComplete: Function;
        public widgetName: string = "brookson-widget-actions";
        public panelDisplayed: number = 0;
        public transactionsToAllocate: number = 0;
        public error: boolean = false;
        public transactionsList = [];

        public dashboardService: Shared.IDashboardService = this._dashboardService;

        static $inject: Array<string> = ['bankLink.enums', '$q', '$timeout', '$scope', 'taskPlannerSrv', 'brookson.navigation', 'taskPlanner.enums', 'dashboardService', 'bankLinkFactory'];

        constructor(
            private bankLinkEnums: Shared.IBankLinkEnums,
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private $scope: ng.IScope,
            private taskPlannerSrv: Shared.TaskPlannerSrv,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private enums: Shared.ITaskPlannerEnums,
            private _dashboardService: Shared.IDashboardService,
            private bankLinkFactory: Shared.IBankLinkFactory
        ) {

            $q.all([
                taskPlannerSrv.buildMonths(),
                bankLinkFactory.getBankAccounts(), 
                bankLinkFactory.getTransactions(false)
            ]).then((data) => {
                // TASKS
                this.tasks = _.chain(taskPlannerSrv.flatActions)
                    .filter((action) => {
                        return (action.status === 1 || action.status === 3) &&
                            action.responsibility === this.enums.TaskResponsibility.ME;
                    })
                    .orderBy('dueDate', 'asc')
                    .take(5)
                    .map((n) => {
                        n.camelCase = _.camelCase(n.taskGroup);
                        return n;
                    })
                    .value();
                // /TASKS

                // BANK
                if (bankLinkFactory.transactions && bankLinkFactory.transactions.length > 0) {
                    let transactions = bankLinkFactory.transactions;
                    let transactionType = {value: 2};

                    this.transactionsToAllocate = _.filter(transactions, {
                        transactionType: bankLinkEnums.TransactionType.UNALLOCATED
                    }).length;

                    bankLinkFactory.selectedBankAccount = {bankAccountId: "*"};
                    bankLinkFactory.selectedTransactionTypeChanged(transactionType);

                    this.transactionsList = _.take(bankLinkFactory.transactionsList, 5);
                }
                // /BANK
            }, () => {
                this.error = true;
            }).then(() => {
                this.showSpinner = false;
                this.$timeout(() => { this.onComplete(); });
            });
        }

        public gotoTask = (task) => {
            this.brooksonNavigation.taskPlanner.selectedActionTask(task.camelCase, task.actionId);
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonWidgetActions', new Shared.BrooksonWidgetActions());
