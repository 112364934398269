/// <reference path="./../models/sdc/SdcQuestionnaire.ts" />
/// <reference path="./../models/sdc/SdcQuestionnaireOutcome.ts" />
/// <reference path="./../models/sdc/SdcQuestionnaireInput.ts" />
/// <reference path="./../models/sdc/JobTitle.ts" />
/// <reference path="./../models/sdc/AssignmentSdcOutcome.ts" />

module Shared {
    export interface ISdcService {
        getLatestQuestionnaire(assignmentId: string): ng.IPromise<SdcQuestionnaire>;
        getOutcome(input: Shared.SdcQuestionnaireInput): ng.IPromise<SdcQuestionnaireOutcome>;
        searchJobTitle(data: string, silentMode?: boolean): ng.IPromise<Array<Shared.JobTitle>>;
        getAssignmentOutcomes(silentMode?: boolean): ng.IPromise<Array<Shared.AssignmentSdcOutcome>>;
        declineExpensesForAssignment(assignmentId: string, silentMode?: boolean): ng.IPromise<boolean>;
    }

    export class SdcService implements ISdcService {

        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        /**
         * Returns the latest questionnaire for a given assignment
         * 
         * 
         * @memberOf SdcService
         */
        getLatestQuestionnaire = (assignmentId: string): ng.IPromise<SdcQuestionnaire> => {
            return this.$http.get(`api/Sdc/GetLatestQuestionnaire?assignmentId=${assignmentId}`).then((response: ng.IHttpPromiseCallbackArg<SdcQuestionnaire>) => {
                return response.data;
            });
        }

        /**
         * Returns whether a user has passed SDC based on their input
         * 
         * 
         * @memberOf SdcService
         */
        getOutcome = (input: Shared.SdcQuestionnaireInput): ng.IPromise<SdcQuestionnaireOutcome> => {
            return this.$http.post(`api/Sdc/GetOutcome`, input).then((response: ng.IHttpPromiseCallbackArg<SdcQuestionnaireOutcome>) => {
                return response.data;
            });
        }

        /**
         * Searches Digita for a list of Job Titles based on the query provided
         * 
         * 
         * @memberOf SdcService
         */
        searchJobTitle = (data: string, silentMode?: boolean): ng.IPromise<Array<Shared.JobTitle>> => {
            return this.$http.get(`api/Sdc/SearchJobTitle?query=${data}`, {
                silentMode: silentMode ? silentMode : false,
                cache: true
            }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.JobTitle>>) => {
                return response.data;
            });
        }

        /**
         * Returns a list of assignment outcomes for the member
         * 
         * 
         * @memberOf SdcService
         */
        getAssignmentOutcomes = (silentMode?: boolean): ng.IPromise<Array<Shared.AssignmentSdcOutcome>> => {
            return this.$http.get(`api/Sdc/GetAssignmentOutcomes`, {
                silentMode: silentMode ? silentMode : false,
                cache: true
            }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.AssignmentSdcOutcome>>) => {
                return response.data;
            });
        }

        /**
         * Declines as assignment to submit expenses
         * 
         * 
         * @memberOf SdcService
         */
        declineExpensesForAssignment = (assignmentId: string, silentMode?: boolean): ng.IPromise<boolean> => {
            return this.$http.patch(`api/Sdc/DeclineExpensesForAssignment?assignmentId=${assignmentId}`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared').service('sdcService', Shared.SdcService);