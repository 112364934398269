/// <reference path="../../services/brookson.services.company-year-end.ts" />

module Shared {
    'use strict';

    export interface IBrooksonYearEndTileController {
        actionTile: any;
    }

    export class BrooksonYearEndTileController implements IBrooksonYearEndTileController{
        public actionTile: Notification;

        public yearEndTileDetails: CompanyYearEndTile;
        public isConfirming: boolean;
        public confirmationError: boolean;

        static $inject: Array<string> = ['brooksonServicesCompanyYearEnd'];

        constructor(
            private data: Shared.BrooksonServicesCompanyYearEnd,
        ) {
            data.getCompanyYearEndTileDetails().then((result: CompanyYearEndTile) => {
                this.yearEndTileDetails = result;
            });
        }

        public setAsConfirming = (value: boolean): void => {
            this.isConfirming = value;
            this.confirmationError = false;
        }
        
        public setAccountsConfirmed = (): void => {
            this.data.setDraftAccountsConfirmed(this.yearEndTileDetails.currentYearEnd, "").then((result: boolean) => {
                if(result)
                {
                    this.yearEndTileDetails.draftAccountsConfirmed = true;
                    this.confirmationError = false;
                    this.isConfirming = false;
                }
                else
                {
                    this.confirmationError = true;
                    this.isConfirming = false;
                }
            });
        }
    }

    export const BrooksonYearEndTile: ng.IComponentOptions = {
        bindings: {
            actionTile: '='
        },
        controller: BrooksonYearEndTileController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.confirm.year.end.html',
        transclude: true
    };

}

angular
    .module('app.shared')
    .component('brooksonYearEndTile', Shared.BrooksonYearEndTile);