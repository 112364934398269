
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class ForecastCalculations  {
        
        // MONTHSYEARTODATE
        public monthsYearToDate: number = 0;
        // MONTHSREMAINING
        public monthsRemaining: number = 0;
        // WEEKSYEARTODATE
        public weeksYearToDate: number = 0;
        // WEEKSREMAINING
        public weeksRemaining: number = 0;
        // WEEKSYEARTODATEFROMTAXSTART
        public weeksYearToDateFromTaxStart: number = 0;
        // WEEKSREMAININGFROMTAXSTART
        public weeksRemainingFromTaxStart: number = 0;
        // PSCEMPLOYMENT
        public pscEmployment: number = 0;
        // CURRENTDIRECTORSFEEWEEKLY
        public currentDirectorsFeeWeekly: number = 0;
        // SHAREHOLDERSPLIT
        public shareholderSplit: number = 0;
        // CONFIRMEDASDIVIDENDS
        public confirmedAsDividends: number = 0;
        // UNCONFIRMEDTRANSACTIONS
        public unconfirmedTransactions: number = 0;
        // TRANSACTIONSBEINGPROCESSED
        public transactionsBeingProcessed: number = 0;
        // TAXDEDUCTEDFROMPSCINCOME
        public taxDeductedFromPSCIncome: number = 0;
        public isMonthlyBureau: boolean = false;
    }
}
