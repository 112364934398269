/// <reference path="../../../../app/_all.ts"/>
/// <reference path="./../../models/UserFeedback.ts"/>

module Shared {
    
    export class BrooksonUiFeedback implements ng.IComponentOptions {
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.controller = ["brooksonUiFeedbackFactory", BrooksonUiFeedbackController];
            this.templateUrl = 'src/app/shared/views/ui/brookson.ui.feedback.html';
        }
    }
    
    class BrooksonUiFeedbackController {

        static $inject = ['$window'];
        
        constructor(private factory: IBrooksonUiFeedbackFactory,
            private $window: Window) { }

        openModal() {
            this.factory.openModal();
        }

        cancelScreenshotPressed() {
            this.factory.isScreenshotMode = false;
            this.factory.showModal();
            this.factory.destroyCanvas();
        }

        takeScreenshotPressed() {
            this.factory.takeScreenshot();
        }
    }
}
angular
    .module('app.shared')
    .component('brooksonUiFeedback', new Shared.BrooksonUiFeedback());