namespace Shared {
    "use strict";

    export class BrooksonInputExpenseAmount implements ng.IComponentOptions {
        bindings: { [binding: string]: string };
        controller: Function;
        templateUrl: string;
        require: {[controller: string]: string};

        constructor() {
            this.bindings = {
                expense: "=",
                index: "="
            };
            this.require = {
                
            };
            this.controller = BrooksonInputExpenseAmountController;
            this.templateUrl = "src/app/shared/views/inputs/brookson.input.expense-amount.html";
        }
    }

    export class BrooksonInputExpenseAmountController {
        
        expense: RegularExpense;
        maxVat = 0;
        vatRates: Array<VatRate>;
        vatRateConfig: Array<VatRateConfig>;

        static $inject = ["$scope", "orderByFilter", "brookson.utilities.date", "brooksonFactoriesExpenses"];

        constructor(
            private $scope: ng.IScope,
            private orderByFilter: ng.IFilterOrderBy,
            private dateUtils: IBrooksonUtilitiesDate,
            private expensesFactory: IExpensesFactory,
            private expensesEnums: IExpensesEnums
        ) { }

        $onInit = () => {
            this.$scope.$watch("$ctrl.expense.isVatReclaimable",
                (newValue, oldValue) => {
                    if (oldValue && !newValue) {
                        this.expense.hasValidVatReceipt = false;
                        this.clearVat();
                    }
                });

            this.expensesFactory.getAllVatRates(true).then((result) => {
                this.vatRates = this.orderByFilter(result, "-startDate");
                this.setMaxVat();
            });
            
            this.expensesFactory.getAllVatRatesConfig(true).then((result) => {
                this.vatRateConfig = this.orderByFilter(result, "-effectiveDate");
            });
        }

        vatChanged = (): void => {
            this.expense.net = (this.expense.gross || 0) - (this.expense.vat || 0);
        }

        clearVat = (): void => {
            this.expense.vat = 0;
            this.vatChanged();
            this.maxVat = 0;
        }

        getVatAmount = () => {
            const vatRate = this.getApplicableVatRate();
            return Math.floor((vatRate * (this.expense.gross || 0)) / (100 + vatRate) * 100) / 100;
        }

        setMaxVat = () => {

            var vat = this.getVatAmount();
            const vatRate = this.getApplicableVatRate();
            var vat20precent = Math.floor((20 * (this.expense.gross || 0)) / (100 + 20) * 100) / 100;          
            this.maxVat = vatRate > 0 && vatRate < 20 ? vat20precent : vat; 
        }

        grossChanged = (): void => {
            if (this.expense.hasValidVatReceipt) {
                this.expense.vat = this.getVatAmount();
                this.setMaxVat();
                this.vatChanged();
            } else {
                this.clearVat();
            }
        }

        checkForExpenseWarning = (index: number): void => {
            let expenseLine = this.expensesFactory.regularExpenses[index];
            if (expenseLine && expenseLine.typeObject && expenseLine.expenseWarning) {
                let expenseWarning = this.expensesFactory.getExpenseWarning(expenseLine.typeObject);
                if (expenseWarning) {
                    if (expenseLine.expenseWarning && expenseLine.expenseWarning.warning && !expenseLine.expenseWarning.warning.highRisk) {
                        if (expenseLine.net) {
                            this.expensesFactory.addExpenseWarningMessage(expenseWarning, expenseLine);
                            if (expenseLine.expenseWarning.showWarningAmount) {
                                this.expensesFactory.removeExpenseWarnings(expenseWarning, expenseLine);
                            } else {
                                let exp = _.find(this.expensesFactory.regularExpenses, (e: Shared.RegularExpense) => !e.expenseWarning.warning && e.typeObject.paymentCode === expenseLine.typeObject.paymentCode);
                                if (exp) {
                                    this.expensesFactory.addExpenseWarningMessage(expenseWarning, expenseLine);
                                }
                            }
                        }
                    }

                }                
            }
        }

        hasValidVatReceiptChanged = (): void => {
            if (!this.expense.hasValidVatReceipt) {
                this.clearVat();
            } else if (this.expense.gross) {
                this.grossChanged();
            }
        }

        private getApplicableVatRate = (): number => {
            const expenseDate = this.expense.date ? this.dateUtils.convertToJsDate(this.expense.date) : new Date();

            const applicableRateConfig = this.vatRateConfig ? _.find(this.vatRateConfig, (rateConfig: VatRateConfig) => {
                if (this.expense.typeObject && this.expense.typeObject)
                {
                return expenseDate >= rateConfig.effectiveDate  &&  expenseDate <= rateConfig.endDate && rateConfig.expenseCode === this.expense.typeObject.paymentCode;
                }
            }) : new VatRateConfig();
           
            const applicableRateAmount = this.vatRates ? _.find(this.vatRates, (rate: VatRate) => {
                return rate.startDate <= expenseDate;
            }).amount : 20;

            if(applicableRateConfig && applicableRateConfig.vatRate)
            {
                return applicableRateConfig.vatRate;
            }

            return applicableRateAmount;
        }
    }
}

angular.module("app.shared")
    .component("brooksonInputExpenseAmount", new Shared.BrooksonInputExpenseAmount());