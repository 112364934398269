/// <reference path="./../../_all.ts"/>

module Shared {
    export interface INotificationService {
        getNotifications(silentMode?: boolean): ng.IPromise<GetNotificationsResponse>;
        closeNotification(notification: Notification) : ng.IPromise<boolean>;
    }
}

(() => {
    'use strict';

    angular
        .module('app.shared')
        .service('notificationSrv', notificationSrv);

    notificationSrv.$inject = ['$http', 'shared.config'];

    function notificationSrv($http, sharedConfig) {

        var service:any = {};
        var serviceBase = sharedConfig.baseApiUrl;

        // Methods
        service.getNotifications = getNotifications;
        service.closeNotification = closeNotification;

        function getNotifications(silentMode) {
            return $http.get('api/Notifications/GetNotifications', {
                silentMode: silentMode ? silentMode : false,
                cache: true
            }).then((response) => {
                return response.data;
            });
        }

        function closeNotification(notification) {
            return $http.delete(serviceBase + "api/Notifications/DeleteNotification/" + notification.id)
                .then((response) => {
                    return response.data;
                });
        }

        return service;
    }
})();
