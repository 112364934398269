/// <reference path="../../../../app/_all.ts"/>

module Shared {
    export class AcceptTermsAndConditions implements ng.IComponentOptions {
        public bindings: any;
        public templateUrl: any;
        public controller: any;
        public transclude: boolean;

        constructor() {
            this.bindings = {
                ngModel: '=',
                headingText: '@',
                acceptText: '@'
            };
            this.templateUrl = 'src/app/shared/views/ui/brookson.ui.accept-terms-and-conditions.html';
            this.controller = ['$scope', AcceptTermsAndConditionsController];
            this.transclude = true;
        }
    }

    export class AcceptTermsAndConditionsController {
        constructor(private $scope: ng.IScope){}
    }
}

angular.module('app.shared')
    .component("brooksonUiAcceptTermsAndConditions", new Shared.AcceptTermsAndConditions());