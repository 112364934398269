/// <reference path="../../_all.ts"/>
/// <reference path="./brookson.enums.ts"/>
/// <reference path="./../models/Notification.ts"/>
/// <reference path="./../models/GetNotificationsResponse.ts"/>
/// <reference path="./../models/TaskAction.ts"/>
/// <reference path="./brookson.navigation.ts"/>
/// <reference path="./../services/brookson.services.notification.ts"/>
/// <reference path="./../services/brookson.services.task-planner.ts"/>
/// <reference path="./brookson.utilities.date.ts"/>
/// <reference path="./../services/brookson.services.calendly.ts" />

module Shared {

    /**
     * Model for local storage to identify whether notifications have been shown
     * 
     * @export
     * @class NotificationsShown
     */
    export class NotificationsShown {
        /**
         * Have the task planner notifications been displayed to the user
         * 
         * @type {boolean}
         * @memberOf NotificationsShown
         */
        taskPlannerNotifications: boolean;
        /**
         * Has the unallocated transaction notifcation been displayed to the user
         * 
         * @type {boolean}
         * @memberOf NotificationsShown
         */
        unallocatedTransactions: boolean;
    }

    /**
     * A model of all the notifications / smart tiles when getNotifications is called 
     * 
     * @export
     * @class Notifications
     */
    export class Notifications {
        /**
         * Creates an instance of Notifications.
         * 
         * @param {Array<Notification>} notifications
         * @param {Array<Notification>} tiles
         * @param {Array<TaskAction>} planner
         * @param {Array<Notification>} banners 
         * 
         * @memberOf Notifications
         */
        constructor(public notifications: Array<Notification>, public tiles: Array<Notification>, public planner: Array<TaskAction>, public banners: Array<Notification>) { }
    }

    /**
     * Interface for the brookson notification manager
     * 
     * @export
     * @interface IBrooksonNotificationManager
     */
    export interface IBrooksonNotificationManager {
        /**
         * Property to store the notifications
         * 
         * @type {Notifications}
         * @memberOf IBrooksonNotificationManager
         */
        notifications: Notifications;
        /**
         * Gets a model of all the notifications 
         * 
         * @returns {ng.IPromise<Notifications>}
         * 
         * @memberOf IBrooksonNotificationManager
         */
        getNotifications(): ng.IPromise<Notifications>;
        /**
         * Returns any task planner notifications
         * 
         * @returns {ng.IPromise<Array<TaskAction>>}
         * 
         * @memberOf IBrooksonNotificationManager
         */
        getTaskPlannerNotifications(): ng.IPromise<Array<TaskAction>>;
        /**
         * Returns if there are an unallocated transactions
         * 
         * @returns {ng.IPromise<Notification>}
         * 
         * @memberOf IBrooksonNotificationManager
         */
        getUnallocatedTransactions(): ng.IPromise<Notification>;
        /**
         * Dismisses a smart tile
         * 
         * @param {Notification} smartTile
         * 
         * @memberOf IBrooksonNotificationManager
         */
        closeSmartTile(smartTile: Notification): void;
        /**
         * Closes all of the alerts
         * 
         * 
         * @memberOf IBrooksonNotificationManager
         */
        closeAlerts(): void;
        /**
         * Go to a Task in Task Planner 
         * 
         * @param {TaskAction} task
         * 
         * @memberOf IBrooksonNotificationManager
         */
        goToTask(task: TaskAction): void;
        /**
         * Reset the local storage value to state that the user has not viewed the notifications
         * 
         * 
         * @memberOf IBrooksonNotificationManager
         */
        clearNotificationsShown(): void;
    }


    /**
     * A notification manager to help maintain the notifications and smart tiles
     * 
     * @export
     * @class BrooksonNotificationManager
     * @implements {IBrooksonNotificationManager}
     */
    export class BrooksonNotificationManager implements IBrooksonNotificationManager {
        /**
         * The local storage key for storing whether a user has seen notifications
         * 
         * @static
         * @type {string}
         * @memberOf BrooksonNotificationManager
         */
        static LOCAL_STORAGE_KEY: string = 'brookson.notifications';
        /**
         * The local storage key for whether they have seen the task planner notifications
         * 
         * @static
         * @type {string}
         * @memberOf BrooksonNotificationManager
         */
        static TASK_PLANNER_NOTIFICATION_KEY: string = 'taskPlannerNotifications';
        /**
         * The local storage key for whether they have seen the unallocated transactions notifications
         * 
         * @static
         * @type {string}
         * @memberOf BrooksonNotificationManager
         */
        static UNALLOCATED_TRANSACTIONS_KEY: string = 'unallocatedTransactions';
        /**
         * The local storage key for whether the user has dismissed all of the alerts 
         * 
         * @static
         * @type {string}
         * @memberOf BrooksonNotificationManager
         */
        static UNALLOCATED_TRANSACTIONS_CLEAR_KEY: string = 'unallocatedTransactionsClear';

        /**
         * Property that stores whether a type of notification has been shown
         * 
         * @private
         * @type {NotificationsShown}
         * @memberOf BrooksonNotificationManager
         */
        private notificationsShown: NotificationsShown = {
            taskPlannerNotifications: false,
            unallocatedTransactions: false
        };

        /**
         * A model of all notifications 
         * 
         * @type {Notifications}
         * @memberOf BrooksonNotificationManager
         */
        public notifications: Notifications;

        /**
         * Angular Dependency Injection
         * 
         * @static
         * 
         * @memberOf BrooksonNotificationManager
         */
        static $inject = ['$q', '$window', 'notificationSrv', 'taskPlannerSrv', 'moneyManagerSrv', 'brookson.utilities.date', 'growl', 'taskPlanner.enums', 'bankLink.enums', 'localStorageService', 'brookson.navigation', 'screenSize', 'calendlySrv', 'brookson.utilities.date'];

        /**
         * Creates an instance of BrooksonNotificationManager.
         * 
         * @param {ng.IQService} $q
         * @param {ng.IWindowService} $window
         * @param {INotificationService} notificationSrv
         * @param {ITaskPlannerSrv} taskPlannerSrv
         * @param {*} moneyManagerSrv
         * @param {IBrooksonUtilitiesDate} brooksonUtilitiesDate
         * @param {ng.growl.IGrowlService} growl
         * @param {ITaskPlannerEnums} taskPlannerEnums
         * @param {IBankLinkEnums} bankLinkEnums
         * @param {ng.local.storage.ILocalStorageService} localStorageService
         * @param {IBrooksonNavigation} brooksonNavigation
         * @param {*} screenSize
         * 
         * @memberOf BrooksonNotificationManager
         */
        constructor(
            private $q: ng.IQService,
            private $window: ng.IWindowService,
            private notificationSrv: INotificationService,
            private taskPlannerSrv: ITaskPlannerSrv,
            private moneyManagerSrv: any,
            private brooksonUtilitiesDate: IBrooksonUtilitiesDate,
            private growl: ng.growl.IGrowlService,
            private taskPlannerEnums: ITaskPlannerEnums,
            private bankLinkEnums: IBankLinkEnums,
            private localStorageService: ng.local.storage.ILocalStorageService,
            private brooksonNavigation: IBrooksonNavigation,
            private screenSize: any,
            private calendlyService: Shared.ICalendlyService,
            private dateUtils: IBrooksonUtilitiesDate) { }

        /**
         * Gets a model of all the notifications
         * 
         * @returns {ng.IPromise<Notifications>}
         * 
         * @memberOf BrooksonNotificationManager
         */
        public getNotifications = (): ng.IPromise<Notifications> => {
            return this.$q.all([this.getTaskPlannerNotifications(), this.getSmartNotifications(), this.getUnallocatedTransactions()]).then(data => {
                var planner = <Array<TaskAction>>data[0];
                var smartNotifications = <GetNotificationsResponse>data[1];
                var unallocatedTransactionNotification = <Notification>data[2];

                planner.forEach(task => {
                    task.camelCase = _.camelCase(task.taskTitle);
                    return task;
                });

                var notifications: Array<Notification> = [];
                notifications = _.concat(notifications, smartNotifications.alertNotifications);

                if (unallocatedTransactionNotification) notifications.push(unallocatedTransactionNotification);

                this.calendlyService.getCalendlyFinancialHealthCheckBooking().then((financialHealthCheckCalendlyBooking: Shared.MicroCalendlyBooking): void => {
                    if (financialHealthCheckCalendlyBooking) {
                        var endDate = this.dateUtils.convertToJsDate(financialHealthCheckCalendlyBooking.endDate);
                        var minimumPreviousDate = new Date();
                        minimumPreviousDate.setDate(minimumPreviousDate.getDate() - 30);
                       
                        if (!(endDate <= minimumPreviousDate)) {
                            smartNotifications.actionTileNotifications = smartNotifications.actionTileNotifications.filter(f => f.action !== "FinancialHealthCheck");
                        }
                    }
                    var tiles: Array<Notification> = [];
                    tiles = _.concat(tiles, smartNotifications.actionTileNotifications);
                    tiles = _.concat(tiles, smartNotifications.smartTileNotifications);


                    var result = new Notifications(notifications, tiles, planner, smartNotifications.bannersNotifications);

                    this.notifications = result;
                });

                return this.notifications;

            });
        }

        /**
         * Returns any task planner notifications
         * 
         * @returns {ng.IPromise<Array<TaskAction>>}
         * 
         * @memberOf BrooksonNotificationManager
         */
        public getTaskPlannerNotifications = (): ng.IPromise<Array<TaskAction>> => {
            return this.taskPlannerSrv.getTasksToBeDisplayed(true).then(data => {
                if (!this.getNotificationKey(BrooksonNotificationManager.TASK_PLANNER_NOTIFICATION_KEY)) {
                    var taskActions = data;

                    _.filter(taskActions, (taskAction) => {
                        return taskAction.responsibility === this.taskPlannerEnums.TaskResponsibility.ME;
                    });

                    var overDueTaskActions = _.filter(taskActions, (taskAction) => taskAction.status === this.taskPlannerEnums.TaskStatus.OVERDUE);
                    var upcomingTaskActions = _.filter(taskActions, (taskAction) => taskAction.status !== this.taskPlannerEnums.TaskStatus.OVERDUE);

                    /**
                    *  If there is more than 1 upcoming task action to be completed, only display
                    * one notification so we don't clutter the dashboard 
                    * */
                    if (upcomingTaskActions.length === 1) {
                        _.forEach(upcomingTaskActions, taskAction => {
                            if (!this.screenSize.is('xs, sm')) {
                                this.growl.warning(taskAction.actionTitle + " is upcoming <strong>" + moment(taskAction.dueDate).format("DD/MM/YYYY") + "</strong>", {
                                    title: "Task Planner",
                                    ttl: 5000,
                                    disableCountDown: true,
                                    variables: {
                                        onClick: () => {
                                            this.goToTask(taskAction);
                                        }
                                    }
                                });
                            }
                        });
                    } else if (upcomingTaskActions.length > 1) {
                        this.growl.warning(`You have tasks that are upcoming.`, {
                            title: "Task Planner",
                            ttl: 5000,
                            disableCountDown: true,
                            variables: {
                                onClick: () => {
                                    this.goToTaskPlanner();
                                }
                            }
                        });
                    }

                    /**
                     *  If there is more than 1 overdue task action to be completed, only display
                     * one notification so we don't clutter the dashboard 
                     * */
                    if (overDueTaskActions.length === 1) {
                        _.forEach(overDueTaskActions, taskAction => {

                            if (!this.screenSize.is('xs, sm')) {
                                this.growl.error(taskAction.actionTitle + " is overdue <strong>" + moment(taskAction.dueDate).format("DD/MM/YYYY") + "</strong>", {
                                    title: "Task Planner",
                                    ttl: -1,
                                    disableCountDown: true,
                                    variables: {
                                        onClick: () => {
                                            this.goToTask(taskAction);
                                        }
                                    }
                                });
                            }

                        });
                    } else if (overDueTaskActions.length > 1) {
                        this.growl.error(`You have tasks that are overdue.`, {
                            title: "Task Planner",
                            ttl: -1,
                            disableCountDown: true,
                            variables: {
                                onClick: () => {
                                    this.goToTaskPlanner();
                                }
                            }
                        });
                    }

                    this.setNotificationKey(BrooksonNotificationManager.TASK_PLANNER_NOTIFICATION_KEY);
                }

                return data;
            });
        }

        /**
        * Go to a Task in Task Planner 
        * 
        * @param {TaskAction} task
        * 
        * @memberOf IBrooksonNotificationManager
        */
        public goToTask = (task: TaskAction): void => {
            this.brooksonNavigation.taskPlanner.selectedActionTask('todo', task.actionId);
        }

        /**
         * 
         * 
         * 
         * @memberOf BrooksonNotificationManager
         */
        public getUnallocatedTransactions = (): ng.IPromise<Notification> => {
            if (this.getNotificationKey(BrooksonNotificationManager.UNALLOCATED_TRANSACTIONS_CLEAR_KEY)) {
                var deferred = this.$q.defer();
                deferred.resolve();
                return deferred.promise;
            }

            return this.moneyManagerSrv.getTransactions('', true).then((data) => {
                let result: Notification = null;
                if (data == null) {
                    return result;
                }
                var transactions = data.transactions;


                var transactionsToAllocate = _.filter(transactions, {
                    transactionType: this.bankLinkEnums.TransactionType.UNALLOCATED
                }).length;



                if (transactionsToAllocate > 0) {

                    if (!this.getNotificationKey(BrooksonNotificationManager.UNALLOCATED_TRANSACTIONS_KEY)) {
                        this.growl.warning(`You have ${transactionsToAllocate} unallocated transaction${transactionsToAllocate === 1 ? '' : 's'}`, {
                            title: "Bank",
                            ttl: 5000,
                            disableCountDown: true,
                            variables: {
                                /**
                                 * 
                                 */
                                onClick: () => {
                                    this.brooksonNavigation.bankLink.main();
                                }
                            }
                        });

                    }

                    result = <Notification>{
                        title: 'Bank',
                        metaDescription: `${transactionsToAllocate} unallocated transaction${transactionsToAllocate === 1 ? '' : 's'}`,
                        colour: 'yellow',
                        symbol: 'money'
                    };

                    result.onClick = () => this.brooksonNavigation.expenses.business();
                }

                this.notificationsShown.unallocatedTransactions = true;

                this.setNotificationKey(BrooksonNotificationManager.UNALLOCATED_TRANSACTIONS_KEY);

                return result;
            });
        }

        /**
          * Dismisses a smart tile
          * 
          * @param {Notification} smartTile
          * 
          * @memberOf IBrooksonNotificationManager
          */
        public closeSmartTile = (smartTile: Notification): void => {
            _.pull(this.notifications.tiles, smartTile);
            this.notificationSrv.closeNotification(smartTile);
        }

        /**
         * Closes all of the alerts
         * 
         * 
         * @memberOf IBrooksonNotificationManager
         */
        public closeAlerts = (): void => {
            _.forEach(this.notifications.notifications, (alert) => {
                if (alert.id) this.notificationSrv.closeNotification(alert);
            });

            this.notifications.notifications.length = 0;
            this.setNotificationKey(BrooksonNotificationManager.UNALLOCATED_TRANSACTIONS_CLEAR_KEY);
        }

        /**
         * Reset the local storage value to state that the user has not viewed the notifications
         * 
         * 
         * @memberOf IBrooksonNotificationManager
         */
        public clearNotificationsShown = (): void => {
            this.localStorageService.set(BrooksonNotificationManager.LOCAL_STORAGE_KEY, {});
        }

        /**
         * Returns a list of all the smart notifications to be displayed 
         * 
         * @private
         * @returns {ng.IPromise<GetNotificationsResponse>}
         * 
         * @memberOf BrooksonNotificationManager
         */
        private getSmartNotifications = (): ng.IPromise<GetNotificationsResponse> => {
            return this.notificationSrv.getNotifications(true).then(data => data);
        }

        /**
         * Returns a value from notificaiton local storage container and returns the property
         * 
         * @private
         * @param {string} notification
         * @returns
         * 
         * @memberOf BrooksonNotificationManager
         */
        private getNotificationKey = (notification: string) => {
            var data = <NotificationsShown>this.localStorageService.get(BrooksonNotificationManager.LOCAL_STORAGE_KEY);

            this.notificationsShown = data === null ? <NotificationsShown>{} : data;

            return this.notificationsShown[notification];
        }

        /**
         * Sets that a certain type of notification has been displayed to the user by setting it in local storage
         * 
         * @private
         * 
         * @memberOf BrooksonNotificationManager
         */
        private setNotificationKey = (notification: string) => {
            this.notificationsShown[notification] = true;
            this.localStorageService.set(BrooksonNotificationManager.LOCAL_STORAGE_KEY, this.notificationsShown);
        }

        /**
         * Redirects the user to Task Planner
         * 
         * 
         * @memberOf BrooksonNotificationManager
         */
        private goToTaskPlanner = (): void => {
            this.brooksonNavigation.taskPlanner.main();
        }
    }
}

angular
    .module('app.shared')
    .service('brookson.notifications.manager', Shared.BrooksonNotificationManager);
