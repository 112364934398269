module Shared {
    export function FilterBankAccount() {
        return (value) => {
            if (value && value.length >= 6 && value.indexOf('-') === -1) {
                return value.substr(0, 2) + "-" + value.substr(2, 2) + "-" + value.substr(4, 2);
            }

            return value;
        };
    }
}

angular.module("app.shared").filter("sortcode", Shared.FilterBankAccount);