module Shared {
    export class UmbPayslipTable {
        payYear: number;
        payPeriod: number;
        totalFunds: number;
        eMPNI: number;
        appLevy: number;
        margin: number;
        grossPay: number;
        pAYE: number;
        eENI: number;
        eEPen: number;
        studentLoan: number;
        netPay: number;
        businessID: string;
        personID: string;
        employer_Pension_Recovery: number;
        pen_er_con: number;
        pENERDR: number;
        pENSION_EE: number;
        pension_ER: number;
        rounding: number;
        eE_NEST_Refund: number;
        er_salsac: number;
        standard_day: number;
        lOAN: number;
        other_pay: number;
        unrecovered_NI: number;
        nEST_Penson_Employers_Refund: number;
        sSP: number;
        aegon_ae_er: number;
        aegone_ae_ee: number;
        cOVID_19_PAY: number;
        exp_umbrella: number;
        fur_app_levy: number;
        furlough_lel: number;
        nET_ADDITION: number;
        paid_Expenses: number;
        unpaid_Claimed_Expense: number;
        p45_GROSS: number;
        connectUser: string;
        createdDate: string;
        section: string;
        subSection: string;
        order: number;
    }
}