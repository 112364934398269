/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetDocumentStorageUmbrellaRecentDocuments', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.document-storage.umbrella-recent-documents.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetDocumentStorageUmbrellaRecentDocuments
    });

    brooksonWidgetDocumentStorageUmbrellaRecentDocuments.$inject = ['$timeout', 'documentStorageSrv', 'brooksonDocumentPreview', 'brookson.navigation','dashboardService','accountSrv'];

    function brooksonWidgetDocumentStorageUmbrellaRecentDocuments($timeout, documentStorageSrv, brooksonDocumentPreview, brooksonNavigation, _dashboardService, accountSrv: Shared.IAccountService) {
        
       
        var vm = this;

        vm.data = documentStorageSrv;
        vm.brooksonNavigation = brooksonNavigation;
        vm.showSpinner = true;
        vm.previewDocument = brooksonDocumentPreview.previewDoc;
        vm.error = false;
        vm.dashboardService = _dashboardService;
        vm.widgetName = "brookson-widget-document-storage-umbrella-recent-documents";
        vm.panelDisplayed = 0;

        vm.openContract = (documentType: string) => {

            if(documentType == undefined)
            {
                accountSrv.getAccountDetailsForUser().then((data: Shared.NavContactInformation) => {
                    documentType = data.personalInformation.contractFileName;        
                    documentStorageSrv.downloadEmploymentFile(documentType);        
                });

            } else {
                documentStorageSrv.downloadEmploymentFile(documentType);
            };
        };

        function getRecentDocuments() {
            documentStorageSrv.getDocumentStorageWidgetData(true).then((data) => {
                vm.showSpinner = false;
                
                if (data.recentDocuments) {
                    vm.data.recentDocuments = _.take(data.recentDocuments, 5);
                } else {
                    vm.panelDisplayed = 1;
                }
            }, (error) => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
            });
        }

        function activate() {
            getRecentDocuments();
        }

        activate();
    }
})();
