module Shared {
    export class UnconfirmedTable {
        unconfirmedTransactions: number;
        reconciledto: string;
        businessID: string;
        personID: string;
        connectUser: string;
        createdDate: string;
        section: string;
        subSection: string;
        order: number;
    }
}