/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetUmbrellaPayalerts', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.umbrella.payalerts.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetUmbrellaPayalerts
    });

    brooksonWidgetUmbrellaPayalerts.$inject = ['$timeout', 'invoicingSrv', 'brookson.navigation','dashboardService', 'appSettings','memberSrv'];

    function brooksonWidgetUmbrellaPayalerts($timeout, invoicingSrv, brooksonNavigation, dashboardService, appSettings, memberSrv) {
        var vm = this;

        vm.latestInvoices = {};
        vm.latestPayments = {};
        vm.showSpinner = false;
        vm.brooksonNavigation = brooksonNavigation;
        vm.memberSrv = memberSrv;
        vm.showNewSetupText = false;
        vm.dashboardService = dashboardService;
        vm.widgetName = "brookson-widget-umbrella-payalerts";
        vm.appSettings = appSettings;
        vm.memberBusinessType = '';
    }
})();
