module Shared {
    export module Invoicing {
        export class InvoicePreference {
            includeCompanyLogo: boolean;
            azureCompanyLogoSharedAccessSignature: string;
            colour: string;
            fontfamily: string;
            includeCompanyAddress: boolean;
            includeCompanyRegNo: boolean;
            includeInvoiceDate: boolean;
            includePaymentDueDate: boolean;
            includeOrderReference: boolean;
            includeDiscountPounds: boolean;
            includeDiscountPercentage: boolean;
            includeDeductions: boolean;
            includeCisDeductions: boolean;
            includeBankDetails: boolean;
            includeNotes: boolean;
            defaultNoteText: string;
            fullInvoiceId: number;
        }
    }
}