/// <reference path="./DashboardUserWidget.ts" />

module Shared {
    export class DashboardUser
    {
        dashboardUserId: number;
        memberId: number;
        companyRef: string;
        name: string;
        isDefault: boolean;
        widgets: Array<DashboardUserWidget>;
        isBrookson:boolean;
        status:string;
    }
}