/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetDocumentStorageRecentDocuments', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.document-storage.recent-documents.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetDocumentStorageRecentDocuments
    });

    brooksonWidgetDocumentStorageRecentDocuments.$inject = ['$timeout', 'documentStorageSrv', 'brooksonDocumentPreview', 'brookson.navigation','dashboardService'];

    function brooksonWidgetDocumentStorageRecentDocuments($timeout, documentStorageSrv, brooksonDocumentPreview, brooksonNavigation, _dashboardService) {
        
       
        var vm = this;

        vm.data = documentStorageSrv;
        vm.brooksonNavigation = brooksonNavigation;
        vm.showSpinner = true;
        vm.previewDocument = brooksonDocumentPreview.previewDoc;
        vm.error = false;
        vm.dashboardService = _dashboardService;
        vm.widgetName = "brookson-widget-document-storage-recent-documents";
        
        
        function getRecentDocuments() {
            documentStorageSrv.getDocumentStorageWidgetData(true).then((data) => {
                vm.showSpinner = false;

                if (data.recentDocuments) {
                    vm.data.recentDocuments = _.take(data.recentDocuments, 10);
                }
            }, (error) => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
            });
        }

        function activate() {
            getRecentDocuments();
        }

        activate();
    }
})();
