/// <reference path="../../../_all.ts"/>

module Shared {
    'use strict';

    export class BrooksonWidgetEmploymentRecord implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: '&'
            };

            this.controller = WidgetEmploymentRecordCtrl;
            this.templateUrl = 'src/app/shared/views/widgets/brookson.widgets.employment-record.html';
        }
    };

    export interface IWidgetEmploymentRecordCtrl {
        tasks: Array<Object>;
    }

    export class WidgetEmploymentRecordCtrl implements IWidgetEmploymentRecordCtrl {
        public tasks: Array<Object> = [];
        public showSpinner: boolean = true;
        public onComplete: Function;
        public error: boolean;
        public widgetName = "brookson-widget-employment-record";
        public nationalities: Array<NAVNationality>;
        public dashboardService: Shared.IDashboardService = this._dashboardService;

        static $inject: Array<string> = ['$timeout', '$scope', 'brookson.navigation', 'accountSrv', 'dashboardService'];

        constructor(private $timeout: ng.ITimeoutService,
            private $scope: ng.IScope,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private accountSrv: Shared.IAccountService,
            private _dashboardService: Shared.IDashboardService) {

            accountSrv.getAccountDetailsForUser().then(data => {
                console.log(data.personalInformation);
                this.showSpinner = false;
                accountSrv.getNationalities().then(data => {
                    this.nationalities = data;
                });
            },
                ex => {
                    this.error = true;
                })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); }, 0);
                });
        }

        public openContract(documentType: string) {
            this._dashboardService.downloadFile(documentType);
        }

    }

    angular
        .module('app.shared')
        .component('brooksonWidgetEmploymentRecord', new Shared.BrooksonWidgetEmploymentRecord());

}