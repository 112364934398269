/// <reference path="./../models/MicroCalendlyBooking.ts"/>

module Shared {
    export interface ICalendlyService {

        getCalendlyIntroductionBooking(silentMode?: boolean): ng.IPromise<MicroCalendlyBooking>;

        getCalendlyAdvisoryBooking(silentMode?: boolean): ng.IPromise<MicroCalendlyBooking>;

        getCalendlyFinancialHealthCheckBooking(silentMode?: boolean): ng.IPromise<MicroCalendlyBooking>;
        /**
         * 
         * Gets the latest advisory doc date for customer
         * @param {boolean} silentMode
         * @returns {ng.IPromise<boolean>}
         */
        //getAdvisoryDocDate(silentMode?: boolean): ng.IPromise<Date>;
    }

    export class CalendlyService implements ICalendlyService {

        static $inject = ['$http'];

        constructor(private $http: Shared.IBrooksonHttp) { }

        getCalendlyIntroductionBooking = (silentMode?: boolean): ng.IPromise<MicroCalendlyBooking> => {
            return this.$http.get(`api/Calendly/GetCalendlyIntroductionBooking`,
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                    return response.data;
                });
        }

        getCalendlyAdvisoryBooking = (silentMode?: boolean): ng.IPromise<MicroCalendlyBooking> => {
            return this.$http.get(`api/Calendly/GetCalendlyAdvisoryBooking`,
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                    return response.data;
                });
        }

        getCalendlyFinancialHealthCheckBooking = (silentMode?: boolean): ng.IPromise<MicroCalendlyBooking> => {
            return this.$http.get(`api/Calendly/GetCalendlyFinancialHealthCheckBooking`,
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response: ng.IHttpPromiseCallbackArg<MicroCalendlyBooking>) => {
                    return response.data;
                });
        }
       
    }
}

angular.module('app.shared')
    .service('calendlySrv', Shared.CalendlyService);