/// <reference path="./AddressMole.ts" />

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class Customer  {
        
        // CUSTOMERNUMBER
        public customerNumber: string = null;
        // CUSTOMERNAME
        public customerName: string = null;
        // CUSTOMERADDRESS
        public customerAddress: string = null;
        // CUSTOMERADDRESSOBJ
        public customerAddressObj: AddressMole = null;
        // CONTACTNAME
        public contactName: string = null;
        // CONTACTNUMBER
        public contactNumber: string = null;
        // BILLINGADDRESS
        public billingAddress: string = null;
        // BILLINGADDRESSOBJ
        public billingAddressObj: AddressMole = null;
        // ASSIGNMENTS
        public assignments: InvoicingAssignment[] = [];
        public umbTimesheetDisabled: number = 0;
    }
}
