module Shared {
    export class ConnectReceipt {
        receiptID: number;
        memberID: number;
        imageName: string;
        imageData: string;
        imageDataEncoded: string;
        imageType: number;
        description: string;
        uploadedDate: Date;
        usedBy: number;
        status: any;
        expenses: any;
    }
}