/// <reference path="./SdcQuestionnaireAnswer.ts" />
/// <reference path="./../BrooksonDate.ts" />

module Shared {
    export class SdcQuestionnaireInput {
        agencyNumber: string; 
        answers: Array<SdcQuestionnaireAnswer<any>>; 
        assignmentIndustry: string; 
        clientAddress: Address; 
        clientName: string; 
        expectedLengthOfAssignmentMonths: number; 
        jobTitle: string; 
        startDate: BrooksonDate;
        workSiteAddress: Address;
        sdcStatus: SdcOutcomeType;
    }
}