
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class FixedTaxData  {
        
        // TAXFREEDIVIDENDALLOWANCE
        public taxFreeDividendAllowance: number = 0;
        // LOWERRATEDIVIDENDTAXRATE
        public lowerRateDividendTaxRate: number = 0;
        // HIGHERRATEDIVIDENDTAXRATE
        public higherRateDividendTaxRate: number = 0;
        // ADDITIONALRATEDIVIDENDTAXRATE
        public additionalRateDividendTaxRate: number = 0;
        // PERSONALALLOWANCE
        public personalAllowance: number = 0;
        // BASICRATETHRESHOLD
        public basicRateThreshold: number = 0;
        // HIGHERRATETHRESHOLD
        public higherRateThreshold: number = 0;
        // FULLPERSONALALLOWANCEUPPERLIMIT
        public fullPersonalAllowanceUpperLimit: number = 0;
        // LOWERRATEINCOMETAX
        public lowerRateIncomeTax: number = 0;
        // BASICRATEINCOMETAX
        public basicRateIncomeTax: number = 0;
        // HIGHERRATETAX
        public higherRateTax: number = 0;
        // ADDITIONALRATETAX
        public additionalRateTax: number = 0;
        // EMPLOYMENTINCOMESTUDENTREPAYTHRESHOLD
        public employmentIncomeStudentRepayThreshold: number = 0;
        // NONEMPLOYMENTINCOMESTUDENTREPAYTHRESHOLD
        public nonEmploymentIncomeStudentRepayThreshold: number = 0;
        // REPAYMENTPERCENTAGE
        public repaymentPercentage: number = 0;
        // CHILDBENEFITTAXCHARGETHRESHOLD
        public childBenefitTaxChargeThreshold: number = 0;
        // PERCENTAGEINCREMENTS
        public percentageIncrements: number = 0;
    }
}
