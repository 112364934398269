/// <reference path="../../../../app/_all.ts" />
module Shared {
    export class BrooksonWidgetSmeActionsToTake implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$timeout", "$window", "$q", "smeManagementSrv", "brookson.navigation", "brookson.utilities.date", "dashboardService", BrooksonWidgetsSmeActionsToTakeController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.sme.actions-to-take.html";
        }
    }

    export class BrooksonWidgetsSmeActionsToTakeController {
        error: boolean;
        showSpinner: boolean = true;
        onComplete: Function;
        actionsToTakeSummary: ActionsToTakeSummary;
        hasActionsToTake: boolean = false;

        public widgetName: string = "brookson-widget-sme-actions-to-take";

        constructor(
            private $timeout: ng.ITimeoutService,
            private $window: ng.IWindowService,
            private $q: ng.IQService,
            private smeManagementSrv: ISmeManagementSrv,
            private brooksonNavigation: IBrooksonNavigation,
            private dateUtils: IBrooksonUtilitiesDate,
            private dashboardService: Shared.IDashboardService) { }

        $onInit() {
            this.getActionsToTakeSummary();
        }

        private getActionsToTakeSummary() {
            return this.smeManagementSrv.getActionsToTakeSummary()
                .then(data => {
                    this.actionsToTakeSummary = data;
                    this.hasActionsToTake = this.actionsToTakeSummary.expensesToAuthorize + this.actionsToTakeSummary.holidaysToAuthorize + this.actionsToTakeSummary.overtimeToAuthorize > 0;
                    return data;
                },
                ex => {
                    this.error = true;
                })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); }, 0);
                });
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetSmeActionsToTake", new Shared.BrooksonWidgetSmeActionsToTake());
