/// <reference path="./AssignmentRateCompact.ts"/>
/// <reference path="./BrooksonDate.ts"/>
/// <reference path="./sdc/JobTitle.ts"/>
/// <reference path="./EnumObj.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class AssignmentCompact  {
        
        // ASSIGNMENTID
        public assignmentID: string = null;
        // ASSIGNMENTNAME
        public assignmentName: string = null;
        // ASSIGNMENTADDRESS
        public assignmentAddress: string = null;
        // ASSIGNMENTADDRESSOBJ
        public assignmentAddressObj: Address = null;
        // ASSIGNMENTNOTES
        public assignmentNotes: string = null;
        // MEMBERID
        public memberID: number = 0;
        // CUSTOMERNAME
        public customerName: string = null;
        // CLIENT
        public client: string = null;
        // AGENCY
        public agency: string = null;
        // COMPANY
        public company: string = null;
        // CURRENCY
        public currency: string = null;
        // DATESTARTED
        public dateStarted: string | Date = null;
        // DATEENDED
        public dateEnded: string | Date = null;
        // MEMBERADVISEDSTATUSOLD
        public memberAdvisedStatusOld: string = null;
        // CUSTOMER
        public customer: CustomerCompact = null;
        // RATES
        public rates: AssignmentRateCompact[] = [];
        // ISSELFBILLING
        public isSelfBilling: boolean = false;
        // PREFERREDEMAILADDRESSES
        public preferredEmailAddresses: string[] = [];
        // UNIQUEIDENTIFIERNUMBER
        public uniqueIdentifierNumber: string = null;
        // AGENCYREFERENCE
        public agencyReference: string = null;
        // LOCATIONS
        public locations: Location[] = [];
        // DEEMED
        public deemed: boolean = false;
        public publicSector: Sectors = null;
        public publicSectorFromDate: Date;
        public sectorSelector: any = null;
        public ir35AssignmentSelector: any = null;
        public jobTitle: string = null;
        public jobTitleOption: JobTitle = null;
        public industry: string = null;
        public ir35Capture: boolean;
        public ir35AssignmentStatus: any;
        public ir35AssessmentCaptured: Shared.IIR35Captured;
        public timesheetFrequencySelector: EnumObj;
        public timesheetFrequency: any;
        public timesheetFrequencyStatus: any;
        public timesheetFrequencyOptionsList: Array<EnumObj>;
    }
}
