/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var app = angular.module('app.shared');

    app.factory('brookson.ui.image-preview.modal', ["$q", "$uibModal", ($q, $uibModal) => {
        var options: any = {
            animation: true,
            size: "lg",
            templateUrl: "src/app/shared/views/ui/brookson.ui.image-preview.modal.html"
        };

        var _showImagePreviewModal = (imageData) => {
            var defer = $q.defer();
            var confirmationOptions = options;

            confirmationOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                $scope.imageData = imageData;

                $scope.close = () => {
                    $uibModalInstance.close();
                    defer.resolve();
                };
            }];

            $uibModal.open(confirmationOptions);

            return defer.promise;
        };

        return {
            showImagePreviewModal: _showImagePreviewModal
        };
    }]);
})();
