/// <reference path="../../../_all.ts"/>

module Shared {

    interface IBrooksonBankLinkTransactionsMobileBindings {
        getData: Array<any>;
        hideAside: Array<any>;
    }

    export class BrooksonBankLinkTransactionsMobile implements ng.IComponentOptions {
        public controller: Function = BrooksonBankLinkTransactionsMobileController;
        public templateUrl: string = 'src/app/shared/views/bank/brookson.bankLink.transactions.mobile.html';
        public bindings: { [binding: string]: string } = {
            getData: '@',
            hideAside: '@'
        };
    }

    class BrooksonBankLinkTransactionsMobileController implements IBrooksonBankLinkTransactionsMobileBindings {
        static $inject = ['bankLinkFactory', 'bankLink.enums', 'AssignmentSrv', 'brookson.utilities.date', "$location", "orderByFilter","$state"];

        public getData;
        public hideAside;

        vatRateConfig: Array<VatRateConfig>;

        constructor(
            public data: Shared.IBankLinkFactory,
            public bankLinkEnums: Shared.IBankLinkEnums,
            public assignmentSrv: Shared.IAssignmentService,
            public dateUtils: Shared.IBrooksonUtilitiesDate,
            private $location: ng.ILocationService,
            private orderByFilter: ng.IFilterOrderBy,
            private $state: ng.ui.IStateService,
        ) { }

        $onInit = () => {
            if (this.getData && this.data.transactionTypes.length === 0) {
                this.data.getTransactionTypes();
                this.data.getBankAccounts();
                this.data.getTransactions(true);
                this.data.getCategories();
                this.data.getReceipts();
                this.data.getMoneyManagerData();
            }
            this.data.getAllVatRatesConfig().then((result) => {
                this.vatRateConfig = this.orderByFilter(result, "-effectiveDate");
            });

            this.getAssignments();
        }

        vatChanged = (transaction): void => {
            if (transaction.vat > 0) {
                transaction.vat = -transaction.vat;
            }

            transaction.net = (-transaction.gross || 0) - (transaction.vat || 0);
        }

        viewAutoAllocationSettings = (expense: Shared.ExpenseHeader): void => {
            this.$state.go("brookson.banklink-auto-allocation-settings");
        }

        viewBankFileUpload = (expense: Shared.ExpenseHeader): void => {
            this.$state.go("brookson.bank-file-upload");
        }

        clearVat = (transaction): void => {
            transaction.vat = 0;
            this.vatChanged(transaction);
            transaction.maxVat = 0;
            transaction.maxVatMessage = "VAT must be 0";
        }

        getVatAmount = (transaction) => {
            const vatRate = this.getApplicableVatRate(transaction);
            return -Math.floor((vatRate * (transaction.gross || 0)) / (100 + vatRate) * 100) / 100;
        }

        setMaxVat = (transaction) => {
            var vat = this.getVatAmount(transaction);
            const vatRate = this.getApplicableVatRate(transaction);
            var vat20precent = -Math.floor((20 * (transaction.gross || 0)) / (100 + 20) * 100) / 100;
            transaction.maxVat  = vatRate > 0 && vatRate < 20 ? vat20precent : vat; 
            transaction.maxVatMessage = `VAT must be less than or equal to ${-transaction.maxVat}`;
        }

        grossChanged = (transaction): void => {
            if (transaction.hasValidVatReceipt) {
                transaction.vat = this.getVatAmount(transaction);
                this.setMaxVat(transaction);
                this.vatChanged(transaction);
            } else {
                this.clearVat(transaction);
            }
        }

        hasValidVatReceiptChanged = (transaction): void => {
            if (!transaction.hasValidVatReceipt) {
                this.clearVat(transaction);
            } else if (transaction.gross) {
                this.grossChanged(transaction);
            }
        }

        getApplicableVatRate = (transaction): number => {
            const transactionDate = transaction.transactionDate ? this.dateUtils.convertToJsDate(transaction.transactionDate) : new Date();
            
            const applicableRateConfig = this.vatRateConfig ? _.find(this.vatRateConfig, (rateConfig: VatRateConfig) => {
                if (transaction)
                {
                return transactionDate >= rateConfig.effectiveDate  &&  transactionDate <= rateConfig.endDate && rateConfig.expenseCode === transaction.transactionCategory.code;
                }
            }) : new VatRateConfig();


            const applicableRate = transaction.vatRates ? _.find(transaction.vatRates, (rate: VatRate) => {
                return rate.startDate <= transactionDate;
            }).amount : 20;

            if(applicableRateConfig && applicableRateConfig.vatRate)
            {
                return applicableRateConfig.vatRate;
            }

            return applicableRate;
        }

        public getAssignments = () => {
            return this.assignmentSrv.getAssignments()
                .then(data => {

                    if (data.length === 0 && this.$location.path().indexOf("Business")) {
                        let blankAssignment: Shared.InvoicingAssignment = new Shared.InvoicingAssignment();
                        blankAssignment.fullAssignmentName = 'No Active Assignments';
                        data.push(blankAssignment);

                        this.data.selectedAssignment = data[0];
                    }

                    //supply the assignments in reverse date order
                    let orderAssignmentsByDateStarted: any = _.sortBy(data, [(o) => this.toDate(o)]);
                    this.data.assignments = orderAssignmentsByDateStarted.reverse();

                    // If the year is 1753 or the top assignment is still current, then assign it in the dropdown as the selected assignment
                    if (
                        (orderAssignmentsByDateStarted.length !== 0) &&
                        (orderAssignmentsByDateStarted[0].dateEnded !== undefined)
                    ) {
                        if (
                            (
                                this.dateUtils.isSame(orderAssignmentsByDateStarted[0].dateEnded, '1753-01-01') || this.dateUtils.isAfter(orderAssignmentsByDateStarted[0].dateEnded, moment().startOf('day'))
                            ) && orderAssignmentsByDateStarted.length === 1
                        ) {
                            this.data.selectedAssignment = orderAssignmentsByDateStarted[0];
                        }
                    }

                    return data;
                });
        }

        public toDate = (item: Shared.Assignment): Date => {
            return this.dateUtils.convertToJsDate(item.dateStarted);
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonBankLinkTransactionsMobile', new Shared.BrooksonBankLinkTransactionsMobile());