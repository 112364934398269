/// <reference path="./../models/ConnectIntro.ts" />
/// <reference path="./../models/MicroCalendlyBooking.ts"/>

module Shared {
   
    export interface IBrooksonServicesIntro {
        
        getConnectIntros(silentMode?: boolean) : ng.IPromise<ConnectIntro>;
       
        setFirstTimeLoginSeen(silentMode?: boolean): ng.IPromise<boolean>;
    }

   
    export class BrooksonServicesIntro implements IBrooksonServicesIntro {
       
        static $inject = ['$http'];
       
        constructor(private $http: Shared.IBrooksonHttp) {}

       
        getConnectIntros = (silentMode?: boolean) : ng.IPromise<ConnectIntro> => {
            return this.$http.get(`api/Intro/GetConnectIntros`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<ConnectIntro>) => {
                return response.data;
            });
        }
       
        setFirstTimeLoginSeen = (silentMode?: boolean ) : ng.IPromise<boolean> => {
            return this.$http.post(`api/Intro/SetFirstTimeLoginSeen`, {}, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared')
    .service('brooksonServicesIntro', Shared.BrooksonServicesIntro);