module Shared {
    /**
     * IncomeProfit
     */
    export class IncomeProfit {
        constructor(parameters) {

        }
        public month: any;
        public expenses: any;
        public sales: any;
        public directorsFee:any;
        public profit:any;

    }
}