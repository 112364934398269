/// <reference path="./BrooksonDate.ts" />

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class HolidayRequest {
        
        public authorizedBy: number;

        public companyRef: string;

        public employeeId: number;

        public duration: string;

        public endDate: BrooksonDate;

        public holidayRequestId: number;

        public reason: string;

        public startDate: BrooksonDate;

        public status: number;

        public hidden: boolean;

        public canBeCancelled: boolean;
    }
}
