/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var appShared = angular.module('app.shared');

    appShared.directive('hideMobile', ['$compile', '$detection', hideMobileController]);

    function hideMobileController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngIf && ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone())) {
                $attrs.$set('ng-if', false);
                $compile($element)($scope);
            } else if (!$attrs.ngIf) {
                $attrs.$set('ng-if', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('showMobile', ['$compile', '$detection', showMobileController]);

    function showMobileController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngIf && ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone())) {
                $attrs.$set('ng-if', true);
                $compile($element)($scope);
            } else if (!$attrs.ngIf) {
                $attrs.$set('ng-if', false);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('hideIos', ['$compile', '$detection', hideIosController]);

    function hideIosController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngHide && $detection.isiOS()) {
                $attrs.$set('ng-hide', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('showIos', ['$compile', '$detection', showIosController]);

    function showIosController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngShow && $detection.isiOS()) {
                $attrs.$set('ng-show', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('hideAndroid', ['$compile', '$detection', hideAndroidController]);

    function hideAndroidController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngHide && $detection.isAndroid()) {
                $attrs.$set('ng-hide', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('showAndroid', ['$compile', '$detection', showAndroidController]);

    function showAndroidController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngShow && $detection.isAndroid()) {
                $attrs.$set('ng-show', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('hideWindowsPhone', ['$compile', '$detection', hideWindowsPhoneController]);

    function hideWindowsPhoneController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngHide && $detection.isWindowsPhone()) {
                $attrs.$set('ng-hide', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('showWindowsIos', ['$compile', '$detection', showWindowsPhoneController]);

    function showWindowsPhoneController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngShow && $detection.isWindowsPhone()) {
                $attrs.$set('ng-show', true);
                $compile($element)($scope);
            }
        }
    }

    /**
     * IF
     *
     */
    appShared.directive('ifDesktop', ['$compile', '$detection', ifNotMobileController]);

    function ifNotMobileController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngIf && ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone())) {
                $attrs.$set('ng-if', false);
                $compile($element)($scope);
            } else if (!$attrs.ngIf) {
                $attrs.$set('ng-if', true);
                $compile($element)($scope);
            }
        }
    }

    appShared.directive('ifMobile', ['$compile', '$detection', ifMobileController]);

    function ifMobileController($compile, $detection) {
        var directive = {
            controller: ['$scope', '$element', '$attrs', '$compile', controller],
            restrict: 'A'
        };
        return directive;

        function controller($scope, $element, $attrs) {
            if (!$attrs.ngIf && ($detection.isiOS() || $detection.isAndroid() || $detection.isWindowsPhone())) {
                $attrs.$set('ng-if', true);
                $compile($element)($scope);
            } else if (!$attrs.ngIf) {
                $attrs.$set('ng-if', false);
                $compile($element)($scope);
            }
        }
    }
})();
