module Shared {

    export module Models {
        export class AgencyMarginCompact {
            client: string;
            agency: string;
            currentMarginValue: number;
            onePlusUmbrella: number;
            umbrellaMargin: number;
            umbrellaPlusMargin: number;
        }
    }
}
