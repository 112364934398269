module Shared {
	export function FilterBytes() {
		return (bytes, precision, displayUnit) => {
			if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
				return '-';
			}

			if (typeof precision === 'undefined') {
				precision = 1;
			}

			var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
			var number = Math.floor(Math.log(bytes) / Math.log(1024));
			var value = <any>(bytes / Math.pow(1024, Math.floor(number))).toFixed(precision);

			value = isNaN(value) ? 0 : value;

			if (displayUnit === false) {
				return value;
			} else {
				return value + ' ' + units[number];
			}
		};
	}
}

angular.module("app.shared").filter("bytes", Shared.FilterBytes);