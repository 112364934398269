module Shared {
    export class MicroCalendlyBooking {
        memberId: string;
        startDate: string;
        endDate: Date;
        uuid: string;
        eventName: string;
        eventSlug: string;
        createdDate: Date;
        utmCampaign: string;
        utmMedium: string;
        utmSource: string;
        eventType: EventType;
    }

    export enum EventType {
        AccountantIntroduction,
        AdvisoryDocument,
        FinancialHealthCheck
    }
}