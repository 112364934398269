(() => {
    'use strict';

    angular
        .module('app.shared')
        .service('loggerSrv', loggerSrv);

    loggerSrv.$inject = ['$injector', 'shared.config'];

    function loggerSrv($injector, sharedConfig) {
        // Service
        var service:any = {};

        // Data
        var serviceBase = sharedConfig.baseApiUrl;
        var $http;

        // Methods
        service.log = log;

        function log(level, title, data, cause) {
            $http = $http || $injector.get('$http');

            var request = {
                loggingLevel: level,
                url: location.href,
                title: title,
                data: data ? angular.toJson(data, true) : data,
                cause: cause
            };

            return $http.post(serviceBase + 'api/Logging/Log', request, {
                silentMode: true
            }).then((response) => {
                return response.data;
            });
        }

        return service;
    }
})();
