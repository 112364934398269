/// <reference path="./../models/WelcomeScreen.ts" />
/// <reference path="./../models/WelcomeScreen.ts" />

module Shared {
    export interface IRemusRewardSrv {
        getRemusRewardsUrlParameters(): ng.IPromise<RemusRewardParameters>;
        isUserUmbrellaPlus(): ng.IPromise<boolean>;
        getSelectedPackage(): ng.IPromise<SelectedPackageEnum>;
        getAgencyMargin(): ng.IPromise<Models.AgencyMarginCompact>;
        setSelectedPackage(selectedPackage: SelectedPackageEnum): boolean;
        setSelectedPackageNav(selectedPackage: SelectedPackageEnum): ng.IPromise<boolean>;
        setPackageNavUmbrellaProtect(): ng.IPromise<boolean>;
    }

    export class RemusRewardSrv implements IRemusRewardSrv {

        static $inject: Array<string> = ["$http", "shared.config"];
        
        private selectedUmbrellaPackage = SelectedPackageEnum.umbrella;

        constructor(private $http: ng.IHttpService) {    

        }
        
        getRemusRewardsUrlParameters(): ng.IPromise<RemusRewardParameters> {
            return this.$http.get('api/RemusRewards/GetParameters')
                .then((response: ng.IHttpPromiseCallbackArg<RemusRewardParameters>) => {
                    return response.data;
                });
        }

        isUserUmbrellaPlus(): ng.IPromise<boolean> {
            return this.$http.get('api/RemusRewards/IsUserUmbrellaPlus')
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                    return response.data;
                });
        }
        
        public setSelectedPackage(selectedPackage: SelectedPackageEnum): boolean {
            this.setSelectedPackageNav(selectedPackage).then((result) => {           
                 this.selectedUmbrellaPackage = selectedPackage;
                return result;
            });
            return false;
        }
        
        public getAgencyMargin(): ng.IPromise<Models.AgencyMarginCompact> {
            return this.$http.get('api/Intro/GetCurrentUmbrellaService')
                .then((response: ng.IHttpPromiseCallbackArg<Models.AgencyMarginCompact>) => {
                    return response.data;
                });
        } 

        public getSelectedPackage(): ng.IPromise<SelectedPackageEnum> {
            return this.$http.get('api/RemusRewards/IsUserUmbrellaPlus')
                .then((response: ng.IHttpPromiseCallbackArg<SelectedPackageEnum>) => {
                    if(response.data)
                    {
                        return this.selectedUmbrellaPackage = SelectedPackageEnum.umbrellaProtect;
                    } else 
                    {
                        return this.selectedUmbrellaPackage = SelectedPackageEnum.umbrella;
                    }
                });
        }  

        
        setPackageNavUmbrellaProtect = (): ng.IPromise<boolean> => { 
           let SelectedPackageRequestData = {
                umbrellaPlusService:  true
            };

            return this.$http.post('api/Intro/UpdateUmbrellaPlusService', SelectedPackageRequestData)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        
        }

        setSelectedPackageNav = (selectedPackage: SelectedPackageEnum): ng.IPromise<boolean> => { 
           let SelectedPackageRequestData = {
                umbrellaPlusService:  ((selectedPackage === SelectedPackageEnum.umbrellaProtect) ? true : false)
            };

            return this.$http.post('api/Intro/UpdateUmbrellaPlusService', SelectedPackageRequestData)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        
        }
}

angular
    .module("app.shared")
    .service("remusRewardSrv", Shared.RemusRewardSrv);
}