/// <reference path="./../../_all.ts"/>
/// <reference path="./../interfaces/brookson.interfaces.window.ts" />
/// <reference path="./../services/brookson.services.member.ts" />
/// <reference path="./../services/brookson.services.authentication.ts" />
/// <reference path="./../modules/brookson.navigation.ts" />
/// <reference path="./../models/member/ServiceLine.ts" />

module Shared {
    
    export interface ISwapServiceLineService {
        changeSelectedServiceLine(selectedCompanyRef: string): void;
        changeSelectedServiceLineLocation(selectedCompanyRef: string, href: string): void;
    }

    export class SwapUserServiceLineService implements  ISwapServiceLineService {
        static $inject = [    '$window', 
                                '$stateParams', 
                                'memberSrv', 
                                'authenticationSrv', 
                                'brookson.navigation'];
        public selectedServiceLine: Shared.ServiceLine;
        public member: Shared.MemberModel;
        public serviceLines: Array<any> = [];
        public message: string = "";

        constructor(
            private $window: Window,
            private $stateParams: ng.ui.IStateParamsService,
            private memberSrv: Shared.IMemberService,
            private authenticationSrv: Shared.IAuthenticationService,
            private brooksonNavigation: Shared.IBrooksonNavigation
        ) { }

        $onInit = (): void => {
            this.member = this.memberSrv.getMember();
            this.serviceLines = this.member.serviceLines;
        }

        changeSelectedServiceLine = (selectedCompanyRef: string): void => {
                
                this.member = this.memberSrv.getMember();
                //update the user service active service line
                this.member.companyRef = selectedCompanyRef;
                this.memberSrv.setMember(this.member);
                //this.selectedServiceLine = this.serviceLines.filter(s => s.)

                let line: ServiceLine[] = _.filter(this.member.serviceLines, (o: ServiceLine): boolean => o.customerReference === selectedCompanyRef);

                this.authenticationSrv.updateAccessTokenServiceLine(this.member.memberId, line[0], this.member.refreshToken, this.member.apiKeySource)
                    .then(
                        (data): void => {
                            if (this.$stateParams['ReturnUrl']) {
                                this.$window.location.href = this.$stateParams['ReturnUrl'];
                            } else {
                                this.brooksonNavigation.dashboard.main();
                            }
                        },
                        (err): void => {
                            this.message = err.data.error_description;
                        })
                    .catch((): void => { });
        }

        changeSelectedServiceLineLocation = (selectedCompanyRef: string, href: string): void => {
                
            this.member = this.memberSrv.getMember();
            //update the user service active service line
            this.member.companyRef = selectedCompanyRef;
            this.memberSrv.setMember(this.member);
            //this.selectedServiceLine = this.serviceLines.filter(s => s.)

            let line: ServiceLine[] = _.filter(this.member.serviceLines, (o: ServiceLine): boolean => o.customerReference === selectedCompanyRef);

            this.authenticationSrv.updateAccessTokenServiceLine(this.member.memberId, line[0], this.member.refreshToken, this.member.apiKeySource)
                .then(
                    (data): void => {
                        if (href) {
                            this.$window.location.href = href;
                        } else {
                            this.brooksonNavigation.dashboard.main();
                        }
                    },
                    (err): void => {
                        this.message = err.data.error_description;
                    })
                .catch((): void => { });
    }
    }
}
angular
    .module("app.shared")
    .service("swapServiceLineSrv", Shared.SwapUserServiceLineService);