/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var appShared = angular.module("app.shared");

    appShared.directive("brooksonWithinSameVatScheme", [() => {
        return {
            restrict: "A",
            require: "^ngModel",
            link: (scope, el, attrs, ngModel) => {
                var validate = (value) => {
                    var isValid = value === null || value === undefined || value === "";

                    if (!isValid) {
                        const vatScheme = scope.$eval(attrs.brooksonWithinSameVatScheme);
                        isValid = _.every(scope.$eval(attrs.brooksonAllExpenses),
                            e => {
                                var expense = e as any;
                                return expense.markedAsDelete || expense.effectiveVatScheme === vatScheme;
                            });
                    }

                    ngModel.$setValidity("brooksonWithinSameVatScheme", isValid);
                };

                ngModel.$parsers.unshift((value) => {
                    validate(value);
                    return value;
                });

                ngModel.$formatters.unshift((value) => {
                    validate(value);
                    return value;
                });
            }
        };
    }]);
})();