
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class Notification  {
        
        // ID
        public id: number = 0;
        // COMPANYCONTACTNO
        public companyContactNo: string = null;
        // TYPE
        public type: string = null;
        // TITLE
        public title: string = null;
        // METADESCRIPTION
        public metaDescription: string = null;
        // LINK
        public link: string = null;
        // SYMBOL
        public symbol: string = null;
        // COLOUR
        public colour: string = null;
        // SHOWGUIDE
        public showGuide: boolean = false;
        // ACTION
        public action: string = null;

        public sortOrder: number = 0;

        public onClick: Function = null;

        public documentCategoryMappingId: number = 0;
    }
}
