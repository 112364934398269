/// <reference path="../invoicing/invoicePreference.ts" />

module Shared {
    export module Invoicing {

        export class FullInvoice {
            id: number;
            memberId: number;
            companyRef: string;
            assignmentId: string;
            periodEndDate: Shared.BrooksonDate;
            invoiceDate: Shared.BrooksonDate;
            paymentDueDate: Shared.BrooksonDate;
            poNumber: string;
            totalExVat: number;
            discount: number;
            discountPercentage: number;
            vatRate: number;
            vatAmount: number;
            totalincVat: number;
            deductions: number;
            deductionText: string;
            cisDeductions: number;
            totalAmountDue: number;
            notes: string;
            invoicePreferences: InvoicePreference;
            invoiceLines: FullInvoiceLine[];
            fullInvoiceAttachments: FullInvoiceAttachment[];
            submitToNav: boolean;
            type: string;
            bankAccountId: string;
            invoiceNumber: string;
            currencyCode: number;
        }

        export class FullInvoiceLine {
            id: number;
            fullInvoiceId: number;
            description: string;
            quantity: number;
            rateCode: string;
            rateCodeDescription: string;
            rateCodeValue: number;
            lineAmount: number;
        }

        export class FullInvoiceAttachment {
            id: number;
            fullInvoiceId: number;
            fileName: string;
            azureFileName: string;
            mimetype: string;
        }
    }
}