module Shared {
    /**
     * Interface for the Brookson SDC Modal Service
     * 
     * @export
     * @interface IBrooksonSdcModalService
     */
    export interface IBrooksonSdcModalService {
        /**
         * Displays the SDC Unassessed Assignments modal
         * 
         * @returns {ng.ui.bootstrap.IModalServiceInstance}
         * 
         * @memberOf IBrooksonSdcModalService
         */
        show(): ng.ui.bootstrap.IModalServiceInstance;
    }

    /**
     * Brookson SDC Modal Service
     * 
     * @export
     * @class BrooksonSdcModalService
     * @implements {IBrooksonSdcModalService}
     */
    export class BrooksonSdcModalService implements IBrooksonSdcModalService {
        /**
         * The options for the bootstrap modal 
         * 
         * @type {ng.ui.bootstrap.IModalSettings}
         * @memberOf BrooksonSdcModalService
         */
        options: ng.ui.bootstrap.IModalSettings = {
            animation: true,
            size: "md",
            templateUrl: "src/app/shared/views/modals/brookson.modals.sdc.html",
            keyboard: false,
            controller: BrooksonSdcModalController,
            controllerAs: "$ctrl",
            backdrop: 'static',
            windowClass: 'modal-sdcOutcome'
        };

        /**
         * Dependency Injection
         * 
         * @static
         * 
         * @memberOf BrooksonSdcModalService
         */
        static $inject = ['$uibModal'];

        /**
         * Creates an instance of BrooksonSdcModalService.
         * 
         * @param {ng.ui.bootstrap.IModalService} $uibModal
         * 
         * @memberOf BrooksonSdcModalService
         */
        constructor(
            private $uibModal: ng.ui.bootstrap.IModalService
        ) { }

        /**
         * Displays the SDC Unassessed Assignments modal
         * 
         * 
         * @memberOf BrooksonSdcModalService
         */
        show = (): ng.ui.bootstrap.IModalServiceInstance => {
            return this.$uibModal.open(this.options);
        }
    }

    /**
     * Customer $scope class for the Brookson SDC Modal
     * 
     * @export
     * @interface BrooksonSdcModalScope
     * @extends {ng.IScope}
     */
    export interface BrooksonSdcModalScope extends ng.IScope {
        /**
         * Closes the bootstrap modal
         * 
         * @param {*} [result]
         * 
         * @memberOf BrooksonSdcModalScope
         */
        $close(result?: any);
    }

    /**
     * The controller for the Brookson SDC Modal
     * 
     * @export
     * @class BrooksonSdcModalController
     */
    export class BrooksonSdcModalController {

        /**
         * A list of assignments that require the users attention
         * 
         * @type {Array<Shared.AssignmentSdcOutcome>}
         * @memberOf BrooksonSdcModalController
         */
        assignmentOutcomes: Array<Shared.AssignmentSdcOutcome>;

        /**
         * A list of assignment id's that are making the CloseAssignment request
         * 
         * @type {Array<string>}
         * @memberOf BrooksonSdcModalController
         */
        assignmentEndedRequestInProgress: Array<string> = [];

        /**
         * A list of assignment id's that are making the DeclineExpensesForAssignment request
         * 
         * @type {Array<string>}
         * @memberOf BrooksonSdcModalController
         */
        noClaimOnAssignmentRequestInProgress: Array<string> = [];

        /**
         * Angular DI
         * 
         * @static
         * 
         * @memberOf BrooksonSdcModalController
         */
        static $inject = ['$scope', 'sdcService', 'AssignmentSrv', 'brookson.navigation'];

        /**
         * Creates an instance of BrooksonSdcModalController.
         * 
         * @param {BrooksonSdcModalScope} $scope
         * @param {Shared.ISdcService} sdcService
         * @param {Shared.IAssignmentService} assignmentSrv
         * 
         * @memberOf BrooksonSdcModalController
         */
        constructor(
            private $scope: BrooksonSdcModalScope,
            private sdcService: Shared.ISdcService,
            private assignmentSrv: Shared.IAssignmentService,
            private navigation: Shared.IBrooksonNavigation
        ) {
            this.getAssignmentOutcomes();
        }

        /**
         * Event fired when the "Yes I wish to claim" button is pressed
         * 
         * 
         * @memberOf BrooksonSdcModalController
         */
        claimOnAssignmentButtonPressed = (assignment: AssignmentSdcOutcome) : void => {
            this.claimOnAssignment(assignment);
        }

        /**
         * Event fired when the "No I do not wish to claim" button is pressed
         * 
         * 
         * @memberOf BrooksonSdcModalController
         */
        noClaimOnAssignmentButtonPressed = (assignment: AssignmentSdcOutcome): void => {
            this.noClaimOnAssignment(assignment);
        }

        /**
         * Event fired when the "This assignment has ended" button is pressed
         * 
         * 
         * @memberOf BrooksonSdcModalController
         */
        closeAssignmentButtonPressed = (assignment: AssignmentSdcOutcome): void => {
            this.closeAssignment(assignment);
        }

        /**
         * Returns whether the assignment ended button should be disabled for this assignment
         * 
         * 
         * @memberOf BrooksonSdcModalController
         */
        disableAssignmentEndedButton = (assignment: AssignmentSdcOutcome): boolean => {
            return _.some(this.assignmentEndedRequestInProgress, (assignmentId: string) => {
                return assignmentId === assignment.assignmentId;
            });
        }

         /**
         * Returns whether the no claim assignment button should be disabled for this assignment
         * 
         * 
         * @memberOf BrooksonSdcModalController
         */
        disableAssignmentNoClainButton = (assignment: AssignmentSdcOutcome): boolean => {
            return _.some(this.noClaimOnAssignmentRequestInProgress, (assignmentId: string) => {
                return assignmentId === assignment.assignmentId;
            });
        }

        /**
         * Returns a list of assignment outcomes from the API
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private getAssignmentOutcomes = () : ng.IPromise<Array<Shared.AssignmentSdcOutcome>> => {
            return this.sdcService.getAssignmentOutcomes().then(this.handleGetAssignmentOutcomesResponse);
        }

        /**
         * Sets the appropiate properties when the reponse has returned from the API GetAssignnmentOutcome response
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private handleGetAssignmentOutcomesResponse = (data: Array<Shared.AssignmentSdcOutcome>): Array<Shared.AssignmentSdcOutcome> => {
            this.assignmentOutcomes = data.filter(x => x.outcome === Shared.SdcOutcomeType.NotAssessed);
            return this.assignmentOutcomes;
        }

        /**
         * Closes the modal
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private closeModal = () => {
            this.$scope.$close();
        }

        /**
         * Navigate to the edit assignment page so the user can complete an SDC questionnaire
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private claimOnAssignment = (assignment: AssignmentSdcOutcome): void => {
            this.navigation.assignments.editAssignment(assignment.assignmentId);
        }

        /**
         * Submits an SDC questionnaire with the incurr expenses key
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private noClaimOnAssignment = (assignment: AssignmentSdcOutcome): void => {
            this.noClaimOnAssignmentRequestInProgress.push(assignment.assignmentId);

            this.sdcService.declineExpensesForAssignment(assignment.assignmentId).then((data: boolean) => {
                _.remove(this.noClaimOnAssignmentRequestInProgress, (inProgress: string) => {
                    return inProgress === assignment.assignmentId;
                });

                if (data) {
                    this.removeAssignmentFromList(assignment);
                }
            });
        }

        /**
         * Closes the assignment and removes it from the list if the request was successful
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private closeAssignment = (assignment: AssignmentSdcOutcome): void => {
            this.assignmentEndedRequestInProgress.push(assignment.assignmentId);

            this.assignmentSrv.closeAssignment(assignment.assignmentId).then((data: boolean) => {
                _.remove(this.assignmentEndedRequestInProgress, (inProgress: string) => {
                    return inProgress === assignment.assignmentId;
                });

                if (data) {
                    this.removeAssignmentFromList(assignment);
                }
            });
        }

        /**
         * Removes a given assignment from the list
         * 
         * @private
         * 
         * @memberOf BrooksonSdcModalController
         */
        private removeAssignmentFromList = (assignment: AssignmentSdcOutcome): void => {
            _.remove(this.assignmentOutcomes, assignment);

            if (this.assignmentOutcomes.length === 0) {
                this.closeModal();
            }
        }
    }
}

angular.module("app.shared").service("brookson.modal.sdc", Shared.BrooksonSdcModalService);