/// <reference path="./../../_all.ts"/>
/// <reference path="./../models/PersonalTaxTrackerData.ts"/>
/// <reference path="./../models/GLEntrySummary.ts"/>
/// <reference path="./../models/advisory/UmbrellaTaxTracker.ts"/>
/// <reference path="./../interfaces/brookson.interfaces.http.ts" />
/// <reference path="./../modules/brookson.enums.ts" />
/// <reference path="./../modules/brookson.utilities.date.ts" />


module Shared {
    export interface ITimeframe {
        value: string;
        description: string;
    }

    export interface IDetailedViewToggles {
        all: boolean;
        incomeSummary: boolean;
        forecastCalculations: boolean;
        tracker: boolean;
        liabilityDue: boolean;
    }

    export interface IBreakdownWidgetData {
        pscEmployment(): number;
        otherEmploymentIncome(): number;
        pensionIncome(): number;
        interestIncome(): number;
        taxableBenefitsInKind(): number;
        pscDividendIncome(): number;
        otherDividendIncome(): number;
    }

    export interface IForecastCalculationsWidgetData {
        monthsYearToDate(): number;
        monthsRemaining(): number;
        weeksYearToDate(): number;
        weeksRemaining(): number;
        weeksYearToDateFromTaxStart(): number;
        weeksRemainingFromTaxStart(): number;
        pscEmployment(): number;
        currentDirectorsFeeWeekly(): number;
        forecastPSCEmployment(): number;
        confirmedAsDividends(): number;
        unconfirmedTransactions(): number;
        transactionsBeingProcessed(): number;
        totalPSCDividendIncome(): number;
        forecastPSCDividendIncome(): number;
    };

    export interface ITaxLiabilityDueWidgetData {
        totalTaxPayable(): number;
        studentLoanPaymentDue(): number;
        childBenefitTaxCharge(): number;
        taxBillForThisYear(): number;
        taxDeductedFromDirectorsFees(): number;
        taxAlreadyPaid(): number;
        paymentsOnAccountPaidforJan(): number;
        paymentOnAccountPaidForJuly(): number;
        paymentsMadeAgainstTaxBill(): number;
        balancingPaymentDueJan(): number;
        paymentOnAccountDueJan(): number;
        unpaidTaxForTaxYear(): number;
        paymentOnAccountDueJuly(): number;
        taxDeductedUmbrellaEarnings(): number;
    };

    export interface ITaxTrackerWidgetData {
        nonDividendIncome: {
            personalAllowance(): number;
            basicRate: {
                income(): number;
                tax(): number;
            };
            higherRate: {
                income(): number;
                tax(): number;
            }
            additionalRate: {
                income(): number;
                tax(): number;
            },
            totalNonDividendIncome: {
                income(): number;
                tax(): number;
            }
        };
        dividendIncome: {
            personalAllowance(): number;
            dividendAllowance(): number;
            basicRate: {
                income(): number;
                tax(): number;
            },
            higherRate: {
                income(): number;
                tax(): number;
            },
            taxableDividends: {
                income(): number;
                tax(): number;
            },
            totalDividendIncome: {
                income(): number;
                tax(): number;
            }
        };
        totalIncome: {
            income(): number;
            tax(): number;
        };
        incomeLeftInEachTaxBand: {
            personalAllowance(): number;
            dividendIncome: {
                dividendAllowance(): number;
                basicRate(): number;
                higherRate(): number;
            },
            incomeAvailableBeforeHigherRateTax(): number;
        };
    }

    export interface IIncomeSummaryWidgetData {
        directorsFees(): number;
        grossSalaryFromUmbrella(): number;
        nonDividendIncome(): number;
        dividends(): number;
        dividendIncome(): number;
        totalIncome(): number;
        hasUmbrella(): boolean;
    };

    export interface IPersonalTaxTrackerService {
        title: string;
        selectedTimeframe: ITimeframe;
        selectedTaxYear: string;
        availableTaxYears: string[];
        taxYearOffset: number;
        companyName: string;
        hasUmbrella: boolean;
        msnry: IMasonry;
        isEditingDividends: boolean;
        debounceLength: number;
        fixedTaxData: FixedTaxData;
        forecastCalculations: ForecastCalculations;
        userInputData: UserInputData;
        umbrellaTaxTracker: UmbrellaTaxTracker;
        detailedViewToggles: IDetailedViewToggles;
        resetEditingDividends(): void;
        personalTaxData: IPersonalTaxData;
        incomeSummaryWidgetData: IIncomeSummaryWidgetData;
        taxTrackerWidgetData: ITaxTrackerWidgetData;
        taxLiabilityDueWidgetData: ITaxLiabilityDueWidgetData;
        forecastCalculationsWidgetData: IForecastCalculationsWidgetData;
        breakdownWidgetData: IBreakdownWidgetData;
        reloadMasonry(): void;
        getPersonalTaxTrackerData(silentMode?: boolean): ng.IPromise<PersonalTaxTrackerData>;
        getConfirmedAsDividendsTransactions(): ng.IPromise<Array<GLEntrySummary>>;
        getUnconfirmedTransactions(): ng.IPromise<Array<GLEntrySummary>>;
        postUserInputData(): ng.IPromise<boolean>;
        selectedTaxYearChanged(selectedTaxYear: any);
        getPersonalTaxTrackerDataByOffset(offset: number, silentMode?: boolean): ng.IPromise<PersonalTaxTrackerData>;
    }

    export interface IPersonalTaxData {
        umbrellaTaxTracker: {
            payYear(): number;
            sumGrossPay(): number;
            sumPaye(): number;
        };
        fixedTaxData: {
            grossPensionContribution(): number;
            basicRateThresholdPension(): number;
            basicRateThresholdDividends(): number;
            higherRateThresholdPension(): number;
            higherRateThresholdDividends(): number;
            higherRateAllowanceDividends(): number;
            taxFreeDividendAllowance(): number;
            lowerRateDividendTaxRate(): number;
            higherRateDividendTaxRate(): number;
            additionalRateDividendTaxRate(): number;
            personalAllowance(): number;
            basicRateThreshold(): number;
            higherRateDividendThreshold(): number;
            higherRateThreshold(): number;
            fullPersonalAllowanceUpperLimit(): number;
            lowerRateIncomeTax(): number;
            basicRateIncomeTax(): number;
            higherRateTax(): number;
            additionalRateTax(): number;
            employmentIncomeStudentRepayThreshold(): number;
            nonEmploymentIncomeStudentRepayThreshold(): number;
            repaymentPercentage(): number;
            childBenefitTaxChargeThreshold(): number;
            percentageIncrements(): number;
        };
        forecastCalculations: {
            forecastTotal(): number;
            forecastPSCEmployment(): number;
            forecastPSCDividendIncome(): number;
            yearToDatePSCEmployment(): number;
            yearToDatePSCDividendIncome(): number;
            monthsYearToDate(): number;
            monthsRemaining(): number;
            weeksYearToDate(): number;
            weeksRemaining(): number;
            weeksYearToDateFromTaxStart(): number;
            weeksRemainingFromTaxStart(): number;
            pscEmployment(): number;
            currentDirectorsFeeWeekly(): number;
            currentDirectorsFeeMonthly(): number;
            feesRemainingForYear(): number;
            shareholderSplit(): number;
            confirmedAsDividends(): number;
            unconfirmedTransactionsWithShareholderSplit(): number;
            transactionsBeingProcessedWithShareholderSplit(): number;
            taxDeductedFromPSCIncome(): number;
        };
        incomeCalculations: {
            pscEmploymentIncome: {
                // K28
                yearToDate(): number;
                // L28
                forecast(): number;
            },
            nonDividendIncome: {
                // K32
                yearToDate(): number;
                // L32
                forecast(): number;
            },
            pscDividendIncome: {
                // K36
                yearToDate(): number;
                // L36
                forecast(): number;
            },
            dividendIncome: {
                // K36
                yearToDate(): number;
                // L36
                forecast(): number;
            },
            grossTaxableIncome: {
                // K38
                yearToDate(): number;
                // L38
                forecast(): number;
            }
        };
        taxCalculationWorkings: {
            nonDividendIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            dividends: {
                yearToDate(): number;
                forecast(): number;
            },
            grossTaxableIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            annualPersonalAllowance: {
                yearToDate(): number;
                forecast(): number;
            },
            fullPaUpperLimit: {
                yearToDate(): number;
                forecast(): number;
            },
            grossIncomeAboveUpperLimit: {
                yearToDate(): number;
                forecast(): number;
            },
            reductionInPA: {
                yearToDate(): number;
                forecast(): number;
            },
            paAfterSalaryAndDividendIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            personalAllowance: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryWithinPA: {
                // =IF(K51>($K$87/12*L5),($K$87/12*L5),K51)
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtBR: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtHR: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtAR: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtBRTax: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtHRTax: {
                yearToDate(): number;
                forecast(): number;
            },
            salaryAtARTax: {
                yearToDate(): number;
                forecast(): number;
            },
            totalGrossSalary: {
                yearToDate(): number;
                forecast(): number;
            },
            incomeTaxDueOnSalary: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsWithinPA: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsWithinTFDA: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtBR: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtHR: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtAR: {
                yearToDate(): number;
                forecast(): number;
            },
            totalDividends: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtBRTax: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtHRTax: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendsAtARTax: {
                yearToDate(): number;
                forecast(): number;
            },
            additionalTaxDueOnDividends: {
                yearToDate(): number;
                forecast(): number;
            },
            totalTaxDueAtSelfAssessment: {
                yearToDate(): number;
                forecast(): number;
            }
        };
        studentLoanRepayments: {
            earnedIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            nonEarnedIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            employmentIncomeStudentRepay: {
                yearToDate(): number;
                forecast(): number;
            },
            nonEmploymentIncomeStudentRepay: {
                yearToDate(): number;
                forecast(): number;
            },
            studentPaymentAmount: {
                yearToDate(): number;
                forecast(): number;
            }
        };
        childBenefitTaxCharge: {
            grossTaxableIncome: {
                yearToDate(): number;
                forecast(): number;
            },
            relativePercentageCharge: {
                yearToDate(): number;
                forecast(): number;
            },
            childBenefitTaxCharge: {
                yearToDate(): number;
                forecast(): number;
            }
        };
        taxPayments: {
            totalIncomeTaxPayable: {
                yearToDate(): number;
                forecast(): number;
            },
            studentLoanPaymentDue: {
                yearToDate(): number;
                forecast(): number;
            },
            childBenefitTaxCharge: {
                yearToDate(): number;
                forecast(): number;
            },
            totalPayableOnSelfAssessment: {
                yearToDate(): number;
                forecast(): number;
            },
            paymentOnAccount: {
                yearToDate(): number;
                forecast(): number;
            },
            totalTaxPayments(): number;
        };
        taxBandCapacity: {
            personalAllowance: {
                yearToDate(): number;
                forecast(): number;
            },
            dividendAllowance: {
                yearToDate(): number;
                forecast(): number;
            },
            basicRate7: {
                yearToDate(): number;
                forecast(): number;
            }
        };
    };

    export class PersonalTaxTrackerService implements IPersonalTaxTrackerService {
        title: string = "Personal Tax";
        selectedTimeframe: ITimeframe;
        selectedTaxYear: string;
        availableTaxYears: string[];
        taxYearOffset: number;
        companyName: string = '';
        hasUmbrella: boolean;
        umbrellaTaxData: UmbrellaTaxData;
        msnry: IMasonry = null;
        isEditingDividends: boolean = false;
        debounceLength: number = 1000;
        fixedTaxData: FixedTaxData;
        forecastCalculations: ForecastCalculations;
        userInputData: UserInputData;
        umbrellaTaxTracker: UmbrellaTaxTracker;

        detailedViewToggles: IDetailedViewToggles = <IDetailedViewToggles>{
            all: false,
            incomeSummary: false,
            forecastCalculations: false,
            tracker: false,
            liabilityDue: false
        };

        static $inject = ['$http', '$q', '$timeout', 'personalTax.enums', 'brookson.utilities.date'];

        constructor(private $http: Shared.IBrooksonHttp,
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private personalTaxEnums: Shared.IPersonalTaxEnums,
            private brooksonUtilitiesDate: Shared.IBrooksonUtilitiesDate) {
            this.clearData();
            this.selectedTimeframe = this.personalTaxEnums.Timeframes.properties[this.personalTaxEnums.Timeframes.FORECAST];
            this.selectedTimeframe.value = this.personalTaxEnums.Timeframes.FORECAST;
            //default to previous tax year

            this.getTaxYear();
        }


        getTaxYear() {

            let offset = 0;
            let currentDate = new Date();

            if (currentDate >= new Date('04/06/' + currentDate.getFullYear()) &&
                currentDate <= new Date('05/01/' + currentDate.getFullYear())) {
                offset = -1;
            }

            this.taxYearOffset = offset;
            this.availableTaxYears = ['Current Tax Year', 'Previous Tax Year'];
            this.selectedTaxYear = this.availableTaxYears[offset === -1 ?  1 : offset];
            
        }

        selectedTaxYearChanged = (selectedTaxYear: any) => {  
            this.clearData();
            this.taxYearOffset = -1 * this.availableTaxYears.indexOf(selectedTaxYear);
            return this.getPersonalTaxTrackerDataByOffset(this.taxYearOffset);
        }

        clearData = (): void => {

            this.fixedTaxData = <FixedTaxData>{};
            this.forecastCalculations = <ForecastCalculations>{};
            this.umbrellaTaxData = <UmbrellaTaxData>{};
            this.userInputData = <UserInputData>{
                // Income Calculations
                otherEmploymentIncome: 0,
                otherEmploymentIncomeYTD: 0,
                pensionIncome: 0,
                pensionIncomeYTD: 0,
                interestIncome: 0,
                interestIncomeYTD: 0,
                taxableBenefitsInKind: 0,
                taxableBenefitsInKindYTD: 0,

                dividends: 0,
                otherDividendIncome: 0,
                otherDividendIncomeYTD: 0,

                personalPensionPayments: 0,
                personalPensionPaymentsYTD: 0,
                childBenefitAmountReceived: 0,
                childBenefitAmountReceivedYTD: 0,
                studentLoanBalanceAtBeginningOfTaxYear: 0,
                studentLoanBalanceAtBeginningOfTaxYearYTD: 0,

                // Tax Payments
                taxAlreadyPaid: 0,
                paymentOnAccountPaidJanuary: 0,
                paymentOnAccountPaidJuly: 0
            };
        }
        private roundedDecimal = (value): number => {
            return _.round(value) / Math.pow(10, 2);
        }

        resetEditingDividends = (): void => {
            this.isEditingDividends = false;
            this.userInputData.dividends = 0;
        }

        personalTaxData: IPersonalTaxData = <IPersonalTaxData>{
            umbrellaTaxTracker: {
                // payYear: number;
                // sumGrossPay: number;
                // sumPaye: number;

                payYear: (): number => {
                    if(!this.personalTaxData.umbrellaTaxTracker)
                    {
                        return 0;
                    }
                    return this.personalTaxData.umbrellaTaxTracker.payYear();
                },
                sumGrossPay: (): number => {
                    if(!this.personalTaxData.umbrellaTaxTracker)
                    {
                        return 0;
                    }
                    return this.personalTaxData.umbrellaTaxTracker.sumGrossPay();
                },
                sumPaye: (): number => {
                    if(!this.personalTaxData.umbrellaTaxTracker)
                    {
                        return 0;
                    }
                    return this.personalTaxData.umbrellaTaxTracker.sumPaye();
                },
            },
            fixedTaxData: {
                grossPensionContribution: (): number => {
                    // =L47/0.8
                    return this.userInputData.personalPensionPayments / 0.8;
                },
                basicRateThresholdPension: (): number => {
                    return this.personalTaxData.fixedTaxData.basicRateThreshold() + this.personalTaxData.fixedTaxData.grossPensionContribution();
                },
                basicRateThresholdDividends: (): number => {
                    return this.personalTaxData.fixedTaxData.basicRateThresholdPension() - this.personalTaxData.fixedTaxData.taxFreeDividendAllowance();
                },
                higherRateThresholdPension: (): number => {
                    return this.personalTaxData.fixedTaxData.higherRateThreshold() + this.personalTaxData.fixedTaxData.grossPensionContribution();
                },
                higherRateThresholdDividends: (): number => {
                    return this.personalTaxData.fixedTaxData.higherRateThresholdPension() - this.personalTaxData.fixedTaxData.taxFreeDividendAllowance();
                },
                higherRateAllowanceDividends: (): number => {
                    return this.personalTaxData.fixedTaxData.higherRateThresholdDividends() - this.personalTaxData.fixedTaxData.basicRateThresholdDividends();
                },
                taxFreeDividendAllowance: (): number => {
                    return this.fixedTaxData.taxFreeDividendAllowance;
                },
                lowerRateDividendTaxRate: (): number => {
                    return this.fixedTaxData.lowerRateDividendTaxRate;
                },
                higherRateDividendTaxRate: (): number => {
                    return this.fixedTaxData.higherRateDividendTaxRate;
                },
                additionalRateDividendTaxRate: (): number => {
                    return this.fixedTaxData.additionalRateDividendTaxRate;
                },
                personalAllowance: (): number => {
                    return this.fixedTaxData.personalAllowance;
                },
                basicRateThreshold: (): number => {
                    return this.fixedTaxData.basicRateThreshold;
                },
                higherRateDividendThreshold: (): number => {
                    return this.personalTaxData.fixedTaxData.higherRateThresholdPension() - this.personalTaxData.fixedTaxData.taxFreeDividendAllowance();
                },
                higherRateThreshold: (): number => {
                    return this.fixedTaxData.higherRateThreshold;
                },
                fullPersonalAllowanceUpperLimit: (): number => {
                    return this.fixedTaxData.fullPersonalAllowanceUpperLimit;
                },
                lowerRateIncomeTax: (): number => {
                    return this.fixedTaxData.lowerRateIncomeTax;
                },
                basicRateIncomeTax: (): number => {
                    return this.fixedTaxData.basicRateIncomeTax;
                },
                higherRateTax: (): number => {
                    return this.fixedTaxData.higherRateTax;
                },
                additionalRateTax: (): number => {
                    return this.fixedTaxData.additionalRateTax;
                },
                employmentIncomeStudentRepayThreshold: (): number => {
                    return this.fixedTaxData.employmentIncomeStudentRepayThreshold;
                },
                nonEmploymentIncomeStudentRepayThreshold: (): number => {
                    return this.fixedTaxData.nonEmploymentIncomeStudentRepayThreshold;
                },
                repaymentPercentage: (): number => {
                    return this.fixedTaxData.repaymentPercentage;
                },
                childBenefitTaxChargeThreshold: (): number => {
                    return this.fixedTaxData.childBenefitTaxChargeThreshold;
                },
                percentageIncrements: (): number => {
                    return this.fixedTaxData.percentageIncrements;
                }
            },
            forecastCalculations: {
                forecastTotal: (): number => {
                    return this.personalTaxData.forecastCalculations.confirmedAsDividends() +
                        this.personalTaxData.forecastCalculations.unconfirmedTransactionsWithShareholderSplit() +
                        this.personalTaxData.forecastCalculations.transactionsBeingProcessedWithShareholderSplit();
                },
                forecastPSCEmployment: (): number => {
                    return this.personalTaxData.forecastCalculations.pscEmployment() +
                        this.personalTaxData.forecastCalculations.feesRemainingForYear();
                },
                forecastPSCDividendIncome: (): number => {
                    if (this.userInputData.dividends > 0) {
                        return this.userInputData.dividends;
                    } else {
                        return this.personalTaxData.forecastCalculations.forecastTotal() *
                            (this.personalTaxData.forecastCalculations.weeksYearToDateFromTaxStart() +
                            this.personalTaxData.forecastCalculations.weeksRemainingFromTaxStart()) /
                            this.personalTaxData.forecastCalculations.weeksYearToDateFromTaxStart();
                    }
                },
                yearToDatePSCEmployment: (): number => {
                    return this.personalTaxData.forecastCalculations.pscEmployment();
                },
                yearToDatePSCDividendIncome: (): number => {
                    return this.personalTaxData.forecastCalculations.confirmedAsDividends() +
                        this.personalTaxData.forecastCalculations.unconfirmedTransactionsWithShareholderSplit() +
                        this.personalTaxData.forecastCalculations.transactionsBeingProcessedWithShareholderSplit();
                },
                monthsYearToDate: (): number => {
                    return this.forecastCalculations.monthsYearToDate;
                },
                monthsRemaining: (): number => {
                    return this.forecastCalculations.monthsRemaining;
                },
                weeksYearToDate: (): number => {
                    return this.forecastCalculations.weeksYearToDate;
                },
                weeksRemaining: (): number => {
                    return this.forecastCalculations.weeksRemaining;
                },
                weeksYearToDateFromTaxStart: (): number => {
                    return this.forecastCalculations.weeksYearToDateFromTaxStart;
                },
                weeksRemainingFromTaxStart: (): number => {
                    return this.forecastCalculations.weeksRemainingFromTaxStart;
                },
                pscEmployment: (): number => {
                    return this.forecastCalculations.pscEmployment;
                },
                currentDirectorsFeeWeekly: (): number => {
                    return this.forecastCalculations.currentDirectorsFeeWeekly < 0 ? 0 : this.forecastCalculations.currentDirectorsFeeWeekly;
                },
                currentDirectorsFeeMonthly: (): number => {
                    return this.forecastCalculations.pscEmployment / this.forecastCalculations.monthsYearToDate;
                },
                feesRemainingForYear: (): number => {
                    //the weekly fee now accounts also for the months remaining for the current payroll period (this could do with changing to WeeksOrMonthsRemaining)
                    return this.personalTaxData.forecastCalculations.currentDirectorsFeeWeekly() *
                        this.personalTaxData.forecastCalculations.weeksRemaining();
                },
                shareholderSplit: (): number => {
                    return this.forecastCalculations.shareholderSplit;
                },
                confirmedAsDividends: (): number => {
                    return this.forecastCalculations.confirmedAsDividends;
                },
                unconfirmedTransactionsWithShareholderSplit: (): number => {
                    return this.forecastCalculations.unconfirmedTransactions * (this.forecastCalculations.shareholderSplit / 100);
                },
                transactionsBeingProcessedWithShareholderSplit: (): number => {
                    return this.forecastCalculations.transactionsBeingProcessed * (this.forecastCalculations.shareholderSplit / 100);
                },
                taxDeductedFromPSCIncome: (): number => {
                    return this.forecastCalculations.taxDeductedFromPSCIncome;
                }
            },

            incomeCalculations: {
                pscEmploymentIncome: {
                    // K28
                    yearToDate: (): number => {
                        return this.personalTaxData.forecastCalculations.yearToDatePSCEmployment();
                    },
                    // L28
                    forecast: (): number => {
                        return this.personalTaxData.forecastCalculations.forecastPSCEmployment();
                    }
                },
                nonDividendIncome: {
                    // =K33+SUM(K34:K37)
                    yearToDate: (): number => {
                        return this.personalTaxData.incomeCalculations.pscEmploymentIncome.yearToDate() +
                            (this.userInputData.otherEmploymentIncomeYTD +
                                this.userInputData.pensionIncomeYTD +
                                this.userInputData.interestIncomeYTD +
                                this.userInputData.taxableBenefitsInKindYTD);
                    },
                    // =L33+SUM(L34:L37)
                    forecast: (): number => {
                        return this.personalTaxData.incomeCalculations.pscEmploymentIncome.forecast() +
                            (this.userInputData.otherEmploymentIncome +
                                this.userInputData.pensionIncome +
                                this.userInputData.interestIncome +
                                this.userInputData.taxableBenefitsInKind);
                    }
                },
                pscDividendIncome: {
                    // K40
                    yearToDate: (): number => {
                        return this.personalTaxData.forecastCalculations.yearToDatePSCDividendIncome();
                    },
                    // L40
                    forecast: (): number => {
                        return this.personalTaxData.forecastCalculations.forecastPSCDividendIncome();
                    }
                },
                dividendIncome: {
                    // K36
                    yearToDate: (): number => {
                        return this.personalTaxData.incomeCalculations.pscDividendIncome.yearToDate() + this.userInputData.otherDividendIncome;
                    },
                    // L36
                    forecast: (): number => {
                        return this.personalTaxData.incomeCalculations.pscDividendIncome.forecast() + this.userInputData.otherDividendIncome;
                    }
                },
                grossTaxableIncome: {
                    // K38
                    yearToDate: (): number => {
                        return this.personalTaxData.incomeCalculations.nonDividendIncome.yearToDate() + this.personalTaxData.incomeCalculations.dividendIncome.yearToDate();
                    },
                    // L38
                    forecast: (): number => {
                        return this.personalTaxData.incomeCalculations.nonDividendIncome.forecast() + this.personalTaxData.incomeCalculations.dividendIncome.forecast();
                    }
                }
            },
            taxCalculationWorkings: {
                nonDividendIncome: {
                    yearToDate: (): number => {
                        if(!this.umbrellaTaxTracker)
                        {
                            return this.personalTaxData.incomeCalculations.nonDividendIncome.yearToDate();
                        }

                        return this.personalTaxData.incomeCalculations.nonDividendIncome.yearToDate() + this.umbrellaTaxTracker.sumGrossPay;// + this.personalTaxData.umbrellaTaxTracker.sumGrossPay();
                    },
                    forecast: (): number => {
                        if(!this.umbrellaTaxTracker)
                        {
                            return this.personalTaxData.incomeCalculations.nonDividendIncome.forecast();
                        }

                        return this.personalTaxData.incomeCalculations.nonDividendIncome.forecast() + this.umbrellaTaxTracker.sumGrossPay;// + this.personalTaxData.umbrellaTaxTracker.sumGrossPay();
                    }
                },
                dividends: {
                    yearToDate: (): number => {
                        return this.personalTaxData.incomeCalculations.dividendIncome.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.incomeCalculations.dividendIncome.forecast();
                    }
                },
                grossTaxableIncome: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() + this.personalTaxData.incomeCalculations.dividendIncome.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.incomeCalculations.nonDividendIncome.forecast() + this.personalTaxData.incomeCalculations.dividendIncome.forecast();
                    }
                },
                annualPersonalAllowance: {
                    yearToDate: (): number => {
                        return this.personalTaxData.fixedTaxData.personalAllowance(); // =F10
                    },
                    forecast: (): number => {
                        return this.personalTaxData.fixedTaxData.personalAllowance(); // =F10
                    }
                },
                fullPaUpperLimit: {
                    yearToDate: (): number => {
                        return this.personalTaxData.fixedTaxData.fullPersonalAllowanceUpperLimit(); // =F20
                    },
                    forecast: (): number => {
                        return this.personalTaxData.fixedTaxData.fullPersonalAllowanceUpperLimit(); // F20
                    }
                },
                grossIncomeAboveUpperLimit: {
                    yearToDate: (): number => {
                        return ((this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.yearToDate() - this.personalTaxData.taxCalculationWorkings.fullPaUpperLimit.yearToDate()) > 0) ?
                            (this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.yearToDate() - this.personalTaxData.taxCalculationWorkings.fullPaUpperLimit.yearToDate())
                            : 0; // =IF(K77-K76>0,K77-K76,0)
                    },
                    forecast: (): number => {
                        return ((this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.forecast() - this.personalTaxData.taxCalculationWorkings.fullPaUpperLimit.forecast()) > 0) ?
                            (this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.forecast() - this.personalTaxData.taxCalculationWorkings.fullPaUpperLimit.forecast())
                            : 0; // =IF(L77-L76>0,L77-L76,0)
                    }
                },
                reductionInPA: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.grossIncomeAboveUpperLimit.yearToDate() / 2 > this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.yearToDate() ?
                            this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.yearToDate()
                            : this.personalTaxData.taxCalculationWorkings.grossIncomeAboveUpperLimit.yearToDate() / 2; // =IF(K78/2>K75,K75,K78/2)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.grossIncomeAboveUpperLimit.forecast() / 2 > this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.forecast() ?
                            this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.forecast()
                            : this.personalTaxData.taxCalculationWorkings.grossIncomeAboveUpperLimit.forecast() / 2; // =IF(L78/2>L75,L75,L78/2)
                    }
                },
                paAfterSalaryAndDividendIncome: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.yearToDate() - this.personalTaxData.taxCalculationWorkings.reductionInPA.yearToDate(); // =K75-K79
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.annualPersonalAllowance.forecast() - this.personalTaxData.taxCalculationWorkings.reductionInPA.forecast(); // =L75-L79
                    }
                },
                personalAllowance: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.forecast();
                    }
                },
                salaryWithinPA: {
                    // =IF(K51>($K$87/12*L5),($K$87/12*L5),K51)
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() > (this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.yearToDate() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate())
                            ? (this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.yearToDate() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate())
                            : this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast() > this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.forecast() ?
                            this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.forecast()
                            : this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast(); // =IF(L50>L80,L80,L50)
                    }
                },
                salaryAtBR: {
                    yearToDate: (): number => {
                        // =IF(K51-K57>($F$15/12*L5),($F$15/12*L5),K51-K57)
                        return (this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() - this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate()) > (this.personalTaxData.fixedTaxData.basicRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate()) ?
                            (this.personalTaxData.fixedTaxData.basicRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate())
                            : (this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() - this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate());
                    },
                    forecast: (): number => {
                        return (this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast() - this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast()) > this.personalTaxData.fixedTaxData.basicRateThresholdPension() ?
                            this.personalTaxData.fixedTaxData.basicRateThresholdPension()
                            : (this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast() - this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast());
                    }
                },
                salaryAtHR: {
                    yearToDate: (): number => {
                        // =IF(K57-SUM(K63:K64)>($F$20/12*L6)-($F$16/12*L6),($F$20/12*L6)-SUM(K63:K64),K57-SUM(K63:K64))

                        let k57 = this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate();
                        let k63Sum = this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate();
                        let f20 = this.personalTaxData.fixedTaxData.higherRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate();
                        let f16 = this.personalTaxData.fixedTaxData.basicRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate();

                        return k57 - k63Sum > f20 - f16 
                            ? f20 - k63Sum
                            : k57 - k63Sum;
                    },
                    forecast: (): number => {
                        // =IF(L51-SUM(L57:L58)>$F$19-$F$15,$F$19-SUM(L57:L58),L51-SUM(L57:L58))

                        // SUM(L57:L58)
                        var v1 = (this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast());
                        var f19 = this.personalTaxData.fixedTaxData.higherRateThresholdPension();
                        var f15 = this.personalTaxData.fixedTaxData.basicRateThresholdPension();
                        var l51 = this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast();

                        return (l51 - v1) > f19 - f15
                            ? f19 - v1
                            : l51 - v1;
                    }
                },
                salaryAtAR: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() - (this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate());  // =K50-SUM(K56:K58)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast() - (this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast()); // =L50-SUM(L56:L58)
                    }
                },
                salaryAtBRTax: {
                    yearToDate: (): number => {
                        return this.roundedDecimal((this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() * this.personalTaxData.fixedTaxData.basicRateIncomeTax()));
                    },
                    forecast: (): number => {
                        return this.roundedDecimal((this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() * this.personalTaxData.fixedTaxData.basicRateIncomeTax()));
                    }
                },
                salaryAtHRTax: {
                    yearToDate: (): number => {
                        //=ROUND((K58*$F24),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate() * this.personalTaxData.fixedTaxData.higherRateTax());
                    },
                    forecast: (): number => {
                        //ROUND((L58*$F24),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast() * this.personalTaxData.fixedTaxData.higherRateTax());
                    }
                },
                salaryAtARTax: {
                    yearToDate: (): number => {
                        //=ROUND((K59*$F25),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.salaryAtAR.yearToDate() * this.personalTaxData.fixedTaxData.additionalRateTax());
                    },
                    forecast: (): number => {
                        //ROUND((L59*$F25),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.salaryAtAR.forecast() * this.personalTaxData.fixedTaxData.additionalRateTax());
                    }
                },
                totalGrossSalary: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtAR.yearToDate(); //=SUM(K56:K59)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast() + this.personalTaxData.taxCalculationWorkings.salaryAtAR.forecast(); //=SUM(K56:K59)
                    }
                },
                incomeTaxDueOnSalary: {
                    yearToDate: (): number => {
                        // =SUM(K69:K71)
                        return (this.personalTaxData.taxCalculationWorkings.salaryAtBRTax.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtHRTax.yearToDate() + this.personalTaxData.taxCalculationWorkings.salaryAtARTax.yearToDate());
                    },
                    forecast: (): number => {
                        return (this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() * (this.personalTaxData.fixedTaxData.basicRateIncomeTax() / 100))
                            +
                            (this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast() * (this.personalTaxData.fixedTaxData.higherRateTax() / 100))
                            +
                            (this.personalTaxData.taxCalculationWorkings.salaryAtAR.forecast() * (this.personalTaxData.fixedTaxData.additionalRateTax() / 100)); // =ROUND((L57*$F23)+(L58*$F24)+(L59*$F25),2)
                    }
                },
                dividendsWithinPA: {
                    yearToDate: (): number => {
                        // =IF(K52<=($K$87/12*L5)-K57,K52,IF(K52>($K$87/12*L5)-K57,($K$87/12*L5)-K57,($K$87/12*L5)))


                        //($K$87/12*L5)
                        var v1 = (this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.yearToDate() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate());
                        var k52 = this.personalTaxData.taxCalculationWorkings.dividends.yearToDate();
                        var k57 = this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate();


                        return (k52 <= v1 - k57)
                            ? k52
                            : k52 > v1 - k57
                                ? v1 - k57
                                : v1;
                    },
                    forecast: (): number => {
                        // =IF(L52<=L87-L57,L52,IF(L52>L87-L57,L87-L57,L87))
                        var l52 = this.personalTaxData.taxCalculationWorkings.dividends.forecast();
                        var l87 = this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.forecast();
                        var l57 = this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast();

                        return l52 <= (l87 - l57)
                            ? l52
                            : l52 > (l87 - l57)
                                ? l87 - l57
                                : l87;
                    }
                },
                dividendsWithinTFDA: {
                    yearToDate: (): number => {
                        // =IF(K52-K68>($F$6/12*L5),($F$6/12*L5),K52-K68)

                        // ($F$6/12*L5)
                        var v1 = this.personalTaxData.taxCalculationWorkings.dividends.yearToDate() - this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate();
                        var f6 = (this.personalTaxData.fixedTaxData.taxFreeDividendAllowance() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate());

                        return v1 > f6
                            ? f6
                            : v1;
                    },
                    forecast: (): number => {
                        // =IF(L52-L68>$F$6,$F$6,L52-L68)
                        var v1 = this.personalTaxData.taxCalculationWorkings.dividends.forecast() - this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast();
                        var f6 = this.personalTaxData.fixedTaxData.taxFreeDividendAllowance();

                        return v1 > f6 ? f6 : v1;
                    }
                },
                dividendsAtBR: {
                    yearToDate: (): number => {
                        // =IF(K59>0,0,IF(K52-SUM(K68:K69)>=($F$15/12*L5)-K58-K69,IF((($F$15/12*L5)-K58-K69)<0,0,(($F$15/12*L5)-K58-K69)),IF((K52-SUM(K68:K69))<0,0,(K52-SUM(K68:K69)))))

                        // K52-SUM(K68:K69)
                        var v1 = (this.personalTaxData.taxCalculationWorkings.dividends.yearToDate() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate()));
                        // ($F$15/12*L5)-K58-K69
                        var v2 = (this.personalTaxData.fixedTaxData.basicRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate()) - this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() - this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate();

                        return this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate() > 0
                            ? 0
                            : v1 >= v2
                                ? v2 < 0
                                    ? 0
                                    : v2
                                : v1 < 0
                                    ? 0
                                    : v1;
                    },
                    forecast: (): number => {
                        // =IF(L59>0,0,IF(L52-SUM(L68:L69)>=$F$15-L58-L69,IF(($F$15-L58-L69)<0,0,($F$15-L58-L69)),IF((L52-SUM(L68:L69))<0,0,(L52-SUM(L68:L69)))))


                        // L52-SUM(L68:L69)
                        var v1 = (this.personalTaxData.taxCalculationWorkings.dividends.forecast() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast()));
                        // $F$15-L58-L69
                        var v2 = (this.personalTaxData.fixedTaxData.basicRateThresholdPension() - this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() - this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast());

                        return this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast() > 0
                            ? 0
                            : v1 >= v2
                                ? v2 < 0
                                    ? 0
                                    : v2
                                : v1 < 0
                                    ? 0
                                    : v1;
                    }
                },
                dividendsAtHR: {
                    yearToDate: (): number => {
                        // =IF(K60>0,0,IF(K52-SUM(K68:K70)>=($F$19/12*L5)-SUM(K58:K59)-K69-K70,IF((($F$19/12*L5)-SUM(K58:K59)-SUM(K69:K70))<0,0,(($F$19/12*L5)-SUM(K58:K59)-SUM(K69:K70))),IF((K52-SUM(K68:K70))<0,0,(K52-SUM(K68:K70)))))

                        // K52-SUM(K68:K70)
                        const k52 = (this.personalTaxData.taxCalculationWorkings.dividends.yearToDate() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate()));

                        // ($F$19/12*L5)-SUM(K58:K59)
                        const f19 = (this.personalTaxData.fixedTaxData.higherRateThresholdPension() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate()) - (this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() +
                            this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate());

                        // ($F$19/12*L5)-SUM(K58:K59)-SUM(K69:K70)
                        const f19k68 = f19 - (this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate());


                        return this.personalTaxData.taxCalculationWorkings.salaryAtAR.yearToDate() > 0
                            ? 0
                            : k52 >= f19 - this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate() - this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate()
                                ? f19k68 < 0
                                    ? 0
                                    : f19k68
                                : k52 < 0
                                    ? 0
                                    : k52;

                    },
                    forecast: (): number => {
                        // =IF(L60>0,0,IF(L52-SUM(L68:L70)>=$F$19-SUM(L58:L59)-L69-L70,IF(($F$19-SUM(L58:L59)-SUM(L69:L70))<0,0,($F$19-SUM(L58:L59)-SUM(L69:L70))),IF((L52-SUM(L68:L70))<0,0,(L52-SUM(L68:L70)))))

                        // L52-SUM(L68:L70)
                        const k52 = (this.personalTaxData.taxCalculationWorkings.dividends.forecast() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast()));

                        // $F$19-SUM(K58:K59)
                        const f19 = this.personalTaxData.fixedTaxData.higherRateThresholdPension() - (this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() +
                            this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast());

                        // $F$19-SUM(K58:K59)-SUM(K69:K70)
                        const f19k68 = f19 - (this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast());


                        return this.personalTaxData.taxCalculationWorkings.salaryAtAR.forecast() > 0
                            ? 0
                            : k52 >= f19 - this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast() - this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast()
                                ? f19k68 < 0
                                    ? 0
                                    : f19k68
                                : k52 < 0
                                    ? 0
                                    : k52;
                    }
                },
                dividendsAtAR: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividends.yearToDate() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtHR.yearToDate()); // =K51-SUM(K64:K67)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividends.forecast() - (this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtHR.forecast()); // =K51-SUM(K64:K67) // =L51-SUM(L64:L67)
                    }
                },
                totalDividends: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtHR.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtAR.yearToDate(); // =SUM(K64:K68)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtHR.forecast() + this.personalTaxData.taxCalculationWorkings.dividendsAtAR.forecast(); // =SUM(L64:L68)
                    }
                },
                dividendsAtBRTax: {
                    yearToDate: (): number => {
                        // ROUND((K69*$F7),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate() * this.personalTaxData.fixedTaxData.lowerRateDividendTaxRate());
                    },
                    forecast: (): number => {
                        // =ROUND((L69*$F7),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast() * this.personalTaxData.fixedTaxData.lowerRateDividendTaxRate());
                    }
                },
                dividendsAtHRTax: {
                    yearToDate: (): number => {
                        // ROUND((K70*$F8),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtHR.yearToDate() * this.personalTaxData.fixedTaxData.higherRateDividendTaxRate());
                    },
                    forecast: (): number => {
                        //ROUND((L70*$F8),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtHR.forecast() * this.personalTaxData.fixedTaxData.higherRateDividendTaxRate());
                    }
                },
                dividendsAtARTax: {
                    yearToDate: (): number => {
                        // ROUND((K71*$F9),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtAR.yearToDate() * this.personalTaxData.fixedTaxData.additionalRateDividendTaxRate());
                    },
                    forecast: (): number => {
                        //ROUND((L71*$F9),2)
                        return this.roundedDecimal(this.personalTaxData.taxCalculationWorkings.dividendsAtAR.forecast() * this.personalTaxData.fixedTaxData.additionalRateDividendTaxRate());
                    }
                },
                additionalTaxDueOnDividends: {
                    yearToDate: (): number => {
                        // =SUM(K80:K82)
                        return (this.personalTaxData.taxCalculationWorkings.dividendsAtBRTax.yearToDate() + this.personalTaxData.taxCalculationWorkings.dividendsAtHRTax.yearToDate()
                            + this.personalTaxData.taxCalculationWorkings.dividendsAtARTax.yearToDate());
                    },
                    forecast: (): number => {
                        // =SUM(L80:L82)
                        return (
                            this.personalTaxData.taxCalculationWorkings.dividendsAtBRTax.forecast() +
                            this.personalTaxData.taxCalculationWorkings.dividendsAtHRTax.forecast() +
                            this.personalTaxData.taxCalculationWorkings.dividendsAtARTax.forecast()
                        );
                    }
                },
                totalTaxDueAtSelfAssessment: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.incomeTaxDueOnSalary.yearToDate() + this.personalTaxData.taxCalculationWorkings.additionalTaxDueOnDividends.yearToDate(); // =SUM(K62+K71)
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.incomeTaxDueOnSalary.forecast() + this.personalTaxData.taxCalculationWorkings.additionalTaxDueOnDividends.forecast(); // =SUM(L62+L71)
                    }
                }
            },
            studentLoanRepayments: {
                earnedIncome: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.yearToDate() - this.userInputData.interestIncome; // =K56-K36
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.nonDividendIncome.forecast() - this.userInputData.interestIncome; // =L56-L36
                    }
                },
                nonEarnedIncome: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividends.yearToDate() + this.userInputData.interestIncome; // =K57+K36
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.dividends.forecast() + this.userInputData.interestIncome; // =L57+L36
                    }
                },
                employmentIncomeStudentRepay: {
                    yearToDate: (): number => {
                        return this.personalTaxData.studentLoanRepayments.earnedIncome.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.studentLoanRepayments.earnedIncome.forecast();
                    }
                },
                nonEmploymentIncomeStudentRepay: {
                    yearToDate: (): number => {
                        //=IF(K99<(F30/12*L6),0,K99)
                        return this.personalTaxData.studentLoanRepayments.nonEarnedIncome.yearToDate() < (this.personalTaxData.fixedTaxData.nonEmploymentIncomeStudentRepayThreshold() / 12 * this.personalTaxData.forecastCalculations.monthsRemaining())
                            ? 0
                            : this.personalTaxData.studentLoanRepayments.nonEarnedIncome.yearToDate();
                    },
                    forecast: (): number => {
                        // =IF(L99<F30,0,L99)
                        return this.personalTaxData.studentLoanRepayments.nonEarnedIncome.forecast() < this.personalTaxData.fixedTaxData.nonEmploymentIncomeStudentRepayThreshold()
                            ? 0
                            : this.personalTaxData.studentLoanRepayments.nonEarnedIncome.forecast();
                    }
                },
                studentPaymentAmount: {
                    yearToDate: (): number => {
                        // =IF((K100+K101)<F29/12*L6,0,if((K100+K101-(F29/12*L6))*F31>K49,K49,(K100+K101-(F29/12*L6))*F31))
                        let k100K101 = ((this.personalTaxData.studentLoanRepayments.employmentIncomeStudentRepay.yearToDate() + this.personalTaxData.studentLoanRepayments.nonEmploymentIncomeStudentRepay.yearToDate()));
                        let f29 = this.personalTaxData.fixedTaxData.employmentIncomeStudentRepayThreshold() / 12 * this.personalTaxData.forecastCalculations.monthsYearToDate();
                        let f31 = this.personalTaxData.fixedTaxData.repaymentPercentage();
                        let k49 = this.userInputData.studentLoanBalanceAtBeginningOfTaxYearYTD;

                        return k100K101 < f29
                            ? 0
                            : (k100K101 - f29) * f31 > k49
                                ? k49
                                : (k100K101 - f29) * f31;
                    },
                    forecast: (): number => {
                        // =IF((L100+L101)<F29,0,if((L100+L101-F29)*F31>L49,L49,(L100+L101-F29)*F31))
                        let l100 = this.personalTaxData.studentLoanRepayments.employmentIncomeStudentRepay.forecast();
                        let l101 = this.personalTaxData.studentLoanRepayments.nonEmploymentIncomeStudentRepay.forecast();
                        let f29 = this.personalTaxData.fixedTaxData.employmentIncomeStudentRepayThreshold();
                        let f31 = this.personalTaxData.fixedTaxData.repaymentPercentage() / 100;
                        let l49 = this.userInputData.studentLoanBalanceAtBeginningOfTaxYear;

                        return (l100 + l101) < f29
                            ? 0
                            : (l100 + l101 - f29) * f31 > l49
                                ? l49
                                : (l100 + l101 - f29) * f31;
                    }
                } 
            },
            childBenefitTaxCharge: {
                grossTaxableIncome: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.yearToDate(); // =K52
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.grossTaxableIncome.forecast(); // =L52
                    }
                },
                relativePercentageCharge: {
                    yearToDate: (): number => {
                        // =IF(K108>=(F33+F34*100),1,IF(K108<F33,0,(K108-F33)/F34/100))
                        return this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.yearToDate() >= (this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold() + this.personalTaxData.fixedTaxData.percentageIncrements() * 100)
                            ? 1
                            : this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.yearToDate() < this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold() ?
                                0
                                : (this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.yearToDate() - this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold()) / this.personalTaxData.fixedTaxData.percentageIncrements(); // =IF(K96>=($F32+$F33*100),1,IF(K96<$F32,0,(K96-$F32)/$F33/100))
                    },
                    forecast: (): number => {
                        // =IF(L108>=(F33+F34*100),1,IF(L108<F33,0,(L108-F33)/F34/100))
                        if (
                            this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.forecast()
                            >=
                            (this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold() + this.personalTaxData.fixedTaxData.percentageIncrements() * 100)
                        ) {
                            return 1;
                        } else {
                            if (
                                this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.forecast()
                                <
                                this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold()
                            ) {
                                return 0;
                            } else {
                                return (this.personalTaxData.childBenefitTaxCharge.grossTaxableIncome.forecast() - this.personalTaxData.fixedTaxData.childBenefitTaxChargeThreshold())
                                    /
                                    this.personalTaxData.fixedTaxData.percentageIncrements()
                                    /
                                    100;
                            }
                        }
                    }
                },
                childBenefitTaxCharge: {
                    yearToDate: (): number => {
                        return this.personalTaxData.childBenefitTaxCharge.relativePercentageCharge.yearToDate() * this.userInputData.childBenefitAmountReceivedYTD; //=K97*L42
                    },
                    forecast: (): number => {
                        return this.personalTaxData.childBenefitTaxCharge.relativePercentageCharge.forecast() * this.userInputData.childBenefitAmountReceived; // =L97*L42
                    }
                }
            },
            taxPayments: {
                totalIncomeTaxPayable: {
                    yearToDate: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.totalTaxDueAtSelfAssessment.yearToDate(); // K73
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.totalTaxDueAtSelfAssessment.forecast(); // L73
                    }
                },
                studentLoanPaymentDue: {
                    yearToDate: (): number => {
                        return this.personalTaxData.studentLoanRepayments.studentPaymentAmount.yearToDate(); // K90
                    },
                    forecast: (): number => {
                        return this.personalTaxData.studentLoanRepayments.studentPaymentAmount.forecast(); // L102
                    }
                },
                childBenefitTaxCharge: {
                    yearToDate: (): number => {
                        return this.personalTaxData.childBenefitTaxCharge.childBenefitTaxCharge.yearToDate(); // K98
                    },
                    forecast: (): number => {
                        return this.personalTaxData.childBenefitTaxCharge.childBenefitTaxCharge.forecast(); // L98
                    }
                },
                totalPayableOnSelfAssessment: {
                    yearToDate: (): number => {
                        // =K117+K118+K119
                        return this.personalTaxData.taxPayments.totalIncomeTaxPayable.yearToDate() +
                            this.personalTaxData.taxPayments.studentLoanPaymentDue.yearToDate() +
                            this.personalTaxData.taxPayments.childBenefitTaxCharge.yearToDate();
                    },
                    forecast: (): number => {
                        // =L117+L118+L119
                        return this.personalTaxData.taxPayments.totalIncomeTaxPayable.forecast() +
                            this.personalTaxData.taxPayments.studentLoanPaymentDue.forecast() +
                            this.personalTaxData.taxPayments.childBenefitTaxCharge.forecast();
                    }
                },
                paymentOnAccount: {
                    yearToDate: (): number => {

                        /*
                            L126 : tax deducted from PSC income          : this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome()
                            L127 : tax already paid/deducted from income : this.userInputData.taxAlreadyPaid
                            K118 : total income tax payable YTD          : this.personalTaxData.taxPayments.totalIncomeTaxPayable.yearToDate()

                            =IF((L126+L127)/K118>0.8,0,IF(K118>1000,K118/2,0))

                            =IF(
                                (L126 + L127)/K118>0.8,
                                0,
                                IF(
                                    K118>1000,
                                    K118/2,
                                    0
                                )
                            )
                        */

                        if (
                            (this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome() + this.userInputData.taxAlreadyPaid) / this.personalTaxData.taxPayments.totalIncomeTaxPayable.yearToDate() > 0.8
                        ) {
                            return 0;
                        } else {
                            if (this.personalTaxData.taxPayments.totalIncomeTaxPayable.yearToDate() > 1000) {
                                return this.personalTaxData.taxPayments.totalIncomeTaxPayable.yearToDate() / 2;
                            } else {
                                return 0;
                            }
                        }
                    },
                    forecast: (): number => {

                        /*
                            L117 : total income tax payable              : this.personalTaxData.taxPayments.totalIncomeTaxPayable.forecast()
                            L119 : child benefit tax charge              : this.personalTaxData.taxPayments.childBenefitTaxCharge.forecast()
                            L125 : tax deducted from PSC income          : this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome()
                            L126 : tax already paid/deducted from income : this.userInputData.taxAlreadyPaid

                            =IF(L126/L117>0.8,0,IF(L117+L119-L125-L126>1000,(L117+L119-L125-L126)/2,0))

                            =IF(
                                L126/L117>0.8,
                                0,
                                IF(
                                    L117+L119-L125-L126>1000,
                                    (L117+L119-L125-L126)/2,
                                    0
                                )
                            )
                        */

                        if (
                            (this.userInputData.taxAlreadyPaid/this.personalTaxData.taxPayments.totalIncomeTaxPayable.forecast()) > 0.8
                        ) {
                            return 0;
                        } else {
                            if (
                                (this.personalTaxData.taxPayments.totalIncomeTaxPayable.forecast() + this.personalTaxData.taxPayments.childBenefitTaxCharge.forecast() - this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome() - this.userInputData.taxAlreadyPaid) > 1000
                            ) {
                                return (this.personalTaxData.taxPayments.totalIncomeTaxPayable.forecast() + this.personalTaxData.taxPayments.childBenefitTaxCharge.forecast() - this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome() - this.userInputData.taxAlreadyPaid) /2;
                            } else {
                                return 0;
                            }
                        }
                    }
                },
                totalTaxPayments: (): number => {
                    // =SUM(L125:L128)
                    return this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome() +
                        this.userInputData.taxAlreadyPaid +
                        this.userInputData.paymentOnAccountPaidJanuary +
                        this.userInputData.paymentOnAccountPaidJuly;
                }
            },
            taxBandCapacity: {
                personalAllowance: {
                    yearToDate: (): number => {
                        // =(K92*L6/12)-K62-K73
                        return (
                            this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.yearToDate() *
                            this.personalTaxData.forecastCalculations.monthsYearToDate() / 12) -
                            this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate() -
                            this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate();
                    },
                    forecast: (): number => {
                        return this.personalTaxData.taxCalculationWorkings.paAfterSalaryAndDividendIncome.forecast() - this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast() - this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast(); // =L80-L56-L64
                    }
                },
                dividendAllowance: {
                    yearToDate: (): number => {
                        // =if(($F$7*L6/12)-K74<0,0,($F$7*L6/12)-K74)
                        let sum = this.personalTaxData.fixedTaxData.taxFreeDividendAllowance() *
                            this.personalTaxData.forecastCalculations.monthsYearToDate() / 12 -
                            this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate();

                        return sum < 0
                            ? 0
                            : sum;
                    },
                    forecast: (): number => {
                        return this.personalTaxData.fixedTaxData.taxFreeDividendAllowance() - this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast();  // =$F$6-L65
                    }
                },
                basicRate7: {
                    yearToDate: (): number => {
                        // =IF((F16*L6/12)-(F7*L6/12)-K63-K75<0,0,(F16*L6/12)-(F7*L6/12)-K63-K75)
                        let sum =
                            (this.personalTaxData.fixedTaxData.basicRateThresholdPension() *                 // F16
                                this.personalTaxData.forecastCalculations.monthsYearToDate() / 12) - // L6
                            (this.personalTaxData.fixedTaxData.taxFreeDividendAllowance() * // F7
                                this.personalTaxData.forecastCalculations.monthsYearToDate() / 12) -// L6
                            this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate() - // K63
                            this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate(); // K75

                        return (sum < 0) ? 0 : sum;
                    },
                    forecast: (): number => {
                        // =IF(F16-F7-L63-L74-L75<0, 0, F16-F7-L63-L74-L75)
                        let sum =
                            this.personalTaxData.fixedTaxData.basicRateThresholdPension() -                 // F16
                            this.personalTaxData.fixedTaxData.taxFreeDividendAllowance() -                  // F7
                            this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast() -             // L63
                            this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast();           // L75

                        return (sum < 0) ? 0 : sum;
                    }
                }
            }
        };

        // Widget Data
        incomeSummaryWidgetData: IIncomeSummaryWidgetData = <IIncomeSummaryWidgetData>{
            hasUmbrella: (): boolean => {
                return this.hasUmbrella;
            },
            directorsFees: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    // K28
                    return this.personalTaxData.incomeCalculations.pscEmploymentIncome.yearToDate();
                } else {
                    // L28
                    return this.personalTaxData.incomeCalculations.pscEmploymentIncome.forecast();
                }                
            },
            grossSalaryFromUmbrella: (): number => {
                if(this.umbrellaTaxTracker)
                {
                    return this.umbrellaTaxTracker.sumGrossPay;
                }
                
                return 0;//this.personalTaxData.umbrellaTaxTracker.sumGrossPay();             
            },
            nonDividendIncome: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    return (
                        this.incomeSummaryWidgetData.directorsFees() +
                        this.incomeSummaryWidgetData.grossSalaryFromUmbrella() +
                        this.userInputData.otherEmploymentIncomeYTD +
                        this.userInputData.pensionIncomeYTD +
                        this.userInputData.interestIncomeYTD +
                        this.userInputData.taxableBenefitsInKindYTD
                    );
                } else {
                    // E9:E12
                    return (
                        this.incomeSummaryWidgetData.directorsFees() +
                        this.incomeSummaryWidgetData.grossSalaryFromUmbrella() +
                        this.userInputData.otherEmploymentIncome +
                        this.userInputData.pensionIncome +
                        this.userInputData.interestIncome +
                        this.userInputData.taxableBenefitsInKind
                    );
                }
            },
            dividends: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    return this.personalTaxData.incomeCalculations.pscDividendIncome.yearToDate();
                } else {
                    // L40
                    return this.personalTaxData.incomeCalculations.pscDividendIncome.forecast();
                }
            },
            dividendIncome: (): number => {
                // F16+F17
                return (
                    this.incomeSummaryWidgetData.dividends() +
                    this.userInputData.otherDividendIncome
                );
            },
            totalIncome: (): number => {
                // F14+F18
                return (
                    this.incomeSummaryWidgetData.nonDividendIncome() +
                    this.incomeSummaryWidgetData.dividendIncome()
                );
            }
        };

        taxTrackerWidgetData: ITaxTrackerWidgetData = <ITaxTrackerWidgetData>{
            nonDividendIncome: {
                personalAllowance: (): number => {
                    if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                        // K56
                        return this.personalTaxData.taxCalculationWorkings.salaryWithinPA.yearToDate();
                    } else {
                        // L56
                        return this.personalTaxData.taxCalculationWorkings.salaryWithinPA.forecast();
                    }
                },
                basicRate: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K57
                            return this.personalTaxData.taxCalculationWorkings.salaryAtBR.yearToDate();
                        } else {
                            // L57
                            return this.personalTaxData.taxCalculationWorkings.salaryAtBR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K62
                            return this.personalTaxData.taxCalculationWorkings.salaryAtBRTax.yearToDate();
                        } else {
                            // L62
                            return this.personalTaxData.taxCalculationWorkings.salaryAtBRTax.forecast();
                        }
                    }
                },
                higherRate: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K58
                            return this.personalTaxData.taxCalculationWorkings.salaryAtHR.yearToDate();
                        } else {
                            // L58
                            return this.personalTaxData.taxCalculationWorkings.salaryAtHR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K63
                            return this.personalTaxData.taxCalculationWorkings.salaryAtHRTax.yearToDate();
                        } else {
                            // L63
                            return this.personalTaxData.taxCalculationWorkings.salaryAtHRTax.forecast();
                        }
                    }
                },
                additionalRate: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K59
                            return this.personalTaxData.taxCalculationWorkings.salaryAtAR.yearToDate();
                        } else {
                            // L59
                            return this.personalTaxData.taxCalculationWorkings.salaryAtAR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K64
                            return this.personalTaxData.taxCalculationWorkings.salaryAtARTax.yearToDate();
                        } else {
                            // L64
                            return this.personalTaxData.taxCalculationWorkings.salaryAtARTax.forecast();
                        }
                    }
                },
                totalNonDividendIncome: {
                    income: (): number => {
                        // K10:K13
                        return (
                            this.taxTrackerWidgetData.nonDividendIncome.personalAllowance() +
                            this.taxTrackerWidgetData.nonDividendIncome.basicRate.income() +
                            this.taxTrackerWidgetData.nonDividendIncome.higherRate.income() +
                            this.taxTrackerWidgetData.nonDividendIncome.additionalRate.income()
                        );
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K71
                            return this.personalTaxData.taxCalculationWorkings.incomeTaxDueOnSalary.yearToDate();
                        } else {
                            // L71
                            return this.personalTaxData.taxCalculationWorkings.incomeTaxDueOnSalary.forecast();
                        }
                    }
                }
            },
            dividendIncome: {
                personalAllowance: (): number => {
                    if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                        // K64
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.yearToDate();
                    } else {
                        // L64
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinPA.forecast();
                    }
                },
                dividendAllowance: (): number => {
                    if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                        // K65
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.yearToDate();
                    } else {
                        // L65
                        return this.personalTaxData.taxCalculationWorkings.dividendsWithinTFDA.forecast();
                    }
                },
                basicRate: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K66
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtBR.yearToDate();
                        } else {
                            // L66
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtBR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K74
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtBRTax.yearToDate();
                        } else {
                            // L74
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtBRTax.forecast();
                        }
                    }
                },
                higherRate: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K67
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtHR.yearToDate();
                        } else {
                            // L67
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtHR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K75
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtHRTax.yearToDate();
                        } else {
                            // L75
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtHRTax.forecast();
                        }
                    }
                },
                taxableDividends: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K68
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtAR.yearToDate();
                        } else {
                            // L68
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtAR.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K76
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtARTax.yearToDate();
                        } else {
                            // L76
                            return this.personalTaxData.taxCalculationWorkings.dividendsAtARTax.forecast();
                        }
                    }
                },
                totalDividendIncome: {
                    income: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            return this.personalTaxData.taxCalculationWorkings.totalDividends.yearToDate();
                        } else {
                            return this.personalTaxData.taxCalculationWorkings.totalDividends.forecast();
                        }
                    },
                    tax: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K77
                            return this.personalTaxData.taxCalculationWorkings.additionalTaxDueOnDividends.yearToDate();
                        } else {
                            // L77
                            return this.personalTaxData.taxCalculationWorkings.additionalTaxDueOnDividends.forecast();
                        }
                    }
                }
            },
            totalIncome: {
                income: (): number => {
                    // K22+K14
                    return (
                        this.taxTrackerWidgetData.nonDividendIncome.totalNonDividendIncome.income() +
                        this.taxTrackerWidgetData.dividendIncome.totalDividendIncome.income()
                    );
                },
                tax: (): number => {
                    // L79
                    return this.personalTaxData.taxCalculationWorkings.totalTaxDueAtSelfAssessment.forecast();
                }
            },
            incomeLeftInEachTaxBand: {
                personalAllowance: (): number => {
                    if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                        return this.personalTaxData.taxBandCapacity.personalAllowance.yearToDate();
                    } else {
                        return this.personalTaxData.taxBandCapacity.personalAllowance.forecast();
                    }
                },
                dividendIncome: {
                    dividendAllowance: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K129
                            return this.personalTaxData.taxBandCapacity.dividendAllowance.yearToDate();
                        } else {
                            // L129
                            return this.personalTaxData.taxBandCapacity.dividendAllowance.forecast();
                        }
                    },
                    basicRate: (): number => {
                        if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                            // K130
                            return this.personalTaxData.taxBandCapacity.basicRate7.yearToDate();
                        } else {
                            // L130
                            return this.personalTaxData.taxBandCapacity.basicRate7.forecast();
                        }
                    },
                    higherRate: (): number => {
                        return this.taxTrackerWidgetData.incomeLeftInEachTaxBand.personalAllowance() + this.taxTrackerWidgetData.incomeLeftInEachTaxBand.dividendIncome.dividendAllowance() + this.taxTrackerWidgetData.incomeLeftInEachTaxBand.dividendIncome.basicRate();
                    }
                },
                incomeAvailableBeforeHigherRateTax: (): number => {
                    return this.taxTrackerWidgetData.incomeLeftInEachTaxBand.personalAllowance() + this.taxTrackerWidgetData.incomeLeftInEachTaxBand.dividendIncome.dividendAllowance() + this.taxTrackerWidgetData.incomeLeftInEachTaxBand.dividendIncome.basicRate();
                }
            }
        };

        taxLiabilityDueWidgetData: ITaxLiabilityDueWidgetData = <ITaxLiabilityDueWidgetData>{
            taxDeductedUmbrellaEarnings: (): number => {
                if(this.umbrellaTaxTracker)
                {
                    return this.umbrellaTaxTracker.sumPaye;
                }
                
                return 0;
            },
            totalTaxPayable: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    // K105
                    return this.personalTaxData.taxCalculationWorkings.totalTaxDueAtSelfAssessment.yearToDate();
                } else {
                    // L105
                    return this.personalTaxData.taxCalculationWorkings.totalTaxDueAtSelfAssessment.forecast();
                }
            },
            studentLoanPaymentDue: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    // K106
                    return this.personalTaxData.taxPayments.studentLoanPaymentDue.yearToDate();
                } else {
                    // L106
                    return this.personalTaxData.taxPayments.studentLoanPaymentDue.forecast();
                }
            },
            childBenefitTaxCharge: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    // K107
                    return this.personalTaxData.taxPayments.childBenefitTaxCharge.yearToDate();
                } else {
                    // L107
                    return this.personalTaxData.taxPayments.childBenefitTaxCharge.forecast();
                }
            },
            taxBillForThisYear: (): number => {
                // SUM(E30:E32)
                return this.taxLiabilityDueWidgetData.totalTaxPayable() + this.taxLiabilityDueWidgetData.studentLoanPaymentDue() + this.taxLiabilityDueWidgetData.childBenefitTaxCharge();
            },
            taxDeductedFromDirectorsFees: (): number => {
                // L113
                return this.personalTaxData.forecastCalculations.taxDeductedFromPSCIncome();
            },
            taxAlreadyPaid: (): number => {
                // L114
                return this.userInputData.taxAlreadyPaid;
            },
            paymentsOnAccountPaidforJan: (): number => {
                // L115
                return this.userInputData.paymentOnAccountPaidJanuary;
            },
            paymentOnAccountPaidForJuly: (): number => {
                // L116
                return this.userInputData.paymentOnAccountPaidJuly;
            },
            paymentsMadeAgainstTaxBill: (): number => {
                // =SUM(F37:F40)
                return this.taxLiabilityDueWidgetData.taxDeductedFromDirectorsFees() + this.userInputData.taxAlreadyPaid + this.userInputData.paymentOnAccountPaidJanuary + this.userInputData.paymentOnAccountPaidJuly;
            },
            balancingPaymentDueJan: (): number => {
                // =E33-E39
                return this.taxLiabilityDueWidgetData.taxBillForThisYear() - this.taxLiabilityDueWidgetData.paymentOnAccountPaidForJuly() - this.taxLiabilityDueWidgetData.paymentsOnAccountPaidforJan() - this.taxLiabilityDueWidgetData.taxAlreadyPaid() - this.taxLiabilityDueWidgetData.taxDeductedFromDirectorsFees() - this.taxLiabilityDueWidgetData.taxDeductedUmbrellaEarnings();
            },
            unpaidTaxForTaxYear: (): number => {
                return this.taxLiabilityDueWidgetData.paymentsMadeAgainstTaxBill() - this.taxLiabilityDueWidgetData.balancingPaymentDueJan() - this.taxLiabilityDueWidgetData.paymentOnAccountPaidForJuly();
            },
            paymentOnAccountDueJan: (): number => {
                return this.taxLiabilityDueWidgetData.balancingPaymentDueJan() + this.taxLiabilityDueWidgetData.paymentOnAccountDueJuly();
            },
            paymentOnAccountDueJuly: (): number => {
                if (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) {
                    // K110
                    return this.personalTaxData.taxPayments.paymentOnAccount.yearToDate();
                } else {
                    // L110
                    return this.personalTaxData.taxPayments.paymentOnAccount.forecast();
                }
            },
            totalPaymentDueJanThisYear: () : number => {
                // =SUM(S18+S15-S16)
                return this.taxLiabilityDueWidgetData.paymentOnAccountDueJuly() +
                this.taxLiabilityDueWidgetData.taxBillForThisYear() -
                this.taxLiabilityDueWidgetData.paymentsMadeAgainstTaxBill();
            } 
        };

        forecastCalculationsWidgetData: IForecastCalculationsWidgetData = <IForecastCalculationsWidgetData>{
            monthsYearToDate: (): number => {
                // L5
                return this.personalTaxData.forecastCalculations.monthsYearToDate();
            },
            monthsRemaining: (): number => {
                // L6
                return this.personalTaxData.forecastCalculations.monthsRemaining();
            },
            weeksYearToDate: (): number => {
                return this.personalTaxData.forecastCalculations.weeksYearToDate();
            },
            weeksRemaining: (): number => {
                return this.personalTaxData.forecastCalculations.weeksRemaining();
            },
            weeksYearToDateFromTaxStart: (): number => {
                return this.forecastCalculations.weeksYearToDateFromTaxStart;
            },
            weeksRemainingFromTaxStart: (): number => {
                return this.forecastCalculations.weeksRemainingFromTaxStart;
            },
            pscEmployment: (): number => {
                // L8
                return this.personalTaxData.forecastCalculations.pscEmployment();
            },
            currentDirectorsFeeWeekly: (): number => {
                // L9
                return this.personalTaxData.forecastCalculations.currentDirectorsFeeWeekly();
            },
            forecastPSCEmployment: (): number => {
                // K44+(K45*K46)
                return this.personalTaxData.forecastCalculations.forecastPSCEmployment();
            },
            confirmedAsDividends: (): number => {
                // L13
                return this.personalTaxData.forecastCalculations.confirmedAsDividends();
            },
            unconfirmedTransactions: (): number => {
                // L14
                return this.personalTaxData.forecastCalculations.unconfirmedTransactionsWithShareholderSplit();
            },
            transactionsBeingProcessed: (): number => {
                // L15
                return this.personalTaxData.forecastCalculations.transactionsBeingProcessedWithShareholderSplit();
            },
            totalPSCDividendIncome: (): number => {
                // SUM(K50:K52)
                return (
                    this.forecastCalculationsWidgetData.confirmedAsDividends() +
                    this.forecastCalculationsWidgetData.unconfirmedTransactions() +
                    this.forecastCalculationsWidgetData.transactionsBeingProcessed()
                );
            },
            forecastPSCDividendIncome: (): number => {
                // L19
                return this.personalTaxData.forecastCalculations.forecastPSCDividendIncome();
            }
        };

        breakdownWidgetData: IBreakdownWidgetData = <IBreakdownWidgetData>{
            pscEmployment: (): number => { return this.incomeSummaryWidgetData.directorsFees(); },
            otherEmploymentIncome: (): number => { return (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) ? this.userInputData.otherEmploymentIncomeYTD : this.userInputData.otherEmploymentIncome; },
            pensionIncome: (): number => { return (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) ? this.userInputData.pensionIncomeYTD : this.userInputData.pensionIncome; },
            interestIncome: (): number => { return (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) ? this.userInputData.interestIncomeYTD : this.userInputData.interestIncome; },
            taxableBenefitsInKind: (): number => { return (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) ? this.userInputData.taxableBenefitsInKindYTD : this.userInputData.taxableBenefitsInKind; },
            pscDividendIncome: (): number => { return this.incomeSummaryWidgetData.dividends(); },
            otherDividendIncome: (): number => { return (this.selectedTimeframe.value === this.personalTaxEnums.Timeframes.YEARTODATE) ? this.userInputData.otherDividendIncomeYTD : this.userInputData.otherDividendIncome; },
        };

        // Masonry
        reloadMasonry = (): void => {
            this.$timeout(() => {
                if (this.msnry) {
                    this.msnry.layout();
                    this.msnry.reloadItems();
                }
            }, 1);
        }

        // Requests
        getPersonalTaxTrackerData = (silentMode?: boolean): ng.IPromise<PersonalTaxTrackerData> => {
            return this.$http.get('api/PersonalTaxTracker/GetPersonalTaxTrackerData', {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.PersonalTaxTrackerData>) => {
                if (response.data) {
                    if (response.data.fixedTaxData) {
                        this.fixedTaxData = response.data.fixedTaxData;
                    }

                    if (response.data.forecastCalculations) {
                        this.forecastCalculations = response.data.forecastCalculations;
                        // This commented section is used for testing purposes to replicate the values seen on the spreadsheet
                        // this.forecastCalculations = <ForecastCalculations>{
                        //     monthsYearToDate: 5,
                        //     monthsRemaining: 7,

                        //     pscEmployment: 3432.56,
                        //     currentDirectorsFeeWeekly: 156.98,

                        //     unconfirmedTransactions: 24000,
                        //     transactionsBeingProcessed: 0,
                        //     shareholderSplit: 100,
                        //     confirmedAsDividends: 9436,

                        //     taxDeductedFromPSCIncome: 565.80
                        // };
                    }

                    if(response.data.umbrellaTaxTracker)
                    {
                        this.umbrellaTaxTracker = response.data.umbrellaTaxTracker;
                    }

                    if (response.data.companyName) {
                        this.companyName = response.data.companyName;
                    }

                    if (response.data.hasUmbrella) {
                        this.hasUmbrella = response.data.hasUmbrella;
                    }


                    if (response.data.userInputData) {
                        _.assign(this.userInputData, response.data.userInputData);
                    }
                }

                return response.data;
            });
        }

        getPersonalTaxTrackerDataByOffset = (offset: number, silentMode?: boolean): ng.IPromise<PersonalTaxTrackerData> => {
            return this.$http.get('api/PersonalTaxTracker/GetPersonalTaxTrackerDataByOffset', {
                params: { offset:offset },
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.PersonalTaxTrackerData>) => {
                if (response.data) {
                    
                    if (response.data.fixedTaxData) {
                        this.fixedTaxData = response.data.fixedTaxData;
                    }

                    if (response.data.forecastCalculations) {
                        this.forecastCalculations = response.data.forecastCalculations;
                        // This commented section is used for testing purposes to replicate the values seen on the spreadsheet
                        // this.forecastCalculations = <ForecastCalculations>{
                        //     monthsYearToDate: 5,
                        //     monthsRemaining: 7,

                        //     pscEmployment: 3432.56,
                        //     currentDirectorsFeeWeekly: 156.98,

                        //     unconfirmedTransactions: 24000,
                        //     transactionsBeingProcessed: 0,
                        //     shareholderSplit: 100,
                        //     confirmedAsDividends: 9436,

                        //     taxDeductedFromPSCIncome: 565.80
                        // };
                    }

                    if (response.data.companyName) {
                        this.companyName = response.data.companyName;
                    }

                    if (response.data.hasUmbrella) {
                        this.hasUmbrella = response.data.hasUmbrella;
                    }
                    
                    if (response.data.umbrellaTaxTracker) {
                        this.umbrellaTaxTracker = response.data.umbrellaTaxTracker;
                    }
                    
                    if (response.data.userInputData) {
                        _.assign(this.userInputData, response.data.userInputData);
                    }
                }

                return response.data;
            });
        }

        getConfirmedAsDividendsTransactions = (): ng.IPromise<Array<GLEntrySummary>> => {
            return this.$http.get('api/PersonalTaxTracker/GetConfirmedAsDividendsTransactions/',
                {
                    params: { offset: this.taxYearOffset }
                }).then(response => response.data);
        }

        getUnconfirmedTransactions = (): ng.IPromise<Array<GLEntrySummary>> => {
            return this.$http.get('api/PersonalTaxTracker/GetUnconfirmedTransactions').then(response => response.data);
        }

        postUserInputData = (): ng.IPromise<boolean> => {
            return this.$http.post('api/PersonalTaxTracker/PostPersonalTaxTrackerUserInputData', this.userInputData, {
                silentMode: true
            }).then(response => response.data);
        }
    }
}

angular
    .module('app.shared')
    .service('personalTaxTrackerSrv', Shared.PersonalTaxTrackerService);
