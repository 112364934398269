/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetExpensesOverview', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.expenses.overview.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetExpensesOverview
    });

    brooksonWidgetExpensesOverview.$inject = ['$timeout', 'expensesSrv', 'brookson.navigation', 'dashboardService'];

    function brooksonWidgetExpensesOverview($timeout, expensesSrv, brooksonNavigation, dashboardService) {
        var vm = this;

        vm.showSpinner = true;
        vm.brooksonNavigation = brooksonNavigation;
        vm.dashboardService = dashboardService;
        vm.widgetName = "brookson-widget-expenses-overview";

        this.$onInit = () => {
            getExpenseOverview();
        };

        function getExpenseOverview() {

            expensesSrv.getExpenseOverView(true).then((response) => {
                $timeout(() => {
                    let chart = c3.generate({
                        bindto: '#expensesOverview',
                        point: {
                            r: 6
                        },
                        data: {
                            x: 'x',
                            columns: [

                            ],
                            types: {
                                Allowances: 'area-spline',
                                Receipted: 'area-spline',
                                Mileage: 'area-spline'
                            },
                            colors: {
                                Allowances: '#FFD132',
                                Receipted: '#449d44',
                                Mileage: '#33ADFF'
                            },
                            names: {
                                Allowances: 'Allowances',
                                Receipted: 'Receipted',
                                Mileage: 'Mileage'
                            }
                        },
                        axis: {
                            x: {
                                tick: {
                                    fit: true,
                                    rotate: 40,
                                    multiline: false
                                },
                                type: 'category',
                                label: {
                                    text: 'Weeks',
                                    position: 'inner-right'
                                }
                            },
                            y: {
                                label: {
                                    text: 'Amount',
                                    position: 'inner-top'
                                },
                                tick: {
                                    format: (d) => { return '£' + d; }
                                }
                            }
                        },
                        tooltip: {
                            format: {
                                value: (value: any) => {
                                    return "£" + d3.format(",.2f")(value);
                                }
                            }
                        },
                        transition: {
                            duration: 1200
                        },
                        padding: {
                            right: 30
                        }
                    });

                    var data = response.chartData;
                    var columns = [];

                    if (data) {
                        var xAxis = ['x'];
                        xAxis = _(xAxis).concat(_.keys(data).map((n) => {
                            return moment(n).format('DD/MM').toString();
                        })).value();

                        var mileage = ['Mileage'];
                        var mileageValues = _.map(data, 'mileageExpensesSum');
                        mileage = <Array<any>>_(mileage).concat(mileageValues).value();

                        var receipted = ['Receipted'];
                        var receiptedValues = _.map(data, 'regularExpensesSum');
                        receipted = <Array<any>>_(receipted).concat(receiptedValues).value();

                        var allowances = ['Allowances'];
                        var allowancesValues = _.map(data, 'allowancesExpensesSum');
                        allowances = <Array<any>>_(allowances).concat(allowancesValues).value();

                        columns.push(xAxis, mileage, receipted, allowances);

                        if (_.sum([_.sum(mileageValues), _.sum(receiptedValues), _.sum(allowancesValues)]) > 0) {
                            chart.load({
                                columns: columns
                            });
                        } else {
                            vm.noData = true;
                        }
                    } else {
                        vm.noData = true;
                    }
                }, 10);
            }, error => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
            });
        }

        function activate() {
            // getExpenseOverview();
        }

        activate();
    }
})();
