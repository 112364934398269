/// <reference path="../../../../app/_all.ts" />
module Shared {
    export class BrooksonWidgetSmeEmployeesOnHoliday implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$timeout", "$window", "$q", "holidaysSrv", "brookson.navigation", "brookson.utilities.date", "dashboardService", BrooksonWidgetSmeEmployeesOnHolidayController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.sme.employees-on-holiday.html";
        }
    }

    export class BrooksonWidgetSmeEmployeesOnHolidayController {
        error: boolean;
        showSpinner: boolean = true;
        onComplete: Function;
        holidayRequests: HolidayRequest[];

        public widgetName: string = "brookson-widget-sme-employees-on-holiday";

        public dashboardService: Shared.IDashboardService = this._dashboardService;

        constructor(
            private $timeout,
            private $window,
            private $q,
            private holidaySrv: IHolidaysSrv,
            private brooksonNavigation: IBrooksonNavigation,
            private dateUtils: IBrooksonUtilitiesDate,
            private _dashboardService: Shared.IDashboardService) { }

        $onInit() {
            this.getActiveHolidayRequests();
        }

        private getActiveHolidayRequests() {
            return this.holidaySrv.getCompanyActiveHolidayRequests()
                .then(data => {
                    this.holidayRequests = data;
                    return data;
                },
                ex => {
                    this.error = true;
                })
                .then(() => {
                    this.showSpinner = false;
                    this.$timeout(() => { this.onComplete(); }, 0);
                });
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetSmeEmployeesOnHoliday", new Shared.BrooksonWidgetSmeEmployeesOnHoliday());
