module Shared {
    export enum AccountType {
        unknown = 0,
        limitedCompany = 1,
        deemed = 99,
        soleTrader = 5,
        umbrella = 3,
    }

    export enum MoleAccountType {
        unknown = 0,
        limitedCompany = 1,
        limitedCompany2 = 2,
        umbrella = 3,
        cis = 4,
        soleTrader = 5,
    }
}
