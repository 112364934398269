module Shared {
    export class DocumentCompact {
        docType: number;
        dataSource: number;
        date: Date;
        name: string;
        path: string;
        documentId: string;
        storedFileName: string;
        originalFileName: string;
        displayName: string;
        folderName: string;
        isInCurrentYear: boolean;
        isFavourite: boolean;
        sharedDriveId: number;
        lastOpened: Date;
        selected: boolean;
        imageData: string;
        imageDataError: boolean;
    }
}