/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonUiAttachReceipt', {
         templateUrl: 'src/app/shared/views/ui/brookson.ui.attach-receipt.html',
         bindings: {
            flow: '=',
            receiptId: '=',
            receiptThumbnail: '=',
            showReceiptBank: '=',
            receiptAttached: '&',
            receiptDeleted: '&',
            dropupClass: '@?',
			isReadonly: '@?'
        },
        controller: brooksonUiAttachReceipt
    });

    brooksonUiAttachReceipt.$inject = ['$scope', '$uibModal', 'brooksonFactoriesExpenses', 'brookson.modal.receipt-bank', 'brookson.modal.receipt-preview', 'receiptSrv', 'brookson.ui.popup'];

    function brooksonUiAttachReceipt($scope, $uibModal, expensesSrv, brooksonModalReceiptBank, brooksonModalReceiptPreview, receiptSrv: Shared.IReceiptsService, brooksonUiPopup: Shared.IBrooksonUiPopup) {
        var vm = this;

        // Data
        var $ctrl = $scope.$ctrl;
        $scope.$flow = $ctrl.flow; // Needs to be $scope so the $flow directive can access it correctly
        vm.receipts = [];

        // Methods
        vm.showAttachReceiptModal = showAttachReceiptModal;
        vm.previewReceipt = previewReceipt;
        vm.deleteReceipt = deleteReceipt;

        // Attach receipt
        function showAttachReceiptModal() {
            if (!vm.receipts.length) {
                expensesSrv.getReceipts().then(data => {
                    vm.receipts = data;

                    _.forEach(vm.receipts, n => {
                        n.isSelected = false;
                    });

                    openAttachReceiptModal(vm.receipts);
                });
            } else {
                _.map(vm.receipts,
                (n: any) => {
                    n.isSelected = false;
                });
                openAttachReceiptModal(vm.receipts);
            }
        }

        function openAttachReceiptModal(receipts) {
            brooksonModalReceiptBank.attachReceipt(receipts, $ctrl.receiptId).then(receipt => {
                if ($ctrl.receiptAttached) {
                    $ctrl.receiptAttached()(receipt);
                }
            });
        }

        // Preview receipt
        function previewReceipt() {
          brooksonModalReceiptPreview.show($ctrl.receiptId);
        }

        function deleteReceipt() {
            $scope.$flow.files = [];

            if ($ctrl.receiptDeleted) {
                $ctrl.receiptDeleted();
            }
        }
    }
})();
