module Shared {
    export interface IReceiptsService {
        getReceipts(silentMode?: boolean) : ng.IPromise<Array<Shared.Receipt>>;
        getReceiptImageData(id: number, size?: Shared.ImageSize, width?: number, height?: number, silentMode?: boolean): ng.IPromise<string>;
        deleteReceipts(receipts: Array<number>): ng.IPromise<boolean>;
        cropReceipt(receiptId: number, imageData: string): ng.IPromise<boolean>;
    }

    export class ReceiptsService implements IReceiptsService {
        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        getReceipts(silentMode?: boolean): ng.IPromise<Array<Shared.Receipt>> {
            return this.$http.get(`api/Receipts/GetReceipts`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.Receipt>>) => {
                return response.data;
            });
        }

        getReceiptImageData(id: number, size?: Shared.ImageSize, width?: number, height?: number, silentMode?: boolean): ng.IPromise<string> {
            const requestData = {
                size: size,
                width: width,
                height: height
            };

            return this.$http.get(`api/Receipts/GetReceiptImageData/${id}`, {
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<string>) => response.data);
        }

        deleteReceipts(receipts: Array<number>): ng.IPromise<boolean> {
            return this.$http.post(`api/Receipts/DeleteReceipts`, receipts)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        cropReceipt(receiptId: number, imageData: string): ng.IPromise<boolean> {
            const requestData = {
                receiptId: receiptId,
                imageData: imageData
            };

            return this.$http.post(`api/Receipts/CropReceipt`, requestData)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }
    }
}

angular
    .module("app.shared")
    .service("receiptSrv", Shared.ReceiptsService);