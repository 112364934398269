/// <reference path="./../BrooksonDate.ts" />

module Shared {
    export module Invoicing {
        export class PaymentUmbrellaCompact {
            memberId: string;
            payslip: string;
            paymentDate: string;
            paymentAmount: number;
            cumulativePayment: number;
        }
    }
}