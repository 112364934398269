/// <reference path="./../AppliedRate.ts" />
/// <reference path="./Agency.ts" />

module Shared {
    export class SaveAssignmentRequest {
        assignment: Shared.SaveAssignment;
        rates: Array<Shared.AppliedRate>;
        agency: Shared.Agency;
    }

    //  Brookson.Connect3.Web.Models.Requests.Assignment.Assignment
    export class SaveAssignment {
        assignmentId: string;
        agencyReference: string;
        assignmentName: string;
        dateStarted: Shared.BrooksonDate;
        dateEnded: Shared.BrooksonDate;
        uniqueId: string;
        preferredEmailAddresses: Array<string>;
        isSelfBilling: boolean;
        publicSector: Sectors;
        publicSectorFromDate: Shared.BrooksonDate;
        jobTitle: string;
        industry: string;
        ir35Capture: boolean;
        ir35AssignmentStatus: Shared.IIR35Captured;
        timesheetFrequencySelector: EnumObj;

        public fromAssignmentCompact = (data: Shared.AssignmentCompact) : this => {
            if (!data) return this;

            this.assignmentId = data.assignmentID;
            this.agencyReference = data.agencyReference;
            this.assignmentName = data.assignmentName;
            this.dateStarted = data.dateStarted;
            this.dateEnded = data.dateEnded;
            this.uniqueId = data.uniqueIdentifierNumber;
            this.preferredEmailAddresses = data.preferredEmailAddresses;
            this.isSelfBilling = data.isSelfBilling;
            this.publicSector = data.publicSector;
            this.publicSectorFromDate = data.publicSectorFromDate;
            this.jobTitle = data.jobTitleOption ? data.jobTitleOption.titleText : '';
            this.industry = data.industry;
            this.ir35Capture = data.ir35Capture;
            this.ir35AssignmentStatus = data.ir35AssessmentCaptured;
            this.timesheetFrequencySelector = data.timesheetFrequency === 0 ? null : data.timesheetFrequency;
            
            return this;
        }
    }
}