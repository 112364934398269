/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/models/open-bankiing-widget/WidgetAccount.ts" />

module Shared {
    export interface IOpenbankingWidgetService {
        getOpenBankingWidgetData(): ng.IPromise<Shared.openbankingWidget.WidgetAccount[]>;
        getGetBankStatementData(): ng.IPromise<Shared.openbankingWidget.WidgetAccount[]>;
        disableWidgetButtons(disableButtons: boolean): ng.IPromise<boolean>;
    }

    export class OpenbankingWidgetService implements IOpenbankingWidgetService {
        static $inject = ['$http', '$q'];
        constructor(private $http: IBrooksonHttp, private $q: ng.IQService) { }

        public getOpenBankingWidgetData = (silentMode?: boolean): ng.IPromise<Shared.openbankingWidget.WidgetAccount[]> => {

            const deferred = this.$q.defer<Shared.openbankingWidget.WidgetAccount[]>();
            const url = "api/ob/GetOpenBankingWidgetData";

            this.$http.get(url, { silentMode: silentMode ? silentMode : false})
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }
        
        public getGetBankStatementData = (silentMode?: boolean): ng.IPromise<Shared.openbankingWidget.WidgetAccount[]> => {

            const deferred = this.$q.defer<Shared.openbankingWidget.WidgetAccount[]>();
            const url = "api/ob/GetBankStatementData";

            this.$http.get(url, { silentMode: silentMode ? silentMode : false})
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });

            return deferred.promise;
        }

        public disableWidgetButtons = (disable: boolean, silentMode?: boolean): ng.IPromise<boolean> => {
            const deferred = this.$q.defer<boolean>();
            const url = "api/ob/DisableWidgetButtons?disable=" + disable;

            this.$http.get(url, { silentMode: silentMode ? silentMode : false })
                .then((response: any): void => {
                    deferred.resolve(response.data);
                });


            return deferred.promise;
        }

    }
}
angular.module("app.shared").service('openbankingWidgetService', Shared.OpenbankingWidgetService);