/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetMoneyManagerMoneyAvailable', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.money-manager.money-available.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetMoneyManagerMoneyAvailable
    });

    brooksonWidgetMoneyManagerMoneyAvailable.$inject = ['$uibModal', '$timeout', '$q', 'moneyManagerSrv', 'brookson.navigation','dashboardService'];

    function brooksonWidgetMoneyManagerMoneyAvailable($uibModal, $timeout, $q, moneyManagerSrv, brooksonNavigation,dashboardService) {
        var vm = this;

        vm.data = moneyManagerSrv;
        vm.showSpinner = true;
        vm.error = false;
        vm.showNewSetupText = false;
        vm.dashboardService = dashboardService;
        vm.widgetName = "brookson-widget-money-manager-money-available";
        vm.brooksonNavigation = brooksonNavigation;
        vm.showUnpaidInvoicesModal = showUnpaidInvoicesModal;
      
        function activate() {
            var moneyManagerFinancialData = moneyManagerSrv.getMoneyManagerFinancialData(true);

            $q.all([moneyManagerFinancialData]).then((data) => {
                vm.showSpinner = false;
                moneyManagerSrv.getLastReconciledDate = '';
                moneyManagerSrv.moneyManagerFinancialData = data[0];
            }, (error) => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); }, 0);
            });
        }

        function showUnpaidInvoicesModal() {
            $uibModal.open({
                animation: true,
                templateUrl: 'src/app/shared/views/modals/brookson.modals.unpaidInvoices.html',
                size: 'md',
                controller: ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.cancel = cancel;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }
                }]
            });
        }

        activate();
    }
})();
