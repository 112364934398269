namespace Shared {
    "use strict";

    export class BrooksonInputExpenseAttach implements ng.IComponentOptions {
        bindings: { [binding: string]: string };
        controller: Function;
        templateUrl: string;
        require: { [controller: string]: string };

        constructor() {
            this.bindings = {
                flow: '=',
                expense: "=",
                index: "=",
                parentController: "=",
                listener: "=",
            };
            this.require = {
            };
            this.controller = BrooksonInputExpenseAttachController;
            this.templateUrl = "src/app/shared/views/inputs/brookson.input.expense-attach.html";
        }
    }

    export class BrooksonInputExpenseAttachController {
        expense: RegularExpense;
        static $inject = ["$scope", "orderByFilter", "brookson.utilities.date", "brooksonFactoriesExpenses"];

        constructor(
            private $scope: ng.IScope,
            private orderByFilter: ng.IFilterOrderBy,
            private dateUtils: IBrooksonUtilitiesDate,
            private expensesFactory: IExpensesFactory
        ) { }
    }
}

angular.module("app.shared")
    .component("brooksonInputExpenseAttach", new Shared.BrooksonInputExpenseAttach());