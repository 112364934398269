module Shared {
    export class IR35AssessmentAnswers {
        participate: boolean;
        jobTitle: string;
        sector: string;
        agencyName: string;
        clientName: string;
        rateOfPay: number;
        employeeOfCurrentClientBeforeContract: boolean;
        sameTypeOfWork: boolean;
        definedProject: string;
        assignmentLengthMonths: number;
        coveringPermanentEmployee: string;
        spokenToHirer: boolean;
        viewOnIr35Status: string;
    }
}