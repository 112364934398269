module Shared {
    export class IntroductionSection {
        accountantName: string;
        introduction1: string;
        businessID: string;
        personID: string;
        createdDate: string;
        section: string;
        subSection: string;
        order: number;
        redCount: number;
        greenCount: number;
        amberCount: number;
        calendlyLink: string;
        showLink: string;
    }
}