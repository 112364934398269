/// <reference path="../../../_all.ts"/>

module Shared {
  export class BrooksonExpensesInputSelectAssignment implements ng.IComponentOptions {
    public bindings: any;
    public templateUrl: any;

    constructor() {
      this.bindings = {
        ngModel: '=',
        ngChange: '=',
        ngDisabled: '=',
        optionsData: '=',
        classes: '@'
      };
      this.templateUrl = 'src/app/shared/views/inputs/expenses.input.select-assignment.html';
    }
  }
}

angular.module('app.shared')
    .component('brooksonExpensesInputSelectAssignment', new Shared.BrooksonExpensesInputSelectAssignment());
