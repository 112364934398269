/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .factory('brookson.ui.scroll-top', brooksonUiScrollTop);

    brooksonUiScrollTop.$inject = ['$window'];

    function brooksonUiScrollTop($window) {
        // Data
        var self = $window;

        // Methods
        var _currentYPosition = () => {
            if (self.pageYOffset)
                return self.pageYOffset;
            if ($window.document.documentElement && $window.document.documentElement.scrollTop)
                return $window.document.documentElement.scrollTop;
            if ($window.document.body.scrollTop)
                return $window.document.body.scrollTop;
            return 0;
        };

        var _smoothScroll = (setSpeed) => {
            var startY = _currentYPosition();
            var stopY = 0;
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                $window.scrollTo(0, stopY);
                return;
            }

            setSpeed = setSpeed ? setSpeed : 1500;

            var speed = Math.round(setSpeed / 100);
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for (var i = startY; i < stopY; i += step) {
                    $window.setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                    leapY += step;
                    if (leapY > stopY) leapY = stopY;
                    timer++;
                }
                return;
            }
            for (var j = startY; j > stopY; j -= step) {
                $window.setTimeout("window.scrollTo(0, " + leapY + ")", timer * speed);
                leapY -= step;
                if (leapY < stopY) leapY = stopY;
                timer++;
            }
        };

        var service = {
            currentYPosition: _currentYPosition,
            smoothScroll: _smoothScroll
        };

        return service;
    }
})();
