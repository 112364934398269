/// <reference path="./../BrooksonDate.ts" />

module Shared {
    export module Invoicing {
        export class TimesheetCompactUmbrella {   
            companyNumber: string;
            timesheetID: string;
            timesheetEndDate: Shared.BrooksonDate;
            timesheetCreated: Shared.BrooksonDate;
            timesheetValue: number;
            timesheetModified: Shared.BrooksonDate;
            agencyName: string;
            inRemittanceApp: boolean;
            remittanceReceived: Shared.BrooksonDate;
            hasRemittanceReceived: boolean;
            fundsReceivedDate: Shared.BrooksonDate;
            payYear: number;
            payPeriod: number;
            payrollStatus: string;
            payrollDate: Shared.BrooksonDate;
            fundsReceived: boolean;
            payrollProcessed: boolean;
            paymentMade: boolean;            
        }
    }
}