/// <reference path="../../../../app/_all.ts" />
/// <reference path="./../../models/InvoicingAssignment.ts" />
/// <reference path="./../../services/brookson.services.employment-status.ts" />
module Shared {
    export class BrooksonWidgetsAssignment implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$filter", "$timeout", "AssignmentSrv", "brookson.navigation", "invoicing.enums", "brookson.utilities.date","dashboardService", BrooksonWidgetsAssignmentController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.assignments.html";
        }
    }

    export class BrooksonWidgetsAssignmentController {
        error: boolean;
        showSpinner: boolean;
        onComplete: Function;
        assignments: Array<AssignmentCompact>;
        public widgetName:string = "brookson-widgets-assignments";
 
        public dashboardService:Shared.IDashboardService = this._dashboardService;
        constructor(private $filter, private $timeout: ng.ITimeoutService, private assignmentSrv: IAssignmentService, private brooksonNavigation: IBrooksonNavigation, private invoicingEnums: IInvoicingEnums, private dateUtils: IBrooksonUtilitiesDate,private _dashboardService:Shared.IDashboardService) {}

        $onInit() {
            this.getAssignments();
        }

        currentAssignmentPressed() {
            this.brooksonNavigation.invoicing.raiseInvoice();
        }

        goToIR35(assignment: AssignmentCompact) {
            this.brooksonNavigation.employmentStatus.ir35Assignment(assignment.assignmentID);
        }

        goToAssignments() {
            this.brooksonNavigation.assignments.main();
        }

        goToAssignmentDetails(assignment: AssignmentCompact) {
            if (assignment) this.brooksonNavigation.assignments.summary(assignment.assignmentID);
            else this.goToAddAssignment();
        }

        goToAddAssignment() {
            this.brooksonNavigation.assignments.addAssignment();
        }

        private getAssignments() {
            this.showSpinner = true;

            this.assignmentSrv.getAllAssignments(true).then((data: Array<CustomerCompact>) => {
                const assignments = _.flatten(_.map(data, (assignment) => {
                    return assignment.assignments;
                }));

                this.assignments = _.filter(assignments, (assignment) => {
                    var dateIsAfterToday = this.dateUtils.isAfter(assignment.dateEnded, moment().toDate());
                    return this.$filter("bkShortDate")(assignment.dateEnded, "DD/MM/YYYY") === "" || dateIsAfterToday;
                });

                this.assignments = _.orderBy(this.assignments, (assignment) => {
                    return new Date(assignment.dateStarted.toString());
                }, "desc");
                

            }, () => {
                this.error = true;
            }).then(() => {
                this.showSpinner = false;
                this.$timeout(() => { this.onComplete(); });
            });
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetsAssignments", new Shared.BrooksonWidgetsAssignment());
