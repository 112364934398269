/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .factory('brookson.file.download', fileDownloadSrv);

    fileDownloadSrv.$inject = ['$http', 'FileSaver', 'Blob', 'growl'];

    function fileDownloadSrv($http, FileSaver, Blob, growl) {
        var service = {
            download: download,
            downloadAndSave: downloadAndSave
        };

        return service;

        function download(url, requestData, fileName, expectedMediaType) {
            var responseType = 'arraybuffer';

            return $http.post(url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': expectedMediaType
                },
                responseType: responseType
            }).then(response => {
                return response;
            });
        }

        function downloadAndSave(url, requestData, fileName, expectedMediaType) {
            download(url, requestData, fileName, expectedMediaType)
                .then(response => openSaveAsDialog(fileName, response.data, expectedMediaType))
                .catch(e => growl.error('We were unable to retrieve your document'));
        }

        function openSaveAsDialog(filename, content, mediaType) {
            var blob = new Blob([content], { type: mediaType });
            FileSaver.saveAs(blob, filename);
        }
    }
})();
