/// <reference path="../../_all.ts" />
module Shared {
    export interface IFinancialCenterService {
        getProfitAndLossSummary(startDate: Date, endDate: Date): ng.IPromise<ProfitAndLossSummary>;
    }

    export class FinancialCenterService implements IFinancialCenterService {

        static $inject = ["$http", "shared.config", "brookson.utilities.date"];

        private serviceBase: any;

        constructor(private $http: ng.IHttpService, private sharedConfig: any, private dateUtils: IBrooksonUtilitiesDate) {
            this.serviceBase = sharedConfig.baseApiUrl;
        }

        getProfitAndLossSummary(startDate: Date, endDate: Date): angular.IPromise<ProfitAndLossSummary> {
            let url = this.serviceBase + "api/FinancialCenter/GetProfitAndLossSummary";

            var requestData = {
                startDate: this.dateUtils.convertToServerString(startDate),
                endDate: this.dateUtils.convertToServerString(endDate)
            };

            return this.$http.get(url, { params: requestData }).then((response: ng.IHttpPromiseCallbackArg<ProfitAndLossSummary>) => response.data);
        }
    }
}

angular.module("app.shared").service("financialCenterSrv", Shared.FinancialCenterService);