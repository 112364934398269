module Shared {
    export function FilterStartFrom() {
        return (input, start) => {
            if (input && _.isArray(input)) {
                start = +start;
                return input.slice(start);
            }

            return [];
        };
    }
}

angular.module("app.shared").filter("startFrom", Shared.FilterStartFrom);