/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var app = angular.module('app.shared');


    app.factory('brookson.pdf.modal', brooksonPdfModal);

    brooksonPdfModal.$inject = ['$uibModal'];
    function brooksonPdfModal($uibModal) {

        var service = {
            view: view
        };

        return service;
        ////////////////
        function view(id, path, callback) {

            $uibModal.open({
                animation: true,
                controller: 'brooksonPdfModalCtrl',
                template: ' <div class="modal-header">' +
                '<h3 class="modal-title pull-left"></h3>' +
                '<a style="color: gray">' +
                '<i class="fa fa-close pull-right add-margin-right-1"style="font-size: 18px"data-ng-click="cancel(); $event.stopPropagation()"></i></a></div>' +
                '<div class="modal-body">' +
                '<ng-pdf template-url="/src/app/shared/views/ui/brookson.ui.pdf-view.html" canvasid="pdf-canvas"  pdfid="' + id + '" pdfpath="' + path + '" scale="page-fit"></ng-pdf>' +
                '</div>',
                size: "lg",
                resolve: {
                    callback: () => {
                        return callback;
                    },
                    documentID: () => {
                        return id;
                    },
                    documentPath: () => {
                        return path;
                    }
                }
            });
        }
    }

    app.controller('brooksonPdfModalCtrl', ['$scope', '$uibModalInstance', 'callback', 'documentID', 'documentPath', 'documentStorageSrv', 'FileSaver',
        ($scope, $uibModalInstance, callback, documentID, documentPath, documentStorageSrv, FileSaver) => {
            $scope.callback = callback;
            $scope.documentID = documentID;

            $scope.documentPath = documentPath;


            $scope.downloadPDF = () => {
                documentStorageSrv.getSelectedPDF(documentID, documentPath).then((data) => {
                    var blobObject = new Blob([data], { type: 'application/pdf' });
                    
                    FileSaver.saveAs(blobObject, documentPath += ".pdf");
                });
            };

            $scope.cancel = () => {
                $uibModalInstance.dismiss('cancel');
            };
        }]);
})();
