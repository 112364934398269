module Shared {
    export module questionnaire{
        export interface IQuestionaireFormItem {
            formId: number;
            formQuestionId: string;
            label: string;
            dataType: string;
            sortOrder: number;
            answer: string;
            otherRightToWorkAnswer: string;
            section: string;
        }

        export class QuestionaireFormItem implements IQuestionaireFormItem {
            formId: number;
            formQuestionId: string;
            label: string;
            dataType: string;
            sortOrder: number;
            answer: string;
            otherRightToWorkAnswer: string;
            section: string;
        }

        export class QuestionnaireSubmission {
            submissionForm: QuestionaireFormItem[];
        }

        export class AuditTrail {
            formId: number;
            submittedBy: string;
        }
        export class QuestionnaireMonthlySalaryPayments {
            year: number;
            connectUser: string;
            paymentDate: string;
            grossPay: number;
            tax: number;
            ni: number;
            net: number;
            empNi: number;
        }
    }
}