/// <reference path="../../_all.ts"/>
/// <reference path="./../models/NavContactInformation.ts"/>
/// <reference path="./../models/NAVPersonalInformation.ts"/>
/// <reference path="./../models/NavCompanyInformation.ts"/>
/// <reference path="./../models/NavBusinessInformation.ts"/>
/// <reference path="./../../shared/services/brookson.services.member.ts"/>
/// <reference path="./../models/NavNationality.ts" />

module Shared {
    export interface IAccountService {
        getAccountDetailsForUser(): ng.IPromise<NavContactInformation>;
        updateAccountDetailsForUser(personalInformation: NavPersonalInformation, password: string): ng.IPromise<boolean>;
        getNationalities(silentMode?: boolean): ng.IPromise<Array<NAVNationality>>;
        getCommonPasswords(silentMode?: boolean): ng.IPromise<Array<string>>;

        company: NavCompanyInformation;
        personalInformation: NavPersonalInformation;
    }

    export class AccountService implements IAccountService {
        member: Shared.MemberModel;
        personalInformation: NavPersonalInformation;
        businessInformation: NavBusinessInformation;
        company: NavCompanyInformation;

        static $inject = ['$http', 'memberSrv'];

        constructor(private $http: Shared.IBrooksonHttp, private memberSrv: Shared.IMemberService) {
            this.member = memberSrv.getMember();
        }

        getAccountDetailsForUser = (): ng.IPromise<NavContactInformation> => {
            return this.$http.get('api/Account/GetAccountDetailsForUser').then((response: ng.IHttpPromiseCallbackArg<NavContactInformation>) => {
                if (response.data) {
                    this.personalInformation = response.data.personalInformation;
                    this.businessInformation = response.data.businessInformation;
                    this.company = _.find(response.data.businessInformation.companies, (company: NavCompanyInformation) => {
                        return company.companyReference === this.member.companyRef;
                    });
                    _.each(this.company.bankAccounts, (bankAccount: Shared.BankAccount) => {
                        bankAccount.bankName = bankAccount.bankName.toLowerCase();
                    });
                }

                return response.data;
            });
        }

        updateAccountDetailsForUser = (personalInformation: NavPersonalInformation, password: string): ng.IPromise<boolean> => {
            return this.$http.post('api/Account/UpdateAccountDetailsForUser', {
                personalInformation: personalInformation,
                password: password
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        getNationalities = (silentMode?: boolean): ng.IPromise<Array<NAVNationality>> => {
            return this.$http.get('api/Account/GetNationalities', {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<NAVNationality>>) => {
                return response.data;
            });
        }

        getCommonPasswords = (silentMode?: boolean): ng.IPromise<Array<string>> => {
            return this.$http.get('api/Account/common-passwords', {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<string>>) => {
                return response.data;
            });
        }
    }
}

angular
    .module('app.shared')
    .service('accountSrv', Shared.AccountService);