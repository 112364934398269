
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class InvoicingAssignmentRate {

        // RATETYPES
        public rateTypes: string[] = [];
        // ASSIGNMENTRATEID
        public assignmentRateID: number = 0;
        // RATE
        public rate: number = 0;
        // DESCRIPTION
        public description: string = null;
        // CURRENCY
        public currency: Currencies = null;
        // RATEPERIOD
        public ratePeriod: RatePeriods = null;
        // RATETYPE
        public rateType: string = null;
        // PAYMENTCODE
        public paymentCode: string = null;
        // STARTDATE
        public startDate: Date = null;
        // ENDDATE
        public endDate: Date = null;
        public chargableDays: number = 0;
        public chargableTotal: number = 0;
        public totalHoursWorked: number = 0;
    }

    export enum Currencies {
        GBP = 10,
        EUR = 20,
        USD = 30,
        YEN = 60,
        AUD = 70,
        CAD = 80,
        NOK = 90,
        ZAR = 100,
        SEK = 110,
        CHF = 120,
        AED = 130
    }

    export enum RatePeriods {
        Hour,
        Day,
        Week
    }
}
