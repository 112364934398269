/// <reference path="../../_all.ts"/>
/// <reference path="./../models/ExpenseTotals.ts" />

module Shared {
    export interface IExpensesService {
        getMileageRates(silentMode?: boolean): ng.IPromise<Array<Shared.MileageExpenseRateLookup>>;
        getExpenses(pageSize?: number, pageNo?: number): ng.IPromise<Array<Shared.ExpenseHeader>>;
        saveExpenses(expenses: Shared.SaveExpenseRequest, silentMode?: boolean, hideGrowl?: boolean): ng.IPromise<Shared.ExpenseDetails>;
        deleteExpense(expenseSubmissionId: number): ng.IPromise<boolean>;
        submitExpense(id: number, source: Shared.ExpenseDataSource, acceptMissingReceiptsExpenses?: RegularExpense[]): ng.IPromise<boolean>;
        getExpenseDetails(expenseSubmissionId: number, dataSource: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails>;
        expenseReport(id: number, source: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails>;
        getExpenseOverView(silentMode?: boolean): ng.IPromise<Shared.ExpensesOverview>;
        getExpenseTypes(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseType>>;
        getExpenseWarnings(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseWarnings>>;
        getExpenseWarningTypes(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseWarningTypes>>;
        getPublicSectorExpenseTypes(silentMode?: boolean): ng.IPromise<Array<ExpenseType>>;
        getExpenseVehicles(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseMileageVehicle>>;
        getExpenseVehiclesLookup(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseMileageVehicle>>;
        saveExpenseVehicle(vehicle: Shared.ExpenseMileageVehicle): ng.IPromise<boolean>;
        getTotalMileageForCurrentTaxYear(silentMode?: boolean): ng.IPromise<number>;
        getTotalMileageForPreviousTaxYear(silentMode?: boolean): ng.IPromise<number> ;
        getPersonalMileageAndTotalExpenses(): ng.IPromise<Shared.ExpenseTotals>;
        getExpenseGuidancePaymentCodes(): ng.IPromise<Array<string>>;
        removeWarningConfirmationsByExpenseId(expenseSubmissionId: number): ng.IPromise<boolean>;
    }

    export class ExpensesService implements IExpensesService {

        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        getMileageRates(silentMode?: boolean): ng.IPromise<Array<Shared.MileageExpenseRateLookup>> {
            var requestData = {
                effectiveDate: new Date()
            };

            return this.$http.get("api/Expenses/GetMileageRateLookup",
                {
                    params: requestData,
                    silentMode: silentMode ? silentMode : false
                }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.MileageExpenseRateLookup>>) => response.data);
        }

        getExpenses(pageSize: number, pageNo: number): ng.IPromise<Array<Shared.ExpenseHeader>> {
            const requestData = {
                pageSize: pageSize,
                pageNo: pageNo
            };

            return this.$http.get("api/Expenses/GetExpenses",
                {
                    params: requestData
                }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseHeader>>) => response.data);
        }

        getExpenseGuidancePaymentCodes(): ng.IPromise<Array<string>> {
            return this.$http.get("api/Expenses/GetExpenseGuidancePaymentCodes")
                .then((response: ng.IHttpPromiseCallbackArg<Array<string>>) => response.data);
        }

        getPersonalMileageAndTotalExpenses(): ng.IPromise<Shared.ExpenseTotals> {

            return this.$http.get("api/Expenses/GetPersonalMileageAndTotalExpenses")
                .then((response: ng.IHttpPromiseCallbackArg<Shared.ExpenseTotals>) => response.data);
        }

        saveExpenses(expenses: Shared.SaveExpenseRequest, silentMode?: boolean, hideGrowl?: boolean): ng.IPromise<Shared.ExpenseDetails> {
            expenses.hideGrowl = hideGrowl;

            return this.$http.post("api/Expenses/SaveExpenses", expenses, {
                silentMode: silentMode
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.ExpenseDetails>) => response.data);
        }

        deleteExpense(expenseSubmissionId: number): ng.IPromise<boolean> {
            return this.$http.delete(`api/Expenses/DeleteExpense/${expenseSubmissionId}`)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        submitExpense(id: number, source: any, acceptMissingReceiptsExpenses?: RegularExpense[]): ng.IPromise<boolean> {
            var request = {
                id: id,
                source: source,
                acceptMissingReceiptsExpenses: acceptMissingReceiptsExpenses
            };

            return this.$http.post("api/Expenses/SubmitExpenses", request)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        getExpenseDetails(expenseSubmissionId: number, dataSource: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails> {
            var requestData = {
                id: expenseSubmissionId,
                source: dataSource
            };

            return this.$http.get("api/Expenses/GetExpenseDetails", {
                params: requestData
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.ExpenseDetails>) => response.data);
        }

        expenseReport(id: number, source: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails> {
            const requestData = {
                id: id,
                source: source
            };

            return this.$http.get(`api/Expenses/GetExpenseReport`, {
                params: requestData
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.ExpenseDetails>) => response.data);
        }

        getExpenseOverView(silentMode?: boolean): ng.IPromise<Shared.ExpensesOverview> {
            return this.$http.get(`api/Expenses/GetExpenseOverview`,
                {
                    silentMode: silentMode ? silentMode : false
                }).then((response: ng.IHttpPromiseCallbackArg<Shared.ExpensesOverview>) => response.data);
        }

        getExpenseTypes(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseType>> {
            return this.$http.get(`api/Expenses/GetExpenseTypes/`)
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseType>>) => response.data);
        }

        getExpenseWarnings(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseWarnings>> {
            return this.$http.get('api/Expenses/GetExpenseWarnings/')
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseWarnings>>) => response.data);
        }

        getExpenseWarningTypes(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseWarningTypes>> {
            return this.$http.get('api/Expenses/GetExpenseWarningTypes/')
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseWarningTypes>>) => response.data);
        }

        getPublicSectorExpenseTypes(silentMode?: boolean): ng.IPromise<Array<ExpenseType>> {
            return this.$http.get("api/Expenses/GetPublicSectorExpenseTypes/")
                .then((response: ng.IHttpPromiseCallbackArg<Array<ExpenseType>>) => response.data);
        }

        getExpenseVehicles(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseMileageVehicle>> {
            return this.$http.get(`api/Expenses/GetExpenseVehicles/`)
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseMileageVehicle>>) => response.data);
        }
        
        getExpenseVehiclesLookup(silentMode?: boolean): ng.IPromise<Array<Shared.ExpenseMileageVehicle>> {
            return this.$http.get(`api/Expenses/GetExpenseVehiclesLookup/`)
                .then((response: ng.IHttpPromiseCallbackArg<Array<Shared.ExpenseMileageVehicle>>) => response.data);
        }
        
        saveExpenseVehicle(vehicle: Shared.ExpenseMileageVehicle): ng.IPromise<boolean> {
            return this.$http.post("api/Expenses/SaveExpenseVehicle", vehicle).then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        getTotalMileageForCurrentTaxYear(silentMode?: boolean): ng.IPromise<number> {
            const requestData = {
                effectiveDate: new Date()
            };
            return this.$http.get(`api/Expenses/GetMileageTotal`, {
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<number>) => {
                return response.data;
            });
        }

        getTotalMileageForPreviousTaxYear(silentMode?: boolean): ng.IPromise<number> {
            const requestData = {
                effectiveDate: moment(new Date()).add(-1, 'year').toDate()
            };
            return this.$http.get(`api/Expenses/GetMileageTotal`, {
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<number>) => {
                return response.data;
            });
        }

        removeWarningConfirmationsByExpenseId(expenseSubmissionId: number): ng.IPromise<boolean> {
            return this.$http.delete(`api/Expenses/DeleteWarningConfirmation/${expenseSubmissionId}`)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }
    }
}

angular
    .module("app.shared")
    .service("expensesSrv", Shared.ExpensesService);