/// <reference path="../../_all.ts"/>
/// <reference path="./../models/InvoicingAssignment.ts"/>
/// <reference path="./../models/CustomerCompact.ts"/>
/// <reference path="./../models/PaymentCode.ts"/>
/// <reference path="./../modules/brookson.utilities.date.ts"/>
/// <reference path="./../models/TimesheetFrequencyDetails.ts"/>
/// <reference path="../modules/brookson.enums.ts" />

module Shared {
    export interface IAssignmentService {
        publicOrPrivateAssignment: Array<Shared.ISectors>;
        ir35CapturedAssignment: Array<Shared.IIR35Captured>;
        timesheetFrequency: Array<Shared.ITimesheetFrequency>;
        paymentCodes: Array<PaymentCode>;
        
        getAssignment(assignmentRef: string): ng.IPromise<InvoicingAssignment>;
        getAssignments(silentMode?: boolean): ng.IPromise<Array<InvoicingAssignment>>;
        getPaymentCodes(): ng.IPromise<Array<PaymentCode>>;
        getPaymentCodeValue(paymentCode: string): string;
        getAllAssignments(silentMode?: boolean): ng.IPromise<Array<CustomerCompact>>;
        getAssignmentsForInvoiceCrediting(invoiceId: any, silentMode?: boolean): ng.IPromise<Array<InvoicingAssignment>>;
        closeAssignment(id: string): ng.IPromise<boolean>;
        reopenAssignment(id: number): ng.IPromise<boolean>;
        getTimesheetFrequencyDetails(): ng.IPromise<TimesheetFrequencyDetails>;
    }

    export class AssignmentSrv implements IAssignmentService {
        private serviceBase: string;
        public publicOrPrivateAssignment: Array<Shared.ISectors>;
        public ir35CapturedAssignment: Array<Shared.IIR35Captured>;
        public timesheetFrequency: Array<Shared.ITimesheetFrequency>;
        public paymentCodes: Array<PaymentCode>;

        static $inject = ['$http', 'shared.config', "brookson.utilities.date", "invoicing.enums"];

        constructor(
            private $http: ng.IHttpService,
            private sharedConfig: any,
            private dateUtils: IBrooksonUtilitiesDate,
            private invoicingEnums: Shared.IInvoicingEnums
        ) {
            this.serviceBase = sharedConfig.baseApiUrl;

            let publicOrPrivateAssignment = this.invoicingEnums.Sectors.properties;
            delete publicOrPrivateAssignment['2'];
            this.publicOrPrivateAssignment = publicOrPrivateAssignment;

            this.ir35CapturedAssignment = this.invoicingEnums.Ir35Captured.properties;           
            this.timesheetFrequency = this.invoicingEnums.TimesheetFrequency.properties;           
        }

        getAssignment(assignmentRef: string): ng.IPromise<InvoicingAssignment> {
            let url = this.serviceBase + 'api/Assignment/GetAssignment/' + assignmentRef;
            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<InvoicingAssignment>) => {
                if (response.data.publicSectorFromDate) {
                    response.data.publicSectorFromDate = this.dateUtils.convertToJsDate(response.data.publicSectorFromDate);
                }
                return response.data;
            });
        }

        getAssignments(silentMode?: boolean, umbrellaExpenses?: boolean): ng.IPromise<Array<InvoicingAssignment>> {
            let url = this.serviceBase + 'api/Assignment/GetAssignments?umbrellaExpenses=' + umbrellaExpenses;
            let params = {
                silentMode: silentMode ? silentMode : false
            };
            return this.$http.get(url, params).then((response: ng.IHttpPromiseCallbackArg<Array<InvoicingAssignment>>) => {
                _.forEach(response.data,
                    (assignment: InvoicingAssignment) => {
                        if (assignment.publicSectorFromDate) {
                            assignment.publicSectorFromDate = this.dateUtils.convertToJsDate(assignment.publicSectorFromDate);
                        }
                    });
                return response.data;
            });
        }

        getAssignmentsForFullInvoice(silentMode?: boolean, umbrellaExpenses?: boolean): ng.IPromise<Array<InvoicingAssignment>> {
            let url = this.serviceBase + 'api/Assignment/GetAssignmentsForFullInvoice?umbrellaExpenses=' + umbrellaExpenses;
            let params = {
                silentMode: silentMode ? silentMode : false
            };
            return this.$http.get(url, params).then((response: ng.IHttpPromiseCallbackArg<Array<InvoicingAssignment>>) => {
                _.forEach(response.data,
                    (assignment: InvoicingAssignment) => {
                        if (assignment.publicSectorFromDate) {
                            assignment.publicSectorFromDate = this.dateUtils.convertToJsDate(assignment.publicSectorFromDate);
                        }
                    });
                return response.data;
            });
        }

        getPaymentCodes(): ng.IPromise<Array<PaymentCode>> {
            let url = this.serviceBase + 'api/Assignment/getPaymentCodes';
            //return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<Array<PaymentCode>>) => response.data);
            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<Array<PaymentCode>>) => {
                this.paymentCodes = response.data;
                return this.paymentCodes;
            });
        }

        getPaymentCodeValue(paymentCode: string): string {
            let paymentCodeObj = _.find(this.paymentCodes, (paymentCodeIndex) => {
                return paymentCodeIndex.code === paymentCode;
            });

            return (paymentCodeObj) ? paymentCodeObj.description : '';
        }

        getTimesheetFrequencyDetails(): ng.IPromise<TimesheetFrequencyDetails> {
            let url = this.serviceBase + 'api/Assignment/GetTimesheetFrequencyDetails';
            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<TimesheetFrequencyDetails>) => response.data);
        }

        getAllAssignments(silentMode?: boolean): ng.IPromise<Array<CustomerCompact>> {
            const url = this.serviceBase + 'api/Assignment/GetCustomers';
            const config = { silentMode: silentMode ? silentMode : false };
            return this.$http.get(url, config).then((response: ng.IHttpPromiseCallbackArg<Array<CustomerCompact>>) => {
                _.forEach(response.data,
                    customer => {
                        _.forEach(customer.assignments,
                            assignment => {
                                if (assignment.publicSectorFromDate) {
                                    assignment.publicSectorFromDate = new Date(assignment.publicSectorFromDate);
                                }
                            });
                    });
                return response.data;
            });
        }

        getAssignmentsForInvoiceCrediting(invoiceId: any): ng.IPromise<Array<InvoicingAssignment>> {
            var id = invoiceId.replace(":","-");
            let url = this.serviceBase + 'api/Assignment/GetAssignmentsForInvoiceCrediting?invoiceId=' + id;
            const config = { silentMode: false };
            return this.$http.get(url, config).then((response: ng.IHttpPromiseCallbackArg<Array<InvoicingAssignment>>) => {
                _.forEach(response.data,
                    (assignment: InvoicingAssignment) => {
                        if (assignment.publicSectorFromDate) {
                            assignment.publicSectorFromDate = this.dateUtils.convertToJsDate(assignment.publicSectorFromDate);
                        }
                    });
                return response.data;
            });
        }

        closeAssignment(id: string): ng.IPromise<boolean> {
            let url = this.serviceBase + 'api/Assignment/CloseAssignment';
            let data = {
                assignmentId: id
            };
            return this.$http.put(url, data).then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }

        reopenAssignment(id: number): ng.IPromise<boolean> {
            let url = this.serviceBase + 'api/Assignment/ReopenAssignment';
            let data = {
                assignmentId: id
            };
            return this.$http.put(url, data).then((response: ng.IHttpPromiseCallbackArg<boolean>) => response.data);
        }
    }
}

angular.module('app.shared').service('AssignmentSrv', Shared.AssignmentSrv);
