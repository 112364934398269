/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.http.interceptor", ['$q', '$injector', brooksonHttpInterceptor])
        .config(['$httpProvider', httpInterceptorConfig]);

    function brooksonHttpInterceptor($q, $injector) {
        var numRequests = 0;
        var showing = false;
        var previousResponseErrors = [];

        var hide = (r) => {
            if (r && !r.config.silentMode && !--numRequests) {
                NProgress.done(true);
                showing = false;
            }

            return r;
        };

        return {
            'request': (config) => {
                if (!config.silentMode) {
                    numRequests++;
                    if (!showing) {
                        showing = true;
                        NProgress.start();
                    }
                }

                return config;
            },

            'response': (response) => {
                return hide(response);
            },

            'responseError': (response) => {
                if (response && response.status !== 401 && response.data.error !== 'invalid_grant') {
                    var previousResponseError = _.find(previousResponseErrors, (previousError) => {
                        return previousError.config.url === response.config.url &&
                            previousError.method === response.method &&
                            previousError.status === response.status;
                    });

                    if (!previousResponseError) {
                        var logger = $injector.get('brookson.logger');

                        if (response.config.data != null && response.config.data.password != null) {
                            angular.forEach(response.config.data.password, (item, key) => {
                                // substitute all passwords enterd by the user before logging
                                response.config.data.password[key] = "********";
                            });
                        }

                        logger.error("HTTP Error: " + response.config.url, response);

                        previousResponseErrors.push(response);
                    }
                }

                return $q.reject(hide(response));
            }
        };
    }

    function httpInterceptorConfig($httpProvider) {
        $httpProvider.interceptors.push("brookson.http.interceptor");
    }
})();
