/// <reference path="../../../../app/_all.ts" />
module Shared {
    export class BrooksonWidgetsSmeLastPayslip implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: "&"
            };

            this.controller = ["$timeout", "$q", "brookson.navigation", "brookson.utilities.date", "dashboardService", BrooksonWidgetsSmeLastPayslipController];
            this.templateUrl = "src/app/shared/views/widgets/brookson.widgets.sme.last-payslip.html";
        }
    }

    export class BrooksonWidgetsSmeLastPayslipController {
        error: boolean = false;
        showSpinner: boolean = true;
        onComplete: Function;

        public widgetName: string = "brookson-widget-sme-last-payslip";

        public dashboardService: Shared.IDashboardService = this._dashboardService;
        constructor(private $timeout, private $q, private brooksonNavigation: IBrooksonNavigation, private dateUtils: IBrooksonUtilitiesDate, private _dashboardService: Shared.IDashboardService) { }

        $onInit() {
            this.onComplete();
            this.showSpinner = false;
        }
    }
}

angular
    .module("app.shared")
    .component("brooksonWidgetSmeLastPayslip", new Shared.BrooksonWidgetsSmeLastPayslip());
