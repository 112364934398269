
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class UserInputData  {

        // PERSONALTAXTRACKERDATAID
        public personalTaxTrackerDataId: number = 0;
        // YEAR
        public year: string = null;
        // OTHEREMPLOYMENTINCOME
        public otherEmploymentIncome: number = 0;
        // OTHEREMPLOYMENTINCOMEYTD
        public otherEmploymentIncomeYTD: number = 0;
        // PENSIONINCOME
        public pensionIncome: number = 0;
        // PENSIONINCOMEYTD
        public pensionIncomeYTD: number = 0;
        // INTERESTINCOME
        public interestIncome: number = 0;
        // INTERESTINCOMEYTD
        public interestIncomeYTD: number = 0;
        // TAXABLEBENEFITSINKIND
        public taxableBenefitsInKind: number = 0;
        // TAXABLEBENEFITSINKINDYTD
        public taxableBenefitsInKindYTD: number = 0;
        // OTHERDIVIDENDINCOME
        public otherDividendIncome: number = 0;
        // OTHERDIVIDENDINCOMEYTD
        public otherDividendIncomeYTD: number = 0;
        // PERSONALPENSIONPAYMENTS
        public personalPensionPayments: number = 0;
        // PERSONALPENSIONPAYMENTSYTD
        public personalPensionPaymentsYTD: number = 0;
        // CHILDBENEFITAMOUNTRECEIVED
        public childBenefitAmountReceived: number = 0;
        // CHILDBENEFITAMOUNTRECEIVEDYTD
        public childBenefitAmountReceivedYTD: number = 0;
        // STUDENTLOANBALANCEATBEGINNINGOFTAXYEAR
        public studentLoanBalanceAtBeginningOfTaxYear: number = 0;
        // STUDENTLOANBALANCEATBEGINNINGOFTAXYEARYTD
        public studentLoanBalanceAtBeginningOfTaxYearYTD: number = 0;
        // TAXALREADYPAID
        public taxAlreadyPaid: number = 0;
        // PAYMENTONACCOUNTPAIDJANUARY
        public paymentOnAccountPaidJanuary: number = 0;
        // PAYMENTONACCOUNTPAIDJULY
        public paymentOnAccountPaidJuly: number = 0;
        // DIVIDENDS
        public dividends: number = 0;
    }
}
