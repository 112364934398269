
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class GLEntrySummary  {
        
        // ENTRYNO
        public entryNo: number = 0;
        // GLACCOUNTNO
        public glAccountNo: string = null;
        // BALACCOUNTNO
        public balAccountNo: string = null;
        // DESCRIPTION
        public description: string = null;
        // DESCRIPTION2
        public description2: string = null;
        // AMOUNT
        public amount: number = 0;
        // POSTINGDATE
        public postingDate: Date = null;
        // DOCUMENTDATE
        public documentDate: Date = null;
        // PERIODSTART
        public periodStart: Date = null;
        // PERIODEND
        public periodEnd: Date = null;
    }
}
