/// <reference path="./AccountType.ts"/>
/// <reference path="./EnumObj.ts"/>
/// <reference path="./PaymentFrequency.ts"/>
/// <reference path="./PayPersonBureau.ts"/>

module Shared {
    export class TimesheetFrequencyDetails  {
        
        public payPersonBureau: PayPersonBureau;

        public payPersonBureauDescription: string = null;

        public timesheetFrequencyStatus: PaymentFrequency;

        public timesheetFrequencies: PaymentFrequency[] = [];

        public timesheetFrequencyDescription: string = null;

        public timesheetFrequencyOptions: PaymentFrequency[] = [];

        public timesheetFrequencyOptionsList: Array<EnumObj>;
    }
}
