/// <reference path="../../_all.ts"/>
/// <reference path="./../models/Assignment.ts" />
/// <reference path="./../models/SaveExpensesRequest.ts" />
/// <reference path="./../models/ExpenseHeader.ts" />
/// <reference path="./../models/ExpenseType.ts" />
/// <reference path="./../models/RegularExpense.ts" />
/// <reference path="./../models/MileageExpense.ts" />
/// <reference path="./../models/Receipt.ts" />
/// <reference path="./../models/ExpenseDetails.ts" />
/// <reference path="./../models/ExpenseDataSource.ts" />
/// <reference path="./../models/VatRateConfig.ts" />
/// <reference path="./../models/ExpensesOverview.ts" />
/// <reference path="./../models/MileageExpenseRateLookup.ts" />
/// <reference path="./../models/BrooksonDate.ts" />
/// <reference path="./../models/ExpenseTotals.ts" />
/// <reference path="./../models/VatRate.ts" />
/// <reference path="./../enums/ImageSize.ts" />
/// <reference path="./../modules/brookson.enums.ts" />
/// <reference path="./../interfaces/brookson.interfaces.window.ts" />
/// <reference path="./../services/brookson.services.receipts.ts" />
/// <reference path="./../services/brookson.services.member.ts" />
/// <reference path="./../services/brookson.services.expenses.ts" />
/// <reference path="./../modules/brookson.utilities.date.ts"/>
/// <reference path="./../models/ExpenseWarnings.ts"/>
/// <reference path="./../services/brookson.services.expense-warnings.ts"/>
/// <reference path="./../models/ExpenseWarningTypes.ts"/>
/// <reference path="./../enums/ExpenseWarningCodeTypes.ts"/>
/// <reference path="./../enums/ExpenseTypeRef.ts"/>
/// <reference path="./../models/AppSettings.ts"/>

module Shared {
    export interface IExpensesFactory {
        editMode: boolean;
        selectedAssignment: Shared.Assignment;
        assignments: Array<Shared.Assignment>;
        selectedVehicle: Shared.ExpenseMileageVehicle;
        expenseLineCounter: number;
        expenseHeader: Shared.ExpenseHeader;
        expenseTypes: Array<Shared.ExpenseType>;
        expenseGuidancePaymentCodes: Array<string>;
        expenseWarnings: Array<Shared.ExpenseWarnings>;
        expenseWarningTypes: Array<Shared.ExpenseWarningTypes>;
        publicSectorExpenseTypes: Array<ExpenseType>;
        mileageVehicles: Array<Shared.ExpenseMileageVehicle>;
        mileageVehiclesLookup: Array<Shared.ExpenseMileageVehicle>;
        vehicleFuelTypes: Shared.IExpensesEnums;
        regularExpenses: Array<Shared.RegularExpense>;
        mileageExpenses: Array<Shared.MileageExpense>;
        allowanceExpenses: Array<Shared.RegularExpense>;
        receipts: Array<Shared.Receipt>;
        totalMileageForCurrentTaxYear: number;
        totalMileageForPreviousTaxYear: number;
        restrictMileage: boolean;
        restrictMileageMaxDate: Shared.BrooksonDate;
        maxDate: moment.Moment;
        refreshRequired: boolean;
        isAutoSaving: boolean;
        expensesWaitingForIds: Array<Shared.CoreExpenseItem>;
        copyMode: boolean;
        copyModeValidateMileage: boolean;
        copyModeValidateRegular: boolean;
        copyModeValidateAllowance: boolean;
        showMileageVatableExpenses: boolean;
        confirmedExpenseWarning: Array<Shared.ExpenseWarning>;
        expensesWarningsConfirmed: Array<ConfirmedExpenseWarning>;

        getExpenses(pageSize?: number, pageNo?: number): ng.IPromise<Array<Shared.ExpenseHeader>>;
        getExpenseWarningText(expenseWarningId: number, expenseWarningCodeTypeId: number): any;
        getExpenseWarning(expense: Shared.ExpenseType): Shared.ExpenseWarnings;
        getPersonalMileageAndTotalExpenses(): ng.IPromise<Shared.ExpenseTotals>;
        getExpenseGuidancePaymentCodes(): ng.IPromise<Array<string>>;
        addNewExpenseLineData(data: any): void;
        mapRatesToMileageExpenseTypes(rates: any): void;
        getExpenseTypes(): ng.IPromise<Array<Shared.ExpenseType>>;
        getExpenseWarnings(): ng.IPromise<Array<Shared.ExpenseWarnings>>;
        deleteIndividualConfirmedExpenseLine(warningCodeValue: string): ng.IPromise<Boolean>;
        getExpenseWarningTypes(): ng.IPromise<Array<Shared.ExpenseWarningTypes>>;
        getPublicSectorExpenseTypes(): ng.IPromise<Array<ExpenseType>>;
        getExpenseVehicles(): ng.IPromise<Array<Shared.ExpenseMileageVehicle>>;
        getExpenseVehiclesLookup(): ng.IPromise<Array<Shared.ExpenseMileageVehicle>>;
        saveExpenseVehicle(request: Shared.ExpenseMileageVehicle): ng.IPromise<boolean>;
        saveExpenses(request: Shared.SaveExpenseRequest, silentMode?: boolean, hideGrowl?: boolean): ng.IPromise<Shared.ExpenseDetails>;
        deleteExpense(expenseSubmissionId: number): ng.IPromise<boolean>;
        submitExpense(id: number, source: Shared.ExpenseDataSource, acceptMissingReceiptsExpenses?: RegularExpense[]): ng.IPromise<boolean>;
        generateGUID(): string;
        removeWarningConfirmationsByExpenseId(expenseSubmissionId: number): ng.IPromise<boolean>;
        mapExpenseDetails(data: Shared.ExpenseDetails);
        getExpenseDetails(expenseSubmissionId: number, dataSource: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails>;
        getTotalMileageForCurrentTaxYear(refresh?: boolean): ng.IPromise<number>;
        getTotalMileageForPreviousTaxYear(refresh?: boolean): ng.IPromise<number>;
        getReceipts(silentMode?: boolean): ng.IPromise<Array<Receipt>>;
        getReceiptImageData(id: number, size?: Shared.ImageSize, width?: number, height?: number, silentMode?: boolean): ng.IPromise<string>;
        getReceiptImageThumbnailData(receiptId: number): ng.IPromise<string>;
        deleteReceipts(receipts: Array<number>): ng.IPromise<boolean>;
        cropReceipt(receiptId: number, imageData: string): ng.IPromise<boolean>;
        resetReceiptStatus(receiptId: number): void;
        expenseReport(id: number, source: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails>;
        createReceiptFlowFactory(): flowjs.IFlowOptions;
        getExpenseOverView(silentMode?: boolean): ng.IPromise<Shared.ExpensesOverview>;
        mileageExpensesTotal(): number;
        regularExpensesTotal(): number;
        allowanceExpensesTotal(): number;
        receiptsTotal(): number;
        receiptAttached(expense: Shared.RegularExpense, listener: Function): void;
        deleteReceiptFromExpenseLine(expense: Shared.RegularExpense): void;
        prepareSaveExpense(mileageExpenses?: Array<Shared.MileageExpense>, regularExpenses?: Array<Shared.RegularExpense>, allowanceExpenses?: Array<Shared.RegularExpense>): Shared.SaveExpenseRequest;
        prepareMileageExpense(expenseItem: Shared.MileageExpense) : void;
        prepareAllowanceExpense(expenseItem: Shared.RegularExpense) : void;
        prepareRegularExpense(expenseItem: Shared.RegularExpense): void;
        getAllVatRates(silentMode?: boolean): ng.IPromise<Array<VatRate>>;
        getAllVatRatesConfig(silentMode?: boolean): ng.IPromise<Array<VatRateConfig>>;
        isDeemedAssignment(assignment: Shared.Assignment): boolean;
        grossPaymentReceived(assignment: Shared.Assignment): boolean;
        saveConfirmedExpenseWarning(assignmentId: string, memberId: string, expenseHeaderId: number, expenseWarnings: ExpenseWarning[]): ng.IPromise<boolean>;
        getConfirmedExpenseWarnings(memberid, assignmentId): ng.IPromise<Shared.ConfirmedExpenseWarning[]>;
        addExpenseWarningMessage(expenseWarning: ExpenseWarnings, expenseLine: Shared.CoreExpenseItem): void;
        removeExpenseWarnings(expenseWarning: ExpenseWarnings, expenseLine: Shared.RegularExpense): void;
        removeConfirmedExpense(expense: any): ng.IPromise<Boolean>;
        getExpenseWarningType(expense: Shared.ExpenseType): Shared.ExpenseWarnings;
        populateConfirmedExpenseWarnings(memberid: number, assignmentId: string): ng.IPromise<Shared.ConfirmedExpenseWarning[]>;
        diffMonths(dt1, dt2): number;
        getLatestConfirmedExpense(expenseWarning: Shared.ExpenseWarnings, warningCodeType: ExpenseWarningCodeTypes): Shared.ConfirmedExpenseWarning;
        setExpenseWarningMessage(expenseLine: Shared.CoreExpenseItem, expenseWarning: Shared.ExpenseWarnings, expenseWarningCodeTypes: ExpenseWarningCodeTypes): void;
        showGuidance(paymentCode: string): ng.IPromise<any>;
        expenseGuidanceExists(expenseType: any): boolean;
        buildPresentedExpenseWarnings(): void;
        tabExpenseWarningCount(tabName: string): number;
        confirmExpenseWarning(expenseLine: Shared.CoreExpenseItem): void;
        confirmExpenseWarningTwentyFour(expenseLine: Shared.CoreExpenseItem): void;
        expenseTypeChanged(expense: Shared.ExpenseType, index: number, expenseList: any): void;
        autoSaveExpenses(oldVal: Array<Shared.RegularExpense>, newVal: Array<Shared.RegularExpense>, expenseType: ExpenseTypeRef): void;
        updateWarningsAssignmentChange(expenses: Shared.CoreExpenseItem[]): void;
        showExpenseWarningModal(): ng.IPromise<any>;
        clearConfirmedExpenseWarnings();
    }

    export class ExpensesFactory implements IExpensesFactory {
        public vehicleFuelTypes: IExpensesEnums;
        public selectedVehicle: Shared.ExpenseMileageVehicle;
        public editMode: boolean = false;
        public selectedAssignment: Shared.Assignment = null;
        public assignments: Array<Shared.Assignment> = [];

        public expenseLineCounter: number = 0;

        public expenseHeader: Shared.ExpenseHeader = new ExpenseHeader();

        public expenseTypes: Array<Shared.ExpenseType> = [];
        public expenseGuidancePaymentCodes: Array<string> = [];
        public expenseWarnings: Array<Shared.ExpenseWarnings> = [];
        public expenseWarningTypes: Array<Shared.ExpenseWarningTypes> = [];
        public publicSectorExpenseTypes: Array<ExpenseType> = [];
        public mileageVehicles: Array<Shared.ExpenseMileageVehicle> = [];
        public mileageVehiclesLookup: Array<Shared.ExpenseMileageVehicle> = [];
        public regularExpenses: Array<Shared.RegularExpense> = [];
        public mileageExpenses: Array<Shared.MileageExpense> = [];
        public allowanceExpenses: Array<Shared.RegularExpense> = [];
        public receipts: Array<Shared.Receipt> = [];
        public totalMileageForCurrentTaxYear: number = 0;
        public totalMileageForPreviousTaxYear: number = 0;
        public restrictMileage: boolean = false;
        public restrictMileageMaxDate: Date = null;
        public maxDate: moment.Moment = moment().add(7, "days");
        public refreshRequired: boolean = false;
        public isAutoSaving: boolean = false;
        public expensesWaitingForIds: Array<Shared.CoreExpenseItem> = [];
        public showMileageVatableExpenses: boolean = false;
        public confirmedExpenseWarning: ExpenseWarning[];
        public expensesWarningsConfirmed: ConfirmedExpenseWarning[];
        private expensesTabWarningSummary: ExpenseTabWarnings;
        public lastRegularExpenseVal: Array<any> = [];

        /**
         * Property to store whether the user is cloning an expense form
         * 
         * @type {boolean}
         * @memberOf ExpensesFactory
         */
        public copyMode: boolean = false;

        /**
         * These validation properties are used as the validation looks for whether
         * the form is dirty and invalid, however if the user is cloning an expense,
         * the form is prepopulated. The form may not have been rendered so the validation
         * has nothing to check against.
         *  */
        public copyModeValidateMileage: boolean = false;
        public copyModeValidateRegular: boolean = false;
        public copyModeValidateAllowance: boolean = false;

        static $inject = ["$http", "$q", "$window", "expensesSrv", "receiptSrv", "memberSrv", "flowFactory", "expenses.enums", "brookson.utilities.date", "currency.enums", "shared.config", "expensesWarningsService", "$uibModal", "localStorageService", "appSettings"];

        constructor(
            private $http: any,
            private $q: ng.IQService,
            private $window: Window,
            private expensesSrv: Shared.IExpensesService,
            private receiptSrv: Shared.IReceiptsService,
            private memberSrv: Shared.IMemberService,
            private flowFactory: any,
            private expensesEnums: Shared.IExpensesEnums,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            private currencyEnums: Shared.ICurrencyCodes,
            private sharedConfig: any,
            private expensesWarningsService: Shared.IExpenseWarningsService,
            private $uibModal: ng.ui.bootstrap.IModalService,
            private localStorageService: ng.local.storage.ILocalStorageService,
            private appSettings: Shared.AppSettings
        ) {
            if (!this.confirmedExpenseWarning) this.clearConfirmedExpenseWarnings();

            this.expensesWarningsConfirmed = new Array<ConfirmedExpenseWarning>();
            this.getExpenseWarnings().then(data => {
                this.expenseWarnings = data;
            });
            this.getExpenseWarningTypes().then(data => {
                this.expenseWarningTypes = data;
            });
            this.getExpenseGuidancePaymentCodes().then(data => {
                this.expenseGuidancePaymentCodes = data;
            });
        }

        getExpenses(pageSize: number, pageNo: number): ng.IPromise<Array<Shared.ExpenseHeader>> {
            return this.expensesSrv.getExpenses(pageSize, pageNo);
        }

        getPersonalMileageAndTotalExpenses(): ng.IPromise<Shared.ExpenseTotals> {
            return this.expensesSrv.getPersonalMileageAndTotalExpenses();
        }

        addNewExpenseLineData(data: any): void {
            var newId = this.generateGUID();

            data.push({
                markedAsDelete: false,
                tempID: newId
            });
        }

        mapRatesToMileageExpenseTypes(rates: Array<Shared.MileageExpenseRateLookup>): void {
            var mileageExpenseTypes =
                _.filter(this.expenseTypes, eType => (eType.category === this.expensesEnums.ExpenseTypeCategory.MILEAGE));

            _.forEach(mileageExpenseTypes, eType => {
                eType.rates = _.filter(rates, (rate: Shared.MileageExpenseRateLookup) => (rate.paymentCode === eType.paymentCode));
            });
        }

        getExpenseTypes(): ng.IPromise<Array<Shared.ExpenseType>> {
            var deferred = this.$q.defer();

            if (this.expenseTypes.length === 0) {
                return this.expensesSrv.getExpenseTypes().then((data: Array<Shared.ExpenseType>) => {
                    this.expenseTypes = data;
                    return this.expensesSrv.getMileageRates();
                }).then(data => {
                    this.mapRatesToMileageExpenseTypes(data);
                    return this.expenseTypes;
                });
            } else {
                deferred.resolve(this.expenseTypes);
            }

            return deferred.promise;
        }

        getExpenseWarnings(): ng.IPromise<Array<Shared.ExpenseWarnings>> {
            var deferred = this.$q.defer();

            if (this.expenseWarnings.length === 0) {
                return this.expensesSrv.getExpenseWarnings()
                    .then((data: Array<Shared.ExpenseWarnings>) => {
                        this.expenseWarnings = data;
                        return this.expenseWarnings; 
                    }).then(data => {
                        this.expenseWarnings = data;
                        return this.expenseWarnings;
                    });
            } else {
                deferred.resolve(this.expenseWarnings);
            }

            return deferred.promise;
        }

        getExpenseWarningText = (expenseWarningId: number, expenseWarningCodeTypeId: number): any => {
            let expenseType = _.find(this.expenseWarningTypes, (n: any) => {
                return n.expenseWarningId === expenseWarningId && n.expenseWarningCodeTypeId === expenseWarningCodeTypeId;
            });

            return expenseType;
        }

        getExpenseWarningTypes(): ng.IPromise<Array<Shared.ExpenseWarningTypes>> {
            var deferred = this.$q.defer();

            if (this.expenseWarningTypes.length === 0) {
                return this.expensesSrv.getExpenseWarningTypes()
                    .then((data: Array<Shared.ExpenseWarningTypes>) => {
                        this.expenseWarningTypes = data;
                        return this.expenseWarningTypes;
                    }).then(data => {
                        this.expenseWarningTypes = data;
                        return this.expenseWarningTypes;
                    });
            } else {
                deferred.resolve(this.expenseWarningTypes);
            }

            return deferred.promise;
        }

        getPublicSectorExpenseTypes(): ng.IPromise<Array<ExpenseType>> {
            var deferred = this.$q.defer();

            if (this.publicSectorExpenseTypes.length === 0) {
                return this.expensesSrv.getPublicSectorExpenseTypes().then((data: Array<ExpenseType>) => {
                    this.publicSectorExpenseTypes = data;
                    return this.publicSectorExpenseTypes;
                });
            } else {
                deferred.resolve(this.publicSectorExpenseTypes);
            }

            this.getExpenseVehicles();
            return deferred.promise;
        }

        getExpenseVehicles(): ng.IPromise<Array<Shared.ExpenseMileageVehicle>> {
            return this.expensesSrv.getExpenseVehicles().then((data: Array<Shared.ExpenseMileageVehicle>) => {
                this.mileageVehicles = data;
                return data;
            });
        }

        getExpenseGuidancePaymentCodes(): ng.IPromise<Array<string>> {
            return this.expensesSrv.getExpenseGuidancePaymentCodes().then((data: Array<string>) => {
                this.expenseGuidancePaymentCodes = data;
                return data;
            });
        }

        getExpenseVehiclesLookup(): ng.IPromise<Array<Shared.ExpenseMileageVehicle>> {
            return this.expensesSrv.getExpenseVehiclesLookup().then((data: Array<Shared.ExpenseMileageVehicle>) => {
                this.mileageVehiclesLookup = data;
                return data;
            });
        }

        saveExpenseVehicle(request: Shared.ExpenseMileageVehicle): ng.IPromise<boolean> {
            return this.expensesSrv.saveExpenseVehicle(request);
        }
         
        saveExpenses(request: Shared.SaveExpenseRequest, silentMode?: boolean, hideGrowl?: boolean): ng.IPromise<Shared.ExpenseDetails> {
            if (this.isAutoSaving) {
                var defered = this.$q.defer();
                defered.resolve(undefined);
                return defered.promise;
            }

            this.isAutoSaving = true;
            this.buildPresentedExpenseWarnings();
            return this.expensesSrv.saveExpenses(request, silentMode, hideGrowl).then((data: Shared.ExpenseDetails) => {
                var updatedExpenseDetails = this.mapExpenseDetails(data);
                return updatedExpenseDetails;
            }).then((data) => this.postSaveExpenses(request, data));
        }

        deleteExpense(expenseSubmissionId: number): ng.IPromise<boolean> {
            return this.expensesSrv.deleteExpense(expenseSubmissionId);
        }

        submitExpense(id: number, source: Shared.ExpenseDataSource, acceptMissingReceiptsExpenses?: RegularExpense[]): ng.IPromise<boolean> {
            return this.expensesSrv.submitExpense(id, source, acceptMissingReceiptsExpenses);
        }

        generateGUID(): string {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        mapExpenseDetails(data: Shared.ExpenseDetails) {
            if (!_.isNil(data)) {
                this.expenseHeader = data.expenseHeader;

                _.map(data.regularExpenses, (expense: Shared.RegularExpense) => {
                    expense.markedAsDelete = expense.isDeleted;

                    var expenseType = _.find(this.expenseTypes, eType => (eType.expenseTypeId === expense.expenseTypeId));

                    expense.typeObject = expenseType;
                    expense.date = this.dateUtils.convertToJsDate(expense.date);
                });

                _.map(data.mileageExpenses, (expense: Shared.MileageExpense) => {
                    expense.markedAsDelete = expense.isDeleted;

                    var expenseType = _.find(this.expenseTypes, eType => (eType.expenseTypeId === expense.expenseTypeId));
                    var expenseMileageVehicle = _.find(this.mileageVehicles, mVehicles => (mVehicles.registration === expense.vehicleRegistration));
                    if(this.mileageVehicles.length === 1)
                    {
                        expenseMileageVehicle = this.mileageVehicles[0];
                    }
                    expense.typeObject = expenseType;
                    expense.vehicleObject = expenseMileageVehicle;
                    expense.date = this.dateUtils.convertToJsDate(expense.date);

                    // If the expense is an older one from Connect2, it won't have a tempID set, so we make one so that it won't duplicate the expenseItemIDs later in the code [and then the backend ignores the dupes bar 1]
                    if (expense.tempID === "00000000-0000-0000-0000-000000000000") {
                        expense.tempID = this.generateGUID();
                    }

                });

                _.map(data.allowanceExpenses, (expense: Shared.RegularExpense) => {
                    expense.markedAsDelete = expense.isDeleted;

                    var expenseType = _.find(this.expenseTypes, eType => (eType.expenseTypeId === expense.expenseTypeId));

                    expense.typeObject = expenseType;
                    expense.date = this.dateUtils.convertToJsDate(expense.date);
                });
            }

            return data;
        }

        removeWarningConfirmationsByExpenseId(expenseSubmissionId: number): ng.IPromise<boolean> {
            return this.expensesSrv.removeWarningConfirmationsByExpenseId(expenseSubmissionId);
        }

        getExpenseDetails(expenseSubmissionId: number, dataSource: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails> {
            return this.expensesSrv.getExpenseDetails(expenseSubmissionId, dataSource).then((data) => {
                if (data) {
                    this.mapExpenseDetails(data);

                    this.expenseHeader = data.expenseHeader;

                    if (data.mileageExpenses.length > 0) {
                        this.mileageExpenses = data.mileageExpenses;
                    }

                    if (data.allowanceExpenses.length > 0) {
                        this.allowanceExpenses = data.allowanceExpenses;
                    }

                    if (data.regularExpenses.length > 0) {
                        this.regularExpenses = data.regularExpenses;
                    }
                }
                return data;
            });
        }

        getTotalMileageForCurrentTaxYear(refresh?: boolean): ng.IPromise<number> {
            if (!this.totalMileageForCurrentTaxYear || (refresh === true) || this.refreshRequired) {
                return this.expensesSrv.getTotalMileageForCurrentTaxYear();
            } else {
                var defered = this.$q.defer();
                defered.resolve(this.totalMileageForCurrentTaxYear);
                return defered.promise;
            }
        }

        getTotalMileageForPreviousTaxYear(refresh?: boolean): ng.IPromise<number> {
            if (!this.totalMileageForPreviousTaxYear || (refresh === true) || this.refreshRequired) {
                return this.expensesSrv.getTotalMileageForPreviousTaxYear();
            } else {
                var defered = this.$q.defer();
                defered.resolve(this.totalMileageForPreviousTaxYear);
                return defered.promise;
            }
        }

        getReceipts(silentMode?: boolean): ng.IPromise<Array<Receipt>> {
            return this.receiptSrv.getReceipts(silentMode).then((data) => {
                this.receipts = data;

                _.forEach(this.receipts, receipt => {
                    // The status value of the receipt may change through the application so we
                    // need to keep a record of the orignal value
                    receipt.statusOriginalValue = receipt.status;

                    // If the user has added an expense before getReceipts is called, they may have regular expenses with a receipt attached,
                    // we need to go through and check whether the receiptId has been assigned to an expense.
                    var isAttachedToUnsavedExpense = _.filter(this.regularExpenses, (expense: Shared.RegularExpense) => (expense.receiptId === receipt.receiptId && !expense.expenseID)).length > 0;

                    if (isAttachedToUnsavedExpense) {
                        receipt.status = this.expensesEnums.ReceiptStatus.UNSUBMITTED;
                    }
                });

                return data;
            });
        }


        getExpenseWarning = (expense: Shared.ExpenseType): Shared.ExpenseWarnings => {
            if (expense) {
                let expenseType = _.filter(this.expenseWarnings,
                    (n: Shared.ExpenseWarnings) => {
                        return n.codeValue.trim().toString() === expense.paymentCode.trim().toString();
                    });

                return expenseType[0];
            }
            return null;
        }

        getReceiptImageData(id: number, size?: Shared.ImageSize, width?: number, height?: number, silentMode?: boolean): ng.IPromise<string> {
            return this.receiptSrv.getReceiptImageData(id, size, width, height, silentMode);
        }

        getReceiptImageThumbnailData(receiptId: number): ng.IPromise<string> {
            return this.getReceiptImageData(receiptId, Shared.ImageSize.CUSTOM, 50, 50).then(data => data);
        }

        deleteReceipts(receipts: Array<number>): ng.IPromise<boolean> {
            return this.receiptSrv.deleteReceipts(receipts);
        }

        cropReceipt(receiptId: number, imageData: string): ng.IPromise<boolean> {
            return this.receiptSrv.cropReceipt(receiptId, imageData);
        }

        resetReceiptStatus(receiptId: number): void {
            var oldReceipt = _.find(this.receipts, (receipt: any) => (receipt.receiptId === receiptId));

            if (oldReceipt) {
                oldReceipt.status = this.expensesEnums.ReceiptStatus.UNATTACHED;
            }
        }

        expenseReport(id: number, source: Shared.ExpenseDataSource): ng.IPromise<Shared.ExpenseDetails> {
            return this.expensesSrv.expenseReport(id, source);
        }

        createReceiptFlowFactory(): flowjs.IFlowOptions {
            return this.flowFactory.create({
                target: "/api/Receipts/Upload",
                permanentErrors: [404, 500, 501],
                headers: {
                    "__RequestVerificationToken": this.$window.brookson.antiForgeryToken,
                    "Accept": "application/json"
                },
                testChunks: false,
                simultaneousUploads: 1,
                chunkSize: 9007199254740992
            });
        }

        getExpenseOverView(silentMode?: boolean): ng.IPromise<Shared.ExpensesOverview> {
            return this.expensesSrv.getExpenseOverView(silentMode);
        }

        mileageExpensesTotal(): number {
            return _.filter(this.mileageExpenses, (expense: Shared.MileageExpense) => (expense.markedAsDelete !== true && expense.description && expense.date && expense.gross && expense.miles && expense.rate && expense.typeObject)).length;
        }

        regularExpensesTotal(): number {
            return _.filter(this.regularExpenses, (expense: Shared.RegularExpense) => (expense.markedAsDelete !== true && expense.date && expense.description && expense.gross && expense.typeObject)).length;
        }

        allowanceExpensesTotal(): number {
            return _.filter(this.allowanceExpenses, (expense: Shared.RegularExpense) => (expense.markedAsDelete !== true && expense.date && expense.description && expense.gross && expense.typeObject)).length;
        }

        receiptsTotal(): number {
            return _.filter(this.receipts, (receipt: any) => (receipt.status === this.expensesEnums.ReceiptStatus.UNATTACHED)).length;
        }

        receiptAttached = (expense: Shared.RegularExpense, listener: Function) => {
            this.deactivateAutoSave(listener);
            return receipt => {
                // If the expense already has an assigned receiptId, set the status of the receipt to unattached
                if (expense.receiptId) {
                    this.resetReceiptStatus(expense.receiptId);
                }

                receipt.status = this.expensesEnums.ReceiptStatus.UNSUBMITTED;
                receipt.isSelected = false;
                expense.expenseType = expense.typeObject;
                receipt.expense = expense;
                expense.receiptId = receipt.receiptId;
                expense.receiptThumbnail = receipt.imageData;
            };
        }

        /**
         * Deletes an attached receipt from the expense line
         * 
         * @param {Shared.RegularExpense} expense
         * 
         * @memberOf BrooksonExpensesAddExpenseController
         */
        deleteReceiptFromExpenseLine = (expense: Shared.RegularExpense): void => {
            var receipt = _.find(this.receipts, (n: Shared.Receipt) => (n.receiptId === expense.receiptId));

            receipt.status = this.expensesEnums.ReceiptStatus.UNATTACHED;

            expense.receiptId = 0;
        }

        /**
         * Deactive the wtacher for auto save
         * 
         * 
         * @memberOf BrooksonExpensesAddExpenseController
         */
        deactivateAutoSave = (listener: Function): void => {
            if (!_.isNil(listener)) {
                listener();
            }
        }

        prepareSaveExpense(mileageExpenses: Array<Shared.MileageExpense> = [], regularExpenses: Array<Shared.RegularExpense> = [], allowanceExpenses: Array<Shared.RegularExpense> = []): Shared.SaveExpenseRequest {
            var request = new Shared.SaveExpenseRequest();
            request.expenseSubmissionId = this.expenseHeader ? this.expenseHeader.id : 0;
            request.description = this.expenseHeader ? this.expenseHeader.description : this.selectedAssignment
                ? this.selectedAssignment.assignmentName
                : this.expenseHeader.assignment;
            request.assignmentId = this.selectedAssignment
                ? this.selectedAssignment.assignmentId
                : this.expenseHeader.assignmentId;
            request.assignmentName = this.selectedAssignment
                ? this.selectedAssignment.assignmentName
                : this.expenseHeader.assignment;
            request.mainSiteDateTime = this.selectedAssignment
                ? this.dateUtils.convertToServerString(this.selectedAssignment.dateStarted)
                : this.expenseHeader.startDate;
            request.endDateTime = this.selectedAssignment
                ? this.dateUtils.convertToServerString(this.selectedAssignment.dateEnded)
                : null;
            request.mileageExpenseItems = mileageExpenses;
            request.expenseItems = regularExpenses;
            request.allowanceItems = allowanceExpenses;
            request.dataSource = 1;
            request.isDeleted = false;

            return request;
        }

        prepareMileageExpense = (expenseItem: Shared.MileageExpense) : void => {
            expenseItem.isDeleted = expenseItem.markedAsDelete;

            if (expenseItem.typeObject) {
                expenseItem.workSite = expenseItem.typeObject.offsite ? 'Off Site' : 'Main Site';
                expenseItem.type = expenseItem.typeObject.expenseTypeId;
                expenseItem.net = expenseItem.gross;
                expenseItem.vat = 0;
                expenseItem.date = this.dateUtils.convertToServerString(expenseItem.date);
                if(expenseItem.vehicleObject !== undefined && expenseItem.vehicleObject !== null)
                {
                    expenseItem.vehicleRegistration = expenseItem.vehicleObject.registration;
                    expenseItem.expenseMileageVehicle = expenseItem.vehicleObject;
                }
            }
        }
        
        prepareAllowanceExpense = (expenseItem: Shared.RegularExpense) : void => {
            if (expenseItem.typeObject) {
                expenseItem.type = expenseItem.typeObject.expenseTypeId;
                expenseItem.net = expenseItem.gross;
                expenseItem.date = this.dateUtils.convertToServerString(expenseItem.date);
            }

            delete expenseItem.flow;
            delete expenseItem.flowOptions;
        }

        prepareRegularExpense = (expenseItem: Shared.RegularExpense) : void => {
            if (expenseItem && expenseItem.typeObject) {
                if (!expenseItem.typeObject.expenseReceiptAttachable) {
                    expenseItem.receiptId = 0;
                    expenseItem.receiptThumbnail = "";
                }

                if (expenseItem.typeObject.defaultExpenseRate > 0) {
                    expenseItem.quantity = expenseItem.total / expenseItem.typeObject.defaultExpenseRate;
                }

                expenseItem.type = expenseItem.typeObject.expenseTypeId;
                expenseItem.net = expenseItem.gross - (expenseItem.vat ? expenseItem.vat : 0);
                expenseItem.date = this.dateUtils.convertToServerString(expenseItem.date);

                delete expenseItem.flow;
                delete expenseItem.flowOptions;
            }
        }

        getAllVatRates = (silentMode?: boolean): ng.IPromise<Array<VatRate>> => {
            const url = this.sharedConfig.baseApiUrl + "api/Expenses/GetAllVatRates";

            return this.$http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return _.map(response.data,
                    (item: any) => {
                        let rate = new VatRate();
                        rate.amount = item.amount;
                        rate.startDate = new Date(item.startDate);
                        return rate;
                    });
            });
        }

        getAllVatRatesConfig = (silentMode?: boolean): ng.IPromise<Array<VatRateConfig>> => {
            const url = this.sharedConfig.baseApiUrl + "api/Expenses/GetExpenseVatConfig";

            return this.$http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return _.map(response.data,
                    (item: any) => {
                        let rate = new VatRateConfig();
                        rate.expenseCode = item.expenseCode;
                        rate.vatRate = item.vatRate;
                        rate.effectiveDate = new Date(item.effectiveDate);
                        rate.endDate = new Date(item.endDate);
                        return rate;
                    });
            });
        }

        private postSaveExpenses(request: Shared.SaveExpenseRequest, response: Shared.ExpenseDetails): Shared.ExpenseDetails {
            if (_.isNil(response)) {
                this.isAutoSaving = false;
                return;
            }

            this.removeDeletedExpenses(this.mileageExpenses, request.mileageExpenseItems);
            this.removeDeletedExpenses(this.regularExpenses, request.expenseItems);
            this.removeDeletedExpenses(this.allowanceExpenses, request.allowanceItems);
            this.removeExpensesThatHaveBeenAssignedTempIds();
            this.assignExpenseItemIds(response);

            this.isAutoSaving = false;

            return response;
        }

        /**
         * Removes any expenses that were marked as deleted and have been deleted from the database.
         * It checks the requested expenses that were sent up to the API to make sure that the expense has been
         * deleted.
         * 
         * @private
         * @param {Array<Shared.CoreExpenseItem>} savedExpenses
         * @param {Array<Shared.CoreExpenseItem>} requestedExpenses
         * 
         * @memberOf ExpensesFactory
         */
        private removeDeletedExpenses(savedExpenses: Array<Shared.CoreExpenseItem>, requestedExpenses: Array<Shared.CoreExpenseItem>): void {
            _.remove(savedExpenses, (ex: Shared.CoreExpenseItem) =>
                ex.markedAsDelete && (_.find(requestedExpenses, (e: Shared.CoreExpenseItem) => e.tempID === ex.tempID))
            );
        }

        /**
         * Removes expenses in the expenses waiting for ids array that have been assigned a tempID
         * 
         * @private
         * 
         * @memberOf ExpensesFactory
         */
        private removeExpensesThatHaveBeenAssignedTempIds(): void {
            _.remove(this.expensesWaitingForIds,
                (e: Shared.CoreExpenseItem) => {
                    var expenses = [...this.mileageExpenses, ...this.regularExpenses, ...this.allowanceExpenses];

                    var relatedExpense = _.find(expenses, (re: Shared.RegularExpense) => {
                        return e.tempID === re.tempID;
                    });

                    return !_.isNil(relatedExpense);
                });
        }

        /**
         * Assign the generated expense item ids to the associated expense items based on the tempID
         * 
         * @private
         * @param {Shared.ExpenseDetails} response
         * 
         * @memberOf ExpensesFactory
         */
        private assignExpenseItemIds(response: Shared.ExpenseDetails): void {
            var newExpenses = _.filter([...this.mileageExpenses, ...this.regularExpenses, ...this.allowanceExpenses], (expense: Shared.RegularExpense) => !expense.expenseItemId);
            var savedExpenses = [...response.mileageExpenses, ...response.regularExpenses, ...response.allowanceExpenses];

            _.forEach(newExpenses, expense => {
                var relatedExpense: Shared.CoreExpenseItem = _.find(savedExpenses, (e: Shared.RegularExpense) => e.tempID === expense.tempID);

                if (relatedExpense) {
                    expense.expenseItemId = relatedExpense.expenseItemId;
                }
            });
        }

        isDeemedAssignment = (assignment: Shared.Assignment): boolean => {
            if (assignment.publicSector !== Shared.Sectors.public && !_.isNull(assignment.iR35CapturedFromDate)) {
                return true;
            }
            return false;
        }

        grossPaymentReceived = (assignment: Shared.Assignment): boolean => {
            if (assignment.publicSector === Shared.Sectors.public
                && _.isNull(assignment.iR35CapturedFromDate)
                && _.isNull(assignment.iR35CapturedEffectiveDate)
            ) {
                return true;
            }
            return false;
        }

        saveConfirmedExpenseWarning(assignmentId: string, memberId: string, expenseHeaderId: number, expenseWarnings: ExpenseWarning[]): ng.IPromise<boolean> {
            return this.expensesWarningsService.saveExpenseWarnings(assignmentId, memberId, expenseHeaderId, expenseWarnings);
        }

        getConfirmedExpenseWarnings(memberid, assignmentId): ng.IPromise<ConfirmedExpenseWarning[]> {
            return this.expensesWarningsService.getConfirmedExpenseWarnings(memberid, assignmentId);
        }

        addExpenseWarningMessage(expenseWarning: ExpenseWarnings, expenseLine: Shared.CoreExpenseItem): void {
            let expenseWarningType = this.getExpenseWarningText(expenseWarning.expenseWarningId, this.expensesEnums.ExpenseWarningCodeTypes.Amount);
            if (expenseWarningType) {
                expenseLine.expenseWarning.warningMessage = expenseWarningType.warningMessage;
                expenseLine.expenseWarning.showWarningAmount = expenseLine.gross > expenseWarning.valueLimit;
                expenseLine.expenseWarning.warning = expenseWarning;
            }
        }

        removeExpenseWarnings(expenseWarning: ExpenseWarnings, expenseLine: Shared.RegularExpense): void {
            let exp = _.find(this.regularExpenses, (e: Shared.RegularExpense) => e.description !== expenseLine.description && e.expenseWarning.warning === expenseWarning);
            if (exp) {
                exp.expenseWarning = new Shared.ExpenseWarning();
            }
        }

        removeConfirmedExpense(expense: any): angular.IPromise<Boolean> {
            let defer = this.$q.defer();
            if (!expense.expenseWarning || !expense.expenseWarning.warning) return defer.promise;
            var expenseConfirmation = _
                .filter<any>(this.confirmedExpenseWarning, n => (n.warning.codeValue === expense.expenseWarning.warning.codeValue));

            if (expenseConfirmation.length > 0) {
                for (let i = 0; i < expenseConfirmation.length; i++) {
                    let index = this.confirmedExpenseWarning.indexOf(expenseConfirmation[i]);
                    this.confirmedExpenseWarning.splice(index, 1);
                }
                
            }
            return defer.promise;
        }

        checkExpenseConfirmationLength = (expense: Shared.CoreExpenseItem, monthRule: boolean): any => {
            if (expense) {

                let warningType;
                if (expense.typeObject)
                    warningType = this.getExpenseWarningType(expense.typeObject);

                if (!warningType) return false;
                if (this.confirmedExpenseWarning.length === 0 && this.expensesWarningsConfirmed.length === 0) return false;

                if (this.confirmedExpenseWarning.length > 0) {
                    let confirmedExpenses = _.find(this.confirmedExpenseWarning, (n: ExpenseWarning) => {
                        return n.warning.codeValue.trim().toString() === warningType.codeValue.trim().toString() &&
                            ((n.confirmedTwentyFour && monthRule) || (n.confirmed && !monthRule));
                    });

                    if (confirmedExpenses) {
                        return true;
                    }
                }

                if (this.expensesWarningsConfirmed.length > 0) {
                    let expenseWarning = this.getLatestConfirmedExpense(warningType, monthRule ? Shared.ExpenseWarningCodeTypes.TwentyFourMonthRule : Shared.ExpenseWarningCodeTypes.Amount);

                    if (expenseWarning) {
                        let datediff = this.diffMonths(new Date(), expenseWarning.dateConfirmed);
                        if (monthRule) {
                            if (expense.expenseWarning && expenseWarning.dateConfirmed)
                                return !(datediff >= warningType.timeLength);
                        } else {
                            if (expense.expenseWarning && expenseWarning.dateConfirmed) {
                                if (datediff >= warningType.timeLength) {
                                    if (warningType.highRisk) {
                                        return false;
                                    }
                                    if (expense.gross >= warningType.valueLimit) {
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                    return false;
                }
            }
            return false;
        }

        public diffMonths = (dt1, dt2): number => {
            return moment(dt1).diff(moment(dt2), "months", false);
        }

        getExpenseWarningType = (expense: Shared.ExpenseType): Shared.ExpenseWarnings => {
            let expenseType = _.filter(this.expenseWarnings, (n: Shared.ExpenseWarnings) => {
                return n.codeValue.trim().toString() === expense.paymentCode.trim().toString();
            });

            return expenseType[0];
        }

        populateConfirmedExpenseWarnings(memberid: number, assignmentId: string): angular.IPromise<ConfirmedExpenseWarning[]> {
            let defer = this.$q.defer();
            this.getConfirmedExpenseWarnings(memberid, assignmentId)
                .then(data => {
                    this.expensesWarningsConfirmed = data;
                    defer.resolve(this.expensesWarningsConfirmed);
                });

            return defer.promise;            
        }

        deleteIndividualConfirmedExpenseLine(warningCodeValue: string): angular.IPromise<Boolean> {
            let defer = this.$q.defer();
            
            if (this.expensesWarningsConfirmed.length > 0) {
                let warnings = _.filter(this.expensesWarningsConfirmed,
                    cWarning => (cWarning.expenseCodeValue === warningCodeValue &&
                        cWarning.expenseId === this.expenseHeader.id.toString()));

                if (warnings.length > 0) {
                    _.forEach(warnings,
                        confirmedExpenseWarning => {
                            this.expensesWarningsService
                                .deleteExpenseWarningLogById(confirmedExpenseWarning.expenseWarningLogId);
                        });
                    this.populateConfirmedExpenseWarnings(parseInt(this.memberSrv.member.memberId),
                        this.selectedAssignment.assignmentId);
                    defer.resolve(true);
                }
            } else {
                defer.resolve(false);
            }

            return defer.promise;
        }

        getLatestConfirmedExpense(expenseWarning: Shared.ExpenseWarnings, warningCodeType: Shared.ExpenseWarningCodeTypes): Shared.ConfirmedExpenseWarning {
            let expenseWarnings = _.filter(this.expensesWarningsConfirmed,
            (e: Shared.ConfirmedExpenseWarning) => {

                switch (warningCodeType) {
                    case Shared.ExpenseWarningCodeTypes.TwentyFourMonthRule:
                        return e.expenseCodeValue.trim() === expenseWarning.codeValue.trim() && e.twentyFourMonthRuleApplied;
                    default:
                        return e.expenseCodeValue.trim() === expenseWarning.codeValue.trim() && !e.twentyFourMonthRuleApplied;
                }
            });
            return expenseWarnings.sort((first : ConfirmedExpenseWarning, second : ConfirmedExpenseWarning): number => {
                if (first.dateConfirmed > second.dateConfirmed) return -1;
                if (first.dateConfirmed < second.dateConfirmed) return 1;
                return 0;
            })[0];
        }

        setExpenseWarningMessage(expenseLine: Shared.CoreExpenseItem, expenseWarning: Shared.ExpenseWarnings, expenseWarningCodeTypes: Shared.ExpenseWarningCodeTypes): void {
            let expenseWarningType = this.getExpenseWarningText(expenseWarning.expenseWarningId, expenseWarningCodeTypes);
            if (expenseWarningType) {
                expenseLine.expenseWarning.warning = expenseWarning;
                expenseLine.expenseWarning.warningMessage = expenseWarningType.expenseWarningText.replace('(i)', '<a data-ng-click="$ctrl.data.showGuidance(\'' + expenseLine.typeObject.paymentCode + '\')"><i class="fa fa-info-circle guidance-icon-blue" aria-hidden="true"></i></a>');
            } else {
                expenseLine.expenseWarning = new Shared.ExpenseWarning();
            }
        }

        showGuidance = (paymentCode: string): ng.IPromise<any> => {
            var sectionId = _.includes(paymentCode, 'MILEAGE') ? 'MILEAGE' :
                _.includes(paymentCode, 'OVERNIGHT') ? 'OVERNIGHT' :
                paymentCode;

            let modalSettings: angular.ui.bootstrap.IModalSettings = {
                animation: true,
                templateUrl: "src/app/expenses/views/modals/brookson.modals.view-guidance.html",
                windowClass: "viewGuidanceModalWindow",
                controller: "viewGuidanceCtrl as $ctrl",
                size: "lg",
                resolve: {
                    iframeUrl: () => "https://www.brooksonfaq.co.uk/article-tags/" + sectionId.replace(/\s/g, '').replace('&', '').toLowerCase() + "/"
                }
            };

            var modalInstance = this.$uibModal.open(modalSettings);

            return modalInstance.result;
        }

        showExpenseWarningModal = (): ng.IPromise<any> => {
            if (this.appSettings.enableExpenseEnhancementModal) {
                let modalSettings: angular.ui.bootstrap.IModalSettings = {
                    animation: true,
                    templateUrl: "src/app/expenses/views/modals/brookson.modals.expensewarnings.html",
                    windowClass: "expenseWarningsModalWindow",
                    controller: "expenseWarningsCtrl as $ctrl",
                    size: "lg",
                    backdrop: 'static',
                    keyboard: false,
                };

                let cookieVal = this.localStorageService.get("expenseWarningPopup");

                if (!cookieVal) {
                    var modalInstance = this.$uibModal.open(modalSettings);

                    return modalInstance.result;
                }                
            }
            return null;
        }



        expenseGuidanceExists = (expenseType: any): boolean => {
            if (_.includes(this.expenseGuidancePaymentCodes, expenseType.paymentCode)) {
                return true;
            }
            return false;
        }


        private countExpenseTabWarnings(expenseLine: Shared.CoreExpenseItem[], warningCount: number): number {
            _.forEach(expenseLine, warning => {
                let expenseConfirmedMonth = _.find(this.confirmedExpenseWarning, (confirmedExpenseWarning: Shared.ExpenseWarning) => {
                    return confirmedExpenseWarning.warning.codeValue === warning.expenseWarning.warning.codeValue &&
                    (confirmedExpenseWarning.showWarningMonthRule ===
                        warning.expenseWarning.warning.twentyFourMthRuleApplicable &&
                        confirmedExpenseWarning.confirmedTwentyFour);
                });

                let expenseConfirmedHighRisk = _.find(this.confirmedExpenseWarning, (confirmedExpenseWarning: Shared.ExpenseWarning) => {
                    return confirmedExpenseWarning.warning.codeValue === warning.expenseWarning.warning.codeValue &&
                        confirmedExpenseWarning.showWarningHighRisk === warning.expenseWarning.showWarningHighRisk
                        && confirmedExpenseWarning.confirmed;
                });


                let expenseConfirmedAmount = _.find(this.confirmedExpenseWarning, (confirmedExpenseWarning: Shared.ExpenseWarning) => {
                    return confirmedExpenseWarning.warning.codeValue === warning.expenseWarning.warning.codeValue &&
                        ((confirmedExpenseWarning.showWarningAmount === warning.expenseWarning.showWarningAmount)
                            && confirmedExpenseWarning.confirmed);
                });
                
                if (!expenseConfirmedMonth && warning.expenseWarning.warning.twentyFourMthRuleApplicable && warning.expenseWarning.showWarningMonthRule && !warning.expenseWarning.confirmedTwentyFour) warningCount++;
                if (!expenseConfirmedHighRisk && warning.expenseWarning.warning.highRisk && !warning.expenseWarning.confirmed) warningCount++;
                if (!expenseConfirmedAmount && warning.gross >= warning.expenseWarning.warning.valueLimit && !warning.expenseWarning.confirmed) warningCount++;
            });
            return warningCount;
        }

        private obtainPresentedTabWarnings(tabExpenses: Shared.CoreExpenseItem[]): Shared.CoreExpenseItem[] {
            return _.filter(tabExpenses, (tabExpenses: Shared.CoreExpenseItem) => {
                return !tabExpenses.markedAsDelete && tabExpenses.expenseWarning &&
                    (tabExpenses.expenseWarning.showWarningMonthRule || tabExpenses.expenseWarning.showWarningHighRisk || tabExpenses.expenseWarning.showWarningAmount);
            });
        }

        buildPresentedExpenseWarnings = (): void => {
            this.expensesTabWarningSummary = new Shared.ExpenseTabWarnings();
            if (this.allowanceExpenses && this.allowanceExpenses.length > 0) {
                let allowanceWarnings = this.obtainPresentedTabWarnings(this.allowanceExpenses);
                let allowanceTab = new Shared.ExpenseTabWarning("Allowances");
                let allowanceTabWarningCount = 0;
                if (allowanceWarnings.length > 0) {
                    allowanceTabWarningCount = this.countExpenseTabWarnings(allowanceWarnings, allowanceTabWarningCount);
                }
                allowanceTab.presentedWarnings = allowanceTabWarningCount;
                this.expensesTabWarningSummary.tabWarnings.push(allowanceTab);
                this.expensesTabWarningSummary.warningCount += allowanceTab.presentedWarnings;
            }
            if (this.mileageExpenses && this.mileageExpenses.length > 0) {
                let mileageWarnings = this.obtainPresentedTabWarnings(this.mileageExpenses);
                let mileageTab = new ExpenseTabWarning("Mileage");
                let mileageTabWarningCount = 0;
                if (mileageWarnings.length > 0) {
                    mileageTabWarningCount = this.countExpenseTabWarnings(mileageWarnings, mileageTabWarningCount);
                }
                mileageTab.presentedWarnings = mileageTabWarningCount;
                this.expensesTabWarningSummary.tabWarnings.push(mileageTab);
                this.expensesTabWarningSummary.warningCount += mileageTab.presentedWarnings;
            }
            if (this.regularExpenses && this.regularExpenses.length > 0) {
                let regularWarnings = this.obtainPresentedTabWarnings(this.regularExpenses);
                let expensesTab = new ExpenseTabWarning("Expenses");
                let expenseTabWarningCount = 0;
                if (regularWarnings.length > 0) {
                    expenseTabWarningCount = this.countExpenseTabWarnings(regularWarnings, expenseTabWarningCount);
                }
                expensesTab.presentedWarnings = expenseTabWarningCount;
                this.expensesTabWarningSummary.tabWarnings.push(expensesTab);
                this.expensesTabWarningSummary.warningCount += expensesTab.presentedWarnings;
            }
        }

        tabExpenseWarningCount = (tabName: string): number => {
            if (!this.expensesTabWarningSummary || this.expensesTabWarningSummary.tabWarnings.length === 0) return 0;
            let tabWarnings = _.find(this.expensesTabWarningSummary.tabWarnings, (tabWarning: ExpenseTabWarning) => {
                return tabWarning.name === tabName;
            });
            if (tabWarnings) return tabWarnings.presentedWarnings;
            return 0;
        }

        confirmExpenseWarning = (expenseLine: Shared.CoreExpenseItem): void => {
            expenseLine.expenseWarning.confirmed = true;
            expenseLine.expenseWarning.dateConfirmed = new Date();
            expenseLine.expenseWarning.showWarningMonthRule = false;
            this.confirmedExpenseWarning.push(angular.copy(expenseLine.expenseWarning));
            this.buildPresentedExpenseWarnings();
        }

        confirmExpenseWarningTwentyFour = (expenseLine: Shared.CoreExpenseItem): void => {
            expenseLine.expenseWarning.confirmedTwentyFour = true;
            expenseLine.expenseWarning.dateConfirmedTwentyFour = new Date();
            this.confirmedExpenseWarning.push(angular.copy(expenseLine.expenseWarning));
            this.buildPresentedExpenseWarnings();
        }

        autoSaveExpenses = (oldVal: Array<any>, newVal: Array<any>, expenseType: ExpenseTypeRef): void => {
            if (this.isAutoSaving || !this.selectedAssignment || !this.expenseHeader.description || _.isNil(newVal) || !newVal.length || angular.equals(newVal, this.lastRegularExpenseVal)) {
                return;
            }

            var hasChanges = false;

            _.forEach(newVal, val => {
                hasChanges = hasChanges ||
                    (!this.isSameRegularExpense(val, this.lastRegularExpenseVal[newVal.indexOf(val)]) &&
                    !_.isNil(this.lastRegularExpenseVal[newVal.indexOf(val)]) &&
                        this.isCompleteRegularExpense(val));
            });

            var completeExpenses = _.filter(newVal, e => this.isCompleteRegularExpense(e));


            this.lastRegularExpenseVal = angular.copy(newVal);
            if (!hasChanges || !completeExpenses.length) {
                return;
            }

            var changedExpenses = angular.copy(completeExpenses);

            let requestData;

            _.forEach(changedExpenses, expenseItem => {

                switch (expenseType) {
                    case ExpenseTypeRef.EXPENSE:
                        this.prepareRegularExpense(expenseItem);
                        requestData = this.prepareSaveExpense([], changedExpenses);
                        break;
                    case ExpenseTypeRef.MILEAGE:
                        this.prepareMileageExpense(expenseItem);
                        requestData = this.prepareSaveExpense(changedExpenses);
                        break;
                    case ExpenseTypeRef.ALLOWANCE:
                        this.prepareAllowanceExpense(expenseItem);
                        requestData = this.prepareSaveExpense([], [], changedExpenses);
                        break;
                    default:
                        this.prepareRegularExpense(expenseItem);
                        requestData = this.prepareSaveExpense([], changedExpenses);
                }                               
            });


            this.saveExpenses(requestData, true, true);
        }

        isSameRegularExpense = (e1: any, e2: any): boolean => {
            if (_.isNil(e1) || _.isNil(e2)) {
                return false;
            }

            return e1.description === e2.description &&
                this.dateUtils.isSame(e1.date, e2.date) &&
                angular.equals(e1.typeObject, e2.typeObject) &&
                e1.gross === e2.gross &&
                e1.markedAsDelete === e2.markedAsDelete &&
                (_.isNil(e1.receiptId) ? 0 : e1.receiptId) === (_.isNil(e2.receiptId) ? 0 : e2.receiptId);
        }

        isCompleteRegularExpense = (expense: any): boolean => {
            return !_.isNil(expense.date) &&
                !_.isNil(expense.description) &&
                !_.isNil(expense.gross) && expense.gross > 0 &&
                !_.isNil(expense.typeObject);
        }

        expenseTypeChanged = (expense: any, index: number, expenseList: any): void => {
            let expenseLine = expenseList[index];
            expenseLine.expenseWarning = new Shared.ExpenseWarning();
            if (!expense || !expenseList || !this.selectedAssignment) {
                this.buildPresentedExpenseWarnings();
                return;
            }

            let expenseWarning = this.getExpenseWarningType(expense);
            if (!expenseWarning || !expenseWarning.enabled) return;
            let presentedAlert = _.find(expenseList, (ex: any) => ex.expenseWarning && ex.expenseWarning.warning && ex.expenseWarning.warning.codeValue === expenseWarning.codeValue && ex.expenseWarning.warningMessage);
            if (presentedAlert) {
                return;
            }

            expenseLine.expenseWarning.assignment = this.selectedAssignment;
            expenseLine.expenseWarning.warning = expenseWarning;
            let previousConfirmedExpense = this.getLatestConfirmedExpense(expenseWarning, Shared.ExpenseWarningCodeTypes.HighRisk);
            expenseLine.expenseWarning.showWarningHighRisk = expenseWarning.highRisk && !previousConfirmedExpense;
            if (expenseLine.expenseWarning.showWarningHighRisk) {
                this.setExpenseWarningMessage(expenseLine, expenseWarning, this.expensesEnums.ExpenseWarningCodeTypes.HighRisk);
            } else {
                if (expenseLine.net || expenseLine.gross) {
                    previousConfirmedExpense = this.getLatestConfirmedExpense(expenseWarning, Shared.ExpenseWarningCodeTypes.Amount);
                    let expenseLineVal = expenseLine.gross; 
                    if (previousConfirmedExpense) {
                        let monthDifference = this.diffMonths(new Date(), previousConfirmedExpense.dateConfirmed);
                        expenseLine.expenseWarning.showWarningAmount = expenseLineVal >= expenseWarning.valueLimit && monthDifference >= expenseWarning.timeLength;
                    } else {
                        expenseLine.expenseWarning.showWarningAmount = expenseLineVal >= expenseWarning.valueLimit;
                    }

                    if (expenseLine.expenseWarning.showWarningAmount) {
                        this.setExpenseWarningMessage(expenseLine, expenseWarning, this.expensesEnums.ExpenseWarningCodeTypes.Amount);
                    } else {
                        let exp = _.find(expenseList, (e: any) => e.expenseWarning && !e.expenseWarning.warning && e.typeObject && e.typeObject.paymentCode === expenseLine.typeObject.paymentCode);
                        if (exp) {
                            this.setExpenseWarningMessage(expenseLine, expenseWarning, this.expensesEnums.ExpenseWarningCodeTypes.Amount);
                        }
                    }
                }
            }
            if (expenseLine.date) {
                let presentedAlert = _.find(expenseList, (ex: any) => ex.expenseWarning && ex.expenseWarning.warning && ex.expenseWarning.warning.codeValue === expenseWarning.codeValue && ex.expenseWarning.warningMessageTwentyFour);
                if (presentedAlert) {
                    return;
                }

                if (expenseWarning && expenseWarning.twentyFourMthRuleApplicable) {
                    let previousConfirmedExpenseTwentyFourMonth = this.getLatestConfirmedExpense(expenseWarning, Shared.ExpenseWarningCodeTypes.TwentyFourMonthRule);

                    if (previousConfirmedExpenseTwentyFourMonth) {
                        let monthDifference = this.diffMonths(new Date(), previousConfirmedExpenseTwentyFourMonth.dateConfirmed);
                        expenseLine.expenseWarning.showWarningMonthRule = monthDifference >= expenseWarning.timeLength;
                    } else {
                        let monthDifference = this.diffMonths(expenseLine.date, this.selectedAssignment.dateStarted);
                        expenseLine.expenseWarning.showWarningMonthRule = monthDifference >= expenseWarning.twentyFourMthTriggerOverride;
                    }

                    if (expenseLine.expenseWarning.showWarningMonthRule && expenseLine.expenseWarning.warning) {
                        let twentyFourExpenseWarningType = this.getExpenseWarningText(expenseWarning.expenseWarningId, this.expensesEnums.ExpenseWarningCodeTypes.TwentyFourMonthRule);
                        if (twentyFourExpenseWarningType) {
                            expenseLine.expenseWarning.warningMessageTwentyFour = twentyFourExpenseWarningType.expenseWarningText.replace('(i)', '<a data-ng-click="$ctrl.data.showGuidance(\'' + 'TEMPWORKPLACERULES' + '\')" ><i class="fa fa-info-circle guidance-icon-blue" aria-hidden="true"></i></a>');;
                        } else {
                            expenseLine.expenseWarning.showWarningMonthRule = false;
                        }
                    }
                }
            }

            this.buildPresentedExpenseWarnings();
        }

        updateWarningsAssignmentChange = (expenses: CoreExpenseItem[]): void => {
            this.removeConfirmationsBySelectedAssignment();
            if (expenses.length > 0) {
                _.forEach(expenses, (expense) => {
                    if (expense.expenseWarning) {
                        expense.expenseWarning.showWarningMonthRule = false;
                        expense.expenseWarning.showWarningAmount = false;
                        expense.expenseWarning.showWarningHighRisk = false;
                        this.expenseTypeChanged(expense.typeObject, expenses.indexOf(expense), expenses);
                    }
                });
                this.buildPresentedExpenseWarnings();
            }    
        }

        removeConfirmationsBySelectedAssignment = (): void => {
            if (!this.selectedAssignment) return;
            for (let i = this.confirmedExpenseWarning.length - 1; i >= 0; i--) {
                if (this.confirmedExpenseWarning[i].assignment.assignmentId !== this.selectedAssignment.assignmentId) {
                    this.confirmedExpenseWarning.splice(i, 1);
                }
            }
        }

        clearConfirmedExpenseWarnings() {
            this.confirmedExpenseWarning = new Array<ExpenseWarning>();
        }
    }
}

angular
    .module("app.shared")
    .service("brooksonFactoriesExpenses", Shared.ExpensesFactory);