
module Shared {
    export class ConnectIntro {
        public connectIntroId: number;
        public memberId: number;
        public firstTimeLogin: boolean;
        public expenses: boolean;
        public invoicing: boolean;
        public bank: boolean;
        public documentStorage: boolean;
        public selfAssessment: boolean;
        public taskPlanner: boolean;
        public assignments: boolean;
        public ir35: boolean;
    }
}