module Shared {
    export class ActionsToTakeSummary {
        // ADDRESSLINE1
        public expensesToAuthorize: number = null;

        // ADDRESSLINE2
        public holidaysToAuthorize: number = null;

        // ADDRESSLINE3
        public overtimeToAuthorize: number = null;
    }
}