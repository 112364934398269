module app.shared {
    'use strict';

    export interface IUnsavedWarningSharedService {
    }
    export class UnsavedWarningSharedService implements IUnsavedWarningSharedService {


        static $inject: Array<string> = ['$rootScope', '$injector', '$location', '$state', 'brookson.ui.popup'];
        constructor(private $rootScope, private $injector, private $location, private $state, private brooksonPopup) {

        }
        private ignoreEvent: boolean = false;
        private allFormsArray = [];
        private messages = {
            navigate: 'You will lose unsaved changes if you leave this page',
            reload: 'You will lose unsaved changes if you reload this page'
        };
        private removeFunctions: Array<Object> = [angular.noop];
        private routeEvent = ['$locationChangeStart', '$stateChangeStart'];
        public allForms = () => {
            return this.allFormsArray;
        };

        public allFormsClean = () => {
            let cleanFormCount: number = 0;
            angular.forEach(this.allFormsArray, (item, idx) => {
                   if (item.$dirty) {
                    cleanFormCount++;
                }
            });
            return cleanFormCount === 0; // no dirty forms were found
        }
        public init = (form) => {
            if (this.allFormsArray.length === 0) this.setup();
            this.allFormsArray.push(form);
        };

        public removeForm = (form) => {
            var idx = this.allFormsArray.indexOf(form);

            if (idx === -1) return;

            this.allFormsArray.splice(idx, 1);

            if (this.allFormsArray.length === 0) this.tearDown();
        };

        public tearDown = () => {
            angular.forEach(this.removeFunctions, (fn: Function) => {
                fn();
            });
            window.onbeforeunload = null;
            this.ignoreEvent = false;
        }

        public confirmExit = () => {
            if (!this.allFormsClean()) return this.messages.reload;
          
        };

        public setup = (): void => {

            window.onbeforeunload = this.confirmExit;

            var eventsToWatchFor = this.routeEvent;

            angular.forEach(eventsToWatchFor, (aEvent) => {
                // calling this function later will unbind this, acting as $off()
                var removeFn = this.$rootScope.$on(aEvent, (event, next, current) => {
                    if (!this.allFormsClean() && !this.ignoreEvent) {
                        this.ignoreEvent = true;
                        event.preventDefault();
                        this.brooksonPopup.showYesNoPopup("Unsaved Changes", this.messages.navigate).then((result) => {
                            this.completeEvent(event, next);
                        }).catch((er) => {
                            this.ignoreEvent = false;

                        });

                    } else {
                    }

                });
                this.removeFunctions.push(removeFn);
            });
        }

        public completeEvent = (event, next): void => {
            switch (event.name) {
                case '$locationChangeStart':
                    this.$location.path(next);
                    break;
                case '$stateChangeStart':
                    this.$state.go(next);
                    break;
                default:
                    break;
            }
        }

    }

    angular
        .module('app.shared')
        .service('unsavedWarningSharedService', UnsavedWarningSharedService);
}

(() => {
    'use strict';

    angular.module("app.shared").directive('confirmOnExit', ['unsavedWarningSharedService', (unsavedWarningSharedService) => {

        return {
            require: 'form',
            link: (scope, formElement, attrs, formCtrl) => {

                // register this form
                unsavedWarningSharedService.init(formCtrl);

                // bind to form submit, this makes the typical submit button work
                // in addition to the ability to bind to a seperate button which clears warning
                formElement.bind('submit', (event) => {
                    if (formCtrl.$valid) {
                        formCtrl.$setPristine();
                    }
                });

                // @todo check destroy on clear button too? 
                scope.$on('$destroy', () => {
                    unsavedWarningSharedService.removeForm(formCtrl);
                });
            }
        };
    }]);


})();