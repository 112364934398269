/// <reference path="../../_all.ts" />
module Shared {
    export interface ISmeManagementSrv {
        getActionsToTakeSummary(): ng.IPromise<ActionsToTakeSummary>;
    }

    export class SmeManagementSrv implements ISmeManagementSrv {

        static $inject = ["$http", "shared.config"];

        private serviceBase: any;

        constructor(private $http: ng.IHttpService, private sharedConfig: any) {
            this.serviceBase = sharedConfig.baseApiUrl;
        }

        getActionsToTakeSummary(): angular.IPromise<ActionsToTakeSummary> {
            let url = this.serviceBase + "api/SmeManagement/GetActionsToTakeSummary";

            return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<ActionsToTakeSummary>) => {
                return response.data;
            });
        }
    }
}

angular.module("app.shared").service("smeManagementSrv", Shared.SmeManagementSrv);