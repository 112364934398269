module Shared {
    export class SectionHeader {
    businessID: string;
    personId: string;
    section: string;
    subSection: string;
    body: string;
    connectUser: string;
    createdDate: string;
    }
}