/// <reference path="../../../../app/_all.ts"/>

module Shared {
    export class BrooksonWidgetsKeyDates implements ng.IComponentOptions {
        public bindings: any;
        public controller: any;
        public templateUrl: any;

        constructor() {
            this.bindings = {
                onComplete: '&'
            };

            this.controller = ['$timeout', 'taskPlannerSrv', 'brookson.navigation', 'brookson.utilities.date', 'taskPlanner.enums','dashboardService', BrooksonWidgetsKeyDatesController];
            this.templateUrl = 'src/app/shared/views/widgets/brookson.widgets.key-dates.html';
        }
    }

    export class BrooksonWidgetsKeyDatesController {
        error: boolean;
        showSpinner: boolean;
        onComplete: Function;
        tasksActions: Array<TaskAction>;

        public widgetName = "brookson-widgets-key-dates";

        public dashboardService:Shared.IDashboardService = this._dashboardService;

        constructor(private $timeout: ng.ITimeoutService, private taskPlannerSrv: ITaskPlannerSrv, private brooksonNavigation: IBrooksonNavigation, private dateUtils: IBrooksonUtilitiesDate, private taskPlannerEnums: ITaskPlannerEnums,private _dashboardService:Shared.IDashboardService) { }

        $onInit() {
            this.getTasks();
        }

        goToTaskPlanner() {
            this.brooksonNavigation.taskPlanner.calendar();
        }

        keyDatesWidgetPressed() {
            this.brooksonNavigation.taskPlanner.calendar();
        }

        private getTasks() {
            this.showSpinner = true;
            this.taskPlannerSrv.getTaskActions(true).then(data => {
                if (data) {
                    this.tasksActions = data;
                    
                    this.tasksActions = _.filter(this.tasksActions, (task) => {
                        return task.status === this.taskPlannerEnums.TaskStatus.OVERDUE || this.dateUtils.isAfter(task.deadline, moment().toDate());
                    });

                    this.tasksActions = _.orderBy(this.tasksActions, (task) => {
                        return new Date(task.dueDate.toString());
                    }, 'asc');
                }
            }, () => {
                this.error = true;
            }).then(() => {
                this.showSpinner = false;
                this.$timeout(() => { this.onComplete(); });
            });
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonWidgetsKeyDates', new Shared.BrooksonWidgetsKeyDates());