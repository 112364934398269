module Shared {
    export class AmountAvailableTable {
        public businessID: string;
        public personID: string;
        public connectUser: string;
        public availableFunds: number;
        public bank: number;
        public corporation_tax: number;
        public other: number;
        public paye: number;
        public unpaid_Invoices: number;
        public vat: number;
        public createdDate: string;
        public section: string;
        public subSection: string;
        public order: string;
    }
}