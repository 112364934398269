
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class UserFeedback {

        // USERFEEDBACKID
        public userFeedbackId: number = 0;
        // USERFEEDBACKCATEGORYID
        public userFeedbackCategoryId: number = 0;
        // USERFEEDBACKCATEGORYOPTION
        public userFeedbackCategoryOption: EnumObj;
        // DESCRIPTION
        public description: string = null;
        // SCREENSHOT
        public screenshot: number[] = [];
        // SCREENSHOTBASE64
        public screenshotBase64: string = null;
        // DATE
        public date: Date = null;
        // BROWSER
        public browser: string = null;
        // BROWSERVERSION
        public browserVersion: string = null;
        // DEVICETYPE
        public deviceType: string = null;
        // DEVICEMODEL
        public deviceModel: string = null;
        // DEVICEVENDOR
        public deviceVendor: string = null;
        // OS
        public os: string = null;
        // OSVERSION
        public osVersion: string = null;
        // ENGINENAME
        public engineName: string = null;
        // ENGINE
        public engineVersion: string = null;
        // CPUARCHITECTURE
        public cpuArchitecture: string = null;
        // URL
        public url: string = null;
        // STATE
        public state: string = null;
        // STATEPARAMS
        public stateParams: string = null;
        // EMAIL
        public email: string = null;
        // NAME
        public name: string = null;
    }
}
