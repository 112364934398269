/// <reference path="../../_all.ts"/>
/// <reference path="./brookson.services.authentication.ts" />
/// <reference path="./../interfaces/brookson.interfaces.http.ts" />
/// <reference path="./../interfaces/brookson.interfaces.window.ts" />

module Shared {
    export interface IDropboxService {
        connect() : ng.IPromise<string>;
        disconnect(sharedDriveId: string) : ng.IPromise<boolean>;
        createDropboxFileFactory() : flowjs.IFlow;
        flowFilesSubmitted (files: Array<flowjs.IFlowFile>, event: Event, flow: any, path: string) : void;
        flowComplete($flow: flowjs.IFlow) : void;
    }

    export class DropboxService implements IDropboxService {

        static $inject = ['$http', '$q', '$window', '$timeout', 'flowFactory', 'authenticationSrv'];

        constructor(
            private $http: Shared.IBrooksonHttp,
            private $q: ng.IQService,
            private $window: Window,
            private $timeout: ng.ITimeoutService,
            private flowFactory: ng.flow.IFlowFactory,
            private authenticationSrv: Shared.IAuthenticationService
        ) { }

        connect = () : ng.IPromise<string> => {
            return this.$http.get('api/Dropbox/Connect').then((response: ng.IHttpPromiseCallbackArg<string>) => {
                return response.data;
            });
        }

        disconnect = (sharedDriveId: string) : ng.IPromise<boolean> => {
            return this.$http.delete('api/Dropbox/Disconnect?id=' + sharedDriveId).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }

        createDropboxFileFactory = () : flowjs.IFlow => {
            const options: flowjs.IFlowOptions = {
                target: '/api/Dropbox/UploadFile',
                permanentErrors: ['404', '500', '501'],
                headers: {
                    "__RequestVerificationToken": this.$window.brookson.antiForgeryToken,
                    "Accept": "application/json"
                },
                testChunks: false,
                simultaneousUploads: 1,
                chunkSize: 9007199254740992
            };
            
            return this.flowFactory.create(options);
        }

        flowFilesSubmitted = (files: Array<flowjs.IFlowFile>, event: Event, flow: any, path: string) : void => {
            this.authenticationSrv.refreshToken().then((data) => {
                flow.opts.query.dropboxUploadPath = path;
                flow.opts.headers.Authorization = "Bearer " + data.access_token;
                flow.upload();
            });
        }

        flowComplete = ($flow: flowjs.IFlow) : void => {
            this.$timeout((flow) => {
                flow.files = [];
            }, 2000, true, $flow);
        }
    }
}

angular.module('app.shared').service('dropboxSrv', Shared.DropboxService);