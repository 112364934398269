module Shared {
    export class HRTThresholdTable {
        weeksRemaining: number;
        pointHigherRateTaxispayable: number;
        yeartodatetaxableincome: number;
        hrtThresholdlessYTDincome: number;
        estweekstoexceedthreshold: number;
        businessID: string;
        personID: string;
        connectUser: string;
        createdDate: string;
        section: string;
        subSection: string;
        order: number;
    }
}