
module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class Address  {
        
        public name: string = null;
        // ADDRESSLINE1
        public addressLine1: string = null;
        // ADDRESSLINE2
        public addressLine2: string = null;
        // ADDRESSLINE3
        public addressLine3: string = null;
        // TOWN
        public town: string = null;
        // COUNTY
        public county: string = null;
        // POSTCODE
        public postcode: string = null;
        // COUNTRY
        public country: string = null;
    }
}
