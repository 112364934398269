/// <reference path="./../services/brookson.services.publicSectorAssignmentCheck.ts" />

module Shared {
    export interface IBrooksonPublicSectorAssignmentCheckModalService {
        show(): ng.ui.bootstrap.IModalServiceInstance;
    }

    interface AppSettings extends ng.IWindowService {
        appSettings: any;
    }

    export class BrooksonPublicSectorAssignmentCheckModalService implements IBrooksonPublicSectorAssignmentCheckModalService {
        options: ng.ui.bootstrap.IModalSettings = {
            animation: true,
            size: "md",
            templateUrl: "src/app/shared/views/modals/brookson.modals.publicSectorAssignmentCheck.html",
            keyboard: false,
            controller: BrooksonPublicSectorAssignmentCheckModalController,
            controllerAs: "$ctrl",
            backdrop: 'static',
            windowClass: "public-sector-assignment-check"
        };

        static $inject = ['$uibModal'];

        constructor(
            private $uibModal: ng.ui.bootstrap.IModalService
        ) { }

        show = (): ng.ui.bootstrap.IModalServiceInstance => {
            return this.$uibModal.open(this.options);
        }
    }

    export class BrooksonPublicSectorAssignmentCheckModalController {

        // From the backend: whether or not the popup modal can be cancelled or not
        public canBeCancelled: boolean = false;

        // Whether the user is on InternalConnect or not
        private isInternal: boolean;
        private validApiSource: boolean;

        static $inject = ['$uibModalInstance', 'publicSectorAssignmentCheckService', '$window', 'memberSrv'];

        constructor(
            private $uibModalInstance,
            private publicSectorAssignmentCheckService: Shared.IPublicSectorAssignmentCheckService,
            private $window: AppSettings,
            private memberService: Shared.IMemberService
        ) {
            this.isInternal = this.$window.appSettings.isInternal;
            this.validApiSource = this.$window.appSettings.validApiSource;
            this.canBeCancelled = (this.memberService.member.isAdmin || this.isInternal || this.validApiSource);;
        }

        public submitAssignments = (): void => {
            this.publicSectorAssignmentCheckService.postPublicSectorAssignmentCheck(this.publicSectorAssignmentCheckService.publicSectorAssignmentCheckRequiresCompletion).then(() => {
                this.close();
            });
        }

        // Close the modal
        public close = (): void => {
            this.$uibModalInstance.dismiss('cancel');
        }
    }
}

angular.module("app.shared").service("publicSectorAssignmentCheckModalService", Shared.BrooksonPublicSectorAssignmentCheckModalService);