/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.modal.receipt-preview", [
        "$q", "$uibModal", ($q, $uibModal) => {
            var options: any = {
                animation: true,
                size: "md",
                templateUrl: "src/app/shared/views/modals/brookson.modals.receipt-preview.html"
            };
            var _show = (receiptId) => {
                var defer = $q.defer();
                var receiptPreview = options;

                receiptPreview.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.cancel = cancel;
                    $scope.receiptId = receiptId;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }
                }];

                $uibModal.open(receiptPreview);

                return defer.promise;
            };

            return {
                show: _show
            };
        }
    ]);
})();
