module Shared {
    export class ExpenseHeader {
        startDate: Shared.BrooksonDate;
        endDate: Shared.BrooksonDate;
        assignmentId: string;
        assignment: string;
        description: string;
        id: number;
        status: string;
        type: string;
        total: number;
        dataSource: number;
        createdDate: Shared.BrooksonDate;
        startDateRange: Shared.BrooksonDate;
        endDateRange: Shared.BrooksonDate;
    }
}