/// <reference path="../../_all.ts"/>
/// <reference path="./../../employmentStatus/models/ESAQuestionaire.ts"/>
/// <reference path="./../../employmentStatus/models/ESAQuestionairreQuestions3.ts"/>
/// <reference path="./../../employmentStatus/models/MemberAdvisedStatus.ts"/>
/// <reference path="./../models/Assignment.ts"/>
/// <reference path="../../employmentStatus/models/AssignmentHeader.ts"/>

module Shared {
    import EsaQuestionaire = EmploymentStatus.EsaQuestionaire;
    import EsaQuestionaireQuestions3 = EmploymentStatus.EsaQuestionaireQuestions3;
    import MemberStatus = EmploymentStatus.MemberAdvisedStatus;

    export class GetQuestionnaireRequest {
        assignmentId: string;
        reviewId: number;

        constructor(assignmentId: string, reviewId: number) {
            this.assignmentId = assignmentId;
            this.reviewId = reviewId;
        }
    }

    export interface IEmploymentStatusSrv {
        getAssignments(silentMode?: boolean,cache?:boolean): ng.IPromise<{}>;
        getQuestions(silentMode?: boolean): ng.IPromise<Array<Object>>;
        getQuestionnaire(assignmentId: string, reviewId: any): ng.IPromise<EsaQuestionaire>;
        saveQuestionnaire(qnairre: EsaQuestionaire): ng.IPromise<EsaQuestionaire>;
        getQuestionByQuestionNumber(questionNumber: number): Array<EsaQuestionaireQuestions3>;
        getQuestionByQuestionNumberAndGroup(questionNumber: number, group: string): Array<EsaQuestionaireQuestions3>;
        saveIR35Status(memberStatus: MemberStatus): ng.IPromise<boolean>;
        getIR35Status(assignmentId: string): ng.IPromise<MemberStatus>;
        removeContractData(esaQuestionnaireId: string);
        CheckIR35AssessmentAllowed();

        IR35Status_TaxedAsPAYE: EmploymentStatus.IR35Status;
        IR35Status_TaxedAsSelfEmployed: EmploymentStatus.IR35Status;
        IR35Status_Caution: EmploymentStatus.IR35Status;
        IR35Status_ActionRequired: EmploymentStatus.IR35Status;
        IR35Status_WithdrawnReview: EmploymentStatus.IR35Status;
        IR35Status_AssessmentFormSubmit: EmploymentStatus.IR35Status;
        IR35Status_AssessmentFormStarted: EmploymentStatus.IR35Status;
        FinalOutcome_Captured: EmploymentStatus.IR35Status;
        FinalOutcome_NotCaptured: EmploymentStatus.IR35Status;
        FinalOutcome_Inconclusive: EmploymentStatus.IR35Status;
        FinalOutcome_UnderReview: EmploymentStatus.IR35Status;

    }

    class EmploymentStatusSrv implements IEmploymentStatusSrv {

        public IR35Status_TaxedAsPAYE: EmploymentStatus.IR35Status = { text: "Taxed as PAYE (No Review)", style: 'label-success' };
        public IR35Status_TaxedAsSelfEmployed: EmploymentStatus.IR35Status = { text:"Taxed as Self Employed (No Review)", style: 'label-success' };
        public IR35Status_Caution: EmploymentStatus.IR35Status = { text:"Caution", style: 'label-warning' };
        public IR35Status_ActionRequired: EmploymentStatus.IR35Status = { text:"Action Required", style: 'label-danger' };
        public IR35Status_WithdrawnReview: EmploymentStatus.IR35Status = { text:"Review Incomplete", style: 'label-warning' };
        public IR35Status_AssessmentFormSubmit: EmploymentStatus.IR35Status = { text:"Assessment Form Submitted", style: 'label-success' };
        public IR35Status_AssessmentFormStarted: EmploymentStatus.IR35Status = { text:"Assessment Form Incomplete", style: 'label-warning' };
        public FinalOutcome_Captured :EmploymentStatus.IR35Status= { text:"Captured", style: 'label-success' };
        public FinalOutcome_NotCaptured :EmploymentStatus.IR35Status= { text:"Not Captured", style: 'label-success' };
        public FinalOutcome_Inconclusive :EmploymentStatus.IR35Status= { text:"Inconclusive", style: 'label-warning' };
        public FinalOutcome_UnderReview :EmploymentStatus.IR35Status= { text:"Under Review", style: 'label-warning' };

        serviceBase: any;
        private questions: Array<EsaQuestionaireQuestions3>;

        static $inject = ['$http', '$q', 'shared.config'];

        constructor(private $http: ng.IHttpService, private $q: ng.IQService, private sharedConfig: any) {
            this.serviceBase = sharedConfig.baseApiUrl;
            this.$http = $http;
            this.$q = $q;
            this.questions = [];
        }

        getAssignments(silentMode: boolean,cache:boolean=true): ng.IHttpPromise<Array<Assignment>> {
            let url = this.serviceBase + 'api/IR35/GetAssignments';
            let params = {
                cache: cache,
                silentMode: silentMode ? silentMode : false
            };

            return this.$http.get(url, params).then(response => response.data);
        }

        getQuestions(silentMode: boolean): ng.IHttpPromise<Array<EsaQuestionaireQuestions3>> {
            const url = this.serviceBase + 'api/IR35/GetQuestionList';
            const params = {
                cache: true,
                silentMode: silentMode ? silentMode : false
            };
            let defer = this.$q.defer();

            if (!this.questions || !this.questions.length) {
                this.$http.get(url, params).then((response: ng.IHttpPromiseCallbackArg<Array<EsaQuestionaireQuestions3>>) => {
                    defer.resolve(response.data);
                    this.questions = response.data;
                });
            } else {
                defer.resolve(this.questions);
            }

            return defer.promise;
        }

        getQuestionnaire(assignmentId: string, reviewId: any): ng.IHttpPromise<EsaQuestionaire> {
            let url = this.serviceBase + 'api/IR35/GetQuestionnaire';
            var config: ng.IRequestShortcutConfig = { params: { assignmentId: assignmentId, reviewId: reviewId } };
            return this.$http.get(url, config).then(response => response.data);
        }

        saveQuestionnaire(qnnaire: EsaQuestionaire): ng.IPromise<EsaQuestionaire> {
            let url = this.serviceBase + 'api/IR35/SaveQuestionnaire';
            let defer = this.$q.defer();
            this.$http.post(url, qnnaire).then(result => {
                defer.resolve(result.data);
            }).catch(() => {
                defer.reject();
            });

            return defer.promise;
        }

        removeContractData = (esaQuestionnaireId: string, silentMode?: boolean) => {
            let url = this.serviceBase + 'api/IR35/RemoveContractData';
            return this.$http.post(url, esaQuestionnaireId).then(response => response.data);
        }

        getQuestionByQuestionNumber(questionNumber: number): Array<EsaQuestionaireQuestions3> {
            return _.filter(this.questions, { 'questionNumber': questionNumber });
        }

        getQuestionByQuestionNumberAndGroup(questionNumber: number, group: string): Array<EsaQuestionaireQuestions3> {
            return _.filter(this.questions, { 'questionNumber': questionNumber, 'group': group });
        }

        saveIR35Status = (memberStatus: MemberStatus): ng.IPromise<boolean> => {
            let url = this.serviceBase + 'api/IR35/SaveIr35Status';
            return this.$http.post(url, memberStatus).then(response => response.data);
        }

        getIR35Status = (assignmentId: string): ng.IPromise<MemberStatus> => {
            let url = this.serviceBase + 'api/IR35/GetIr35Status';
            var config: ng.IRequestShortcutConfig = { params: { assignmentId: assignmentId } };
            return this.$http.get(url, config).then(response => response.data);
        }

        CheckIR35AssessmentAllowed = (): ng.IPromise<boolean> => {
            let url = this.serviceBase + 'api/IR35/CheckIR35AssessmentAllowed';
            return this.$http.get(url).then(response => response.data);
        }
    }

    angular
        .module('app.shared')
        .service('employmentStatusSrv', EmploymentStatusSrv);
}
