module Shared {
    export interface ICalendarOverlayController {
        calendarUrl: string;
        showCalendarScreen: boolean;
    }
    export class CalendarOverlayController implements ICalendarOverlayController {
        public calendarUrl: string;
        public showCalendarScreen: boolean;

        public hideCalendarScreen = (): void => {
            this.showCalendarScreen = false;
        }
    }

    export const BrooksonCalendarOverlay: ng.IComponentOptions = {
        bindings: {
            calendarUrl: '=',
            showCalendarScreen: '='
        },
        controller: CalendarOverlayController,
        templateUrl: 'src/app/shared/views/ui/brookson.ui.calendar-overlay.html'
    };
}

angular.module('app.shared')
    .component('brooksonCalendarOverlay', Shared.BrooksonCalendarOverlay);