/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonUiReceiptPanel', {
        templateUrl: 'src/app/shared/views/ui/brookson.ui.receipt-panel.html',
        bindings: {
            ngModel: '=',
            checkable: '=',
            hideCheckbox: '=',
            receipt: '=',
            checkboxSelected: '=',
            receiptClick: '='
        },
        controller: brooksonUiReceiptPanel
    });

    brooksonUiReceiptPanel.$inject = ['expenses.enums'];

    function brooksonUiReceiptPanel(enums) {
        var vm = this;

        // Data
        vm.status = enums.ReceiptStatus;
    }
})();
