module Shared {
    export module Invoicing {
        export class InvoiceLine {
            description: string;
            qty: number;
            rate: string;
            amount: number;
            assignmentRate: Shared.InvoicingAssignmentRate;
            adhocRate: number;
        }
    }
}