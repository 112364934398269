module Shared {
    export enum VatBusinessPostingGroup
    {
        // ""
        Blank = 0,
        // "EU"
        EU = 1,
        // "EXEMPT"
        EXEMPT = 2,
        // "UK"
        UK = 3,
        // "NON EU"
        NONEU = 4
    }
}