namespace Shared {
    'use strict';

    export class BrooksonInputJobTitle implements ng.IComponentOptions {
        public bindings: { [binding: string]: string };
        public controller: Function;
        public templateUrl: string;
        public require: {[controller: string]: string};

        constructor() {
            this.bindings = {
                ngModel: '=',
                isRequired: '=',
                label: '@',
                field: '@',
                ngReadonly: '=',
                ngDisabled: '=',
                placeholder: '@',
                ngPattern: '=',
                ngPatternMessage: '@',
                viewOnly: '='
            };
            this.require = {
                form: '^form'
            };
            this.controller = BrooksonInputJobTitleController;
            this.templateUrl = 'src/app/shared/views/inputs/brookson.input.job-title.html';
        }
    }

    export class BrooksonInputJobTitleController {
        public showSpinner: boolean = false;
        public listOfJobTitles: Array<Shared.JobTitle>;

        static $inject: Array<string> = ['sdcFactory'];

        constructor(
            private sdcFactory: Shared.ISdcFactory
        ) { }

        // Job Title Search
        searchJobTitle = (query: string) => {
            if (query) {
                this.showSpinner = true;

                this.sdcFactory.searchJobTitle(query, true).then((data) => {
                    this.showSpinner = false;
                    this.listOfJobTitles = data;
                });
            } else {
                this.listOfJobTitles = [];
            }
        }
    }
}

angular.module('app.shared')
    .component('brooksonInputJobTitle', new Shared.BrooksonInputJobTitle());