/// <reference path="./Assignment.ts"/>
/// <reference path="./Customer.ts"/>
/// <reference path="./NamedAddress.ts"/>
/// <reference path="./InvoicingAssignmentRate.ts"/>
/// <reference path="./../enums/VatBusinessPostingGroup.ts" />
/// <reference path="./../enums/VatProdPostingGroup.ts" />

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class InvoicingAssignment extends Assignment {
        
        // RATES
        public rates: InvoicingAssignmentRate[] = [];
        // CUSTOMER
        public customer: Customer = null;
        // CURRENCYCODE
        public currencyCode: number = 0;
        // ISSELFBILLING
        public isSelfBilling: boolean = false;
        // NARRATIVE
        public narrative: string = null;
        // PONUMBER
        public poNumber: string = null;
        // DEEMED
        public deemed: boolean = false;
        // REMITTANCEADDRESS
        public remittanceAddress: NamedAddress = null;
        // COMPANYNO
        public companyNo: string = null;
        // VATNO
        public vatNo: string = null;
        // ISRAISEDINERROR
        public isRaisedInError: boolean = false;
        // VATSTATUS
        public vatStatus: Shared.VatProdPostingGroup;
        // VATBUSINESSPOSTINGGROUP   
        public vatBusinessPostingGroup: VatBusinessPostingGroup; 
        // VATREGSTATUS
        public vatRegStatus: number = 0;

        public defaultHours: number = 0;
        public invoiceDistributionEnabled: boolean = false;
    }
}
