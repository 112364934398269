/// <reference path="../../_all.ts"/>

module Shared {
    export interface IBrooksonModalsGlEntries {
        show(payload: Array<any>, reportType?: string, title?: string): void;
    }
}

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.modal.glentries", [
        "$q", "$uibModal", "$sce", "screenSize", ($q, $uibModal, $sce, screenSize) => {

            var options: any = {
                animation: true,
                size: "lg",
                templateUrl: screenSize.is('xs') ?
                    "src/app/shared/views/modals/brookson.modals.gl-entries.mobile.html" :
                    "src/app/shared/views/modals/brookson.modals.gl-entries.html"
            };

            var _show = (payload, reportType, title) => {
                var defer = $q.defer();
                var glEntriesOptions = options;

                glEntriesOptions.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    $scope.cancel = cancel;
                    $scope.payload = payload;
                    $scope.reportType = reportType;
                    $scope.title = title;
                    $scope.total = 0;

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function activate() {
                        $scope.total = $scope.reportType === "BS"
                            ? _.first<any>(payload).balance 
                            : _.reduce(payload, (sum: number, n: any) => sum + n.amount, 0);
                    }

                    activate();
                }];

                $uibModal.open(glEntriesOptions);

                return defer.promise;
            };

            return {
                show: _show
            };
        }
    ]);
})();
