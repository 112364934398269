/// <reference path="../../_all.ts"/>

// Please note that most methods can take "any" as input parameters because they all get converted to Moment which can convert from any type (string, number, Date, moment.Moment).
module Shared {
    export interface IBrooksonUtilitiesDate {
        taxYearMonth: number;
        taxYearDay: number;

        getPreviousDayOfWeek(dayOfWeek: number | string): moment.Moment;
        convertToMoment(date: any): moment.Moment;
        convertToUtcMoment(date: any): moment.Moment;
        stripOutTimezone(date);
        convertToJsDate(date: any): Date;
        convertToViewString(date: any): string;
        convertToViewMonthString(date: any): string;
        convertToServerString(date: any): string;
        convertDateToMidnightGmt(date: Date): Date;
        dateDiff(date1: any, date2: any): number;
        isValid(date: any): boolean;
        isSame(date1: any, date2: any): boolean;
        isBefore(date1: any, date2: any): boolean;
        isBeforeOrSame(date1: any, date2: any): boolean;
        isAfter(date1: any, date2: any): boolean;
        isAfterOrSame(date1: any, date2: any): boolean;
        taxYearStart(): moment.Moment;
        taxYearEnd(): moment.Moment;
        currentTaxYear(): string;
        previousTaxYear(): string;
        previousTaxYearStart(): any;
        previousTaxYearEnd(): any;
        nextTaxYearStart(): any;
        nextTaxYearEnd(): any;
        nextTaxYear(): string;
        ageFromDate(date: Date): number;
        isMinDate(date: string): boolean;
        isOverNMonthsAgo(date: Date, numberOfMonths: number): boolean;
        taxYearStartByOffset(offset: number): any;
        taxYearEndByOffset(offset: number): any;
        taxYearByOffset(offset: number): string;

    }

    export class BrooksonUtilitiesDate implements IBrooksonUtilitiesDate {

        public taxYearMonth: number = 4;
        public taxYearDay: number = 5;

        getPreviousDayOfWeek = (dayOfWeek: number): moment.Moment => {
            // Get the current week number of the year
            var currentNumberOfWeeks = moment().weeks();

            // Minus 1 off the above number
            var previousWeekNumber = --currentNumberOfWeeks;

            // Given the above number, find the monday based on that week
            var previousWeekOfDay = moment().weeks(previousWeekNumber).day(dayOfWeek);

            return previousWeekOfDay;
        }

        convertToMoment = (date) => {
            if (moment.isMoment(date)) {
                return date;
            }

            if ((date instanceof Date)) {
                return moment(date);
            }

            return moment(date, [moment.ISO_8601, "YYYY-MM-DD", "DD-MM-YYYY", "YYYY/MM/DD", "DD/MM/YYYY", 'dddd', 'YYYY-MM-DDThh:mm:ss', "ddd MMM DD YYYY hh:mm:ss GMTzzz"], true);
        }

        stripOutTimezone = (date) => {
            if (!moment.isMoment(date)) {
                date = moment(date);
            }

            let noTimezone = date.format("ddd MMM DD YYYY").toString();

            return moment.parseZone(noTimezone + " 00:00:00 GMT+0000");
        }

        convertToUtcMoment = (date) => {
            return moment.utc(date);
        }

        convertToJsDate = (date) => {
            if ((date instanceof Date)) {
                return date;
            }

            return this.convertToMoment(date).toDate();
        }

        convertToViewString = (date) => {
            return this.convertToMoment(date).format("DD/MM/YYYY");
        }

        convertToViewMonthString = (date) => {
            return this.convertToMoment(date).format("DD/MMM/YYYY");
        }

        convertToServerString = (date) => {
            return this.convertToMoment(date).format("YYYY-MM-DD");
        }

        convertDateToMidnightGmt = (date) => {
            if (!date) {
                return date;
            }
            return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).toDate();
        }

        dateDiff = (date1, date2) => {
            return this.convertToMoment(date1).diff(this.convertToMoment(date2));
        }

        isValid = (date) => {
            return this.convertToMoment(date).isValid();
        }

        isSame = (date1, date2) => {
            if (date1 === date2) {
                return true;
            }
            return this.dateDiff(this.convertToMoment(date1), this.convertToMoment(date2)) === 0;
        }

        isBefore = (date1, date2) => {
            return (this.dateDiff(this.convertToMoment(date1), this.convertToMoment(date2)) < 0);
        }

        isBeforeOrSame = (date1, date2) => {
            return (this.dateDiff(this.convertToMoment(date1), this.convertToMoment(date2)) <= 0);
        }

        isAfter = (date1, date2) => {
            return (this.dateDiff(this.convertToMoment(date1), this.convertToMoment(date2)) > 0);
        }

        isAfterOrSame = (date1, date2) => {
            return (this.dateDiff(this.convertToMoment(date1), this.convertToMoment(date2)) >= 0);
        }

        taxYearStart = () => {
            var now = moment();
            var startDateString = this.taxYearDay + "/" + this.taxYearMonth + "/" + now.year();
            var startDate = moment(startDateString, 'DD/MM/YYYY').add(1, 'days');

            if (startDate > now) {
                startDate.add(-1, 'years');
            }

            return startDate;
        }

        taxYearEnd = () => {
            return this.taxYearStart().add(1, 'years').add(-1, 'days');
        }

        currentTaxYear = () => {
            return this.taxYearStart().year() + "/" + this.taxYearEnd().year();
        }

        previousTaxYear = () => {
            return this.previousTaxYearStart().year() + "/" + this.previousTaxYearEnd().year();
        }

        previousTaxYearStart = () => {
            return this.taxYearStart().add(-1, 'years');
        }

        previousTaxYearEnd = () => {
            return this.taxYearEnd().add(-1, 'years');
        }

        nextTaxYearStart = () => {
            return this.taxYearStart().add(1, 'years');
        }

        nextTaxYearEnd = () => {
            return this.taxYearEnd().add(1, 'years');
        }

        nextTaxYear = () => {
            return this.nextTaxYearStart().year() + "/" + this.nextTaxYearEnd().year();
        }

        ageFromDate = (date) => {
            return this.convertToMoment(date).diff(moment(), 'years');
        }


        /**
         * Returns whether the date passed in is the C# default date
         * ('0001-01-01T00:00:00')
         * 
         * @param {string} date
         * @returns {boolean}
         */
        isMinDate = (date: string): boolean => {
            return date === "0001-01-01T00:00:00";
        }

        isOverNMonthsAgo(date: Date, numberOfMonths: number): boolean {
            const nMonthsAgo = moment().subtract(numberOfMonths, 'months');
            return moment(date).isBefore(nMonthsAgo);
        }

        taxYearStartByOffset = (offset: number): any => {
            return this.taxYearStart().add(offset, 'years');
        }

        taxYearEndByOffset = (offset: number): any => {
            return this.taxYearEnd().add(offset, 'years');
        }

        taxYearByOffset = (offset: number): string => {
            return this.taxYearStartByOffset(offset).year() + "/" + this.taxYearEndByOffset(offset).year();
        }
    }
}

angular
    .module('app.shared')
    .service('brookson.utilities.date', Shared.BrooksonUtilitiesDate);