module Shared {
    export class ShareholderSplit {
        public roleTypeCode: string;
        public businessID: string;
        public personID: string;
        public person: string;
        public percentageOfDividend: number;
        public AvailableFunds: number;
        public directorsFees: number;
        public expensesOwed: number;
        public dividend: number;
        public moneyAvailable: number;
    }
}