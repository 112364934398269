/// <reference path="../../shared/models/IntroVideo.ts" />
/// <reference path="../../shared/models/Calendar.ts" />

module Shared {
    export class WelcomeScreen {
        public showWelcomeScreen: boolean;
        public viewCount: number;
        public hideWelcomeScreen: boolean;
        public welcomeScreenLogin: boolean;
        public accountantSalespersonCode: string;
        public accountantFirstName: string;
        public accountantSurname: string;
        public accountantVideoId: string;
        public introVideos: Array<Shared.IntroVideo>;
        public accountantVideo: Shared.IntroVideo;
        public hasWelcomeScreen: boolean;
        public hasCalenderLink: boolean;
        public calendars: Array<Shared.Calendar>;
    }
}