/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.modal.vehicle-details", [
        "$q", "$uibModal", "$sce", 'expenses.enums', ($q, $uibModal, $sce, enums) => {
            var options: any = {
                animation: true,
                size: "lg",
                templateUrl: "src/app/shared/views/modals/brookson.modals.add-edit-vehicle.html"
            };

            var _vehicleDetails = (vehicles, registration) => {
                var defer = $q.defer();
                var addVehicleModal = options;

                addVehicleModal.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    // Data
                    $scope.enums = enums;
                    $scope.selectedVehicles = registration;
                    $scope.vehicles = vehicles;

                    // Methods
                    $scope.cancel = cancel;
                    $scope.selectVehicle = selectVehicle;
                    $scope.vehicleSelected = vehicleSelected;

                    activate();

                    function activate() {
                        if ($scope.vehicles && $scope.selectedVehicles) {
                            _.forEach($scope.vehicles, (vehicle: Shared.ExpenseMileageVehicle) => {
                                if (vehicle.registration === registration) {
                                    vehicle.isSelected = true;
                                }
                            });
                        }
                    }

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function selectVehicle() {
                       
                        let vehicle: Shared.ExpenseMileageVehicle = _.find<Shared.ExpenseMileageVehicle>($scope.vehicles, (vehicle: Shared.ExpenseMileageVehicle) => vehicle.isSelected === true );
                        if (vehicle) {
                            $scope.vehicleSelected = vehicle.registration;
                            $uibModalInstance.close();
                            defer.resolve(vehicle);
                        } else {
                            return;
                        }
                        
                    }

                    function vehicleSelected(data) {
                        if (data.isSelected) {
                            $scope.selectedVehicle = data.registration;

                            _.forEach($scope.vehicles, (vehicle) => {
                                if (data.receiptId !== vehicle.registration) {
                                    vehicle.isSelected = false;
                                }
                            });
                        } else {
                            $scope.selectedVehicle = 0;
                        }
                    }
                }];

                $uibModal.open(addVehicleModal);

                return defer.promise;
            };

            return {
                selectVehicle: _vehicleDetails
            };
        }
    ]);
})();
