/// <reference path="./../enums/VatScheme.ts"/>

module Shared {
    export class BusinessDetailSummary {
        companyName: string;
        companyRegistrationNo: string;
        vatNo: string;
        vatScheme: string;
        flatRateVatEffectiveDate: any;
        standardRateVatEffectiveDate: any;

        getEffectiveVatSchemeOn = (date: Date): string => {
            if (!date || (!this.flatRateVatEffectiveDate && !this.standardRateVatEffectiveDate)) {
                return this.vatScheme;
            }

            switch (this.vatScheme) {
                case VatScheme.nonFlatRate:
                    if (this.standardRateVatEffectiveDate) {
                        return date < this.standardRateVatEffectiveDate ? VatScheme.flatRate : VatScheme.nonFlatRate;
                    }

                    return date < this.flatRateVatEffectiveDate ? VatScheme.nonFlatRate : VatScheme.flatRate;
                case VatScheme.flatRate:
                    if (this.flatRateVatEffectiveDate) {
                        return date < this.flatRateVatEffectiveDate ? VatScheme.nonFlatRate : VatScheme.flatRate;
                    }

                    return date < this.standardRateVatEffectiveDate ? VatScheme.flatRate : VatScheme.nonFlatRate;
                default:
                    if (this.standardRateVatEffectiveDate) {
                        return date < this.standardRateVatEffectiveDate ? VatScheme.none : VatScheme.nonFlatRate;
                    }

                    return date < this.flatRateVatEffectiveDate ? VatScheme.none : VatScheme.flatRate;
            }
        }
    }
}