/// <reference path="./../BrooksonDate.ts" />

module Shared {
    export module Invoicing {
        export class PayslipUmbrellaCompact {
            companyNumber: string;
            payYear: number;
            payPeriod: number;
            payrollNumber: number;
            personId: number;
            paymentDate: string;
            payrollItemCode: string;
            workPeriodEndDate: string;
            amount: number;
            timesheetNumber: string;
            createdDate: string;
            payslip: string;
            invoiceCount: number;
        }
    }
}