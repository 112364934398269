
module Shared {

    export module openbankingWidget{

        export interface IWidgetAccount {
            bankAccountKey: string;
            sortCode: string;
            accountNumber: string;
            availableBalance: number;
            blocked: boolean;
            reconciliationDate: Date;
            bankName: string;
            bankId: string;
            accountType: string;
            type: string;
            viewAccess: boolean;
            key: string;
            viewAccessDescription: string;
            bankLogo: string;
            className: string;
            authorisedProvider: boolean;
            authLink: string;
            consentReminder: boolean;
            consentDateDue: Date;
            consentExpired: boolean;
            consentStatus: number;
            success: boolean;
            pendingStatus: boolean;
            mandateAvailable: boolean;
            hasAccessToken: boolean;
            hasPendingBankStatement: boolean;
            pendingBankStatementBalance: number;
            csvUploadEnabled: boolean;
        }

        export class WidgetAccount implements IWidgetAccount
        {
            bankAccountKey: string;
            sortCode: string;
            accountNumber: string;
            availableBalance: number;
            blocked: boolean;
            reconciliationDate: Date;
            bankName: string;
            bankId: string;
            accountType: string;
            type: string;
            viewAccess: boolean;
            key: string;
            viewAccessDescription: string;
            bankLogo: string;
            className: string;
            authorisedProvider: boolean;
            authLink: string;
            truelayerAuthLink: string;
            consentReminder: boolean;
            consentDateDue: Date;
            consentExpired: boolean;
            consentStatus: number;
            success: boolean;
            pendingStatus: boolean;
            mandateAvailable: boolean;
            hasAccessToken: boolean;
            hasPendingBankStatement: boolean;
            pendingBankStatementBalance: number;
            csvUploadEnabled: boolean;
        }

        export enum accountType {
            Business,
            Personal,
            DLA
        }

        export enum bankType {
            Current,
            Deposit,
            Credit_Card,
            Deposit_Bond
        }

        export enum ConsentStatus {
            ConsentError = -3,
            AccessTokenError = -2,
            NotConsented = 0,
            AccessTokenPending = 1,
            AccessTokenReceived = 2,
            BankAccountsReceived = 3,
            BankBalancesReceived = 4,
            BankTransactionsReceive = 5
        }

    }


}
