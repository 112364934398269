/// <reference path="../../../../app/_all.ts"/>

module Shared {
    export class AcceptCheckbox implements ng.IComponentOptions {
        public bindings: any;
        public templateUrl: any;
        public controller: any;

        constructor() {
            this.bindings = {
                ngModel: '=',
                acceptText: '@'
            };
            this.templateUrl = 'src/app/shared/views/ui/brookson.ui.accept-checkbox.html';
            this.controller = ['$scope', AcceptCheckboxController];
        }
    }

    export class AcceptCheckboxController {
        constructor(private $scope: ng.IScope){}
    }
}

angular.module('app.shared')
    .component("brooksonUiAcceptCheckbox", new Shared.AcceptCheckbox());