/// <reference path="../../../../app/_all.ts"/>
/// <reference path="./../../../shared/models/NavPersonalInformation.ts" />

module Shared {
    
    export class BrooksonUiAccountDetailsContactInformation implements ng.IComponentOptions {
        public controller: any;
        public templateUrl: any;
        public bindings: any;

        constructor() {
            this.bindings = {
                personalInformation: '='
            };
            this.controller = BrooksonUiAccountDetailsContactInformationController;
            this.templateUrl = 'src/app/shared/views/ui/brookson.ui.account-details.contact-information.html';
        }
    }
    
    class BrooksonUiAccountDetailsContactInformationController {
        personalInformation: Shared.NavPersonalInformation;

        constructor() { }

    }
}
angular
    .module('app.shared')
    .component('brooksonUiAccountDetailsContactInformation', new Shared.BrooksonUiAccountDetailsContactInformation());