/// <reference path="./../../shared/models/AccountType.ts"/>
/// <reference path="./BankAccount.ts"/>
/// <reference path="./../../shared/models/InvoicingAssignment.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class NavCompanyInformation  {
        
        // COMPANYTYPE
        public companyType: Shared.AccountType = null;
        // COMPANYNAME
        public companyName: string = null;
        // COMPANYREGISTRATIONNO
        public companyRegistrationNo: string = null;
        // COMPANYREFERENCE
        public companyReference: string = null;
        // DIRECTORSNAME
        public directorsName: string = null;
        // VATREGISTRATIONNO
        public vatRegistrationNo: string = null;
        // BANKACCOUNTS
        public bankAccounts: BankAccount[] = [];
        // ASSIGNMENTS
        public assignments: Shared.InvoicingAssignment[] = [];
        //CORPORATIONTAXREFNO
        public corporationTaxRefNo: string = null;
        public cisEnabled: boolean;
    }
}
