/// <reference path="../../_all.ts"/>

(() => {
    'use strict';


    angular.module('app.shared').factory('brooksonSignalrFactory', brooksonSignalrFactory);

    brooksonSignalrFactory.$inject = ['$rootScope'];
    function brooksonSignalrFactory(rootScope) {
        if (!$.connection) {
            throw ('SignalR required');
        }

        var connected = false;
        var connect = $.connection.hub.start().then(() => { connected = true; });
        var openBankingSubscriptions = [];
        var openBankingReconsentSubscriptions = [];
        var fullInvoicePdfSubscriptions = [];

        const ensureConnected = (callback: any): void => {
            if ($.connection.hub.state === $.signalR.connectionState.connected) {
                callback();
            }
            else if ($.connection.hub.state === $.signalR.connectionState.disconnected) {
                $.connection.hub.start()
                    .done(() => {
                        connected = true;
                        callback();
                    });
            }
        };

        // Base connect instance
        var instance: any = {
            connect: (callback) => {
                connect.then(() => {
                    if (callback) {
                        rootScope.$apply(() => {
                            callback();
                        });
                    }
                });
            }
        };


        // OpenBanking Methods
        var openBankingHub = $.connection.openBankingHub;
        instance.openBanking = {
            subscribeToPage: (memberId, companyRef) => {
                ensureConnected(() => { openBankingHub.server.subscribeToPage(memberId, companyRef); });
                openBankingSubscriptions.push(memberId + '-' + companyRef);
            },
            unSubscribeFromPage: (memberId, companyRef) => {
                ensureConnected(() => { openBankingHub.server.unSubscribeFromPage(memberId, companyRef); });
                var index = openBankingSubscriptions.indexOf(memberId + '-' + companyRef);
                openBankingSubscriptions.splice(index,1);
            }
        };

        var openBankingReconsentHub = $.connection.openBankingReconsentHub;
        instance.openBankingReconsent = {
            subscribeToPage: (memberId, companyRef) => {
                ensureConnected(() => { openBankingReconsentHub.server.subscribeToPage(memberId, companyRef); });
                openBankingReconsentSubscriptions.push(memberId + '-' + companyRef);
            },
            unSubscribeFromPage: (memberId, companyRef) => {
                ensureConnected(() => { openBankingReconsentHub.server.unSubscribeFromPage(memberId, companyRef); });
                var index = openBankingReconsentSubscriptions.indexOf(memberId + '-' + companyRef);
                openBankingReconsentSubscriptions.splice(index, 1);
            }
        };

        // Full Invoice Methods
        var fullInvoiceHub = $.connection.fullInvoicegHub;
        instance.fullInvoicePdfNotifications = {
            subscribeToPage: (memberId, companyRef) => {
                ensureConnected(() => { fullInvoiceHub.server.subscribeToPage(memberId, companyRef); });
                fullInvoicePdfSubscriptions.push(memberId + '-' + companyRef);
            },
            unSubscribeFromPage: (memberId, companyRef) => {
                ensureConnected(() => { fullInvoiceHub.server.unSubscribeFromPage(memberId, companyRef); });
                var index = fullInvoicePdfSubscriptions.indexOf(memberId + '-' + companyRef);
                fullInvoicePdfSubscriptions.splice(index, 1);
            }
        };

        // Radar Listeners
        openBankingHub.client.notification = (notification) => {
            rootScope.$apply(() => {
                rootScope.$broadcast(notification.Action, notification);
            });
        };

        openBankingReconsentHub.client.notification = (notification) => {
            rootScope.$apply(() => {
                rootScope.$broadcast(notification.Action, notification);
            });
        };

        fullInvoiceHub.client.notification = (notification) => {
            rootScope.$apply(() => {
                rootScope.$broadcast(notification.Action, notification);
            });
        };

        return instance;
    }

})();