module Shared {
    export interface IPublicSectorAssignmentCheckService {
        publicSectorAssignmentCheckRequiresCompletion: Array<Shared.Assignment>;
        getPublicSectorAssignmentCheckOutcome(silentMode?: boolean): ng.IPromise<Array<Shared.Assignment>>;
        postPublicSectorAssignmentCheck(assignments: Array<Shared.Assignment>): ng.IPromise<Array<Shared.Assignment>>;
    }

    export class PublicSectorAssignmentCheckService implements IPublicSectorAssignmentCheckService {

        public publicSectorAssignmentCheckRequiresCompletion: Array<Shared.Assignment>;

        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        // Returns whether the member is required to complete the Public Sector Assignment Check form or not
        getPublicSectorAssignmentCheckOutcome = (silentMode?: boolean): ng.IPromise<Array<Shared.Assignment>> => {
            return this.$http.get(`api/Assignment/GetAllOpenUnknownSectorAssignments`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.Assignment>>) => {
                this.publicSectorAssignmentCheckRequiresCompletion = response.data;
                if (response.data !== null) {
                    response.data.forEach(assignment => {
                        assignment.jobTitleOption = new JobTitle();
                        assignment.jobTitleOption.titleText = assignment.jobTitle;
                    });
                }
                return response.data;
            });
        }

        // Posts back to the server what the user chose
        postPublicSectorAssignmentCheck = (assignments: Array<Shared.Assignment>): ng.IPromise<Array<Shared.Assignment>> => {
            assignments.forEach(assignment => {
                assignment.jobTitle = assignment.jobTitleOption.titleText;
            });
            return this.$http.post(`api/Assignment/SaveAssignmentSectorData`, assignments).then((response: ng.IHttpPromiseCallbackArg<Array<Shared.Assignment>>) => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared').service('publicSectorAssignmentCheckService', Shared.PublicSectorAssignmentCheckService);