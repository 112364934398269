/// <reference path="../../_all.ts"/>

(() => {
	angular.module('app.shared')

.run(['$rootScope', ($rootScope) => {
	$rootScope.typeOf = (value) => {
		return typeof value;
	};
}])

.directive('stringToNumber', () => {
	return {
		require: 'ngModel',
		link: (scope, element, attrs, ngModel: any) => {
			ngModel.$parsers.push((value) => {
				return '' + value;
			});
			ngModel.$formatters.push((value) => {
				return parseFloat(value);
			});
		}
	};
});
})();
