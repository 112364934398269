/// <reference path="../../../_all.ts"/>
/// <reference path="./../../modules/brookson.notifications.manager.ts"/>

module Shared {
    export class HeaderController {
        public showCalenderLink: boolean;
        public showAccountantCalenderLink: boolean;
        public showCalenderScreen: boolean;
        public calenderLink: string;
        public displaySourceUrl: string;
        public advisoryDocUrl: string;
        public accountantIntroductionUrl: string;
        public financialHealthCheckUrl: string;
        public accountantName: string;
        public todaysDate: Date;
        //public calendlyButtonText: string = 'Book an appointment';
        public showAdvisoryDoc: boolean;
        // Data
        public member: MemberModel;
        public memberDetails: Member;
        public microCalendlyBooking: MicroCalendlyBooking;
        assignmentOutcomes: Array<Shared.AssignmentSdcOutcome>;

        static $inject = ['$window', 'memberSrv', 'authenticationSrv', 'brookson.notifications.manager',
            'taskPlanner.enums', 'brookson.navigation', 'notification.enums', 'brookson.modal.sdc',
            'brookson.modal.ir35DetailCapture', 'appSettings', 'ir35DetailCaptureService', 'sdcService',
            'umbrellaTrustModalService', "member.enums",
            'publicSectorAssignmentCheckModalService', 'publicSectorAssignmentCheckService',
            '$sce', 'brooksonFactoriesCalendly', 'welcomeSrv', 'calendlySrv', 'brookson.utilities.date',
            'swapServiceLineSrv'];

        constructor(
            private $window: ng.IWindowService,
            private memberSrv: IMemberService,
            private authenticationSrv: any,
            private brooksonNotificationsManager: IBrooksonNotificationManager,
            private taskPlannerEnums: ITaskPlannerEnums,
            private brooksonNavigation: IBrooksonNavigation,
            private notificationEnums: INotificationEnums,
            private sdcModalService: IBrooksonSdcModalService,
            private ir35DetailCaptureModalService: IBrooksonIR35DetailCaptureModalService,
            private appSettings: Shared.AppSettings,
            private ir35DetailCaptureService: Shared.IIR35DetailCaptureService,
            private sdcService: Shared.ISdcService,
            private umbrellaTrustModalService: Shared.IUmbrellaTrustModalService,
            private memberEnums: Shared.IMemberEnums,
            private publicSectorAssignmentCheckModalService: IBrooksonPublicSectorAssignmentCheckModalService,
            private publicSectorAssignmentCheckService: Shared.IPublicSectorAssignmentCheckService,
            public $sce: ng.ISCEService,
            public calendlyFactory: Shared.IBrooksonFactoriesCalendly,
            public welcomeSrv: Shared.WelcomeScreenSrv,
            public calendlySrv: Shared.CalendlyService,
            public dateUtils: Shared.IBrooksonUtilitiesDate,
            public swapServiceLineSrv: Shared.SwapUserServiceLineService
        ) { }

        $onInit = () => {
            this.todaysDate = new Date();
            this.brooksonNotificationsManager.getNotifications();
            this.member = this.memberSrv.getMember();
            this.memberSrv.getMemberDetails(true, false).then((data) => {

                this.memberDetails = data;

                // Check whether the user needs to see the 'umbrella only don't show this to your trust' splash screen enabled
                this.checkUmbrellaTrustOutcome();
            });
            this.checkSdcModal();
            this.checkIR35DetailCapture();
            this.checkPublicSectorAssignmentCheck();
            this.checkWelcomeScreen();
        }

        logout(): void {
            this.authenticationSrv.logout();
        }

        // Account Details Methods
        goToAccountDetails(): void {
            this.brooksonNavigation.account.main();
        }

        // Task Planner Methods
        containsOverdueTasks(): boolean {
            return _.some(this.brooksonNotificationsManager.notifications.planner, { 'status': this.taskPlannerEnums.TaskStatus.OVERDUE });
        }

        // Notifications Methods
        closeAlerts(): void {
            this.brooksonNotificationsManager.closeAlerts();
        }

        /**
         * Returns whether or not the connect 2 button should be shown based
         * on whether the user joined after 03/10/2016
         * 
         * @returns {boolean}
         * 
         * @memberOf HeaderController
         */
        showConnect2Button(): boolean {
            if (!this.memberDetails) return false;

            return this.memberDetails.connect2Enabled;
        }

        /**
         * BEGIN: SDC
         */
        // Returns whether the SDC modal should be displayed for this member
        private shouldDisplaySdcModal = (): boolean => {
            return this.appSettings.displaySdcQuestionnaireCompletionPopup === true && !_.startsWith(window.location.pathname, "/Assignments");
        }
        // Opens the SDC modal - Disabled UMBR-30
        //private displaySdcModal = (): void => {
        //    this.sdcModalService.show();
        //}
        // Checks whether the SDC modal should be displayed for this member and displays it if applicable
        private checkSdcModal = (): void => {
            if (this.shouldDisplaySdcModal()) {
                this.getAssignmentOutcomes();
            }
        }
        // Returns a list of assignment outcomes from the API
        private getAssignmentOutcomes = (): ng.IPromise<Array<Shared.AssignmentSdcOutcome>> => {
            return this.sdcService.getAssignmentOutcomes().then(this.handleGetAssignmentOutcomesResponse);
        }

        // Sets the appropiate properties when the reponse has returned from the API GetAssignnmentOutcome response
        private handleGetAssignmentOutcomesResponse = (data: Array<Shared.AssignmentSdcOutcome>): Array<Shared.AssignmentSdcOutcome> => {
            this.assignmentOutcomes = data.filter(x => x.outcome === Shared.SdcOutcomeType.NotAssessed);

            // Disable SDC (UMBR-30)
            //if (this.assignmentOutcomes.length) {
            //    this.displaySdcModal();
            //}

            return this.assignmentOutcomes;
        }
        /**
         * END: SDC
         */

        /**
         * BEGIN: IR35 detail capture
         * 
         * Methods:
         *  - enableIR35DetailCaptureModal
         *  - displayIR35DetailCaptureModal
         *  - checkIR35DetailCaptureModal
         */

        // Returns whether the IR35 Detail Capture modal is set to be displayed via web.config
        private ir35DetailCaptureModalEnabled = (): boolean => {
            return this.appSettings.displayIR35DetailCapturePopup === true;
        }

        // Opens the IR35 Detail Capture modal
        private displayIR35DetailCaptureModal = (): void => {
            this.ir35DetailCaptureModalService.show();
        }

        // Checks whether the IR35 detail capture modal needs triggering
        private checkIR35DetailCapture = (): void => {
            this.getIR35DetailCaptureOutcome();
        }

        private checkWelcomeScreen = (): void => {
            this.getWelcomeScreenDetails();
        }

        private getWelcomeScreenDetails = (): void => {
            {
                this.welcomeSrv.getWelcomeScreen().then((welcomeScreen: Shared.WelcomeScreen): void => {
                    this.showCalenderLink = false;
                    if (welcomeScreen.hasWelcomeScreen) {
                        this.accountantName = welcomeScreen.accountantFirstName;
                        var accountantIntroductionCalendarUrl = welcomeScreen.calendars.some(f => f.eventType === "Introduction call") ? welcomeScreen.calendars.filter(f => f.eventType === "Introduction call")[0].calendarUrl : null;
                        this.accountantIntroductionUrl = !accountantIntroductionCalendarUrl ? null : this.$sce.trustAsResourceUrl(accountantIntroductionCalendarUrl);
                        if (this.accountantIntroductionUrl) {
                            this.calendlySrv.getCalendlyIntroductionBooking().then((data: any): void => {
                                if (data) {
                                    var microCalendlyBooking = data;
                                    var endDate = this.dateUtils.convertToJsDate(microCalendlyBooking.endDate);
                                    var date = new Date();
                                    date.setDate(date.getDate());
                                    if (microCalendlyBooking.uuid && endDate >= date) {
                                        this.welcomeSrv.microCalendlyBooking = microCalendlyBooking;
                                        this.accountantIntroductionUrl = this.$sce.trustAsResourceUrl('https://calendly.com/reschedulings/' + microCalendlyBooking.uuid);
                                        this.showCalenderLink = true;
                                    } else if (microCalendlyBooking.uuid && endDate < date) {
                                        this.showCalenderLink = false;
                                        this.accountantIntroductionUrl = null;
                                    }
                                    else {
                                        this.welcomeSrv.microCalendlyBooking = microCalendlyBooking;
                                        this.showCalenderLink = true;
                                    }
                                }
                            });
                        }
                    }


                    var advisoryDocCalendarUrl = welcomeScreen.calendars.some(f => f.eventType === "Advisory Doc") ? welcomeScreen.calendars.filter(f => f.eventType === "Advisory Doc")[0].calendarUrl : null;
                    var advisoryDocUrl = !advisoryDocCalendarUrl ? null : this.$sce.trustAsResourceUrl(advisoryDocCalendarUrl);
                    if (advisoryDocUrl) {
                        this.calendlySrv.getCalendlyAdvisoryBooking().then((advisoryDocumentCalendlyBooking: any): void => {
                            if (advisoryDocumentCalendlyBooking) {
                                var endDate = this.dateUtils.convertToJsDate(advisoryDocumentCalendlyBooking.endDate);
                                var date = new Date();
                                date.setDate(date.getDate());
                                var minimumPreviousDate = new Date();
                                minimumPreviousDate.setDate(minimumPreviousDate.getDate() - 30);

                                if (advisoryDocumentCalendlyBooking.uuid && endDate >= date) {
                                    this.welcomeSrv.advisoryDocumentCalendlyBooking = advisoryDocumentCalendlyBooking;
                                    this.advisoryDocUrl = this.$sce.trustAsResourceUrl('https://calendly.com/reschedulings/' + advisoryDocumentCalendlyBooking.uuid);
                                    this.showCalenderLink = true;
                                }
                                if (endDate <= minimumPreviousDate) {
                                    this.welcomeSrv.advisoryDocumentCalendlyBooking = advisoryDocumentCalendlyBooking;
                                    this.advisoryDocUrl = advisoryDocUrl;
                                    this.showCalenderLink = true;
                                    this.welcomeSrv.advisoryDocumentCalendlyBooking.uuid = null;
                                }
                            }
                        });
                    }

                    var financialCalendarUrl = welcomeScreen.calendars.some(f => f.eventType === "Financial Health Check") ? welcomeScreen.calendars.filter(f => f.eventType === "Financial Health Check")[0].calendarUrl : null;
                    var financialHealthCheckUrl = !financialCalendarUrl ? null : this.$sce.trustAsResourceUrl(financialCalendarUrl);
                    if (financialHealthCheckUrl) {
                        this.calendlySrv.getCalendlyFinancialHealthCheckBooking().then((financialHealthCheckCalendlyBooking: MicroCalendlyBooking): void => {
                            if (financialHealthCheckCalendlyBooking) {
                                var endDate = this.dateUtils.convertToJsDate(financialHealthCheckCalendlyBooking.endDate);
                                var todaysDate = new Date();
                                var minimumPreviousDate = new Date();
                                minimumPreviousDate.setDate(minimumPreviousDate.getDate() - 30);

                                if (financialHealthCheckCalendlyBooking.uuid && endDate >= todaysDate) {
                                    this.welcomeSrv.financialHealthCheckCalendlyBooking = financialHealthCheckCalendlyBooking;
                                    this.financialHealthCheckUrl = this.$sce.trustAsResourceUrl('https://calendly.com/reschedulings/' + financialHealthCheckCalendlyBooking.uuid);
                                    this.showCalenderLink = true;

                                }
                                if (endDate <= minimumPreviousDate) {
                                    this.welcomeSrv.financialHealthCheckCalendlyBooking = financialHealthCheckCalendlyBooking;
                                    this.showCalenderLink = true;
                                    this.financialHealthCheckUrl = financialHealthCheckUrl;
                                    this.welcomeSrv.financialHealthCheckCalendlyBooking.uuid = null;
                                }
                            }
                        });
                    }

                });
            }
        }

        public displayIntroductionCalenderScreen = (): void => {
            this.showCalenderScreen = true;
            this.displaySourceUrl = this.accountantIntroductionUrl;
        }

        public displayFinancialHealthCheckScreen = (): void => {
            this.showCalenderScreen = true;
            this.displaySourceUrl = this.financialHealthCheckUrl;
        }

        public displayAdvisoryDocScreen = (): void => {
            this.showCalenderScreen = true;
            this.displaySourceUrl = this.advisoryDocUrl;
        }

        public hideCalenderScreen = (): void => {
            this.showCalenderScreen = false;
        }

        // Returns a list of assignment outcomes from the API
        private getIR35DetailCaptureOutcome = (): ng.IPromise<Shared.IR35DetailCaptureRequiresCompletion> => {
            return this.ir35DetailCaptureService.getIR35DetailCaptureOutcome().then(this.handleRequiresCompletionResponse);
        }

        // Sets the appropiate properties when the reponse has returned from the API GetAssignnmentOutcome response
        private handleRequiresCompletionResponse = (data: Shared.IR35DetailCaptureRequiresCompletion): Shared.IR35DetailCaptureRequiresCompletion => {

            if (this.ir35DetailCaptureModalEnabled() && data.requiresCompletion) {
                this.displayIR35DetailCaptureModal();
            }

            return data;
        }

        /**
         * END: IR35 detail capture
         */

        /**
         * BEGIN: UmbrellaTrustTimesheetModal
         */
        // Returns true if the member's company ref is UM1004U
        private isMemberCompanyRefUM1004U = (): boolean => {
            return this.memberDetails.companyRef === "UM1004U";
        }

        private isUmbrellaTrustModalConfigEnabled = (): boolean => {
            return this.appSettings.displayUmbrellaTrustModal === true;
        }

        private getUmbrellaTrustModalOutcome = (): boolean => {
            return this.isMemberCompanyRefUM1004U() && this.isUmbrellaTrustModalConfigEnabled();
        }

        private checkUmbrellaTrustOutcome = (): void => {
            // if getUmbrellaTrustModalOutcome === true, display the modal
            if (this.getUmbrellaTrustModalOutcome()) {
                this.displayUmbrellaTrustOutcome();
            }
        }

        private displayUmbrellaTrustOutcome = (): void => {
            this.umbrellaTrustModalService.show();
        }
        /**
         * END: UmbrellaTrustTimesheetModal
        */

        // Returns whether the assignemnts check modal is set to be displayed or not via web.config
        private publicSectorAssignmentCheckModalEnabled = (): boolean => {
            return this.appSettings.displayPublicSectorAssignmentCheckPopup === true;
        }

        // Opens the assignment check modal
        private displayPublicSectorAssignmentCheckModal = (): void => {
            this.publicSectorAssignmentCheckModalService.show();
        }

        // Checks whether the assignment check modal needs triggering
        private checkPublicSectorAssignmentCheck = (): void => {
            this.getPublicSectorAssignmentCheckOutcome();
        }

        // Returns a list of flagged assignments from the API
        private getPublicSectorAssignmentCheckOutcome = (): ng.IPromise<Array<Shared.Assignment>> => {
            return this.publicSectorAssignmentCheckService.getPublicSectorAssignmentCheckOutcome().then(this.handlePublicSectorAssignmentCheckCompletionResponse);
        }

        // Sets the appropriate properties when the response has return from the API GetAllOpenUnknownSectorAssignments response
        private handlePublicSectorAssignmentCheckCompletionResponse = (data: Array<Shared.Assignment>): Array<Shared.Assignment> => {
            if (this.publicSectorAssignmentCheckModalEnabled() && data.length) {
                this.displayPublicSectorAssignmentCheckModal();
            }

            return data;
        }

        /**
         * END: Public sector assignment check
         */
    }
}

angular.module('app.shared').component('brooksonSharedHeader', {
    controller: Shared.HeaderController,
    templateUrl: 'src/app/shared/views/header.html'
});