module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/


    export class ExpenseWarnings {

        // EXPENSEWARNINGID
        public expenseWarningId: number = 0;
        // CODEVALUE
        public codeValue: string = null;
        // CODETEXT
        public codeText: string = null;
        // CATEGORY
        public category: string = null;
        // WARNINGMESSAGETEXT
        public warningMessageText: string = null;
        // TIMELENGTH
        public timeLength: number = 0;
        // VALUELIMIT
        public valueLimit: number = 0;
        // HIGHRISK
        public highRisk: boolean = false;
        // 24MTHRULEAPPLICABLE
        public twentyFourMthRuleApplicable: boolean = false;
        // 24MTHTRIGGEROVERRIDE
        public twentyFourMthTriggerOverride: number = 0;
        // ENABLED
        public enabled: boolean = false;
    }
}
