/// <reference path="../../_all.ts"/>
/// <reference path="../models/Transaction.ts"/>
/// <reference path="../modules/brookson.utilities.date.ts"/>
/// <reference path="../../shared/services/brookson.services.bank-link.ts" />
/// <reference path="../../shared/services/brookson.services.money-manager.ts" />
/// <reference path="../../shared/services/brookson.services.authentication.ts" />
/// <reference path="../../shared/services/brookson.services.service-line.ts" />
/// <reference path="../../shared/factories/brookson.factories.expenses.ts" />
/// <reference path="../../shared/enums/VatScheme.ts" />
/// <reference path="../../shared/enums/TransactionTypes.ts" />
/// <reference path="../../shared/models/Receipt.ts" />

module Shared {
    
    export interface IBankLinkFactory {
        confirmedTransactions: BankLink.Transaction[];
        warningTransactions: BankLink.Transaction[];
        confirmedTransactionsTotal: number;
        acceptRisk: string;

        bankAccounts: any;
        bankAccountsFlatList: Array<any>;
        selectedBankAccount: any;
        transactions: Array<any>;
        categories: Array<any>;
        transactionTypes: Array<any>;
        selectedTransactionType: any;
        addYodleeFasklinkUrl: string;
        editYodleeFasklinkUrl: string;
        refreshYodleeFasklinkUrl: string;
        bankEnabled: boolean;
        csvUploadRequired: boolean;
        bankActionRequired: boolean;
        moneyManagerData: any;
        transactionsList: Array<any>;
        businessOnly: boolean;
        modifiedTransactions: BankLink.Transaction[];
        showAside: boolean;
        receipts: Array<any>;
        paginationArray: Array<any>;
        currentPage: number;
        itemsPerPage: number;
        informationDetail: any;
        relatedCategores: Array<any>;
        orderBy: string;
        reverseSort: boolean;
        isMobile: boolean;
        refreshedYodleeAccounts: Array<any>;
        selectedAssignment: any;
        assignments: any;

        getBankAccounts(): void;
        addBankLogoAndIconToBankAccount(account): void;
        addBankNameToTransactions(transactions): void;
        getCategories();
        getAllVatRatesConfig(silentMode?: boolean): ng.IPromise<Array<VatRateConfig>>;
        setRelatedTransationCategories(transaction: BankLink.Transaction);
        getTransactionTypes(): void;
        addYodleeAccount();
        editYodleeAccount(id);
        refreshYodleeAccount(id);
        isBankEnabled();
        isBankActionRequired();
        isCsvUploadRequired();
        addBalanceToTransactions(transactions): void;
        setOtherTransactionCategoryDescription(transactions): void;
        setTransactionsReceiptImageThumbnail(transactions): void;
        getTransactions(getReceiptImageThumbnailData?: boolean);
        getMoneyManagerData(silentMode?: boolean);
        selectedTransactionTypeChanged(transactionType): void;
        selectedBankAccountChanged(bankAccount): void;
        filterTransactions(selectedBankAccount, selectedTransactionType): void;
        addModifiedTransaction(category, transaction: BankLink.Transaction): void;
        check24MonthRuleApplicable(transaction: BankLink.Transaction): boolean;
        getAllocationConfirmationModal(form: ng.IFormController);
        saveTransactions(): void;
        updateSuccessfulllyAllocatedTransaction(transactions, transactionAllocationResult): void;
        downloadFile(bankName: string): void;
        selectBankAccountFromAsideMenu(bankAccount): void;
        getReceipts(): void;
        receiptAttached(transaction: BankLink.Transaction);
        receiptAttachedToAllocated(transaction: BankLink.Transaction);
        receiptDeleted(transaction: BankLink.Transaction);
        receiptsTotal(): number;
        flowOptions(transaction);
        flowFileAdded(file, flow, transaction): boolean;
        flowFileSuccess(file, response, flow, transaction: BankLink.Transaction): void;
        flowFilesSubmitted(files, event, flow): void;
        createPaginationArray(): void;
        filterTransactionType(item): boolean;
        isViewingPersonal(): boolean;
        isViewingAll(): boolean;
    }

    export class BankLinkFactory implements IBankLinkFactory {
        static $inject = [
            '$state', '$uibModal', '$timeout', 'bankLinkSrv', 'moneyManagerSrv', 'memberSrv',
            'authenticationSrv', 'bankLink.enums', 'appSettings', 'brookson.file.download',
            'brookson.modal.receipt-bank', 'brooksonFactoriesExpenses', 'screenSize', 'growl',
            'expenses.enums', 'brookson.utilities.date', 'serviceLineSrv'
        ];

        public bankAccounts;
        public bankAccountsFlatList: Array<any> = [];
        public selectedBankAccount;
        public transactions: Array<any> = [];
        public categories: Array<any> = [];
        public vatRatesConfig: Array<VatRateConfig> = [];
        public transactionTypes: Array<any> = [];
        public selectedTransactionType;
        public addYodleeFasklinkUrl: string = '';
        public editYodleeFasklinkUrl: string = '';
        public refreshYodleeFasklinkUrl: string = '';
        public bankEnabled: boolean = false;
        public csvUploadRequired: boolean = false;
        public bankActionRequired: boolean = false;
        public moneyManagerData;
        public transactionsList: Array<any> = [];
        public businessOnly: boolean = false;
        public modifiedTransactions: BankLink.Transaction[] = [];
        public showAside: boolean = false;
        public receipts: Array<any> = [];
        public paginationArray: Array<any> = [];
        public currentPage: number = 1;
        public itemsPerPage: number = 10;
        public informationDetail = {
            mandate: false,
            yodlee: false,
            noLink: false
        };
        public relatedCategores: Array<any> = [];
        public orderBy: string = 'transactionDate';
        public reverseSort: boolean = false;
        public isMobile: boolean = false;
        public refreshedYodleeAccounts: Array<any> = [];
        public selectedAssignment: any;

        public confirmedTransactions: BankLink.Transaction[] = [];
        public warningTransactions: BankLink.Transaction[] = [];
        public confirmedTransactionsTotal: number = 0;
        public acceptRisk: string = "true";
        public headerForm: ng.IFormController;
        public assignments: any;
        public userName: string;

        constructor (
            private $state,
            private $uibModal,
            private $timeout,
            private bankLinkSrv: Shared.IBankLinkSrv,
            private moneyManagerSrv: Shared.IMoneyManagerService,
            private memberSrv: Shared.IMemberService,
            private authenticationSrv: Shared.IAuthenticationService,
            private bankLinkEnums: Shared.IBankLinkEnums,
            private appSettings,
            private fileDownloadSrv: any,
            private brooksonModalReceiptBank: any,
            private expensesSrv: Shared.IExpensesFactory,
            private screenSize: any,
            private growl: ng.growl.IGrowlService,
            private expensesEnums: Shared.IExpensesEnums,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            public serviceLineSrv: Shared.IServiceLineService
        ) {
            this.isMobile = this.screenSize.is('sm,xs') ? true : false;
            this.memberSrv.getMemberDetails(true, true)
                .then((data: Member) => {
                    this.userName = data.firstname + " " + data.lastname;
                });
        }

        getBankAccounts = () => {
            return this.bankLinkSrv.getBankAccounts().then(data => {
                this.bankAccounts = data;

                _.forEach(data.bankAccountsMandateLink, account => {
                    this.addBankLogoAndIconToBankAccount(account);
                    this.bankAccountsFlatList.push(account);
                });

                _.forEach(data.bankAccountsYodleeLink, account => {
                    this.addBankLogoAndIconToBankAccount(account);
                    this.bankAccountsFlatList.push(account);
                });

                _.forEach(data.bankAccountsNoDataLink, account => {
                    this.addBankLogoAndIconToBankAccount(account);
                    this.bankAccountsFlatList.push(account);
                });

                _.forEach(this.bankAccountsFlatList, account => {
                    account.displayString = account.bankName + " - " + account.accountNumber;
                });

                if (this.screenSize.is('md,lg')) {
                    this.bankAccountsFlatList.unshift({ displayString: "All accounts", bankAccountId: "*" });
                }

                if (this.bankAccountsFlatList.length) {
                    this.selectedBankAccount = this.bankAccountsFlatList[0];
                } else {
                    this.$timeout(() => {
                        this.$state.go('brookson.bankLink.introduction');
                    });
                }

                if (this.transactions && this.transactions.length > 0) {
                    this.addBankNameToTransactions(this.transactions);
                    _.forEach(this.transactions, (transaction: BankLink.Transaction) => this.setRelatedTransationCategories(transaction));
                }

                return data;
            });
        }

        addBankLogoAndIconToBankAccount = (account) => {
            var icon = this.bankLinkEnums.BankIcons[account.bankName.toUpperCase()];
            var logo = this.bankLinkEnums.BankLogos[account.bankName.toUpperCase()];

            account.icon = icon;
            account.logo = logo;
        }

        addBankNameToTransactions = (transactions) => {
            _.forEach(transactions, transaction => {
                var relatedBankAccount = _.find(this.bankAccountsFlatList, (bankAccount: any) => (bankAccount.bankAccountId === transaction.bankId));

                if (relatedBankAccount) {
                    transaction.bankName = relatedBankAccount.bankName;
                    transaction.bankId = relatedBankAccount.bankAccountId;
                    transaction.icon = relatedBankAccount.icon;
                    transaction.logo = relatedBankAccount.logo;
                }
            });
        }

        getCategories = () => {
            return this.bankLinkSrv.getCategories().then(data => {

                // We remove a couple of categories from the list, historic reasons mean we need to keep them in NAV
                _.remove(data, (category:any) => {
                    if (category.code === "DLA" || category.code === "SALARY") {
                        return true;
                    }
                });

                _.forEach(data, category => {
                    category.transactionTypeLabel = category.transactionType === 0 ? "Personal" : "Business";

                    // Hack: don't want to change it properly in NAV so SW said to do it in the view
                    if (category.code === "EXP0185") {
                        category.description = "Subsistence";
                    }
                });

                this.categories = data;

                _.filter(this.transactions, (transaction: any) => {
                    return transaction.transactionType === this.bankLinkEnums.TransactionType.UNALLOCATED;
                }).forEach(transaction => this.setRelatedTransationCategories(transaction));

                return data;
            });
        }

        setRelatedTransationCategories = (transaction: BankLink.Transaction) => {
            var relatedBankAccount = _.find(this.bankAccountsFlatList, (bankAccount: any) => (bankAccount.bankAccountId === transaction.bankId));

            if (!relatedBankAccount) {
                return;
            }

            var nonSoleTraderCategories = _.filter(this.categories, (category: any) => {
                return !_.startsWith(category.code, "ST");
            });

            if (relatedBankAccount.accountType === this.bankLinkEnums.BankAccountType.CREDIT_CARD) {
                transaction.relatedCategories = nonSoleTraderCategories .filter((category:any) => {
                    return category.creditCardApplicable && (category.type === "Debit" ? transaction.debitAmount < 0 : transaction.creditAmount > 0);
                });

                return;
            }

            var categoriesWithNoCcDiv = _.filter(nonSoleTraderCategories, category => { return category.code !== "CC DIV"; });
            var creditOrDebitCategories = _.filter(categoriesWithNoCcDiv, category => {
                return category.type === "Debit" ? transaction.debitAmount < 0 : transaction.creditAmount > 0;
            });
            var categoriesGroupedByDescription = _.groupBy(creditOrDebitCategories, 'description');

            transaction.relatedCategories =
                _.map(categoriesGroupedByDescription, (categoryGrouping: any) => {
                    if (categoryGrouping.length > 1) {
                        var applicableCategory = _.find(categoryGrouping, (category: any) => {
                            return !category.creditCardApplicable;
                        });

                        return applicableCategory;
                    }

                    return categoryGrouping[0];
                });
        }

        getTransactionTypes = () => {
            _.forEach(this.bankLinkEnums.TransactionType, type => {
                if (this.bankLinkEnums.TransactionType.properties[type] && this.bankLinkEnums.TransactionType.properties[type].description) {
                    this.transactionTypes.push({ value: type, description: this.bankLinkEnums.TransactionType.properties[type].description });
                }
            });

            this.selectedTransactionType = _.find(this.transactionTypes,
            (transactionTypes: any) => (transactionTypes.value === this.bankLinkEnums.TransactionType.UNALLOCATED));
        }

        addYodleeAccount = () => {
            return this.bankLinkSrv.addYodleeAccount().then(data => {
                this.addYodleeFasklinkUrl = data;

                return data;
            });
        }

        getAllVatRatesConfig = () => {
            return this.bankLinkSrv.getAllVatRatesConfig(true).then(data => {
                this.vatRatesConfig = data;
                return data;
            });
        }
        
        editYodleeAccount = (id) => {
            return this.bankLinkSrv.editYodleeAccount(id).then(data => {
                this.editYodleeFasklinkUrl = data;

                return data;
            });
        }

        isCsvUploadRequired = () => {
            return this.bankLinkSrv.isCsvUploadRequired().then(data => {
                this.csvUploadRequired = data;

                return data;
            });
        }

        refreshYodleeAccount = (id) => {
            return this.bankLinkSrv.refreshYodleeAccount(id).then(data => {
                this.refreshYodleeFasklinkUrl = data;

                return data;
            });
        }

        isBankEnabled = () => {
            return this.bankLinkSrv.isBankEnabled().then(data => {
                this.bankEnabled = data;

                return data;
            });
        }

        isBankActionRequired = () => {
            return this.bankLinkSrv.isBankActionRequired().then(data => {
                this.bankActionRequired = data;

                return data;
            });
        }

        addBalanceToTransactions = (transactions) => {
            var transactionsOrderedByDate = _.orderBy(transactions, 'transactionDate', 'desc');

            var groupedTransactionsByBankAccount = _.groupBy(transactionsOrderedByDate, 'bankId');

            _.forEach(groupedTransactionsByBankAccount, (transactionGroup, key) => {
                var bankId = key;
                var bankAccount = _.find(this.moneyManagerData.bankAccounts, (bankAccount: any) => (bankAccount.bankAccountID === bankId));

                if (bankAccount) {
                    var endBalance = bankAccount.balance;

                    _.forEach(transactionGroup, (transaction: any) => {
                        transaction.balance = endBalance;
                        endBalance = endBalance - transaction.creditAmount - transaction.debitAmount;
                    });
                }
            });
        }

        setOtherTransactionCategoryDescription = (transactions) => {
            _.forEach(transactions, transaction => {
                if (transaction.transactionType !== 0 && transaction.transactionType === this.bankLinkEnums.TransactionType.UNALLOCATED) {
                    transaction.transactionCategory.description = "Other";
                }
            });
        }

        setTransactionsReceiptImageThumbnail = (transactions) => {
            _.forEach(transactions, transaction => {
                if (transaction.receiptID) {
                    this.expensesSrv.getReceiptImageData(transaction.receiptID, null, null, null, true).then(imageData => {
                        transaction.receiptThumbnail = imageData;
                    });
                }
            });
        }

        getTransactions = (getReceiptImageThumbnailData?: boolean) => {
            return this.moneyManagerSrv.getTransactions().then(data => {
                if(data == null)
                {
                    return null;
                }

                this.transactions = data.transactions;

                this.setOtherTransactionCategoryDescription(this.transactions);

                //if (getReceiptImageThumbnailData) {
                //    this.setTransactionsReceiptImageThumbnail(this.transactions);
                //}

                _.filter(this.transactions, (transaction: any) => transaction.transactionType === this.bankLinkEnums.TransactionType.UNALLOCATED)
                    .forEach((transaction) => {
                        transaction.gross = transaction.debitAmount * -1;
                        return this.setRelatedTransationCategories(transaction);
                    });

                // this.expensesNotYetTaken = data.expensesNotYetTaken;
                // this.salaryNotYetTaken = data.salaryNotYetTaken;

                if (this.bankAccountsFlatList && this.bankAccountsFlatList.length > 0) {
                    this.addBankNameToTransactions(this.transactions);
                }

                if (this.moneyManagerData && this.moneyManagerData.bankAccounts && this.moneyManagerData.bankAccounts.length > 0) {
                    this.addBalanceToTransactions(this.transactions);
                }

                this.filterTransactions(this.selectedBankAccount, this.selectedTransactionType);

                return data;
            });
        }

        getMoneyManagerData = (silentMode?: boolean) => {
            return this.moneyManagerSrv.getMoneyManagerData(silentMode).then(data => {
                this.moneyManagerData = data;

                if (this.transactions && this.transactions.length > 0) {
                    this.addBalanceToTransactions(this.transactions);
                }

                this.filterTransactions(this.selectedBankAccount, this.selectedTransactionType);

                return data;
            });
        }

        selectedTransactionTypeChanged = (transactionType) => {
            if (!transactionType) {
                transactionType = this.selectedTransactionType;
            }

            this.filterTransactions(this.selectedBankAccount, transactionType);
        }

        selectedBankAccountChanged = (bankAccount) => {
            if (!bankAccount) {
                bankAccount = this.selectedBankAccount;
            }

            this.filterTransactions(bankAccount, this.selectedTransactionType);
        }

        filterTransactions = (selectedBankAccount, selectedTransactionType) => {
            var filteredList;

            if (selectedBankAccount && selectedBankAccount.bankAccountId) {
                if (selectedBankAccount.bankAccountId !== "*") {
                    filteredList = _.filter(this.transactions, (transaction: any) => (transaction.bankId === selectedBankAccount.bankAccountId));
                } else {
                    filteredList = this.transactions;
                }
            }

            if (selectedTransactionType && selectedTransactionType.value !== undefined) {
                if (filteredList) {
                    if (selectedTransactionType.value !== this.bankLinkEnums.TransactionType.ALL) {
                        filteredList = _.filter(filteredList, (transaction: any) =>
                            transaction.transactionType === selectedTransactionType.value);
                    }
                } else {
                    if (selectedTransactionType.value !== this.bankLinkEnums.TransactionType.ALL) {
                        filteredList = _.filter(this.transactions, (transaction: any) => (transaction.transactionType === selectedTransactionType.value));
                    }
                }
            }

            if (filteredList === undefined) {
                filteredList = [];
            }

            if (this.businessOnly) {
                filteredList = _.filter(filteredList, (transacation: any) => transacation.debitAmount < 0);
            }

            this.transactionsList = filteredList;

            this.createPaginationArray();
        }

        addModifiedTransaction = (category, transaction: BankLink.Transaction) => {
            _.remove(this.modifiedTransactions, (t: BankLink.Transaction) => t.transactionId === transaction.transactionId);
            transaction.transactionCategory = category;

            if (category) {
                this.modifiedTransactions.push(transaction);
            }

            transaction.isVatReclaimable = this.serviceLineSrv.businessDetailSummary.getEffectiveVatSchemeOn(this.dateUtils.convertToJsDate(transaction.transactionDate)) ===
                VatScheme.nonFlatRate &&
                transaction.transactionCategory &&
                transaction.transactionCategory.isVatable;

            if (!transaction.isVatReclaimable) {
                transaction.hasValidVatReceipt = false;
                transaction.vat = 0;
                transaction.net = (-transaction.gross || 0) - (transaction.vat || 0);
            }
        }

        check24MonthRuleApplicable = (transaction): boolean => {
            return (
                    // Check whether transaction category is 24 month rule applicable
                    transaction.transactionCategory.is24MonthRuleApplicable
                    
                    // AND
                    &&

                    // Check whether assignment is over 24 months old
                    this.selectedAssignment &&
                    this.selectedAssignment.dateStarted &&
                    this.dateUtils.isOverNMonthsAgo(this.dateUtils.convertToJsDate(this.selectedAssignment.dateStarted), 24)
                );
        }

        getAllocationConfirmationModal = (form:ng.IFormController) => {
            this.headerForm = form;
            this.$uibModal.open({
                animation: true,
                templateUrl: 'src/app/shared/views/bank/modals/brookson.bankLink.modals.allocationConfirmation.html',
                controller: 'bankAllocationConfirmationCtrl',
                controllerAs: '$ctrl',
                size: "lg"
            });

        }

        saveTransactions = () => {
            _.forEach(this.modifiedTransactions,
                transaction => {
                    transaction.transactionType = transaction.transactionCategory.transactionType;
                });

            var modifiedTransactionsCount = this.modifiedTransactions.length;

            _.each(this.modifiedTransactions, (transaction) => {
                if (!transaction.passes24MonthRule) {
                    transaction.accept24MonthRuleRisk = this.acceptRisk === "true";
                }
            });

            this.bankLinkSrv.updateTransactions(this.modifiedTransactions)
                .then((result: BankLink.Transaction[]) => {
                    var successfullyUpdatedTransactions = _.filter(this.modifiedTransactions, (t: BankLink.Transaction) =>
                        _.find(result, r => r.transactionId === t.transactionId) != null);

                    var unsuccessfullyUpdatedTransactions = _.filter(this.modifiedTransactions, (t: BankLink.Transaction) =>
                        _.find(result, r => r.transactionId === t.transactionId) == null);

                    this.updateSuccessfulllyAllocatedTransaction(successfullyUpdatedTransactions, result);

                    let config: angular.growl.IGrowlMessageConfig = { title: "Bank", ttl: 5000, disableCountDown: true };

                    if (result.length === modifiedTransactionsCount) {
                        this.growl.success("The modified transactions were successfully allocated", config);
                        this.headerForm.$setPristine();
                        this.headerForm = null;
                    } else {
                        this.growl.warning("We were unable to update all of your allocations. If this error persists, please contact customer service.", config);

                        _.forEach(unsuccessfullyUpdatedTransactions,
                            transaction =>
                            this.growl.error( `Unable to update transaction ${transaction.transactionDate} : ${transaction.description}.`, config));
                    }

                    this.modifiedTransactions.length = 0;
                    this.filterTransactions(this.selectedBankAccount, this.selectedTransactionType);
                });
        }

        updateSuccessfulllyAllocatedTransaction = (transactions, transactionAllocationResult) => {
            _.forEach(transactions,
                transaction => {
                    _.remove(this.transactions, t => t === transaction);
                });

            _.forEach(transactions, (transaction: BankLink.Transaction) => {
                var relatedResult = _.find(transactionAllocationResult, (t: BankLink.Transaction) => { return t.transactionId === transaction.transactionId; });

                transaction.transactionStatus = relatedResult.transactionStatus;
                transaction.transactionType = transaction.transactionCategory.transactionType;
                transaction.transactionCategory = relatedResult.transactionCategory;
                transaction.vat = relatedResult.vat;
                transaction.hasValidVatReceipt = relatedResult.hasValidVatReceipt;
                transaction.receiptID = relatedResult.receiptID;
                this.transactions.push(transaction);
            });

            this.transactions = _.orderBy(this.transactions, ['transactionDate', 'transactionId'], ['desc', 'desc']);
        }

        downloadFile = (bankName: string) => {
            var url = "api/BankLink/DownloadMandateForm?id=" + bankName;
            var expectedMediaType = "application/octet-stream";
            var memberId = this.memberSrv.getMember().memberId;

            this.fileDownloadSrv.downloadAndSave(url, null, 'Mandate - ' + bankName + ' - ' + memberId + '.pdf', expectedMediaType);
        }

        selectBankAccountFromAsideMenu = (bankAccount) => {
            this.selectedBankAccount = bankAccount;
            this.selectedBankAccountChanged(bankAccount);
            this.showAside = false;
        }

        getReceipts = () => {
            this.expensesSrv.getReceipts().then(data => {
                this.receipts = data;
            });
        }

        receiptAttached = (transaction: BankLink.Transaction) => {
            return receipt => {
                transaction.receiptID = receipt.receiptId;
                transaction.receiptThumbnail = receipt.imageData;

                var localReceipt = _.find(this.receipts, (n: Shared.Receipt) => (n.receiptId === transaction.receiptID));
                localReceipt.status = this.expensesEnums.ReceiptStatus.UNSUBMITTED;
                localReceipt.isSelected = false;

                this.addModifiedTransaction(transaction.transactionCategory, transaction);
            };
        }

        receiptAttachedToAllocated = (transaction: BankLink.Transaction) => {
            return receipt => {
                transaction.receiptID = receipt.receiptId;

                var localReceipt = _.find(this.receipts, (n: Shared.Receipt) => (n.receiptId === transaction.receiptID));
                localReceipt.status = this.expensesEnums.ReceiptStatus.UNSUBMITTED;
                localReceipt.isSelected = false;

                this.updateAllocatedTransaction(transaction);
            };
        }

        receiptDeleted = (transaction: BankLink.Transaction) => {
            var receipt = _.find(this.receipts, (n: Shared.Receipt) => (n.receiptId === transaction.receiptID));

            receipt.status = this.expensesEnums.ReceiptStatus.UNATTACHED;

            transaction.receiptID = 0;
            this.addModifiedTransaction(transaction.transactionCategory, transaction);
        }

        receiptsTotal = (): number => {
            return _.filter(this.receipts, (receipt: any) => (receipt.status === this.expensesEnums.ReceiptStatus.UNATTACHED)).length;
        }

        flowOptions = (transaction) => {
            transaction.flowOptions = this.expensesSrv.createReceiptFlowFactory();
            return transaction.flowOptions;
        }

        flowFileAdded = (file, flow, transaction): boolean => {
            if (!{ jpg: 1, png: 1, pdf: 1, jpeg: 1, gif: 1 }[file.getExtension()]) {
                transaction.fileTypeValidationError = true;
            }

            if (file.size > 10000000) {
                transaction.fileSizeValidationError = true;
            }

            if (transaction.fileTypeValidationError || transaction.fileSizeValidationError) {
                this.$timeout(transaction => {
                    transaction.fileTypeValidationError = false;
                    transaction.fileSizeValidationError = false;
                }, 4000, true, transaction);
                return false;
            }

            flow.files = [];

            return true;
        }

        flowFileSuccess = (file, response, flow, transaction: BankLink.Transaction) => {
            var receipt = angular.fromJson(response);
            transaction.receiptID = receipt.receiptId;

            if (transaction.transactionType === this.bankLinkEnums.TransactionType.BUSINESS) {
              flow.files = [];
              this.updateAllocatedTransaction(transaction);
            }
            else {
              this.addModifiedTransaction(transaction.transactionCategory, transaction);
              transaction.receiptThumbnail = receipt.imageDataEncoded;
              receipt.imageData = receipt.imageDataEncoded;
              this.receipts.push(receipt);
              this.$timeout(flow => { flow.files = []; }, 2000, true, flow);
            }
        }

        flowFilesSubmitted = (files, event, flow) => {
            this.authenticationSrv.refreshToken().then(data => {
                flow.opts.headers.Authorization = "Bearer " + data.access_token;
                flow.upload();
            });
        }

        createPaginationArray = () => {
            this.paginationArray = [];

            let pages = this.transactionsList.length / this.itemsPerPage;
            pages = pages < 1 ? pages : Math.ceil(pages);

            for (let i = 0; i < pages; i++) {
                this.paginationArray.push(i);
            }

            this.currentPage = 1;
        }

        filterTransactionType = (item): boolean => {
            if (item.value === this.bankLinkEnums.TransactionType.OUTGOINGS)
                return false;

            if (this.businessOnly && item.value === this.bankLinkEnums.TransactionType.PERSONAL)
                return false;

            return true;
        }

        isViewingPersonal = (): boolean => {
            return this.selectedTransactionType.value === this.bankLinkEnums.TransactionType.PERSONAL;
        }

        colSpanCount = (): number => {
            return this.isViewingPersonal() ? 8 : 9;
        }

        isViewingAll = (): boolean => {
            return this.selectedTransactionType.value === this.bankLinkEnums.TransactionType.ALL;
        }

      private updateAllocatedTransaction(transaction: BankLink.Transaction) {
        var trans = [];
        trans.push(transaction);
        this.bankLinkSrv.updateTransactions(trans);
      }
    }
}

angular
    .module("app.shared")
    .service("bankLinkFactory", Shared.BankLinkFactory);