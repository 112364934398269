/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetInvoicingUmbrella', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.invoicing.umb.detail.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetInvoicingUmbrella
    });

    brooksonWidgetInvoicingUmbrella.$inject = ['$timeout', 'invoicingSrv', 'brookson.navigation','dashboardService', 'appSettings','memberSrv'];

    function brooksonWidgetInvoicingUmbrella($timeout, invoicingSrv, brooksonNavigation, dashboardService, appSettings, memberSrv) {
        var vm = this;

        vm.latestTimesheets = {};
        vm.latestInvoices = {};
        vm.latestPayments = {};
        vm.latestPayslips = {};
        vm.showSpinner = true;
        vm.brooksonNavigation = brooksonNavigation;
        vm.memberSrv = memberSrv;
        vm.showNewSetupText = false;
        vm.dashboardService = dashboardService;
        vm.widgetName = "brookson-widget-invoicing-umbrella";
        vm.appSettings = appSettings;
        vm.memberBusinessType = '';

        function activate() {  
            invoicingSrv.getTimesheetUmbreallaOverview().then(data => {
                vm.latestTimesheets = data;
            }, error => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
                });
            

            invoicingSrv.getInvoicingUmbrellaOverview().then(data => {
                vm.latestInvoices = data.latestInvoices;
                vm.latestPayments = data.latestPayments;
                vm.latestPayslips = data.latestPayslips;
                vm.showNewSetupText = vm.latestInvoices.length === 0 && vm.latestPayments.length === 0 && false;
            }, error => {
                vm.error = true;
            }).then(() => {
                //vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
                });
            vm.memberBusinessType = memberSrv.getMemberBusinessType();
        }
        activate();
    }
})();
