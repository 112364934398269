/// <reference path="./../models/sdc/SdcQuestionnaireInput.ts" />
/// <reference path="./../models/sdc/SdcQuestionnaire.ts" />
/// <reference path="./../models/sdc/SdcQuestionnaireInputForm.ts" />
/// <reference path="./../models/sdc/JobTitle.ts" />
/// <reference path="./../services/brookson.services.sdc.ts" />

module Shared {
    export interface ISdcFactory {
        // Data
        sdcQuestionnaireInput: Shared.SdcQuestionnaireInput;
        sdcQuestionnaireInputForm: Shared.SdcQuestionnaireInputForm;
        sdcQuestionnaire: Shared.SdcQuestionnaire;
        sdcOutcome: Shared.SdcQuestionnaireOutcome;

        // Methods
        getLatestQuestionnaire(assignmentId: string): ng.IPromise<SdcQuestionnaire>;
        getOutcome(form: Shared.SdcQuestionnaireInputForm): ng.IPromise<SdcQuestionnaireOutcome>;
        searchJobTitle(query: string, silentMode?: boolean): ng.IPromise<Array<JobTitle>>;
        setRates(rates: Array<Shared.AssignmentRateCompact>);
        hasOutcomeSet(): boolean;
        hasFailedOutcome(): boolean;
        createSdcQuestionnaireInput(form: SdcQuestionnaireInputForm);
    }

    export class SdcFactory {
        public static SDC_QKEY_RATEVALUEHOURLY: string = "PAY RATE VALUE HOURLY";
        public static SDC_QKEY_RATEVALUEDAILY: string = "PAY RATE VALUE DAILY";
        public static SDC_QKEY_WILLINCUREXPENSES: string = "WILL INCUR EXPENSES";
        public static SDC_QKEY_INTENDMULTIPLEASSIGNMENTS: string = "INTEND MULTIPLE ASSIGNMENTS";
        public static SDC_QKEY_DOESCLIENTSUPERVISE: string = "DOES CLIENT SUPERVISE";
        public static SDC_QKEY_DOESCLIENTINSTRUCT: string = "DOES CLIENT INSTRUCT";
        public static SDC_QKEY_CANNOTOVERRULEIMPLEMENTATION: string = "CANNOT OVERRULE IMPLEMENTATION";
        public static SDC_QKEY_CANNOTOVERRULEPLAN: string = "CANNOT OVERRULE PLAN";
        public static SDC_QKEY_PERMANENTTEAMSAMEROLE: string = "IN PERM TEAM SAME ROLE";
        public static SDC_QKEY_ASSIGNMENTCURRENCY: string = "ASSIGNMENT CURRENCY";

        public static PAYMENT_CODE_STANDARD_DAILY: string = "STD DAYS";
        public static PAYMENT_CODE_STANDARD_HOURLY: string = "STD";

        public sdcQuestionnaireInput: Shared.SdcQuestionnaireInput = new SdcQuestionnaireInput();
        public sdcQuestionnaireInputForm: Shared.SdcQuestionnaireInputForm = new Shared.SdcQuestionnaireInputForm();
        public sdcQuestionnaire: Shared.SdcQuestionnaire = new Shared.SdcQuestionnaire();
        public sdcOutcome: Shared.SdcQuestionnaireOutcome = new Shared.SdcQuestionnaireOutcome();

        private rates: Array<Shared.AssignmentRateCompact>;

        static $inject = ['sdcService'];

        constructor(
            private sdcService: Shared.ISdcService
        ) { }

        /**
         * Returns the latest SDC questionnaire for a given assignment
         * 
         * 
         * @memberOf SdcService
         */
        getLatestQuestionnaire = (assignmentId: string): ng.IPromise<SdcQuestionnaire> => {
            return this.sdcService.getLatestQuestionnaire(assignmentId).then((data) => {
                if (data) {
                    this.sdcQuestionnaire = data;
                    let jobTitle = new JobTitle();
                    jobTitle.titleText = this.sdcQuestionnaire.extraInfo.jobTitle;
                    this.sdcQuestionnaire.extraInfo.jobTitleOption = jobTitle;
                    this.sdcQuestionnaireInputForm = this.createSdcQuestionnaireInputForm(this.sdcQuestionnaire.answers);
                }

                return data;
            });
        }

        /**
         * Returns whether a user has passed SDC based on their input
         * 
         * 
         * @memberOf SdcService
         */
        getOutcome = (form: Shared.SdcQuestionnaireInputForm): ng.IPromise<SdcQuestionnaireOutcome> => {
            this.createSdcQuestionnaireInput(form);

            return this.sdcService.getOutcome(this.sdcQuestionnaireInput).then((data: Shared.SdcQuestionnaireOutcome) => {
                this.sdcOutcome = data;
                return data;
            });
        }

        /**
         * Searches Digita for Job Titles based on the query
         * 
         * 
         * @memberOf SdcFactory
         */
        searchJobTitle = (query: string, silentMode?: boolean): ng.IPromise<Array<JobTitle>> => {
            return this.sdcService.searchJobTitle(query, silentMode);
        }

        /**
         * Sets the array of rates 
         * 
         * 
         * @memberOf SdcFactory
         */
        setRates = (rates: Array<Shared.AssignmentRateCompact>) => {
            this.rates = rates;
        }

        hasOutcomeSet = (): boolean => {
            return this.sdcOutcome && this.sdcOutcome.outcome !== Shared.SdcOutcomeType.NotAssessed;
        }

        hasFailedOutcome = (): boolean => {
            return this.hasOutcomeSet() && this.sdcOutcome.outcome === Shared.SdcOutcomeType.Fail;
        }

        /**
         * Creates a model for the input fields on the form
         * It will assign the property values based on the array of answers
         * 
         * @private
         * 
         * @memberOf SdcFactory
         */
        private createSdcQuestionnaireInputForm = (answers: Array<SdcQuestionnaireAnswer<any>>): Shared.SdcQuestionnaireInputForm => {
            var form = new Shared.SdcQuestionnaireInputForm();

            if (!answers || answers.length === 0) return form;

            form.sdcWillIncurExpenses = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_WILLINCUREXPENSES);
            form.sdcIntendMultipleAssignments = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_INTENDMULTIPLEASSIGNMENTS);
            form.sdcDoesClientSupervise = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_DOESCLIENTSUPERVISE);
            form.sdcDoesClientInstruct = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_DOESCLIENTINSTRUCT);
            form.sdcCannotOverruleImplementation = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_CANNOTOVERRULEIMPLEMENTATION);
            form.sdcCannotOverrulePlan = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_CANNOTOVERRULEPLAN);
            form.sdcInPermanentTeam = this.getAnswerValue<boolean>(answers, SdcFactory.SDC_QKEY_PERMANENTTEAMSAMEROLE);

            return form;
        }

        /**
         * Finds an answer in an array of ansawers and returns the values
         * 
         * @private
         * 
         * @memberOf SdcFactory
         */
        private getAnswerValue = <T>(answers: Array<SdcQuestionnaireAnswer<any>>, key: string): T => {
            var result = _.find(answers, (answer: Shared.SdcQuestionnaireAnswer<any>) => answer.questionKey === key);

            if (!result) return null;

            return result.answer;
        }

        /**
         * Adds the question key and answer to the answers array if the user has answered the question
         * 
         * @private
         * 
         * @memberOf SdcFactory
         */
        private setAnswerValue = (answers: Array<Shared.SdcQuestionnaireAnswer<any>> = [], questionKey: string, answer: any): void => {
            if (!_.isUndefined(answer)) {
                let questionnaireAnswer = new Shared.SdcQuestionnaireAnswer();
                questionnaireAnswer.questionKey = questionKey;
                questionnaireAnswer.answer = answer;
                answers.push(questionnaireAnswer);
            }
        }

        /**
         * Creates an Sdc Questionnaire input model to be sent to the GetOutcome endpoint
         * 
         * @private
         * 
         * @memberOf SdcFactory
         */
        createSdcQuestionnaireInput = (form: Shared.SdcQuestionnaireInputForm) => {
            this.sdcQuestionnaireInput = new Shared.SdcQuestionnaireInput();
            var answers = new Array<Shared.SdcQuestionnaireAnswer<any>>();

            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_WILLINCUREXPENSES, form.sdcWillIncurExpenses);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_INTENDMULTIPLEASSIGNMENTS, form.sdcIntendMultipleAssignments);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_DOESCLIENTSUPERVISE, form.sdcDoesClientSupervise);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_DOESCLIENTINSTRUCT, form.sdcDoesClientInstruct);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_CANNOTOVERRULEIMPLEMENTATION, form.sdcCannotOverruleImplementation);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_CANNOTOVERRULEPLAN, form.sdcCannotOverrulePlan);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_PERMANENTTEAMSAMEROLE, form.sdcInPermanentTeam);
            this.setAnswerValue(answers, SdcFactory.SDC_QKEY_RATEVALUEDAILY, this.findRateValue(SdcFactory.PAYMENT_CODE_STANDARD_DAILY));
            this.setAnswerValue(answers, SdcFactory.PAYMENT_CODE_STANDARD_HOURLY, this.findRateValue(SdcFactory.PAYMENT_CODE_STANDARD_HOURLY));

            this.sdcQuestionnaireInput.agencyNumber = this.sdcQuestionnaire.agencyNumber;
            this.sdcQuestionnaireInput.answers = answers;

            if (this.sdcQuestionnaire.extraInfo) {
                this.sdcQuestionnaireInput.assignmentIndustry = this.sdcQuestionnaire.extraInfo.assignmentIndustry;
                this.sdcQuestionnaireInput.clientAddress = this.sdcQuestionnaire.extraInfo.clientAddress;
                this.sdcQuestionnaireInput.workSiteAddress = this.sdcQuestionnaire.extraInfo.siteAddress;
                this.sdcQuestionnaireInput.clientName = this.sdcQuestionnaire.extraInfo.clientName;
                this.sdcQuestionnaireInput.expectedLengthOfAssignmentMonths = this.sdcQuestionnaire.extraInfo.expectedLengthOfAssignmentMonths;
                this.sdcQuestionnaireInput.startDate = this.sdcQuestionnaire.extraInfo.dateStartedAtWorkSite;

                if (this.sdcQuestionnaire.extraInfo.jobTitleOption) {
                    this.sdcQuestionnaireInput.jobTitle = this.sdcQuestionnaire.extraInfo.jobTitleOption.titleText;
                }
            } else {
                console.warn(`No extra info has been added to the SDC questionnaire input model`);
            }
        }

        private findRateValue = (paymentCode: string) : number => {
            var rate = _.find(this.rates, (rate: Shared.AssignmentRateCompact) => {
                return rate.paymentCode === paymentCode;
            });

            if (!rate) {
                console.warn(`There was no rate matching the payment code: ${paymentCode}`);
                return 0;
            }

            return rate.amount;
        }
    }
}

angular.module('app.shared').service('sdcFactory', Shared.SdcFactory);