/// <reference path="../../_all.ts"/>
/// <reference path="./../models/document-storage/_index.ts" />
/// <reference path="./../enums/FileView.ts" />
/// <reference path="./../modules/brookson.enums.ts" />
/// <reference path="./../interfaces/brookson.interfaces.http.ts" />

module Shared {
    'use strict';

    export interface IDocumentStorageService {
        // Data
        folders: Array<Shared.FolderCompact>;
        recentDocuments: Array<Shared.DocumentCompact>;
        favourites: Array<Shared.DocumentCompact>;
        sharedDrives: Shared.SharedDrives;
        folderId: number;
        sharedDriveId: number;
        sharedDrivePath: string;
        subFolderID: string;
        folder: any;
        sharedDriveFolder: Shared.DocumentStorageFolder;
        fileView: FileView;
        orderByField: string;
        reverseSort: boolean;
        isMobile: boolean;
        currentCategory: string;

        // Methods
        getSharedDriveFolder(): Shared.SharedDrive;
        getSharedDrives(): ng.IPromise<Shared.SharedDrives>;
        getFolders(silentMode?: boolean): ng.IPromise<Shared.DocumentStorageData>;
        removeFavourite(category: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean>;
        setFavourite(dataSource: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean>;
        getThumbnail(document: Shared.GetFullSizedImageRequest, silentMode?: boolean);
        getPreviousFolder(folderPath: string): string;
        getDocumentStorageWidgetData(silentMode?: boolean): ng.IPromise<Shared.DocumentStorageData>;
        downloadEmploymentFile(documentType: string);
    }

    export class DocumentStorageService implements IDocumentStorageService {
        static $inject: Array<string> = ['$rootScope', '$http', 'documentStorage.enums', 'brookson.file.download'];

        public folders: Array<Shared.FolderCompact> = [];
        public recentDocuments: Array<Shared.DocumentCompact> = [];
        public favourites: Array<Shared.DocumentCompact> = [];

        public sharedDrives: SharedDrives = <SharedDrives>{
            dropboxSharedDrive: {}
        };

        public folderId: number = 0;
        public sharedDriveId: number = 0;
        public subFolderID: string;
        public sharedDrivePath: string = '';
        public folder: any = {};
        public sharedDriveFolder: Shared.DocumentStorageFolder = null;
        public fileView: FileView;
        public orderByField: string = 'date';
        public reverseSort: boolean = true;
        public isMobile: boolean = false;
        public currentCategory: string;

        constructor(
            private $rootScope: ng.IRootScopeService,
            private $http: Shared.IBrooksonHttp,
            private enums: Shared.IDocumentStorageEnums,
            private fileDownloadSrv: any
        ) {
            this.fileView = this.enums.FileView.DETAILED;
            this.currentCategory = this.enums.DocumentCategory.FOLDER;

            this.setupWatched();
        }

        public getFolder = (): Shared.FolderCompact | Shared.DocumentStorageFolder => {
            if (this.folderId > 0) {
                this.folder = _.find(this.folders, (folder: Shared.FolderCompact): boolean => {
                    return folder.folderID === parseInt(this.folderId.toString());
                });

                if (this.folder && this.subFolderID) {
                    this.folder = _.find(this.folder.folders, (folder: Shared.FolderCompact): boolean => {
                        return folder.folderID === parseInt(this.subFolderID.toString());
                    });
                }
            } else {
                if (this.folders.length > 0) {
                    this.folderId = this.folders[0].folderID;
                    this.folder = this.folders[0];
                }
            }

            return this.folder;
        }

        public getSharedDriveFolder = (): Shared.SharedDrive => {
            if (this.sharedDriveId > 0) {
                this.folder = this.sharedDrives.dropboxSharedDrive;

                if (this.sharedDrivePath) {
                    this.sharedDriveFolder = this.getNestedFolder(this.folder, this.sharedDrivePath);
                } else {
                    this.sharedDriveFolder = this.folder ? this.folder.folder : <Shared.DocumentStorageFolder>{};
                }
            }

            return this.folder;
        }

        public getNestedFolder = (data: Shared.DocumentStorageFolder, path: string): Shared.DocumentStorageFolder => {
            if (data.path === path) {
                return data;
            }

            let result: Shared.DocumentStorageFolder;
            let p: string;

            for (p in data) {
                if (data.hasOwnProperty(p) && data[p] !== null && typeof data[p] === 'object') {
                    result = this.getNestedFolder(data[p], path);
                    if (result) {
                        return result;
                    }
                }
            }

            return result;
        }

        public getPreviousFolder = (folderPath: string): string => {
            if (!folderPath || folderPath === '/') {
                return folderPath;
            }

            let splitFolderPath: string[] = folderPath.split('/');
            let slicedFolderPath: string[] = _.slice(splitFolderPath, 0, splitFolderPath.length - 1);

            if (slicedFolderPath.length <= 1) {
                return '/';
            }

            let previousFolderPath = slicedFolderPath.join('/');

            return previousFolderPath;
        }

        public isSharedDriveAttached = (): boolean => {
            return this.sharedDrives.dropboxSharedDrive.sharedDriveId > 0;
        }

        public getFolderName = (): string => {
            if (!this.currentCategory && this.folder) {
                return this.folder.name;
            }

            return this.currentCategory;
        }

        public getFavourites = (): Array<DocumentCompact> => {
            this.favourites.length = 0;

            _.forEach(this.folders, (folder: FolderCompact): void => {
                let favourites: DocumentCompact[] = _.filter(folder.documents, { isFavourite: true });

                _.merge(this.favourites, this.favourites.concat(favourites));
            });

            let favourites: DocumentCompact[] = this.getSharedDriveFavourites(this.sharedDrives.dropboxSharedDrive.folder);

            _.merge(this.favourites, this.favourites.concat(favourites));

            return this.favourites;
        }

        public getSharedDriveFavourites = (data: Shared.DocumentStorageFolder): Array<Shared.DocumentCompact> => {
            if (data) {
                let fav: DocumentCompact[] = _.filter(data.documents, { isFavourite: true });
                let f: any;

                for (f in data.folders) {
                    if (data.folders.hasOwnProperty(f)) {
                        fav = fav.concat(this.getSharedDriveFavourites(data.folders[f]));
                    }
                }

                return fav;
            } else {
                return [];
            }
        }

        public removeFavourite = (category: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean> => {
            _.forEach(this.folders, (folder: Shared.FolderCompact): void => {
                _.forEach(folder.documents, (document: Shared.DocumentCompact): void => {
                    if (document.documentId === documentId && document.path === documentPath) {
                        document.isFavourite = false;
                    }
                });
            });

            _.forEach(this.recentDocuments, (document: Shared.DocumentCompact): void => {
                if (document.documentId === documentId && document.path === documentPath) {
                    document.isFavourite = false;
                }
            });

            this.setSharedDriveFavouriteValue(this.sharedDrives.dropboxSharedDrive.folder, documentId, documentPath, false);
            this.getFavourites();

            return this.unfavouriteDocument(category, documentId, documentPath);
        }

        public setFavourite = (dataSource: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean> => {
            _.forEach(this.folders, (folder: Shared.FolderCompact): void => {
                _.forEach(folder.documents, (document: Shared.DocumentCompact): void => {
                    if (document.documentId === documentId && document.path === documentPath) {
                        document.isFavourite = true;
                    }
                });
            });

            _.forEach(this.recentDocuments, (document: Shared.DocumentCompact): void => {
                if (document.documentId === documentId && document.path === documentPath) {
                    document.isFavourite = true;
                }
            });

            this.setSharedDriveFavouriteValue(this.sharedDrives.dropboxSharedDrive.folder, documentId, documentPath, true);
            this.getFavourites();

            return this.favouriteDocument(dataSource, documentId, documentPath);
        }

        public setSharedDriveFavouriteValue = (data: Shared.DocumentStorageFolder, documentId: string, documentPath: string, value: boolean): void => {
            if (data) {
                _.forEach(data.documents, (document: Shared.DocumentCompact): void => {
                    if (document.documentId === documentId && document.path === documentPath) {
                        document.isFavourite = value;
                    }
                });

                let f: any;

                for (f in data.folders) {
                    if (data.folders.hasOwnProperty(f)) {
                        _.forEach(data.folders[f].documents, (document: Shared.DocumentCompact): void => {
                            if (document.documentId === documentId && document.path === documentPath) {
                                document.isFavourite = value;
                            }
                        });

                        this.setSharedDriveFavouriteValue(data.folders[f], documentId, documentPath, value);
                    }
                }
            }
        }

        public getSelected = (): Array<DocumentCompact> => {
            let selected: Array<Shared.DocumentCompact> = [];

            _.forEach(this.folders, (folder: Shared.FolderCompact): void => {
                this.findSelected(folder.documents, selected);
            });

            this.findSelected(this.recentDocuments, selected);
            this.findSelected(this.favourites, selected);
            this.getSharedDriveSelected(this.sharedDrives.dropboxSharedDrive.folder, selected);

            return selected;
        }

        public getSharedDriveSelected = (data: Shared.DocumentStorageFolder, selected: Array<Shared.DocumentCompact>): void => {
            if (!data) {
                return;
            }

            this.findSelected(data.documents, selected);

            let f: any;

            for (f in data.folders) {
                if (data.folders.hasOwnProperty(f)) {
                    this.getSharedDriveSelected(data.folders[f], selected);
                }
            }
        }

        public findSelected = (documents: Array<Shared.DocumentCompact>, selected: Array<Shared.DocumentCompact>): void => {
            let selectedDocs = _.chain(<DocumentCompact[]>documents)
                .filter({ selected: true })
                .value();

            _.forEach(selectedDocs, (selectedDoc: DocumentCompact): void => {
                selected.push(selectedDoc);
            });
        }

        public downloadFile = (document: Shared.DocumentCompact): void => {
            let url: string = "api/DocumentStorage/DownloadFile";
            let expectedMediaType: string = "application/octet-stream";
            let requestData = {
                sharedDriveId: document.sharedDriveId,
                path: document.path,
                dataSource: document.dataSource
            };

            this.fileDownloadSrv.downloadAndSave(url, requestData, document.name, expectedMediaType);
        }

        public downloadEmploymentFile = (documentType: string): void => {
            var url = "api/Dashboard/DownloadEmploymentDocuments?documentType=" + documentType;
            console.log(url);
            var expectedMediaType = "application/octet-stream";
            this.fileDownloadSrv.downloadAndSave(url, null, documentType + '.pdf', expectedMediaType);
        }

        public getThumbnail = (document: Shared.GetFullSizedImageRequest, silentMode?: boolean): ng.IPromise<any> => {
            return this.$http.get('api/DocumentStorage/GetThumbnail?sharedDriveId=' + document.sharedDriveId + '&dataSource=' + document.dataSource + '&path=' + document.path,
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response) => {
                    return response.data;
                });
        }

        public getFullSizedImage = (document: Shared.GetFullSizedImageRequest, silentMode?: boolean): ng.IPromise<any> => {
            return this.$http.post('api/DocumentStorage/GetFullSizedImage',
                document,
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response) => {
                    return response.data;
                });
        }

        public getSelectedDocumentZip = (): void => {
            let selected: DocumentCompact[] = this.getSelected();

            if (selected.length > 0) {
                let url: string = "api/DocumentStorage/GetZipDocuments";
                let expectedMediaType: string = "application/zip";
                let requestData: DocumentCompact[] = selected;

                this.fileDownloadSrv.downloadAndSave(url, requestData, "Brookson.Documents.zip", expectedMediaType);
            }
        }

        public getSharedDrives = (): ng.IPromise<Shared.SharedDrives> => {
            return this.$http.get('api/DocumentStorage/GetSharedDrives')
                .then((response: ng.IHttpPromiseCallbackArg<Shared.SharedDrives>): SharedDrives => {
                    this.sharedDrives = response.data;

                    return this.sharedDrives;
                });
        }

        public favouriteDocument = (dataSource: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean> => {
            let request: Shared.FavouriteRequest = {
                dataSource: dataSource,
                documentId: documentId,
                documentPath: documentPath
            };

            return this.$http.post('api/DocumentStorage/FavouriteDocument', request,
                {
                    silentMode: true
                })
                .then((response) => {
                    return response.data;
                });
        }

        public unfavouriteDocument = (dataSource: Shared.DocumentDataSource, documentId: string, documentPath: string): ng.IPromise<boolean> => {
            const request = <Shared.FavouriteRequest>{
                dataSource: dataSource,
                documentId: documentId,
                documentPath: documentPath
            };

            return this.$http.post('api/DocumentStorage/UnfavouriteDocument', request,
                {
                    silentMode: true
                })
                .then((response) => {
                    return response.data;
                });
        }

        public getSelectedPDF = (id: string, path: string): ng.IPromise<any> => {

            if (id) {
                let url: string = "api/DocumentStorage/GetPDFDocument";
                let expectedMediaType: string = "application/pdf";
                let requestData: any = {
                    documentId: id,
                    documentPath: path
                };
                return this.fileDownloadSrv.download(url, requestData, "Brookson.Documents.zip", expectedMediaType)
                    .then((response) => {
                        return response.data;
                    });
            }
        }

        public getFolders = (silentMode?: boolean): ng.IPromise<Shared.DocumentStorageData> => {
            return this.$http.get('api/DocumentStorage/GetFolders',
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response: ng.IHttpPromiseCallbackArg<Shared.DocumentStorageData>) => {
                    if (response.data) {
                        this.folders.length = 0;
                        this.folders.push.apply(this.folders, response.data.folders);

                        this.recentDocuments.length = 0;
                        this.recentDocuments.push.apply(this.recentDocuments, response.data.recentDocuments);

                        this.folders = _.sortBy(this.folders, (folder: Shared.FolderCompact): string => {
                            return folder.name;
                        });

                        this.getFolder();
                    }

                    return response.data;
                });
        }

        public getDocumentStorageWidgetData = (silentMode?: boolean): ng.IPromise<Shared.DocumentStorageData> => {
            return this.$http.get('api/DocumentStorage/GetDocumentStorageWidgetData',
                {
                    silentMode: silentMode ? silentMode : false
                })
                .then((response: ng.IHttpPromiseCallbackArg<Shared.DocumentStorageData>) => {
                    if (response.data) {
                        this.recentDocuments.length = 0;
                        this.recentDocuments.push.apply(this.recentDocuments, response.data.recentDocuments);
                        this.getFolder();
                    }

                    return response.data;
                });
        }

        public setupWatched = (): void => {
            this.$rootScope.$watch((): number => {
                return this.folderId;
            }, (): void => {
                this.getFolder();
                this.getFavourites();
            }, true);

            this.$rootScope.$watch((): string => {
                return this.subFolderID;
            }, (): void => {
                this.getFolder();
                this.getFavourites();
            }, true);

            this.$rootScope.$watch((): SharedDrives => {
                return this.sharedDrives;
            }, (): void => {
                this.getSharedDriveFolder();
                this.getFavourites();
            }, true);
        }
    }
}

angular
    .module('app.shared')
    .service('documentStorageSrv', Shared.DocumentStorageService);