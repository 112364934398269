module Shared {
    export class DividendsTable {
        dividendsdeclared: number;
        unconfirmedcashpaymentsassumedtobedividends: number;
        totalofdeclaredandassumeddividendsalreadytaken: number;
        businessID: string;
        personID: string;
        connectUser: string;
        createdDate: string;
        section: string;
        subsection: string;
        order: number;
    }
}