module EmploymentStatus {
    export class IndustrySector {
        name: string;
        value: string;
        group: string;
        constructor(name: string, value: string, group: string) {
            this.name = name;
            this.value = value;
            this.group = group;
        }
    }
}
