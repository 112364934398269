module Shared {
    export class UmbrellaTaxTracker {
    businessID: string;
    personID: string;
    connectUser: string;
    section: string;
    subSection: string;
    order: number;
    payYear: number;
    sumGrossPay: number;
    sumPaye: number;
    createdDate: string;
    }
}