module Shared {
    export interface IIR35ModalService {
        getListOfJobTitles(): ng.IPromise<string[]>;
        getListOfSectors(): ng.IPromise<string[]>;
        insertIR35Assessment(answers: IR35AssessmentAnswers): ng.IPromise<boolean>;
        showIR35AssessmentModal(): ng.IPromise<boolean>;
    }

    export class IR35ModalService implements IIR35ModalService {

        static $inject = ['$http'];

        constructor(private $http: Shared.IBrooksonHttp) { }

        getListOfJobTitles(): ng.IPromise<string[]> {
            return this.$http.get('api/IR35Modal/getListOfJobTitles').then((response: ng.IHttpPromiseCallbackArg<string[]>): string[] => {
                return response.data;
            });
        }
        getListOfSectors(): ng.IPromise<string[]> {
            return this.$http.get('api/IR35Modal/getListOfSectors').then((response: ng.IHttpPromiseCallbackArg<string[]>): string[] => {
                return response.data;
            });
        }

        insertIR35Assessment(answers: IR35AssessmentAnswers): ng.IPromise<boolean> {
            return this.$http.post('api/IR35Modal/insertIR35Assessment', answers)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                    return response.data;
                });
        }

        showIR35AssessmentModal(): ng.IPromise<boolean> {
            return this.$http.get('api/IR35Modal/showIR35AssessmentModal').then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared').service('ir35ModalService', Shared.IR35ModalService);