/// <reference path="./../BrooksonDate.ts" />

module Shared {
    export module Invoicing {
        export class InvoiceUmbrellaCompact {   
            companyNumber: string;
            timesheetNumber: string;
            workPeriodEndDate: Shared.BrooksonDate;
            postingDescription: string;
            created: Shared.BrooksonDate;
            rationale: number;
            approvedOn: Shared.BrooksonDate;
            lastUpdated: Shared.BrooksonDate;
            invoiceAmount: number;
            hours: number;
            payPeriod: number;
            statusDescription: string;
            rationaleDescription: string;
            sellToCustomerName: string;
            customerRationaleDis: string;
            payslip: string;
        }
    }
}