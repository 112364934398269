/// <reference path="./AccountType.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class PaymentCode  {
        
        // CODE
        public code: string = null;
        // DESCRIPTION
        public description: string = null;
        // LINETYPE
        public lineType: string = null;
        // TIMEUNITS
        public timeUnits: string = null;
        // GUIDANCE
        public guidance: string = null;
        // RECEIPTREQUIRED
        public receiptRequired: boolean = false;
        // ISBANDED
        public isBanded: boolean = false;
        // CATEGORY
        public category: string = null;
        // ACCOUNTTYPE
        public accountType: AccountType = null;
        // DEFAULTEXPENSERATE
        public defaultExpenseRate: number = 0;
        // ISPUBLIC
        public isPublic: boolean = false;
        // ISMILEAGE
        public isMileage: boolean = false;
        // ISOFFSITE
        public isOffSite: boolean = false;
    }
}
