module Shared {
    export module Invoicing {
        export enum InvoiceType {
            any,
            salesLedger,
            invoicing,
            receipts,
            credits
        }
    }
}