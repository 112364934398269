/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonUiProgressBar', {
        templateUrl: 'src/app/shared/views/ui/brookson.ui.progress-bar.html',
        bindings: {
            file: '='
        }
    });
})();
