/// <reference path="./../models/WelcomeScreen.ts" />

module Shared {
	export interface IWelcomeScreenSrv {
		getWelcomeScreenMessageHtml(): ng.IPromise<any>;
		GetAccountantVideoId(): ng.IPromise<any>;
		getWelcomeVideos(): ng.IPromise<any>;
        youtubeIds: Array<any>;
		accountantYoutubeId: string;
		setWelcomeScreenSeen(): ng.IPromise<boolean>;
		getWelcomeScreen() : ng.IPromise<Shared.WelcomeScreen>;
		getWelcomeScreenEnabled() : ng.IPromise<any>;
		GetAccountantVideoId() : ng.IPromise<any>;
		getWelcomeVideos() : ng.IPromise<any>;
		setWelcomeScreenSeen() : ng.IPromise<boolean>;
        setHideWelcomeScreen(): ng.IPromise<boolean>; 
		microCalendlyBooking: Shared.MicroCalendlyBooking;
        advisoryDocumentCalendlyBooking: Shared.MicroCalendlyBooking;
        financialHealthCheckCalendlyBooking: Shared.MicroCalendlyBooking;

	}

	export class WelcomeScreenSrv implements IWelcomeScreenSrv {
		public youtubeIds: any[];
        public accountantYoutubeId: string;
		public microCalendlyBooking: Shared.MicroCalendlyBooking;
        public advisoryDocumentCalendlyBooking: Shared.MicroCalendlyBooking;
        public financialHealthCheckCalendlyBooking: Shared.MicroCalendlyBooking;

		static $inject: Array<string> = ["$http", "shared.config"];

		private serviceBase: any;

		constructor(
			private $http: ng.IHttpService,
			private sharedConfig: any
		) {
			this.serviceBase = sharedConfig.baseApiUrl;
		}

		getWelcomeScreenMessageHtml = (): ng.IPromise<any> => {
			let url = this.serviceBase + "api/WelcomeScreen/GetContentMessage";
			return this.$http.get(url).then(
				response => response.data
			);
		}

        getWelcomeScreen = (silentMode?: boolean): ng.IPromise<Shared.WelcomeScreen> => {
            return this.$http.get(`api/Intro/GetWelcomeScreen`, {
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.WelcomeScreen>) => {
                return response.data;
            });
        }

		getWelcomeScreenEnabled  = (): ng.IPromise<any> => {
			let url = this.serviceBase + "api/Intro/GetWelcomeScreenEnabled";
			return this.$http.get(url).then(
				response => response.data
			);
		}

		GetAccountantVideoId  = (): ng.IPromise<any> => {
			let url = this.serviceBase + "api/Intro/GetAccountantVideoId";
			return this.$http.get(url).then(
				response => response.data
			);
		}

		getWelcomeVideos  = (): ng.IPromise<any> => {
			let url = this.serviceBase + "api/Intro/GetWelcomeVideos";
			return this.$http.get(url).then((response: ng.IHttpPromiseCallbackArg<Array<any>>) => {
                return response.data;
            });
		}
	
		setWelcomeScreenSeen = () : ng.IPromise<boolean> => {
            return this.$http.post(`api/Intro/SetWelcomeScreenSeen`, {}, {
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
		}
				
		setHideWelcomeScreen = () : ng.IPromise<boolean> => {
            return this.$http.post(`api/Intro/HideWelcomeScreen`, {}, {
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {
                return response.data;
            });
        }
	}
}

angular
    .module("app.shared")
    .service("welcomeSrv", Shared.WelcomeScreenSrv);