/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var appShared = angular.module("app.shared");

    appShared.directive('brooksonBreakpoint', ['ngIfDirective', 'screenSize', (ngIfDirective, screenSize) => {
        /*
            xs - phones
            sm - tables
            md - desktops
            lg - large desktops
        */
        var ngIf = ngIfDirective[0];

        return {
            transclude: ngIf.transclude,
            priority: ngIf.priority,
            terminal: ngIf.terminal,
            restrict: 'A',
            link: (...args) => { // $scope, $element, $attr
                var show = screenSize.is(args[2].brooksonBreakpoint);

                screenSize.on(args[2].brooksonBreakpoint, (match) => {
                    show = match;
                });

                args[2].ngIf = () => {
                    return show;
                };

                ngIf.link.apply(ngIf, args);
            }
        };
    }]);
})();
