module Shared {
    export function FilterBankAccountType() {
        return (input) => {
            var result = 'unknown';

            switch (input) {
                case 0:
                    result = "Current Account";
                    break;
                case 1:
                    result = "Deposit Account";
                    break;
                case 2:
                    result = "Credit Card";
                    break;
                case 3:
                    result = "Deposit Bond";
                    break;
                default:
                    break;
            }
            return result;
        };
    }
}

angular.module("app.shared").filter("bankAccountType", Shared.FilterBankAccountType);