/// <reference path="../../_all.ts"/>

(() => {
    "use strict";

    var app = angular.module("app.shared");

    app.factory("brookson.modal.receipt-bank", [
        "$q", "$uibModal", "$sce", 'expenses.enums', ($q, $uibModal, $sce, enums) => {
            var options: any = {
                animation: true,
                size: "lg",
                templateUrl: "src/app/shared/views/modals/brookson.modals.receipt-bank.attach-receipt.html"
            };

            var _attachReceipt = (receipts, receiptId) => {
                var defer = $q.defer();
                var attachReceiptModal = options;

                attachReceiptModal.controller = ['$scope', '$uibModalInstance', ($scope, $uibModalInstance) => {
                    // Data
                    $scope.enums = enums;
                    $scope.selectedReceipt = receiptId;
                    $scope.receipts = receipts;
                    $scope.currentPage = 1;

                    // Methods
                    $scope.cancel = cancel;
                    $scope.attachReceipt = attachReceipt;
                    $scope.receiptSelected = receiptSelected;

                    activate();

                    function activate() {
                        if ($scope.receipts && $scope.selectedReceipt) {
                            _.forEach($scope.receipts, (receipt: Shared.Receipt) => {
                                if (receipt.receiptId === receiptId) {
                                    receipt.isSelected = true;
                                }
                            });
                        }
                    }

                    function cancel() {
                        $uibModalInstance.dismiss('cancel');
                    }

                    function attachReceipt() {
                       
                            //check to see if any receipts are selected
                        let receipt: Shared.Receipt = _.find<Shared.Receipt>($scope.receipts, (receipt: Shared.Receipt) => receipt.isSelected === true );
                        if (receipt) {
                            $scope.selectedReceipt = receipt.receiptId;
                            $uibModalInstance.close();
                            defer.resolve(receipt);
                        } else {
                            return;
                        }
                        
                    }

                    function receiptSelected(data) {
                        if (data.isSelected) {
                            $scope.selectedReceipt = data.receiptId;

                            _.forEach($scope.receipts, (receipt) => {
                                if (data.receiptId !== receipt.receiptId) {
                                    receipt.isSelected = false;
                                }
                            });
                        } else {
                            $scope.selectedReceipt = 0;
                        }
                    }
                }];

                $uibModal.open(attachReceiptModal);

                return defer.promise;
            };

            return {
                attachReceipt: _attachReceipt
            };
        }
    ]);
})();
