/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    var appShared = angular.module("app.shared");

    appShared.directive('brooksonValidateHighlight', () => {
        return {
            restrict: 'A',
            require: '^form',
            link: (scope, el, attrs, formCtrl) => {
                // find the text box element, which has the 'name' attribute
                var inputEl = el[0].querySelector("[name]");
                // convert the native text box element to an angular element
                var inputNgElement = angular.element(inputEl);
                // get the name on the text box so we know the property to check
                // on the form controller

                inputNgElement.bind('keyup blur change focus input', () => {

                    var inputName = inputNgElement.attr('name');

                    if (!inputName) {
                        return;
                    }

                    if (formCtrl[inputName] && formCtrl[inputName].$invalid && formCtrl[inputName].$touched) {
                        el.addClass("has-error");
                    } else {
                        el.removeClass("has-error");
                    }
                });
            }
        };
    });
})();
