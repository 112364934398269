/// <reference path="../models/BusinessDetailSummary.ts" />
/// <reference path="./brookson.services.member.ts"/>
/// <reference path="./../models/member/ServiceLine.ts"/>

module Shared {
    export interface IServiceLineService {
        serviceBase: string;
        serviceLines: Array<Shared.ServiceLine>;
        businessDetailSummary: BusinessDetailSummary;

        getBusinessDetailSummary(): ng.IPromise<BusinessDetailSummary>;
        getServiceLines(member: MemberModel): any;
    }

    export class ServiceLineService implements IServiceLineService {
        static $inject = ['$http', '$q', 'shared.config', 'memberSrv'];

        public serviceBase: string;
        public serviceLines: Array<Shared.ServiceLine> = [];
        public businessDetailSummary: BusinessDetailSummary;

        constructor(
            private $http: ng.IHttpService,
            private $q: ng.IQService,
            private sharedConfig: any,
            private memberSrv: IMemberService
        ) {
            this.serviceBase = sharedConfig.baseApiUrl;
        }

        getBusinessDetailSummary = (): ng.IPromise<BusinessDetailSummary> => {
            return this.$http.get(this.serviceBase + 'api/Member/GetBusinessDetailSummary', {
                    cache: true
                })
                .then((response: ng.IHttpPromiseCallbackArg<BusinessDetailSummary>) => {
                    var businessDetailSummary = new BusinessDetailSummary();
                    businessDetailSummary.companyName = response.data.companyName;
                    businessDetailSummary.companyRegistrationNo = response.data.companyRegistrationNo;
                    businessDetailSummary.vatNo = response.data.vatNo;
                    businessDetailSummary.vatScheme = response.data.vatScheme;

                    if (response.data.flatRateVatEffectiveDate) {
                        businessDetailSummary.flatRateVatEffectiveDate = new Date(response.data.flatRateVatEffectiveDate);
                    }

                    if (response.data.standardRateVatEffectiveDate) {
                        businessDetailSummary.standardRateVatEffectiveDate = new Date(response.data.standardRateVatEffectiveDate);
                    }

                    this.businessDetailSummary = businessDetailSummary;
                    
                    return this.businessDetailSummary;
                });
        }

        getServiceLines = (member: MemberModel) => {

            var deferred = this.$q.defer();

            if (member.memberId) {
                if (this.serviceLines.length > 0) {
                    deferred.resolve(this.serviceLines);
                } else {
                    this.$http.get(this.serviceBase + 'api/Member/GetServiceLinesDistinct/' + member.memberId)
                        .success((data: Array<Shared.ServiceLine>) => {
                            //save in the service;
                            this.serviceLines = data;
                            deferred.resolve(data);
                        }).error((err, status) => {
                            deferred.reject(err);
                        });
                }
            } else {
                deferred.reject({ error_description: 'Invalid Member Id.' });
            }

            return deferred.promise;
        }
    }
}

angular
    .module('app.shared')
    .service('serviceLineSrv', Shared.ServiceLineService);