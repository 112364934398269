module Shared {
    export class Agency {
        public billingAddressAddressLine1: string = null;
        public billingAddressAddressLine2: string = null;
        public billingAddressAddressLine3: string = null;
        public billingAddressPostcode: string = null;
        public billingAddressTown: string = null;
        public billingAddressCounty: string = null;
        public billingAddressCountry: string = null;

        public customerAddressAddressLine1: string = null;
        public customerAddressAddressLine2: string = null;
        public customerAddressAddressLine3: string = null;
        public customerAddressPostcode: string = null;
        public customerAddressTown: string = null;
        public customerAddressCounty: string = null;
        public customerAddressCountry: string = null;

        public agencyReference: string = null;
        public isSelfBilling: boolean = false;
        public selfBillingMethod: number = null;
        public customerNumber: string = null;
        public crmAgencyId: string = null;
        public isDirectEngagement: boolean = false;
    }
}
