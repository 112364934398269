module Shared {
    export class ProfitTable {
        connectUser: string;
        thisYearsExpensesClaimed: number;
        thisYearsInvoices: number;
        thisYearsProfitBeforeTax: number;
        lastYearsExpensesClaimed: number;
        lastYearsInvoices: number;
        lastYearsProfitBeforeTax: number;
        createdDate: string;
        section: string;
        subSection: string;
        order: string;
    }
}