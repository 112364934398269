/// <reference path="../../_all.ts"/>
/// <reference path="./../models/DashboardUser.ts" />
/// <reference path="./../modules/brookson.ui.popup.ts" />
/// <reference path="./../modules/brookson.modals.select.ts" />

module Shared {
    export interface IDashboardService {
        customiseOn: boolean;
        userDashboards: Array<Object>;
        currentDashboard: Shared.DashboardUser;
        msnry: IMasonry;
        allWidgets: Array<DashboardWidget>;
        availableWidgets: Array<DashboardWidget>;
        showMasonryItems: boolean;

        getDashboardsForMember(silentMode?: boolean): ng.IPromise<Array<DashboardUser>>;
        getDashboardWidgets(silentMode?: boolean): ng.IPromise<Array<DashboardWidget>>;
        makeDefault(currentDash: DashboardUser);
        saveAsNew(currentDash: DashboardUser): ng.IPromise<DashboardUser>;
        resetCurrentDashboard(): void;
        reloadMasonry(): void;
        removeWidget(widgetName: string): void;
        saveExisting(currentDash: DashboardUser, showGritterMessage?: boolean);
        showDelete(): ng.IPromise<Array<DashboardUser>>;
        deleteDashboards(dashboards: Array<DashboardUser>, silentMode?: boolean);
        downloadFile(documentType: string): void;
        getDateIsNull(value: string): boolean;
    }

    export class DashboardService implements IDashboardService {
        static $inject: Array<string> = ['$http', 'brookson.ui.popup', '$q', 'brookson.modal.select.service', 'brookson.file.download'];

        public showMasonryItems: boolean = false;
        public customiseOn: boolean = false;
        public userDashboards: Array<DashboardUser> = [];
        public allWidgets: Array<DashboardWidget>;
        public msnry: IMasonry;

        private _currentDashboard: DashboardUser;
        public get currentDashboard(): DashboardUser {
            return this._currentDashboard;
        }
        public set currentDashboard(v: DashboardUser) {
            this._currentDashboard = angular.copy(v);
        }

        public get availableWidgets(): Array<DashboardWidget> {
            if (this.allWidgets && this.currentDashboard) {


                return _.differenceBy(this.allWidgets, this.currentDashboard.widgets, 'dashboardWidgetId');
            }
            return [];
        }

        constructor(
            private $http: Shared.IBrooksonHttp,
            private popup: Shared.IBrooksonUiPopup,
            private $q: ng.IQService,
            private selectModal: Shared.IBrooksonModalSelect,
            private fileDownloadSrv: any
        ) { }

        public addWidget = (widget: any) => {
            const dashCopy = angular.copy(this.currentDashboard);
            dashCopy.widgets.push(widget);
            this.currentDashboard = dashCopy;
        }

        public removeWidget = (widgetName: string) => {
            const dashCopy = angular.copy(this.currentDashboard);
            _.remove(dashCopy.widgets, (widget) => {
                return widget.componentName === widgetName;
            });
            this.currentDashboard = dashCopy;
        }

        public reloadMasonry = (): void => {
            this.msnry.reloadItems();
            this.msnry.layout();
            this.showMasonryItems = true;
        }

        // Returns a list of dashboards and widgets for the member
        public getDashboardsForMember = (silentMode?: boolean): ng.IPromise<Array<DashboardUser>> => {
            return this.$http.get('api/Dashboard/GetDashboardsForMember', {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<DashboardUser>>) => {
                this.userDashboards = _.sortBy(response.data, 'dashboardUserId');

                if (!_.some(this.userDashboards, { 'isDefault': true })) {
                    //if you can't find a default then set the first as default
                    this.userDashboards[0].isDefault = true;
                    this.insertDashboardForMember(this.userDashboards[0]);
                }

                return this.userDashboards;
            });
        }

        // Returns a list of all the available widgets include their component name and display name
        public getDashboardWidgets = (silentMode?: boolean): ng.IPromise<Array<DashboardWidget>> => {
            return this.$http.get('api/Dashboard/GetDashboardWidgets', {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Array<DashboardWidget>>): DashboardWidget[] => {
                this.allWidgets = response.data;
                return this.allWidgets;
            });
        }

        // Inserts or updates a customised dashboard for the member. It will update if there is an dashboardWidgetId value
        private insertDashboardForMember = (data: DashboardUser, showGritterMessage: boolean = true, silentMode?: boolean): ng.IPromise<DashboardUser> => {
            return this.$http.post('api/Dashboard/InsertDashboardForMember?showGritterMessage=' + showGritterMessage, data, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<DashboardUser>) => {
                return response.data;
            });
        }

        public saveExisting = (currentDash: DashboardUser, showGritterMessage: boolean = true) => {
            return this.insertDashboardForMember(currentDash, showGritterMessage).then((result) => {
                this.replaceDashboard(result);
            });
        }

        public saveAsNew = (currentDash: DashboardUser): ng.IPromise<DashboardUser> => {
            return this.popup.showSaveAs("Save Dashboard", "Save your dashboard as:").then((fileName: string) => {
                let dashCopy = angular.copy(currentDash);
                dashCopy.name = fileName;
                dashCopy.dashboardUserId = null;
                dashCopy.isDefault = false;
                dashCopy.isBrookson = false;
                _.each(dashCopy.widgets, (widget) => { widget.dashboardUserWidgetsId = null; widget.dashboardUserId = null; });

                return this.insertDashboardForMember(dashCopy).then((result) => {
                    this.userDashboards.push(result);
                    this.currentDashboard = result;
                    return result;
                });
            });
        }

        public showDelete = (): ng.IPromise<Array<DashboardUser>> => {
            // return this.popup.selectOptions();
            let items: Array<Shared.ModalSelectItem> = [];
            items = _.chain(this.userDashboards).filter({ 'isBrookson': false }).map((v) => {
                if (!v.isBrookson) {
                    return new ModalSelectItem(v.name, v.dashboardUserId, false);
                }
            }).value();

            return this.selectModal.showSelect(items, "Remove Dashboards").then((selected: Array<ModalSelectItem>) => {
                if (selected.length) {
                    return _.filter(this.userDashboards, (item) => {
                        return _.some(selected, (select) => select.id === item.dashboardUserId);
                    });
                }
            });
        }

        public deleteDashboards = (dashboards: Array<DashboardUser>, silentMode?: boolean) => {

            return this.$http.post('api/Dashboard/DeleteDashboards', dashboards, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<boolean>) => {

                //refresh the list of dashboards
                this.getDashboardsForMember();

            });
        }        

        public makeDefault = (currentDash: DashboardUser) => {
            let promises: Array<ng.IPromise<DashboardUser>> = [];

            _.each(this.userDashboards, (dash) => {
                if (dash.dashboardUserId === currentDash.dashboardUserId) {
                    dash.isDefault = true;
                    promises.push(this.insertDashboardForMember(dash));
                }
                else if (dash.isDefault === true) {
                    dash.isDefault = false;
                    promises.push(this.insertDashboardForMember(dash));
                };
            });

            return this.$q.all(promises).then((promises) => {
                _.each(promises, (result) => {
                    this.replaceDashboard(result);
                });
            });
        }

        public resetCurrentDashboard = (): void => {
            let dash = _.find(this.userDashboards, (dash: DashboardUser): boolean => {
                return dash.dashboardUserId === this.currentDashboard.dashboardUserId;
            });
            this.currentDashboard = dash;
        }

        private replaceDashboard = (newDash: DashboardUser): void => {
            let index = _.indexOf(this.userDashboards, _.find(this.userDashboards, { dashboardUserId: newDash.dashboardUserId }));
            this.userDashboards.splice(index, 1, newDash);
        }

        public downloadFile = (documentType: string): void => {
            var url = "api/Dashboard/DownloadEmploymentDocuments?documentType=" + documentType;
            console.log(url);
            var expectedMediaType = "application/octet-stream";
            this.fileDownloadSrv.downloadAndSave(url, null, documentType + '.pdf', expectedMediaType);
        }

        
        public getDateIsNull(value: string): boolean {
            if (!value) {
                return true; 
            }

            return moment(value).format("DD/MM/YYYY") === '01/01/1753';
        }
    }
}

angular
    .module('app.shared')
    .service('dashboardService', Shared.DashboardService);