module Shared {
  export interface IUmbrellaTrustModalService {
    options: ng.ui.bootstrap.IModalSettings;

    show(): ng.ui.bootstrap.IModalServiceInstance;
  }

  export class BrooksonUmbrellaTrustModalService implements IUmbrellaTrustModalService {
    public options: ng.ui.bootstrap.IModalSettings = {
      animation: true,
      size: "md",
      template: `<div>
        <div class="modal-header">
          <h3 class="modal-title pull-left">Notice</h3>
        </div>
        <div class="modal-body">
          <div class="panel panel-default">
            <div class="panel-body">
              <p>Please ensure that you submit timesheets on the appropriate Trust timesheet portal, which you should have received an email activation for. Connect is for your records and reference only and not viewed by your Trust.</p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" ng-click="$ctrl.close(); $event.stopPropagation()">Close</button>
        </div>
      </div>`,
      keyboard: false,
      controller: BrooksonUmbrellaTrustModalController,
      controllerAs: "$ctrl",
      backdrop: "static",
      windowClass: "modal-umbrellaTrustModal"
    };

    static $inject = ['$uibModal'];

    constructor (
      private $uibModal: ng.ui.bootstrap.IModalService
    ) { }

    public show = (): ng.ui.bootstrap.IModalServiceInstance => {
      return this.$uibModal.open(this.options);
    }
  }

  class BrooksonUmbrellaTrustModalController {
    static $inject = ['$uibModalInstance'];

    constructor(
      private $uibModalInstance
    ) { }

    public close = (): void => {
      this.$uibModalInstance.dismiss("cancel");
    }
  }
}

angular
  .module("app.shared")
  .service("umbrellaTrustModalService", Shared.BrooksonUmbrellaTrustModalService);