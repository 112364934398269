/// <reference path="./CoreExpenseItem.ts" />

module Shared {

    export class RegularExpense extends CoreExpenseItem {
        receiptId: number;
        quantity: number;
        receiptImageData: string;
        receiptThumbnail: string;
        flow: any;
        flowOptions: any;
        allowedExpenseTypes: any;
    }
}