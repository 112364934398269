/// <reference path="../_all.ts" />

(() => {
    "use strict";

    var appSettings = {};

    if (window) {
        // Object.assign(appSettings, window.appSettings);
        appSettings = window.appSettings;
    }

    angular.module("app.shared", [
        // Angular modules
        "ui.router",
        'ui.bootstrap',
        'ngSanitize',
        'ngMessages',
        'ngAnimate',
        'angular.filter',
        // Custom modules

        // 3rd Party Modules
        "LocalStorageModule",
        'ngFileSaver',
        'flow',
        'matchMedia',
        'ngFileSaver',
        'angular-growl',
        'angular-google-analytics',
        'adaptive.detection',
        'ngUaParser',
        'cfp.hotkeys',
        'ui.select'
    ])
        .config([
            "$httpProvider", "$locationProvider", ($httpProvider, $locationProvider) => {
                $httpProvider.interceptors.push("authInterceptorSrv");
                $locationProvider.html5Mode({
                    enabled: true,
                    requireBase: true
                });
            }
        ])
        .run([
            "$rootScope", "$state", "Analytics", "$templateCache",
            ($rootScope, $state, Analytics, $templateCache) => {

                $rootScope.$on("$stateChangeStart", (evt, to, params) => {
                    if (to.redirectTo) {
                        evt.preventDefault();
                        $state.go(to.redirectTo, params);
                    }
                }
                );

                Analytics.pageView();

                $templateCache.remove('templates/growl/growl.html');
                $templateCache.put('templates/growl/growl.html',
                    '<div class="growl-container" ng-class="wrapperClasses()">' +
                    '<div class="growl-item alert" ng-repeat="message in growlMessages.directives[referenceId].messages" ng-class="alertClasses(message)" ng-click="stopTimeoutClose(message); message.variables.onClick()">' +
                    '<button type="button" class="close" data-dismiss="alert" aria-hidden="true" ng-click="growlMessages.deleteMessage(message)" ng-show="!message.disableCloseButton">&times;</button>' +
                    '<button type="button" class="close" aria-hidden="true" ng-show="showCountDown(message)">{{message.countdown}}</button>' +
                    '<h4 class="growl-title" ng-show="message.title" ng-bind="message.title"></h4>' +
                    '<div class="growl-message" ng-bind-html="message.text"></div>' +
                    '</div>' +
                    '</div>');
            }
        ])
        .value("shared.config", {
            baseApiUrl: location.protocol + "//" + location.hostname + (location.port && ":" + location.port) + '/',
            authSettings: { clientId: 'ngConnect3', useRefreshToken: true }
        })
        .config(['growlProvider', growlProvider => {
            growlProvider.onlyUniqueMessages(false);
            growlProvider.globalReversedOrder(true);
        }])
        .config(['AnalyticsProvider', (AnalyticsProvider) => {
            if (window.appSettings) {
                var trackingCode = window.appSettings.googleAnalyticsTrackingCode;

                AnalyticsProvider.setAccount(trackingCode)
                    .trackPages(true)
                    .trackUrlParams(true)
                    .setPageEvent("$stateChangeSuccess");
            }
        }])
        .constant('appSettings', appSettings)
        .factory('$exceptionHandler', ['$injector', 'brookson.logger', 'appSettings', ($injector, logger, appSettings) => (exception, cause) => {
            if (appSettings && appSettings.environment) {
                logger.error("Uncaught Exception - " + exception.message, exception.stack, cause);
            }
        }])
        ;
    ;
})();