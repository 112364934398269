module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/
    export interface ITaskAction {

        // ACTIONID
        actionId: string;
        // ACTION_WEB_TASK_NO
        action_Web_Task_no: string;
        // ACTIONTITLE
        actionTitle: string;
        // ACTIONDESCRIPTION
        actionDescription: string;
        // EXTENDEDACTIONDESCRIPTION
        extendedActionDescription: string;
        // RESPONSIBILITY
        responsibility: string;
        // DUEDATE
        dueDate: Date;
        // DEADLINE
        deadline: Date;
        // STATUS
        status: TaskStatus;
        // ACTIONORDER
        actionOrder: number;
        // ATTACHMENT
        attachment: string;
        // ISINWARNING
        isInWarning: boolean;
        // BUTTON1TEXT
        button1Text: string;
        // BUTTON2TEXT
        button2Text: string;
        // CATEGORYID
        categoryId: number;

        categoryDesc: string;

        documents: Array<any>;
    }

    export class TaskAction implements ITaskAction {


        // ACTIONID
        public actionId: string = null;
        // ACTION_WEB_TASK_NO
        public action_Web_Task_no: string = null;
        // ACTIONTITLE
        public actionTitle: string = null;
        // ACTIONDESCRIPTION
        public actionDescription: string = null;
        // EXTENDEDACTIONDESCRIPTION
        public extendedActionDescription: string = null;
        // RESPONSIBILITY
        public responsibility: string = null;
        // DUEDATE
        public dueDate: Date = null;
        // DEADLINE
        public deadline: Date = null;
        // STATUS
        public status: TaskStatus = null;
        // ACTIONORDER
        public actionOrder: number = 0;
        // ATTACHMENT
        public attachment: string = null;
        // ISINWARNING
        public isInWarning: boolean = false;
        // BUTTON1TEXT
        public button1Text: string = null;
        // BUTTON2TEXT
        public button2Text: string = null;
        // CATEGORYID
        public categoryId: number = 0;

        public categoryDesc: string = "";

        public documents: Array<any> = [];
        public closeable: boolean = false;
        public closeMessage: string = "";

        public taskCode: string = "";
        public taskTitle: string = "";
        public camelCase: string = "";
    }
    
    export enum TaskStatus {
        completed = 0,
        current = 1,
        future = 2,
        overdue = 3,
    }
}
