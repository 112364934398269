/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .factory('brookson.logger', logger);

    logger.$inject = ['$log', 'loggerSrv', 'logging.enums'];

    function logger($log, loggerSrv, loggingEnums) {
        var service = {
            error: error,
            info: info,
            success: success,
            warning: warning,
            log: log
        };

        return service;

        function error(title, data, cause) {
            $log.error('Error: ' + title + '\nStack: ' + data + '\nCause: ' + cause);
            loggerSrv.log(loggingEnums.LoggingLevel.ERROR, title, data, cause);
        }

        function info(title, data, cause) {
            $log.info('Error: ' + title + '\nStack: ' + data + '\nCause: ' + cause);
            loggerSrv.log(loggingEnums.LoggingLevel.INFO, title, data, cause);
        }

        function success(title, data, cause) {
            $log.info('Error: ' + title + '\nStack: ' + data + '\nCause: ' + cause);
            loggerSrv.log(loggingEnums.LoggingLevel.INFO, title, data, cause);
        }

        function warning(title, data, cause) {
            $log.warn('Error: ' + title + '\nStack: ' + data + '\nCause: ' + cause);
            loggerSrv.log(loggingEnums.LoggingLevel.WARN, title, data, cause);
        }

        function log(title, data, cause) {
            $log.log('Error: ' + title + '\nStack: ' + data + '\nCause: ' + cause);
            loggerSrv.log(loggingEnums.LoggingLevel.INFO, title, data, cause);
        }
    }
})();
