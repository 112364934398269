/// <reference path="../../_all.ts"/>

(() => {
    'use strict';

    angular
        .module('app.shared')
        .directive('brooksonInfiniteScroll', brooksonInfiniteScroll);

    brooksonInfiniteScroll.$inject = ['$window'];
    function brooksonInfiniteScroll($window) {
        // Usage:
        //
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'A',
            scope:{
                onScroll:"&",
                scrollBusy:"="
            }
        };
        return directive;

        function link(scope, elem, attrs) {
            $window = angular.element($window);
            var scrollDistance = 0;

            angular.element($window).bind('scroll', handler);
            scope.$on('$destroy', () => {
                    return $window.unbind('scroll', handler);
                    });
            function handler() {
                if (!scope.scrollBusy) {
                    var elementBottom, remaining, shouldScroll, windowBottom;
                    windowBottom = $window.height() + $window.scrollTop();
                    elementBottom = elem.offset().top + elem.height();
                    remaining = elementBottom - windowBottom;
                    shouldScroll = remaining <= $window.height() * scrollDistance;

                    if (shouldScroll) {
                        scope.$apply(scope.onScroll);
                    }
                }
            }
        }


    }

})();
