module Shared {
    export module AutoAllocation {
        export interface IAutoAllocationItem {
            id: number;
            memberId: number;
            company: string;
            keyword: string;
            categoryCode: string;
            reclaimVat: boolean;
            enabled: boolean;
            navCategory: any;
            createdBy: string;
            updatedBy: string;
            validationMessage: string;
            hasChanged: boolean;
            transactionMatches: MatchingTransactions;
        }

        export class AutoAllocationItem implements IAutoAllocationItem {
            id: number;
            memberId: number;
            company: string;
            keyword: string;
            categoryCode: string;
            reclaimVat: boolean;
            enabled: boolean;
            navCategory: any;
            createdBy: string;
            updatedBy: string;
            validationMessage: string;
            hasChanged: boolean;
            transactionMatches: MatchingTransactions;
        }

        export class MatchingTransactions {
            constructor() {
                this.count = 0;
                this.items = [];
            }
            count: number;
            items: Array<any>;
        }
    }
}