/// <reference path="./ExpenseType.ts"/>
/// <reference path="./ExpenseWarning.ts"/>

module Shared {
    export class CoreExpenseItem {
        date: Shared.BrooksonDate;
        isDeleted: boolean;
        expenseID: string;
        expenseType: Shared.ExpenseType;
        expenseMileageVehicle: Shared.ExpenseMileageVehicle;
        expenseItemId: number;
        description: string;
        expenseTypeId: string;
        vehicleRegistration: string;
        paymentCode: string;
        rate: number;
        net: number;
        vat: number;
        gross: number;
        total: number;
        status: string;
        tempID: string;
        markedAsDelete: boolean;
        typeObject: Shared.ExpenseType;
        vehicleObject: Shared.ExpenseMileageVehicle;
        type: string;
        changeId: number;
        hasValidVatReceipt: boolean;
        expenseWarning: ExpenseWarning;
        constructor() {
            this.expenseWarning = new ExpenseWarning();
        }
    }
}