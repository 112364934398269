/// <reference path="../../_all.ts"/>

(() => {
'use strict';

    angular
        .module('app.shared')
        .factory('brooksonDocumentPreview', brooksonDocumentPreview);

    brooksonDocumentPreview.$inject = ['documentStorage.enums','documentStorageSrv','screenSize','brookson.ui.image-preview.modal','FileSaver', 'brookson.pdf.modal', '$detection', '$window'];
    function brooksonDocumentPreview(enums, documentStorageSrv, screenSize, brooksonUiImagePreviewModal, FileSaver, brooksonPdfModal, $detection, $window) {
        var service = {
            previewDoc:previewDoc
        };

        return service;

        ////////////////
        function previewDoc(document) {
            if (document.dataSource === enums.DocumentDataSource.DROPBOX) {
                documentStorageSrv.getFullSizedImage(document).then((data) => {
                    brooksonUiImagePreviewModal.showImagePreviewModal(data);
                });
            } else {
                if (screenSize.is('md, lg')) {
                    brooksonPdfModal.view(document.documentId, document.path, callback);
                } else {
                    documentStorageSrv.getSelectedPDF(document.documentId, document.path).then((data) => {
                        var blobObject = new Blob([data], {type: 'application/pdf'});

                        // If it's iOS and particularly Safari, we can't get a new window with a blob... so do this...
                            FileSaver.saveAs(blobObject, document.path);
                    });
                }
            }

            document.lastOpened = moment();
        }
       function callback(id, path) {

            return documentStorageSrv.getSelectedPDF(id, path).then((data) => {
                return data;
            });
        }
    }
})();
