/// <reference path="../../../_all.ts"/>

module Shared {
    
    interface IBrooksonBankLinkTransactionsPaginationBindings {
        bankAccounts: Array<any>;
    }

    export class BrooksonBankLinkTransactionsPagination implements ng.IComponentOptions {
        public controller: Function = BrooksonBankLinkTransactionsPaginationController;
        public templateUrl: string = 'src/app/shared/views/bank/brookson.bankLink.transactions.pagination.html';
        public bindings: { [binding: string]: string } = {
            bankAccounts: '='
        };
    }

    class BrooksonBankLinkTransactionsPaginationController implements IBrooksonBankLinkTransactionsPaginationBindings {
        static $inject = ['bankLinkFactory'];

        public bankAccounts;

        constructor (
            public data
        ) { }
    }
}

angular
    .module('app.shared')
    .component('brooksonBankLinkTransactionsPagination', new Shared.BrooksonBankLinkTransactionsPagination());