module Shared {
    export class IndustryList {
        static aerospaceAndDefence: string = "Aerospace and Defence"; 
        static automotiveAndMotorsport: string = "Automotive and Motorsport"; 
        static buildingConstructionInfrastructure: string = "Building, Construction and Infrastructure"; 
        static businessServices: string = "Business Services"; 
        static energySectorOfficeBased: string = "Energy sector (office based)"; 
        static energySectorOffshoreSiteBased: string = "Energy sector (offshore/sitebased)"; 
        static financialService: string = "Financial Services"; 
        static informationTechnology: string = "Information Technology"; 
        static lifeSciences: string = "Life Sciences"; 
        static marineAndShipbuilding: string = "Marine and Shipbuilding"; 
        static mediaAndCreative: string = "Media and Creative"; 
        static medicalAndHealthcare: string = "Medical and Healthcare"; 
        static railAndTransportation: string = "Rail and Transportation"; 
        static telecommunications: string = "Telecommunications"; 
        static utilities: string = "Utilities"; 
        static other: string = "Other"; 

        static getArray() : Array<string> {
            return [IndustryList.aerospaceAndDefence, 
            IndustryList.automotiveAndMotorsport, 
            IndustryList.buildingConstructionInfrastructure, 
            IndustryList.businessServices, 
            IndustryList.energySectorOfficeBased, 
            IndustryList.energySectorOffshoreSiteBased, 
            IndustryList.financialService, 
            IndustryList.informationTechnology, 
            IndustryList.lifeSciences, 
            IndustryList.marineAndShipbuilding, 
            IndustryList.mediaAndCreative, 
            IndustryList.medicalAndHealthcare, 
            IndustryList.railAndTransportation, 
            IndustryList.telecommunications, 
            IndustryList.utilities, 
            IndustryList.other];
        }
    }
}