/// <reference path="../../../_all.ts"/>

module Shared {

    interface IBrooksonWidgetsBankLinkBindings {
        onComplete: Array<any>;
    }

    export class BrooksonWidgetsBankLink implements ng.IComponentOptions {
        public controller: Function = BrooksonWidgetsBankLinkController;
        public templateUrl: string = "src/app/shared/views/widgets/brookson.widgets.bank-link.html";
        public bindings: { [binding: string]: string } = {
            onComplete: '&'
        };
    }

    class BrooksonWidgetsBankLinkController implements IBrooksonWidgetsBankLinkBindings {
        static $inject = ['$q', '$timeout', 'growl', 'moneyManagerSrv', 'bankLinkSrv', 'bankLink.enums', 'brookson.navigation', 'brookson.utilities.date', 'dashboardService', 'bankLinkFactory'];

        public transactionsToAllocate: number = 0;
        public error: boolean = false;
        public showSpinner: boolean = false;
        public isBankLinkActionRequired: boolean = false;
        public showNewSetupText: boolean = false;
        public widgetName: string = "brookson-widgets-bank-link";
        public lastReconciledDate = null;
        public chart;

        public onComplete;

        constructor(
            private $q: ng.IQService,
            private $timeout: ng.ITimeoutService,
            private growl: ng.growl.IGrowlService,
            private moneyManagerSrv: Shared.IMoneyManagerService,
            private bankLinkSrv,
            private bankLinkEnums: Shared.IBankLinkEnums,
            private brooksonNavigation: Shared.IBrooksonNavigation,
            private dateUtils: Shared.IBrooksonUtilitiesDate,
            private dashboardService: Shared.IDashboardService,
            private data: Shared.IBankLinkFactory
        ) { }

        $onInit = (): void => {
            this.$q.all([
                this.data.getBankAccounts(),
                this.data.getTransactions(false),
                this.data.getCategories(),
                this.data.getTransactionTypes(),
                this.data.getMoneyManagerData()
            ]).then((data) => {

                if (this.data.transactions && this.data.transactions.length > 0) {
                    let transactions = angular.copy(this.data.transactions);

                    this.transactionsToAllocate = _.filter(transactions, {
                        transactionType: this.bankLinkEnums.TransactionType.UNALLOCATED
                    }).length;

                    let xAxisPoints = ['x'];

                    for (let i = 11; i >= 0; i--) {
                    xAxisPoints.push(moment().add(-i, 'months').startOf('month').format('MM-YYYY'));
                    }

                    _.forEach(transactions, (transaction): void => {
                    transaction.period = this.dateUtils.convertToMoment(transaction.transactionDate).format('MM-YYYY');
                    });

                    let groupedTransactionByBankAccount = _.groupBy(transactions, 'bankKey');

                    let accounts = [];

                    let chartData = _.map(groupedTransactionByBankAccount, (group: any, key) => {
                        let data;

                        // Account not already indexed
                        if (accounts.indexOf(group[0].bankName) === -1) {
                            data = [group[0].bankName];
                            accounts.push(group[0].bankName);
                        } else {
                            // Account already indexed, get a count and increment
                            let count = _.countBy(accounts)[group[0].bankName];

                            data = [group[0].bankName + ' ' + (count + 1)];
                        }

                        let transactionsGroupedByPeriod = _.groupBy(group, 'period');

                        _.forEach(xAxisPoints, (period) => {
                            if (period === "x") {
                                return;
                            }

                            let periodGrouping = <Array<any>>_.find(transactionsGroupedByPeriod, (g, key) => { return key === period; });

                            if (periodGrouping === undefined) {
                                if (data.length > 1) {
                                    data.push(_.last(data));
                                } else {
                                    data.push('0.00');
                                }
                            } else {
                                data.push(_.first(periodGrouping).balance.toFixed(2));
                            }

                        });

                        return data;
                    });

                    this.$timeout(() => {
                        this.chart = c3.generate({
                            bindto: '#bankLinkChart',
                            point: {
                                r: 6
                            },
                            data: {
                                columns: chartData
                            },
                            padding: {
                                right: 30
                            },
                            axis: {
                                x: {
                                    type: 'category',
                                    categories: xAxisPoints.splice(1, xAxisPoints.length),
                                    tick: {
                                        fit: true,
                                        rotate: 40,
                                        multiline: false
                                    }
                                },
                                y: {
                                    tick: {
                                        format: (d) => { return '£' + d; }
                                    }
                                }
                            },
                            onrendered: () => { this.onComplete(); }
                        });
                    }, 10);
                }
            }, () => {
                this.error = true;
            }).then(() => {
                this.showSpinner = false;
                this.$timeout(() => { this.onComplete(); });
            });
        }
    }
}

angular
    .module('app.shared')
    .component('brooksonWidgetsBankLink', new Shared.BrooksonWidgetsBankLink());
