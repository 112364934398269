
module Shared {
    export class IntroVideo {
        public videoId: string;
        public thumbnailFilename: string;
        public description: string;
        public order: number;
        public videoUrl: string;
        public imageUrl: string;
        public thumbnailImage: string;
        public accountantCalenderUrl: string;
        public advisoryDocUrl: string;
    }
}