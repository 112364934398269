
/// <reference path="../enums/FuelType.ts"/>

module Shared {
    // $Classes/Enums/Interfaces(filter)[template][separator]
    // filter (optional): Matches the name or full name of the current item. * = match any, wrap in [] to match attributes or prefix with : to match interfaces or base classes.
    // template: The template to repeat for each matched item
    // separator (optional): A separator template that is placed between all templates e.g. $Properties[public $name: $Type][, ]

    // More info: http://frhagn.github.io/Typewriter/

    
    export class ExpenseMileageVehicle  {
        // VEHICLEMAKE
        public vehicleMake: string = null;
        // VEHICLEMODEL
        public vehicleModel: string = null;
        // FUELTYPE
        public fuelType: string = null;
        // ENGINECC
        public engineCC: number = null;
        // REGISTRATION
        public registration: string = null;
        // STARTDATE
        public startDate: Date = null;
        // ENDDATE
        public endDate: Date = null;
        // isSelected
        public isSelected: boolean;
        // Personal or business
        public privateVehicle: any;
    }
}