/// <reference path="./../models/ir35DetailCapture/requiresCompletion.ts" />

module Shared {
    export interface IIR35DetailCaptureService {
        ir35DetailCaptureRequiresCompletion: Shared.IR35DetailCaptureRequiresCompletion;
        getIR35DetailCaptureOutcome(silentMode?: boolean): ng.IPromise<Shared.IR35DetailCaptureRequiresCompletion>;
        postIR35DetailCapture(sector: Shared.IR35DetailCaptureButtonPressed): ng.IPromise<Shared.IR35DetailCaptureButtonPressed>;
    }

    export class IR35DetailCaptureService implements IIR35DetailCaptureService {

        public ir35DetailCaptureRequiresCompletion: Shared.IR35DetailCaptureRequiresCompletion;

        static $inject = ['$http'];

        constructor(
            private $http: Shared.IBrooksonHttp
        ) { }

        // Returns whether the member is required to complete the IR35 Detail Capture form or not
        getIR35DetailCaptureOutcome = (silentMode?: boolean): ng.IPromise<Shared.IR35DetailCaptureRequiresCompletion> => {
            return this.$http.get(`api/IR35/ir35DetailCapture`, {
                silentMode: silentMode ? silentMode : false
            }).then((response: ng.IHttpPromiseCallbackArg<Shared.IR35DetailCaptureRequiresCompletion>) => {
                this.ir35DetailCaptureRequiresCompletion = response.data;

                return response.data;
            });
        }

        // Posts back to the server what the user chose
        postIR35DetailCapture = (sector: Shared.IR35DetailCaptureButtonPressed): ng.IPromise<Shared.IR35DetailCaptureButtonPressed> => {
            return this.$http.post(`api/IR35/ir35DetailCapture`, sector).then((response: ng.IHttpPromiseCallbackArg<Shared.IR35DetailCaptureButtonPressed>) => {
                return response.data;
            });
        }
    }
}

angular.module('app.shared').service('ir35DetailCaptureService', Shared.IR35DetailCaptureService);