/// <reference path="../../_all.ts"/>

var shared = angular.module('app.shared');

shared.directive('brooksonBackTop', () => {
    return {
        restrict: 'AE',
        templateUrl: 'src/app/shared/views/ui/brookson.ui.back-top.html'
    };
});

shared.directive('backTop', ['$window', 'brookson.ui.scroll-top', ($window, brooksonScrollTop) => {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        template: '<div id="backtop" class="light"><button class="btn {{theme}} btn-circle"><i class="fa {{icon}}"></i></button></div>',
        scope: {
            icon: "@buttonIcon",
            speed: "@scrollSpeed",
            theme: "@buttonTheme",
            yTrigger: "@yTrigger"
        },
        link: (scope, element) => {

            scope.text = scope.text || 'Scroll top';
            scope.speed = parseInt(scope.speed, 10) || 300;
            scope.theme = scope.theme || 'light';
            scope.button = element.find('button');

            scope.button.on('click', () => {
                brooksonScrollTop.smoothScroll(scope.speed);
                element.removeClass('show');
            });

            $window.addEventListener('scroll', () => {
                var top = ($window.scrollY || $window.pageYOffset);
                if (top >= (scope.yTrigger)) {
                    element.addClass('show');
                } else {
                    element.removeClass('show');
                }
            });
        }
    };

}]);
