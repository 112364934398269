module Shared {
    export class PersonalTaxCalcTable {
    connectUser: string;
    businessID: string;
    personID: string;
    primaryDividend: number;
    pscPayeIncomeYtd: number;
    estimated_PSC_income_still_to_be_declared_by_5th_April: number;
    totalPscPayeIncome: number;
    umbrella_Gross_Pay_YTD: number;
    estimated_UMB_income_still_to_be_declared_by_5th_April: number;
    total_UMB_PAYE_income: number;
    totalPayeIncome: number;
    dividends_declared_in_management_accounts: number;
    unconfirmed_cash_payments_assumed_to_be_dividends: number;
    total_of_declared_and_assumed_dividends_already_taken: number;
    number_of_weeks_past: number;
    number_of_weeks_left: number;
    estimated_dividends_still_to_be_declared: number;
    total_Dividends: number;
    total_other_income: number;
    estimated_Total_Gross_Income: number;
    your_personal_allowance_: number;
    basic_rate_tax_band: number;
    personal_pension_contributions: number;
    reductionInPersonalAllowance: number;
    your_estimated_income_point_where_higher_rate_tax_becomes_payable_: number;
    estimated_total_income_: number;
    income_subject_to_higher_rate_tax: number;
    income_subject_to_additional_rate_tax: number;
    total_Income_subject_to_higher_and_additional_rate_tax: number;
    createdDate: string;
    section: string;
    subSection: string;
    order: number;
    }
}