/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetUmbrellaWhatsApp', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.umbrella-whats-app.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetUmbrellaWhatsApp
    });

    brooksonWidgetUmbrellaWhatsApp.$inject = ['$timeout', 'brookson.navigation', 'dashboardService'];

    function brooksonWidgetUmbrellaWhatsApp($timeout, brooksonNavigation, _dashboardService) {

        var vm = this;

        vm.brooksonNavigation = brooksonNavigation;
        vm.widgetName = "brookson-widget-umbrella-whats-app";
        
    }
})();
