/// <reference path="../../../_all.ts"/>

(() => {
    'use strict';

    angular.module('app.shared').component('brooksonWidgetRemusRewardsButton', {
        templateUrl: 'src/app/shared/views/widgets/brookson.widgets.remus-rewards-button.html',
        bindings: {
            onComplete: '&'
        },
        controller: brooksonWidgetRemusRewardsButton
    });

    brooksonWidgetRemusRewardsButton.$inject = ['remusRewardSrv', '$timeout', 'brookson.navigation', 'dashboardService'];

    function brooksonWidgetRemusRewardsButton(remusRewardSrv, $timeout, brooksonNavigation, _dashboardService) {

        var vm = this;

        vm.brooksonNavigation = brooksonNavigation;
        vm.showSpinner = true;
        vm.error = false;
        vm.dashboardService = _dashboardService;
        vm.cidValue = '';
        vm.dataValue = '';
        vm.isUmbrellaPlus = false;
        vm.widgetName = "brookson-widget-remus-rewards";
        

        vm.submitRemusRewardForm = () => {
            var form = angular.element('#remusRewardForm');
            form.submit();
        };

        function getRemusRewards() {
            vm.showSpinner = false;
            remusRewardSrv.getRemusRewardsUrlParameters().then((data) => {
                vm.showSpinner = false;
                vm.cidValue = data.cidValue;
                vm.dataValue = data.dataValue;
            }, () => {
                vm.error = true;
            }).then(() => {
                vm.showSpinner = false;
                $timeout(() => { vm.onComplete(); });
            });
        }

        function isUserUmbrellaPlus() {
            console.log('isUserUmbrellaPlus');
            remusRewardSrv.isUserUmbrellaPlus().then((data) => {
                vm.isUmbrellaPlus = data;
            });
        }

        function activate() {
            getRemusRewards();
            isUserUmbrellaPlus();
        }

        activate();
    }
})();
