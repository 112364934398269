/// <reference path="../../_all.ts"/>
/// <reference path="../../shared/models/VatRateConfig.ts"/>

module Shared {

    export interface IBankLinkSrv {
        getBankAccounts(silentMode?): ng.IPromise<any>;
        getCategories(silentMode?, transactionType?): ng.IPromise<any>;
        addYodleeAccount(silentMode?): ng.IPromise<any>;
        editYodleeAccount(id, silentMode?): ng.IPromise<any>;
        refreshYodleeAccount(id, silentMode?): ng.IPromise<any>;
        isBankEnabled(silentMode?): ng.IPromise<any>;
        isCsvUploadRequired(silentMode?): ng.IPromise<any>;
        isBankActionRequired(silentMode?): ng.IPromise<any>;
        updateTransactions(transactions, silentMode?): ng.IPromise<any>;
        getAllVatRatesConfig(silentMode?: boolean): ng.IPromise<Array<VatRateConfig>>;
        cancelBankStatementBatch(id, silentMode?: boolean);
        clearBankStatementBatch(id, silentMode?: boolean);
        submitBankStatementBatch(id, bankId, reconciliationDate): ng.IPromise<any>;
        updateBankStatementDate(bankId, reconciliationDate): ng.IPromise<any>;
        getLastFiveBankTransaction(bankId, silentMode?: boolean):ng.IPromise<any>;
    }

    export class BankLinkSrv implements IBankLinkSrv {
        static $inject = ['$http'];

        constructor (
            public $http
        ) { }

        public getBankAccounts = (silentMode?: boolean) => {
            return this.$http.get(`api/BankLink/GetBankAccounts`, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public getCategories = (silentMode?: boolean, transactionType?: Shared.TransactionTypes) => {
            let url = `api/BankLink/GetCategories`;
            
            if (!_.isUndefined(transactionType)) {
                url = `${url}?transactionType=${transactionType}`;
            };

            return this.$http.get(url, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public addYodleeAccount = (silentMode?: boolean) => {
            return this.$http.get('api/BankLink/AddYodleeAccount', {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public editYodleeAccount = (id, silentMode?: boolean) => {
            return this.$http.get('api/BankLink/EditYodleeAccount?id=' + id, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public refreshYodleeAccount = (id, silentMode?: boolean) => {
            return this.$http.get('api/BankLink/RefreshYodleeAccount?id=' + id, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public isBankEnabled = (silentMode?: boolean) => {
            return this.$http.get('api/BankLink/IsBankLinkEnabled', {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public isBankActionRequired = (silentMode?: boolean) => {
            return this.$http.get('api/BankLink/IsBankLinkActionRequired', {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }
        
        public isCsvUploadRequired = (silentMode?: boolean) => {
            return this.$http.get('api/BankLink/IsCsvUploadRequired', {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }
        
        public cancelBankStatementBatch = (id, silentMode?: boolean) => {
            return this.$http.get('api/BankLink/CancelBankStatementBatch?id=' + id, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public clearBankStatementBatch = (id, silentMode?: boolean) => {
            return this.$http.get('api/BankLink/ClearBankStatementBatch?id=' + id, {
                silentMode: silentMode ? silentMode : false
            }).then(response => response.data);
        }

        public submitBankStatementBatch = (id, bankId, reconciliationDate) => {
            var url = "api/BankLink/SubmitBankStatementBatch";

            var requestData = {
                id: id !== undefined ? id : "",
                bankId: bankId !== undefined ? bankId : "", 
                reconciliationDate: reconciliationDate !== undefined ? reconciliationDate : "", 
            };

            return this.$http.get(url, {
                cache: true,
                params: requestData,
            }).then((response) => {
                return response.data;
            });
        }

        public updateBankStatementDate = (bankId, reconciliationDate) => {
            var url = "api/BankLink/UpdateBankStatementDate";

            var requestData = {
                bankId: bankId !== undefined ? bankId : "", 
                reconciliationDate: reconciliationDate !== undefined ? reconciliationDate : "", 
            };

            return this.$http.get(url, {
                cache: true,
                params: requestData,
            }).then((response) => {
                return response.data;
            });
        }

        public getLastFiveBankTransaction = (bankAccountKey, silentMode): ng.IPromise<Array<any>> => {
            var url = "api/BankLink/GetLastFiveBankTransaction";

            var requestData = {
                bankAccountKey: bankAccountKey !== undefined ? bankAccountKey : ""
            };

            return this.$http.get(url, {
                cache: true,
                params: requestData,
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return response.data;
            });
        }

        public updateTransactions = (transactions, silentMode?: boolean) => {
            var config = {
                silentMode: silentMode ? silentMode : false
            };

            return this.$http.post('api/MoneyManager/UpdateTransactions', transactions, config)
              .then(response => {
                return response.data;
              });
        }

        public getAllVatRatesConfig = (silentMode?: boolean): ng.IPromise<Array<VatRateConfig>> => {
            const url = "api/Expenses/GetExpenseVatConfig";

            return this.$http.get(url, {
                cache: "true",
                silentMode: silentMode ? silentMode : false
            }).then((response) => {
                return _.map(response.data,
                    (item: any) => {
                        let rate = new VatRateConfig();
                        rate.expenseCode = item.expenseCode;
                        rate.vatRate = item.vatRate;
                        rate.effectiveDate = new Date(item.effectiveDate);
                        rate.endDate = new Date(item.endDate);
                        return rate;
                    });
            });
        }
    }
}

angular
    .module('app.shared')
    .service('bankLinkSrv', Shared.BankLinkSrv);