module Shared {
    export class BankTable {
    name: string;
    bankID: string;
    reconciliationDate: string;
    bankAccountNo_: string;
    dDEReferenceNo_: string;
    lastImportBalance: number;
    type: number;
    viewAccessStatus: string;
    recStatus: string;
    businessID: string;
    personID: string;
    connectUser: string;
    createdDate: string;
    section: string;
    subSection: string;
    order: number;
}
}